<topbar>
    <h1 topbar-title>{{ company.name }}</h1>
</topbar>

<page [pTitle]="company.name">
    <layout>
        <layout-top *ngIf="company.insurance_code99">
            <alert pTitle="Code 99" pVariant="error">
                This company has been flagged with a code99 in the past.
            </alert>
        </layout-top>

        <layout-section>
            <card pTitle="Details">
                <card-section>
                    <list>
                        <list-item
                            pLabel="Account Number"
                            *ngIf="company.account_number"
                        >
                            {{ company.account_number }}
                        </list-item>

                        <list-item
                            pLabel="Vat Number"
                            *ngIf="company.vat_number"
                        >
                            {{ company.vat_number }}
                        </list-item>

                        <list-item
                            pLabel="Year Established"
                            *ngIf="company.established"
                        >
                            {{ company.established }}
                        </list-item>
                    </list>
                </card-section>

                <card-section pTitle="Address Details">
                    <table class="content-card-table">
                        <tr *ngFor="let addressDetails of company.addresses">
                            <td>
                                {{ addressDetails.line1
                                }}{{
                                    addressDetails.line2
                                        ? ', ' + addressDetails.line2
                                        : ''
                                }}{{
                                    addressDetails.line3
                                        ? ', ' + addressDetails.line3
                                        : ''
                                }}
                            </td>
                            <td>{{ addressDetails.city }}</td>
                            <td>
                                {{ addressDetails.country }}
                            </td>
                            <td>
                                {{ addressDetails.postcode }}
                            </td>
                            <td>
                                {{
                                    addressDetails.from_date
                                        | date: 'dd/MM/yyyy'
                                }}
                            </td>
                            <td>
                                {{
                                    addressDetails.to_date | date: 'dd/MM/yyyy'
                                }}
                            </td>
                        </tr>
                    </table>
                </card-section>

                <card-section pTitle="Registered Company Details">
                    <list>
                        <list-item
                            pLabel="Type"
                            *ngIf="company.registered_type"
                        >
                            {{ company.registered_type }}
                            {{
                                company.registered_type == 'Other'
                                    ? ' - ' + company.registered_type_other
                                    : ''
                            }}
                        </list-item>

                        <list-item
                            pLabel="Number"
                            *ngIf="company.registered_number"
                        >
                            {{ company.registered_number }}
                        </list-item>

                        <list-item
                            pLabel="Status"
                            *ngIf="company.registered_status"
                        >
                            {{ company.registered_status }}
                        </list-item>

                        <list-item
                            pLabel="Address"
                            *ngIf="company.registered_address"
                        >
                            <table class="content-card-table">
                                <tr>
                                    <td>
                                        {{ company.registered_address.line1 }}
                                        {{
                                            company.registered_address.line2
                                                ? ', ' +
                                                  company.registered_address
                                                      .line2
                                                : ''
                                        }}
                                        {{
                                            company.registered_address.line3
                                                ? ', ' +
                                                  company.registered_address
                                                      .line3
                                                : ''
                                        }}
                                    </td>
                                    <td>
                                        {{ company.registered_address.city }}
                                    </td>
                                    <td>
                                        {{ company.registered_address.country }}
                                    </td>
                                    <td>
                                        {{
                                            company.registered_address.postcode
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </list-item>
                    </list>
                </card-section>
            </card>

            <card pTitle="Employees">
                <item-list [pDataSource]="company.people">
                    <ng-template pItemListDef let-employee>
                        <item-list-item
                            [pLink]="[
                                '/',
                                'customers',
                                'people',
                                employee.person.id
                            ]"
                        >
                            <h4>
                                {{ employee.person.forename }}
                                {{ employee.person.surname }}
                            </h4>
                            <div>{{ employee.job_title }}</div>
                        </item-list-item>
                    </ng-template>
                </item-list>
            </card>

            <card pTitle="Accounting Details">
                <card-section>
                    <stack pDistribution="equalSpacing">
                        <stack-item>
                            Sage account link
                        </stack-item>

                        <button
                            pButton
                            pSize="small"
                            type="button"
                            *hasPermission="
                                'customer-sage-link';
                                andIf: !company.sage_account_id
                            "
                            (click)="openLinkClick()"
                        >
                            Link
                        </button>

                        <button
                            pButton
                            pVariant="error"
                            pSize="small"
                            type="button"
                            *hasPermission="
                                'customer-sage-unlink';
                                andIf: !!company.sage_account_id
                            "
                            (click)="openUnlinkClick()"
                        >
                            Unlink
                        </button>
                    </stack>
                </card-section>

                <card-section *ngIf="company.sage_account_id">
                    <customers-sage-summary
                        [sageAccountId]="company.sage_account_id"
                    ></customers-sage-summary>
                </card-section>
            </card>

            <activities-short-list
                prefix="companies"
                [id]="company.id"
            ></activities-short-list>
        </layout-section>

        <layout-section pSecondary>
            <card pTitle="Contact Details">
                <ul class="list-styled">
                    <li
                        class="list-styled__item"
                        *ngFor="let contactDetail of company.contact_details"
                    >
                        <a
                            class="list-styled__link"
                            href="{{ getContactLink(contactDetail) }}"
                        >
                            <small class="badge">{{
                                contactDetail.type
                            }}</small>
                            {{ contactDetail.value }}

                            <ul
                                class="list-styled__actions"
                                *ngIf="contactDetail.is_primary"
                            >
                                <li class="list-styled__action">
                                    <fa-icon
                                        [icon]="['fas', 'key']"
                                        matTooltip="Primary contact information."
                                    ></fa-icon>
                                </li>
                            </ul>
                        </a>
                    </li>
                </ul>
            </card>
        </layout-section>
    </layout>
</page>
