import {
    forwardRef,
    Component,
    Input,
    ElementRef,
    ViewChild,
    HostBinding,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
    Validators,
    NG_VALIDATORS,
    Validator,
} from '@angular/forms';
import moment from 'moment';
import { Moment, MomentInput } from 'moment';
import {
    MatDatepickerInputEvent,
    MatDatepicker,
} from '@angular/material/datepicker';
import { coerceBooleanProperty } from '../../utils';

/**
 * The custom date picker input provider.
 */
export const CUSTOM_DATE_TIME_PICKER_VALIDATORS: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomDatepickerComponent),
    multi: true,
};

/**
 * The custom date time picker input provider.
 */
export const CUSTOM_DATE_TIME_PICKER_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomDatepickerComponent),
    multi: true,
};

/**
 * The custom date picker component used to change a date and time.
 */
@Component({
    selector: 'custom-date-time-picker',
    templateUrl: './custom-datepicker.component.html',
    providers: [
        CUSTOM_DATE_TIME_PICKER_VALUE_ACCESSOR,
        CUSTOM_DATE_TIME_PICKER_VALIDATORS,
    ],
})
export class CustomDatepickerComponent
    implements ControlValueAccessor, Validator {

    /**
     * The focused input.
     */
    @ViewChild('elementToFocus', { static: true })
    _input: ElementRef;

    /**
     * Gets the array of minute units input from the parent.
     */
    @Input()
    minuteUnits: string[];

    @Input()
    placeholder: string = '';

    /**
     * The full cloned date.
     */
    _dateValue: any;

    @Input()
    startDate: Date = null;

    /**
     * Gets the full date.
     */
    get dateValue() {
        return this._dateValue;
    }

    /**
     * Sets the full date and updates it.
     */
    set dateValue(val) {
        if (this._dateValue !== val) {
            this._dateValue = val;
            this.updateChanges();
        }
    }

    /**
     * The time input value.
     */
    _timeValue = '00:00';

    /**
     * Gets the value of the time.
     */
    get timeValue() {
        return this._timeValue;
    }

    /**
     * Sets the value of the time and updates it.
     */
    set timeValue(time: string) {
        if (this._timeValue !== time) {
            this._timeValue = time;
            this.updateChanges();
        }
    }

    /**
     * The date and time.
     */
    _value: string;

    /**
     * Gets the date and time as a single string.
     */
    get value() {
        return this._value;
    }

    _rawValue = "";

    /**
     * Sets the date and time as a single string. Then updates the date.
     */
    @Input()
    set value(v: string) {
        const date = moment(v);

        if (this._value !== date.format('YYYY-MM-DDTHH:mm:ss')) {
            this.updateChildValues(v);
            this.propagateChange(this._value);
            this.dateChanged.emit(this._value);
        }
    }

    @Output()
    dateChanged = new EventEmitter<string>();

    _hideTimePicker = false;
    /**
     * Determines wether to show the time picker or not.
     */
    get hideTimePicker() {
        return this._hideTimePicker;
    }

    @Input()
    set hideTimePicker(value: boolean) {
        const newValue = coerceBooleanProperty(value);

        if (this._hideTimePicker !== newValue) {
            this._hideTimePicker = newValue;
        }
    }

    /**
     * Hard min of date that can be selected.
     */
    private _globalMinDate = '1800-01-01';

    /**
     * The minimum date allowed.
     */
    private _minDate: MomentInput;

    /**
     * Gets the minimum date.
     */
    get minDate() {
        return this._minDate;
    }

    /**
     * Sets the minimum date and checks if it's valid.
     */
    @Input()
    set minDate(min: MomentInput) {
        if (min !== this._minDate) {
            this._minDate = min;
            this._validatorOnChange();
            // this.updateChanges();
        }
    }

    /**
     * The maximum date allowed.
     */
    private _maxDate: MomentInput;

    /**
     * Gets the maximum date.
     */
    get maxDate() {
        return this._maxDate;
    }

    /**
     * Sets the maximum date and checks if it's valid.
     */
    @Input()
    set maxDate(max: MomentInput) {
        if (!max) {
            this._maxDate = null;
            return;
        }
        const maxMoment = moment(max);
        if (max !== this._maxDate) {
            this._maxDate = maxMoment;
            this._validatorOnChange();
            // this.updateChanges();
        }
    }

    /**
     * Gets and sets the disabled value to see if the date picker
     * should be disabled.
     */
    @Input()
    get disabled(): boolean {
        return !!this._disabled;
    }

    set disabled(value: boolean) {
        const newValue = coerceBooleanProperty(value);

        if (this._disabled !== newValue) {
            this._disabled = newValue;
        }
    }

    /**
     * The disabled state of the datepicker.
     */
    private _disabled: boolean;

    @Input()
    hideCalendar = false;

    @HostBinding('class.input-datetime-picker')
    classNamesPicker = true;

    // /**
    //  * Opens the passed in date picker.
    //  * @param picker The passed in date picker.
    //  */
    openCalendar(picker: MatDatepicker<any>) {
        if (this.hideCalendar){
            return;
        }
        
        picker.open();

        // use the setTimeout to push the focus capturing
        // into the event loop and put it off until the calendar
        // routines finish.
        // Otherwise the calendar will get the focus back
        setTimeout(() => this._input.nativeElement.focus());
    }

    /**
     * Closes the date picker.
     */
    eventCloseHandler(e) {
        this._onTouched();
        setTimeout(() => this._input.nativeElement.blur());
    }

    /**
     * Updates the date and time data when this method is called.
     */
    updateChanges() {

        const prevValue = !this.value ? null : this.value.toString();

        if (this.dateValue) {
            this._rawValue = this.dateValue;
            var date = moment(this.dateValue);

            // Checks that there is a time if not sets the times default.
            if (this.timeValue) {
                const timeParts = this.timeValue
                    .split(':')
                    .map(t => parseInt(t, 10));
                date.hours(timeParts[0]).minutes(timeParts[1]);
            } else {
                this._timeValue = '00:00';
            }

            /**
             * Formats the date and time into a single string.
             */
            this._value = date.format('YYYY-MM-DDTHH:mm:ss');
        } else {
            this._value = null;
            this._rawValue = null;
        }

        // Sends the saved changes back to the parent.
        if (prevValue != this._value) {
            this.propagateChange(this._value);
            this.dateChanged.emit(this._value);
        }

        this._onTouched();
    }

    /**
     * @inheritDoc
     */
    writeValue(value: MomentInput) {
        this.updateChildValues(value);
    }

    /**
     * Upadtes the value of the child inputs to the value passed in.
     * @param value The date and time value.
     */
    updateChildValues(value: any) {
        const date = moment(value);
        if (value && date.isValid()) {
            this._value = date.format('YYYY-MM-DDTHH:mm:ss');
            this._dateValue = new Date(value);
            this._timeValue = date.format('HH:mm');
        } else {
            this.clearValues();
        }
    }

    /**
     * Sets the date and time values to null.
     */
    clearValues() {
        this._dateValue = null;
        this._timeValue = null;
        this._value = null;
    }

    /**
     * When called sends the value passed in to the parent.
     */
    propagateChange = (_: string) => {};

    /**
     * Checks that the values are valid when there changed.
     */
    _validatorOnChange = () => {};

    /**
     * Checks that the input has been touched.
     */
    _onTouched = () => {};

    /**
     * @inheritDoc
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn) {
        this._onTouched = fn;
    }

    /** @docs-private */
    registerOnValidatorChange(fn: () => void): void {
        this._validatorOnChange = fn;
    }

    /** @docs-private */
    validate(c: AbstractControl): ValidationErrors | null {
        return this._validator ? this._validator(c) : null;
    }

    /** The form control validator for the min date. */
    private _minValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const value = control.value
            ? this._getValidDateOrNull(moment.utc(control.value))
            : null;

        return !value ||
            ((!this.minDate || value.isSameOrAfter(this.minDate)) &&
                value.isSameOrAfter(this._globalMinDate))
            ? null
            : { customDateTimePickerMin: { min: this.minDate, actual: value } };
    };

    /** The form control validator for the max date. */
    private _maxValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const value = control.value
            ? this._getValidDateOrNull(moment.utc(control.value))
            : null;

        return !this.maxDate || !value || value.isSameOrBefore(this.maxDate)
            ? null
            : {
                  customDateTimePickerMax: {
                      maxmin: this.maxDate,
                      actual: value,
                  },
              };
    };

    /** The combined form control validator for this input. */
    private _validator: ValidatorFn | null = Validators.compose([
        this._minValidator,
        this._maxValidator,
    ]);

    /**
     * Checks that the date is valid or sets it to null.
     * @param obj The date being checked.
     */
    private _getValidDateOrNull(obj: Moment) {         
        return obj.isValid() ? obj : null;
    }

    get datepickerVisibility(){
        return this.hideCalendar ? "hidden" : "none";
    }

    isNumeric(str) {
        return !isNaN(parseFloat(str)) && isFinite(str) && str.indexOf(".") == -1;
    }

    dateFormatIsValid(dateString: string) {
        if (dateString == null || dateString.length === 0){
            return { isValid: false };
        }

        return { isValid: moment(dateString).isValid()};
    }

    dateIsWithinRange(dateString: string){

        const date = moment(dateString);
        const lowerBoundValidationPassed = moment(this.minDate).isValid() ? date.isSameOrAfter(this._globalMinDate) : date.isSameOrAfter(this.minDate);
        const upperBoundValidationPassed = this.maxDate === null ? true : date.isSameOrBefore(this.maxDate);

        return lowerBoundValidationPassed && upperBoundValidationPassed;
    }

    get getReadableDate() {

        const formatValidationResult = this.dateFormatIsValid(this._rawValue);
        const dateIsValid = formatValidationResult.isValid && this.dateIsWithinRange(this._rawValue);

        const dateIsNullOrEmpty = this._rawValue === null || this._rawValue.length === 0;

        const dateText =  !dateIsNullOrEmpty && dateIsValid ? moment(this._rawValue).format('MMMM Do YYYY') : "";

        return dateText;
    }
}
