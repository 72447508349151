import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import { coerceBooleanProperty } from '../../utils';
import { FileExtensions } from './file-extensions';

export const FILE_VALIDATORS: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FileComponent),
    multi: true,
};

export const FILE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileComponent),
    multi: true,
};

@Component({
    selector: 'file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss'],
    providers: [FILE_VALUE_ACCESSOR, FILE_VALIDATORS],
})
export class FileComponent implements ControlValueAccessor, Validator {
    private _files: File[];

    get files() {
        return this._files;
    }

    set files(files: File[]) {
        this._files = files;
        this._onChange(this.files);
    }

    private _multiple = false;

    @Input()
    set multiple(value: any) {
        const newValue = coerceBooleanProperty(value);
        if (this._multiple != newValue) {
            this._multiple = newValue;
        }
    }

    get multiple() {
        return this._multiple;
    }

    private _acceptedExtensions: string[] = FileExtensions.all;

    @Input()
    set acceptedExtensions(acceptedExt: string[]) {
        this._acceptedExtensions = acceptedExt;
        this._validatorOnChange();
    }

    @Input()
    uniqueFiles = true;

    @ViewChild('input', { static: true })
    inputVariable: any;

    private _onChange: (files: File[]) => void = () => {};

    private _validatorOnChange = () => {};

    handleFileInput(files: FileList) {
        let _files = [];
        if (this.multiple && this.files) {
            _files = [...this.files];
        }

        for (let f = 0; f < files.length; f++) {
            const fi = files.item(f);
            _files.push(fi);
        }

        this.files = _files;
        this._clearInput();
    }

    clearFiles() {
        this.files = null;
    }

    clearFile(fileIndex: number) {
        const files = [...this.files];
        files.splice(fileIndex, 1);
        this.files = files.length > 0 ? files : null;
    }

    getIcon(mimeType: string) {
        return FileExtensions.getIcon(mimeType);
    }

    writeValue(files: File[]) {
        if (files && files.length > 0) {
            this._files = files;
        } else {
            this._files = null;
        }
    }

    registerOnChange(onChange: (files: File[]) => void) {
        this._onChange = onChange;
    }

    registerOnTouched(fn: any): void {}

    registerOnValidatorChange(fn: () => void): void {
        this._validatorOnChange = fn;
    }

    /** @docs-private */
    validate(c: AbstractControl): ValidationErrors | null {
        return this._validator ? this._validator(c) : null;
    }

    private _clearInput() {
        this.inputVariable.nativeElement.value = '';
    }

    private _uniqueNameValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const value: File[] = control.value ? control.value : null;

        // Dont validate if theres no value or the check is disabled.
        if (!this.uniqueFiles || !this.multiple || !value) {
            return null;
        }

        const counts: { [name: string]: number } = {};
        let moreCount: any[];

        for (let f = 0; f < value.length; f++) {
            const file = value[f];
            counts[file.name] = counts[file.name] ? counts[file.name] + 1 : 1;
        }

        moreCount = map(
            pickBy(counts, (v, key) => {
                return v > 1;
            }),
            (v, key) => ({
                name: key,
                count: v,
            }),
        );

        return !moreCount || moreCount.length < 1
            ? null
            : { uniqueNames: moreCount };
    };

    private _fileExtensionValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const value: File[] = control.value ? control.value : null;

        if (
            !value ||
            !this._acceptedExtensions ||
            isEmpty(this._acceptedExtensions)
        ) {
            return null;
        }

        let invalidFilename = null;
        for (let f = 0; f < value.length; f++) {
            const filename = value[f].name;
            const ext = filename
                .split('.')
                .pop()
                .toLowerCase();
            const matches =
                this._acceptedExtensions.filter(aExt => aExt == ext).length > 0;
            if (!matches) {
                invalidFilename = filename;
                break;
            }
        }

        return !invalidFilename
            ? null
            : {
                  invalidExtension: {
                      accepted: this._acceptedExtensions,
                      filename: invalidFilename,
                  },
              };
    };

    private _validator: ValidatorFn | null = Validators.compose([
        this._uniqueNameValidator,
        this._fileExtensionValidator,
    ]);
}
