<div class="dialog-container">
    <button
        aria-label="Dismiss"
        class="dialog-close"
        mat-dialog-close
        tabindex="-1"
    ></button>

    <div>
        <h3 class="dialog-heading">Contact Details</h3>
    </div>
    <form #CustomersContactDialog="ngForm" (validSubmit)="confirm($event)">
        <div mat-dialog-content>
            <ul class="form">
                <li class="form__item">
                    <label class="input-label" for="agreement_number"
                        >Contact Type</label
                    >

                    <div class="input-select">
                        <mat-select
                            id="type"
                            name="type"
                            [(ngModel)]="type"
                            required
                            #contactType="ngModel"
                        >
                            <mat-option></mat-option>
                            <mat-option
                                *ngFor="
                                    let contactType of contactTypes;
                                    let i = index
                                "
                                [value]="i"
                                >{{ contactType.label }}</mat-option
                            >
                        </mat-select>
                    </div>

                    <div class="input-errors" [ngxErrors]="contactType">
                        <label
                            for="company_registered_type"
                            ngxError="required"
                            class="input-error"
                        >
                            Please select a contact type.
                        </label>
                    </div>
                </li>

                <li class="form__item" *ngIf="type != null">
                    <label class="input-label" for="contact_value">{{
                        contactTypes[type].label
                    }}</label>
                    <input
                        type="text"
                        name="contact_value"
                        id="contact_value"
                        required
                        [(ngModel)]="contactDetail.value"
                        [pattern]="contactTypes[type].pattern"
                        #contactValue="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="contactValue">
                        <label
                            for="contact_value"
                            [ngxError]="['required', 'pattern']"
                            class="input-error"
                        >
                            Please enter a valid {{ contactTypes[type].label }}
                        </label>
                    </div>
                </li>

                <li>
                    <label class="input-label">Primary</label>

                    <label class="input-checkbox">
                        <input
                            type="radio"
                            [value]="false"
                            name="primary"
                            id="primary"
                            [(ngModel)]="contactDetail.is_primary"
                        />
                        <span class="input-checkbox__label">No</span>
                    </label>

                    <label class="input-checkbox">
                        <input
                            type="radio"
                            [value]="true"
                            name="primary"
                            id="primary"
                            [(ngModel)]="contactDetail.is_primary"
                        />
                        <span class="input-checkbox__label">Yes</span>
                    </label>
                </li>
            </ul>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" mat-dialog-close class="btn">
                Cancel
            </button>

            <input
                type="submit"
                value="Confirm"
                class="btn btn--success btn--right"
            />
        </div>
    </form>
</div>