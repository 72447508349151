<div class="container-breadcrumbs">
    <ul class="container-breadcrumbs__crumbs">
        <ng-container *ngFor="let route of breadcrumbs">
            <ng-container *ngIf="!route.terminal">
                <li class="container-breadcrumbs__crumb">
                    <a
                        class="container-breadcrumbs__link"
                        [routerLink]="route.url"
                        >{{ route.displayName }}</a
                    >
                </li>
                <li
                    class="container-breadcrumbs__crumb container-breadcrumbs__crumb--divider"
                >
                    /
                </li>
            </ng-container>
            <ng-container *ngIf="route.terminal">
                <li
                    class="container-breadcrumbs__crumb container-breadcrumbs__crumb--active"
                >
                    {{ route.displayName }}
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>
