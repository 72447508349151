import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Users } from 'county-api';
import get from 'lodash/get';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthState, UserState } from '../../users/store/user.state';
import { ToggleSidebar } from '../store/layout.actions';
import { LayoutState } from '../store/layout.state';
import { IMenuItem, MENU_ITEMS } from './menu-items';

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
    @Select(UserState.user)
    user$: Observable<Users.IUser>;

    @Select(UserState.state)
    authState$: Observable<AuthState>;

    authStates = AuthState;

    user: Users.IUser;

    menus = MENU_ITEMS;

    menuActive = false;

    @Select(LayoutState.isMobile)
    isMobile$: Observable<boolean>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store,
        private dialog: MatDialog,
    ) {
        this.router.events
            .pipe(
                // Close all dialogs that are set to close when navigation
                // happens.
                tap(() => {
                    for (const d of this.dialog.openDialogs) {
                        if (d._containerInstance._config.closeOnNavigation) {
                            d.close();
                        }
                    }
                }),
                filter(event => event instanceof NavigationEnd),
            )
            .subscribe(() => this.routeChange());
    }

    ngOnInit() {
        this.routeChange();
    }

    /**
     * Update the selected menu items when the route is changed.
     */
    routeChange() {
        const routeUrls = this.getRouteUrls();
        this.updateMenu(this.menus, routeUrls);
    }

    updateMenu(menu: IMenuItem[], urls: string[]) {
        let activeChildren = false;
        for (const item of menu) {
            // Set item to inactive.
            item.active = false;
            item.show = false;

            // Compare the menu item url to the original path aor the menuUrl.
            if (item.url && urls.indexOf(item.url) !== -1) {
                item.active = true;
                item.show = true;
                activeChildren = true;
            }

            // If the current item has any child items that are active set it as
            if (item.children && this.updateMenu(item.children, urls)) {
                item.active = true;
                item.show = true;
                activeChildren = true;
            }
        }

        // Return whether any items where set to active so that the parent can
        // be set also.
        return activeChildren;
    }

    /**
     * Get the current route from the router and also any menu
     */
    getRouteUrls() {
        let childrenRoutes = this.route.root.children;
        let lastRoute = this.route.root;
        const urls = [this.router.url.split('?')[0]];

        // Loop the routes finded the deepest route that is set to output in the
        // primary outlet. This is to handle when we user multiple router
        // outlets within a page.
        do {
            for (const route of childrenRoutes) {
                if (route.outlet === 'primary') {
                    childrenRoutes = route.children;
                    lastRoute = route;
                } else {
                    childrenRoutes = [];
                }
            }
        } while (childrenRoutes.length > 0);

        const menuUrl = get(lastRoute, 'snapshot.data.menuUrl');
        const menuUrls = get(lastRoute, 'snapshot.data.menuUrls');
        if (menuUrl) {
            urls.push(menuUrl);
        } else if (menuUrls) {
            urls.push(...menuUrls);
        }

        return urls;
    }

    /**
     * Recursive function to hide all menus and children.
     */
    hideMenu(menus?: IMenuItem[]) {
        if (!menus) {
            menus = this.menus;
        }

        for (let i = 0; i < menus.length; i++) {
            menus[i].show = false;

            if (menus[i].children) {
                this.hideMenu(menus[i].children);
            }
        }
    }

    /**
     * Toggle the display of the sidebar on mobile devices.
     */
    toggleSidebarClick() {
        this.store.dispatch(new ToggleSidebar());
    }
}
