import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomerContactsService } from '../contacts/customers-contacts.service';
import { CustomersSageService } from '../sage/customers-sage.service';
import { MatDialog } from '@angular/material/dialog';
import { tap, switchMap, filter } from 'rxjs/operators';
import { CustomersSageLinkDialogComponent } from '../sage/link/customers-sage-link-dialog.component';
import {
    ConfirmDialogComponent,
    IConfirmDialogData,
} from '../../universal/dialogs/confirm/confirm-dialog.component';
import { IContactDetail, IPerson } from '../../county-api/src/customers';
import { BreadcrumbService } from '../../universal/breadcrumbs/breadcrumb.service';
import { NotificationsService } from '../../universal/notifications/notifications.service';

@Component({
    selector: 'person-details',
    templateUrl: './person-details.component.html',
})
export class PersonDetailsComponent implements OnInit {
    /**
     * Displaying person details.
     */
    person: IPerson;

    /**
     * Determines the state of the loader.
     */
    loading = 0;

    /**
     * Construct injected angular dependencies.
     * @param route The current active route details.
     */
    constructor(
        private route: ActivatedRoute,
        private customerContactsService: CustomerContactsService,
        private breadcrumbService: BreadcrumbService,
        private customersSageService: CustomersSageService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog,
    ) {}

    /**
     * On init get the person data from the route data.
     */
    ngOnInit() {
        this.loading++;

        this.route.data.subscribe()

        this.route.data.subscribe((data) => {
            this.person = data.person;

            this.breadcrumbService.changeBreadcrumb(
                this.route.snapshot,
                this.person.forename + ' ' + this.person.surname,
            );

            this.loading--;
        });
    }

    /**
     * Return a clickable link for a contact type.
     * @param contactDetail The contact to geterate the link for.
     */
    getContactLink(contactDetail: IContactDetail) {
        return this.customerContactsService.getLink(contactDetail);
    }

    openLinkClick() {
        this.dialog
            .open(CustomersSageLinkDialogComponent, {
                width: '400px',
            })
            .afterClosed()
            .pipe(
                filter(r => !!r),
                switchMap(r =>
                    this.customersSageService.link(
                        this.person.id ?? "",
                        r.customer.sl_customer_account_id,
                    ),
                ),
            )
            .subscribe({
                next: r => {
                    this.person.sage_account_id =
                        r.result.customer.sl_customer_account_id;
                    this.person.sage_account_number =
                        r.result.customer.customer_account_name;
                    this.notificationsService.success(
                        'Successfully linked Sage account to customer.',
                    );
                },
                error: () => {
                    this.notificationsService.error(
                        'Failed to link Sage account to customer.',
                    );
                },
            });
    }

    openUnlinkClick() {
        this.dialog
            .open<ConfirmDialogComponent, IConfirmDialogData>(
                ConfirmDialogComponent,
                {
                    data: {
                        btnClass: 'btn--error',
                        title: 'Unlink customer from Sage account?',
                        message: `This will unlink this customer from the Sage account ${this.person.sage_account_number}. This will not update any invoices previously posted to Sage.`,
                    },
                    width: '250px',
                },
            )
            .afterClosed()
            .pipe(
                filter(r => !!r),
                switchMap(r =>
                    this.customersSageService.unlink(this.person.id ?? ""),
                ),
            )
            .subscribe({
                next: r => {
                    this.person.sage_account_id = null;
                    this.person.sage_account_number = null;
                    this.notificationsService.success(
                        'Successfully linked Sage account to customer.',
                    );
                },
            });
    }
}
