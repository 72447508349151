<ng-container [ngSwitch]="type">
    <office-widget *ngSwitchCase="'dashboard-office-widget'"></office-widget>
    <revenue-days-widget
        *ngSwitchCase="'dashboard-revenue-days-widget'"
    ></revenue-days-widget>
    <utilisation-widget
        *ngSwitchCase="'dashboard-utilisation-widget'"
    ></utilisation-widget>
    <incidents-widget
        *ngSwitchCase="'dashboard-incidents-widget'"
    ></incidents-widget>
    <div class="widget-unknown" *ngSwitchDefault>
        Unknown widget: {{ type }}
    </div>
</ng-container>
