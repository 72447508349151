import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bookings } from 'county-api';

/**
 * The interface in which data is passed from the parent.
 */
interface IBookingChargesRefund {
    charge: Bookings.IBookingCharge;
    amountList: number[];
}

/**
 * The booking refund charges dialog used to send back the number of
 * charges to refund.
 */
@Component({
    selector: 'booking-charges-refund-dialog',
    templateUrl: './booking-charges-refund-dialog.component.html',
})
export class BookingChargesRefundDialogComponent {
    /**
     * The charge data passed from the parent.
     */
    charge: Bookings.IBookingCharge;

    /**
     * The amount of charges.
     */
    amountList: number[];

    /**
     * The amount of charges to refund.
     */
    refundCount: number;

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialogs reference functionality.
     * @param data The data passed from the parent.
     */
    constructor(
        private dialogRef: MatDialogRef<BookingChargesRefundDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: IBookingChargesRefund,
    ) {
        this.charge = data ? data.charge : null;
        this.amountList = data ? data.amountList : [];
    }

    /**
     * When valid passes back the refund amount.
     */
    submit() {
        this.dialogRef.close({ count: this.refundCount });
    }
}
