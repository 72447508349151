import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Customers } from 'county-api';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeleteDialogComponent } from '../../universal/dialogs/delete-dialog/delete-dialog.component';
import { NotificationsService } from '../../universal/notifications/notifications.service';
import {
    IPagingParams,
    IRequestPaging,
    PagingController,
} from '../../universal/paging';
import { JobsDialogComponent } from './jobs-dialog.component';
import { JobsService } from './jobs.service';

/**
 * The component for the jobs page, gets and outputs the jobs, also opens and handles the
 * add, update and delete jobs dialogs.
 */
@Component({
    selector: 'jobs',
    templateUrl: './jobs.component.html',
})
export class JobsComponent extends PagingController<IRequestPaging> {
    /**
     * List of jobs to display within the page.
     */
    jobs: Customers.ICustomerJob[] = [];

    /**
     * Query details for collecting the jobs from the api.
     */
    pagingData: IRequestPaging = {
        page: 1,
        filter: null,
        perpage: null,
    };

    /**
     * Determine the loading state of the page.
     */
    loading = 0;

    /**
     * The last active request subscription used for cancelling of the request.
     */
    private request: Subscription;

    /**
     * Contructs injected angulare depencies.
     * @param jobsService The jobs service to get the jobs for the jobs list.
     * @param router Router service used to update the url with the new query strings.
     * @param route Needed for the paging component to gather the current query string params.
     * @param dialog The dialog functionality to open the dialog and pass the data.
     */
    constructor(
        private jobsService: JobsService,
        router: Router,
        route: ActivatedRoute,
        private dialog: MatDialog,
        private notifications: NotificationsService,
    ) {
        super(router, route);
    }

    /**
     * Update the local paging data from the url params.
     * @param params The query params from the url.
     */
    updateLocalParams(params: IPagingParams) {
        this.jobs = [];
        this.pages = 0;

        // Gather the paging information from the url.
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            filter: params['filter'] ? params['filter'] : null,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
        } as IRequestPaging;

        // Floor the page number to 1.
        if (this.pagingData.page < 1) {
            this.pagingData.page = 1;
        }

        return this.pagingData;
    }

    /**
     * Load the latest set of data based on the passed in pagingData.
     * @param pagingData The data returned from updateLocalParams.
     */
    updateData(pagingData: IRequestPaging) {
        this.loading++;

        // If any active request cancel it and then decrease the loader.
        if (this.request && !this.request.closed) {
            this.request.unsubscribe();
            this.loading--;
        }

        // Make a new request for the job details. Handle both success and error states.
        this.request = this.jobsService
            .get(pagingData)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                response => {
                    this.jobs = response.result.jobs;
                    this.pages = response.result.paging.total_pages;
                    this.totalItems = response.result.paging.total;
                },
                () => {
                    this.pages = 0;
                },
                () => this.loading--,
            );
    }

    /**
     * Opens the add dialog and pushes the new job to the jobs array.
     */
    addJob() {
        const openDialog = this.dialog.open(JobsDialogComponent, {
            width: '500px',
            data: { job: {} },
        });

        // Pushes a new job to the array of jobs
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                this.jobsService.create(result).subscribe(
                    response => {
                        this.filterUpdate({ value: 1 }, 'page');
                        this.notifications.success(
                            'Successfully created new job title.',
                        );
                    },
                    () => {
                        this.notifications.error(
                            'Failed to create new job title.',
                        );
                    },
                );
            }
        });
    }

    /**
     * Opens the add dialog and updates an existing job.
     * @param index The index of the job in the jobs array.
     * @param job The jobs data passed in to the dialog.
     */
    editJob(index: number, job: Customers.ICustomerJob) {
        const jobClone = Object.assign({}, job);
        const openDialog = this.dialog.open(JobsDialogComponent, {
            width: '500px',
            data: { job: jobClone },
        });
        // Overrides the previous address with the edited address.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                this.jobsService.edit(job.id, result).subscribe(
                    response => {
                        this.jobs[index] = response.result.job;
                        this.notifications.success(
                            'Successfully updated job title to ' +
                                response.result.job.title,
                        );
                    },
                    () => {
                        this.notifications.error(
                            'Failed to update the job title.',
                        );
                    },
                );
            }
        });
    }

    /**
     * Opens the delete dialog and deletes the person from the people array.
     * @param index The idex of the job from the jobs array.
     */
    deleteJob(id: string) {
        const openDialog = this.dialog.open(DeleteDialogComponent, {
            width: '500px',
        });

        // Splices the job by its index from the jobs array.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                this.jobsService.delete(id).subscribe(
                    response => {
                        this.notifications.success(
                            'Successfully deleted the job.',
                        );
                    },
                    () => {
                        this.notifications.error('Failed to delete the job.');
                    },
                );
            }
        });
    }
}
