import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: '[pActionMenuItem]',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.action-menu__item]': 'true',
    },
})
export class ActionMenuItemComponent {}
