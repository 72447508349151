import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Customers } from 'county-api';

/**
 * The jobs dialog used to submit the add or update of jobs.
 */
@Component({
    selector: 'jobs-dialog',
    templateUrl: './jobs-dialog.component.html',
})
export class JobsDialogComponent {
    /**
     * The individual job data.
     */
    job: Customers.ICustomerJob;

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialogs functionality.
     * @param data The data passed from the parent.
     */
    constructor(
        public dialogRef: MatDialogRef<JobsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        // Checks whether data is undefined.
        if (typeof data !== 'undefined') {
            this.job = data.job;
        }
    }

    /**
     * Handle submitting the form, removing the group from the vehicle and thene
     * emitting the submit event.
     */
    submitJob($event) {
        this.dialogRef.close(this.job);
    }
}
