import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

/**
 * The gbp pipe sets a bumber to a currency in punds.
 */
@Pipe({
    name: 'gbp',
})
export class GbpPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    /**
     * Uses the angular currency pipe but converts the currecy to pounds.
     * @param value The value passed to the pipe.
     * @param args The currency arg being set.
     */
    transform(value: number, args?: any) {
        // TODO: currencies.
        if (args === true) {
            value = value / 100;
        }
        return this.currencyPipe.transform(value, 'GBP');
    }
}
