import { Component } from '@angular/core';

/**
 * Widget to display dashboard links for third party system to a user.
 */
@Component({
    selector: 'office-widget',
    templateUrl: './office-widget.component.html',
    styleUrls: ['./office-widget.component.scss'],
})
export class OfficeWidgetComponent {
    /**
     * Links to display on the initial widget listing.
     */
    links = [
        {
            title: 'Office365',
            url: 'https://www.office.com/?home=1&auth=2',
            icon: 'OfficeLogo',
        },

        {
            title: 'The Hub',
            url: 'https://luteaim2.sharepoint.com',
            icon: 'SharepointLogo',
        },

        {
            title: 'Planner',
            url: 'https://tasks.office.com/',
            icon: 'PlannerLogo',
        },
        {
            title: 'Deputy',
            url: 'http://ccvr.eu.deputy.com/',
            icon: 'DeputyLogo',
        },
        {
            title: 'PeopleHR',
            url: 'https://county.peoplehr.net/',
            icon: 'PeopleLogo',
        },
    ];
}
