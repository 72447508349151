import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Customers } from 'county-api';
import { PeopleService } from '../people/people.service';

/**
 * Maps the data from the people service for the person of the given id.
 */
@Injectable()
export class PersonResolve implements Resolve<Customers.IPerson | boolean> {
    constructor(private peopleService: PeopleService, private router: Router) {}

    /**
     * Uses the current route and maps the passed in id to the url to get the person data.
     * @param route Thecurrent activated route.
     */
    resolve(route: ActivatedRouteSnapshot) {
        return this.peopleService.getById(route.paramMap.get('id')).pipe(
            map(data => {
                return data.result.person;
            }),
            catchError(error => {
                // Delay the navigation in order to maintain the url states.
                setTimeout(() =>
                    this.router.navigate(['/people'], {
                        skipLocationChange: true,
                    }),
                );
                return observableOf(false);
            }),
        );
    }
}
