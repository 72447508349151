import { Users } from 'county-api';
import { INotification } from 'county-api/users';

export class UnreadNotifications {
    static readonly type = '[Notifications] Add unread';

    constructor(public payload: INotification[]) {}
}

export class GetNextNotifications {
    static readonly type = '[Notifications] Get next notifications';
}

export class NewNotification {
    static readonly type = '[Notifications] New';

    constructor(public payload: INotification) {}
}

export class MarkNotificationRead {
    static readonly type = '[Notifications] Mark read';

    constructor(public payload: string) {}
}

export class MarkAllNotificationRead {
    static readonly type = '[Notifications] Mark all read';
}

export class ReadNotification {
    static readonly type = '[Notifications] Read';

    constructor(public payload: INotification[]) {}
}

export class ForceReload {
    static readonly type = '[DEBUG] Force Reload';
}

export class NotificationsDestroy {
    static readonly type = '[Notifications] Destroy';
}

export class NotificationSocket {
    static readonly type = '[Notifications] Start listening';
}

export class NotificationDisableSocket {
    static readonly type = '[Notifications] Stop listening';
}
