import { NgModule } from '@angular/core';
import { ActivitiesModule } from '../activities/activities.module';
import { LayoutModule } from '../layout/layout.module';
import { LocationsModule } from '../locations/locations.module';
import { NotesModule } from '../notes/notes.module';
import { UniversalModule } from '../universal';
import { UsersModule } from '../users/users.module';
import { FleetRoutingModule, routedComponents } from './fleet-routing.module';

@NgModule({
    imports: [
        UniversalModule,
        LayoutModule,
        FleetRoutingModule,
        LocationsModule,
        ActivitiesModule,
        NotesModule,
        UsersModule,
    ],
    declarations: [...routedComponents],
})
export class FleetModule {}
