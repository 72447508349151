import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Permissions } from 'county-api';
import { PermissionsService } from '../../../permissons/permissions.service';

/**
 * The user permission add dialog used to select and add permissions for a
 * selected user.
 */
@Component({
    selector: 'user-permission-add-dialog',
    templateUrl: './user-permission-add-dialog.component.html',
})
export class UserPermissionAddDialogComponent implements OnInit {
    /**
     * The array of ids of the users current permissions.
     */
    currentPermissionIds: string[];

    /**
     * The array of ids of the users selected permissions.
     */
    selectedPermissionIds: string[] = [];

    /**
     * The array of permissions.
     */
    permissions: Permissions.IPermission[] = [];

    /**
     * Determines the loading state of the component.
     */
    loading = 0;

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialogs reference functionality.
     * @param data The data passed from the parent.
     * @param permissionService The permission service to get all permissions.
     */
    constructor(
        private permissionsService: PermissionsService,
        public dialogRef: MatDialogRef<UserPermissionAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string[],
    ) {
        this.currentPermissionIds = this.data;
    }

    /**
     * Calls the get permissions method.
     */
    ngOnInit() {
        this.getPermissions();
    }

    /**
     * Load the permissions that can be added to the user, filtering out any existing ones.
     */
    getPermissions() {
        this.loading++;

        this.permissionsService.get({ paging: 'false' }).subscribe(response => {
            // Loop the returned permissions ignoring permissions that the user already has.
            for (const permission of response.result.permissions) {
                if (this.currentPermissionIds.indexOf(permission.id) === -1) {
                    this.permissions.push(permission);
                }
            }

            this.loading--;
        });
    }

    /**
     * Gets the permission by its id and pushes it to the selected permission
     * id array.
     * @param permissionId The id of the permission selected.
     */
    selectPermissionClick(permissionId: string) {
        const index = this.selectedPermissionIds.indexOf(permissionId);
        if (index === -1) {
            this.selectedPermissionIds.push(permissionId);
        } else {
            this.selectedPermissionIds.splice(index, 1);
        }
    }
}
