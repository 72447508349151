import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faBellSlash } from '@fortawesome/pro-regular-svg-icons/faBellSlash';
import { faBug } from '@fortawesome/pro-regular-svg-icons/faBug';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faCar } from '@fortawesome/pro-regular-svg-icons/faCar';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faCommentAlt } from '@fortawesome/pro-regular-svg-icons/faCommentAlt';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpen';
import { faEraser } from '@fortawesome/pro-regular-svg-icons/faEraser';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons/faFileExcel';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons/faFileImage';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileWord } from '@fortawesome/pro-regular-svg-icons/faFileWord';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey';
import { faListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt';
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/pro-regular-svg-icons/faLockOpen';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSitemap } from '@fortawesome/pro-regular-svg-icons/faSitemap';
import { faSortAmountDown } from '@fortawesome/pro-regular-svg-icons/faSortAmountDown';
import { faStickyNote } from '@fortawesome/pro-regular-svg-icons/faStickyNote';
import { faSync } from '@fortawesome/pro-regular-svg-icons/faSync';
import { faTag } from '@fortawesome/pro-regular-svg-icons/faTag';
import { faTh } from '@fortawesome/pro-regular-svg-icons/faTh';
import { faThLarge } from '@fortawesome/pro-regular-svg-icons/faThLarge';
import { faThList } from '@fortawesome/pro-regular-svg-icons/faThList';
import { faThumbtack } from '@fortawesome/pro-regular-svg-icons/faThumbtack';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo';
import { faWrench } from '@fortawesome/pro-regular-svg-icons/faWrench';

export const registerRegularIcons = (library: FaIconLibrary) => {
    library.addIcons(
        faBars,
        faBell,
        faBellSlash,
        faBug,
        faCalendar,
        faCalendarAlt,
        faCar,
        faCheck,
        faChevronDown,
        faChevronLeft,
        faChevronRight,
        faChevronUp,
        faClock,
        faCommentAlt,
        faEdit,
        faEllipsisV,
        faEnvelopeOpen,
        faEraser,
        faExternalLink,
        faEye,
        faFile,
        faFileExcel,
        faFileImage,
        faFilePdf,
        faFileWord,
        faImage,
        faKey,
        faListAlt,
        faLock,
        faLockOpen,
        faPaperclip,
        faPencil,
        faPlus,
        faQuestionCircle,
        faSearch,
        faSitemap,
        faSortAmountDown,
        faStickyNote,
        faSync,
        faTag,
        faTh,
        faThLarge,
        faThList,
        faThumbtack,
        faTimes,
        faTrash,
        faUndo,
        faWrench,
        faClipboardList,
    );
};
