export const bookingStatuses = [
    {
        id: 'Incomplete',
        label: 'Incomplete',
    },
    {
        id: 'Pending',
        label: 'Pending',
    },
    {
        id: 'Open',
        label: 'Open',
    },
    {
        id: 'Review',
        label: 'In Review',
    },
    {
        id: 'Reserved',
        label: 'Reserved',
    },
    {
        id: 'Completed',
        label: 'Completed',
    },
    {
        id: 'Cancelled',
        label: 'Cancelled',
    },
    {
        id: 'AwaitingPayment',
        label: 'Awaiting Payment',
    },
    {
        id: 'AwaitingDeposit',
        label: 'Awaiting Deposit',
    },
    {
        id: 'Failed',
        label: 'Failed',
    },
    {
        id: 'FailedDeposit',
        label: 'Deposit Failed',
    },
] as IBookingStatus[];

export interface IBookingStatus {
    id: string;
    label: string;
}
