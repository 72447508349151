import { Component, OnInit, Input, HostBinding } from '@angular/core';
import moment from 'moment';
import { MomentInput } from 'moment';

@Component({
    selector: 'time-ago',
    template: '{{time | timeago:displayTime | async}}',
    styleUrls: ['./time-ago.component.scss'],
})
export class TimeAgoComponent {
    private _time: MomentInput;

    @HostBinding()
    title: string;

    @Input()
    get time() {
        return this._time;
    }

    @Input()
    displayTime = true;

    set time(value: MomentInput) {
        this._time = value;
        this.title = moment(this.time).format('LLLL');
    }
}
