import { NgModule } from '@angular/core';
import { UniversalModule } from '../universal';
import { UsersModule } from '../users/users.module';
import { NotesListDialogComponent } from './dialog/notes-list-dialog.component';
import { NotesListAddFormComponent } from './list/add/notes-list-add-form.component';
import { NotesListItemComponent } from './list/item/notes-list-item.component';
import { NotesListComponent } from './list/notes-list.component';
import { NotesService } from './notes.service';

@NgModule({
    imports: [UniversalModule, UsersModule],
    declarations: [
        NotesListComponent,
        NotesListItemComponent,
        NotesListAddFormComponent,
        NotesListDialogComponent,
    ],
    exports: [NotesListComponent],
    providers: [NotesService],
})
export class NotesModule {}
