import { Component, Input } from '@angular/core';

@Component({
    selector: 'loader',
    template: `
        <div
            class="loader"
            [ngClass]="{ 'loader--inline': inline, 'loader--dark': dark }"
        >
            <div class="loader__spinner">
                <div class="loader__spinner-icon"></div>
            </div>

            <div class="loader__label">Loading</div>
        </div>
    `,
})
export class LoaderComponent {
    @Input()
    inline: boolean;

    @Input()
    dark: boolean;
}
