import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FaIconLibrary,
    FontAwesomeModule,
    FaConfig,
} from '@fortawesome/angular-fontawesome';
import { registerBrandIcons } from './register-brands';
import { registerLightIcons } from './register-light';
import { registerRegularIcons } from './register-regular';
import { registerSolidIcons } from './register-solid';

@NgModule({
    imports: [CommonModule],
    exports: [FontAwesomeModule],
})
export class UniversalFontsawesomeModule {
    constructor(library: FaIconLibrary, config: FaConfig) {
        config.defaultPrefix = 'far';
        config.fixedWidth = true;

        registerSolidIcons(library);
        registerRegularIcons(library);
        registerLightIcons(library);
        registerBrandIcons(library);
    }
}
