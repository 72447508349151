import { Injectable } from '@angular/core';
import { IRequestPaging } from '../paging/IRequestPaging';
import { filterRequestParams } from './filterRequestParams';

/**
 * @deprecated Use filterRequestParams helper method.
 */
@Injectable()
export class ApiHttpClientService {
    /**
     * Simple helper message in order to remove null values from the passed in params.
     * @param params The params for a request.
     */
    protected filterRequestParams(params: IRequestPaging | any) {
        return filterRequestParams(params);
    }
}
