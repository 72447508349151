<div class="notes-list">
    <div class="notes-list__header">
        <h4 class="notes-list__header-heading">Notes</h4>
        <a
            class="btn btn--small"
            (click)="showAddForm = !showAddForm"
            *ngIf="!showAddForm"
        >
            add
        </a>
    </div>

    <div *ngIf="!pagingLoading; else loader">
        <notes-list-add-form
            (noteSubmit)="addNoteSubmit($event)"
            (noteCancel)="showAddForm = !showAddForm"
            *ngIf="showAddForm"
        ></notes-list-add-form>

        <ng-container *ngIf="notes && notes.length > 0; else noResult">
            <div class="notes-list__list">
                <notes-list-item
                    class="notes-list__list-item"
                    [note]="note"
                    *ngFor="let note of notes"
                    (noteDelete)="deleteNote($event)"
                ></notes-list-item>
            </div>

            <mat-paginator
                class="notes-list__paginator"
                [length]="totalItems"
                [pageSize]="pagingData.perpage"
                (page)="pageChange($event)"
                [pageIndex]="pagingData.page - 1"
            ></mat-paginator>
        </ng-container>
    </div>
</div>

<ng-template #loader>
    <loader></loader>
</ng-template>

<ng-template #noResult>
    <div class="sub-text align-center">
        No notes added.
    </div>
</ng-template>
