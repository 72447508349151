import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocationsService } from './locations.service';
import { NgxsModule } from '@ngxs/store';
import { LocationsState } from './store/locations.state';
import { LocationSelectionInputComponent } from './selection/location-selection-input.component';
import { UniversalModule } from '../universal/universal.module';
import { LocationLabelComponent } from './label/location-label.component';

@NgModule({
    imports: [UniversalModule, NgxsModule.forFeature([LocationsState])],
    declarations: [LocationSelectionInputComponent, LocationLabelComponent],
    exports: [LocationSelectionInputComponent, LocationLabelComponent],
    providers: [LocationsService],
})
export class LocationsModule {}
