import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import {
    Action,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
    Store,
} from '@ngxs/store';
import reverse from 'lodash/reverse';
import { tap } from 'rxjs/operators';
import { BreadcrumbService } from '../../universal/breadcrumbs';
import { TitleService } from '../../universal/title/title.service';
import {
    SetDisplayNotifications,
    ToggleNotifications,
    TogglePin,
    ToggleSidebar,
    UpdateIsMobile,
    UpdateIsTablet,
    SetSeenVersion,
} from './layout.actions';

export interface LayoutStateModel {
    pinNotifications: boolean;

    openNotifications: boolean;

    openSidebar: boolean;

    isMobile: boolean;

    isTablet: boolean;

    seenVersion: string;
}

@State<LayoutStateModel>({
    name: 'layout',
    defaults: {
        pinNotifications: false,
        openNotifications: false,
        openSidebar: false,
        isMobile: false,
        isTablet: false,
        seenVersion: '1.0.0',
    },
})
@Injectable()
export class LayoutState implements NgxsOnInit {
    constructor(
        private breadcrumbService: BreadcrumbService,
        private titleService: TitleService,
        private store: Store,
        private media: MediaMatcher,
    ) {}

    ngxsOnInit() {
        //this.media.
        this._mobileQuery = this.media.matchMedia('(max-width: 767px)');
        this._tabletQuery = this.media.matchMedia(
            '(min-width: 766px) and (max-width: 1025px)',
        );
        // this._mobileQuery.
        this.store.dispatch(new UpdateIsMobile(this._mobileQuery.matches));
        this.store.dispatch(new UpdateIsTablet(this._tabletQuery.matches));

        this._mobileQuery.addListener((data: MediaQueryListEvent) => {
            this.store.dispatch(new UpdateIsMobile(data.matches));
        });
        // this._tabletQuery.
        this._tabletQuery.addListener((data: MediaQueryListEvent) => {
            this.store.dispatch(new UpdateIsTablet(data.matches));
        });

        this.breadcrumbService.breadcrumbChanged
            .pipe(
                tap(breadcrumbs => {
                    this.titleService.setTitle(
                        reverse(breadcrumbs.map(b => b.displayName)),
                    );
                }),
            )
            .subscribe();
    }

    private _mobileQuery: MediaQueryList;
    private _tabletQuery: MediaQueryList;

    @Selector()
    static pinState(state: LayoutStateModel) {
        return state.pinNotifications;
    }

    @Selector()
    static notificationsState(state: LayoutStateModel) {
        return state.openNotifications;
    }

    @Selector()
    static sidebarState(state: LayoutStateModel) {
        return state.openSidebar;
    }

    @Selector()
    static isMobile(state: LayoutStateModel) {
        return state.isMobile;
    }

    @Selector()
    static isTablet(state: LayoutStateModel) {
        return state.isTablet;
    }

    @Selector()
    static seenVersion(state: LayoutStateModel) {
        return state.seenVersion;
    }

    @Action(TogglePin)
    pinNotifications(
        { patchState, getState }: StateContext<LayoutStateModel>,
        action: TogglePin,
    ) {
        const state = getState();
        patchState({
            pinNotifications: !state.pinNotifications,
        });
    }

    @Action(ToggleNotifications)
    openNotifications({ dispatch, getState }: StateContext<LayoutStateModel>) {
        const state = getState();
        dispatch(new SetDisplayNotifications(!state.openNotifications));
    }

    @Action(SetDisplayNotifications)
    setNotifications(
        { patchState, getState }: StateContext<LayoutStateModel>,
        { payload }: SetDisplayNotifications,
    ) {
        const state = getState();

        patchState({
            openNotifications: payload,
            pinNotifications:
                payload === false && state.pinNotifications === true
                    ? false
                    : state.pinNotifications,
        });
    }

    @Action(ToggleSidebar)
    toggleSidebar({ patchState, getState }: StateContext<LayoutStateModel>) {
        const state = getState();

        patchState({
            openSidebar: !state.openSidebar,
        });
    }

    @Action(UpdateIsMobile)
    updateIsMobile(
        { patchState }: StateContext<LayoutStateModel>,
        action: UpdateIsMobile,
    ) {
        patchState({
            isMobile: action.payload,
        });
    }

    @Action(UpdateIsTablet)
    updateIsTablet(
        { patchState }: StateContext<LayoutStateModel>,
        action: UpdateIsTablet,
    ) {
        patchState({
            isTablet: action.payload,
        });
    }

    @Action(SetSeenVersion)
    updateSeenVersion(
        { patchState }: StateContext<LayoutStateModel>,
        action: SetSeenVersion,
    ) {
        patchState({
            seenVersion: action.payload,
        });
    }
}
