<div class="card__header" *ngIf="!pHideHeader && (pTitle || pSubTitle)">
    <h3 class="card__title" *ngIf="pTitle">{{ pTitle }}</h3>
    <div class="card__sub-title" *ngIf="pSubTitle">{{ pSubTitle }}</div>
    <div class="card__actions" *ngIf="pCardActions">
        <ng-content select="[pCardActions]"></ng-content>
    </div>
</div>

<card-section *ngIf="pSectioned; else cardContent">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
</card-section>

<div class="card__footer" *ngIf="pCardFooter">
    <ng-content select="[pCardFooter]"></ng-content>
</div>

<div class="card__meta-data" *ngIf="pCardMetaData">
    <ng-content select="[pCardMetaData]"></ng-content>
</div>

<ng-template #cardContent>
    <ng-content></ng-content>
</ng-template>
