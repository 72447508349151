<ng-container *ngIf="!isInFrame">
    <div
        class="site-wrapper"
        [ngClass]="{ 'is-mobile': isMobile, 'is-debug': debug }"
        *ngIf="(authState$ | async) != authStates.Unknown; else content"
    >
        <div class="site-debug__message" *ngIf="debug">
            <h1 class="site-debug__message-heading">Warning</h1>
            - This is a test system
        </div>

        <ng-container
            *ngIf="
                (authState$ | async) == authStates.Authenticated;
                else unAuthContent
            "
        >
            <mat-sidenav-container
                class="site-page"
                (backdropClick)="closeSidebarsClick()"
                #nav
            >
                <mat-sidenav
                    class="site-sidebar"
                    mode="side"
                    [opened]="!isMobile || (openSidebar$ | async)"
                    [mode]="isMobile ? 'over' : 'side'"
                    position="start"
                    [fixedInViewport]="isMobile"
                    [fixedTopGap]="debug ? (isMobile ? '35' : '0') : '0'"
                >
                    <sidebar class="site-sidebar__wrapper"></sidebar>
                </mat-sidenav>

                <mat-sidenav
                    class="site-sidebar site-sidebar--notifications"
                    [opened]="openNotifications$ | async"
                    fixedInViewport
                    position="end"
                    [mode]="!isMobile && pinned ? 'side' : 'over'"
                    [fixedTopGap]="debug ? (isMobile ? '35' : '55') : '0'"
                >
                    <sidebar-notifications></sidebar-notifications>
                </mat-sidenav>

                <mat-sidenav-content class="site-page__container">
                    <mobile-nav
                        class="site-mobile-navigation"
                        *ngIf="isMobile"
                    ></mobile-nav>
                    <outlet></outlet>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </ng-container>
    </div>
</ng-container>

<ng-template #content>
    <div class="page-loading-spinner">
        <div class="page-loading-spinner__img">
            <svg class="page-loading-spinner__icon">
                <use xlink:href="#logo"></use>
            </svg>
        </div>

        <div class="page-loading-spinner__error">
            Not loading?
            <a
                class="link"
                title="Clear your session and reload."
                (click)="reloadClick()"
            >
                Click here</a
            >
            to reset.
        </div>
    </div>
</ng-template>

<ng-template #unAuthContent>
    <unauthorised></unauthorised>
</ng-template>
