import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEndorsement } from '../../county-api/src/endorsements';

/**
 * The endorsement add dialog used to add endorsments.
 */
@Component({
    selector: 'endorsement-add-dialog',
    templateUrl: './endorsement-add-dialog.component.html',
})
export class EndorsementAddDialogComponent {
    /**
     * The endorsment data to be added.
     */
    endorsement: IEndorsement;

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialog references functionality.
     * @param data The data passed from the parent.
     */
    constructor(
        private dialogRef: MatDialogRef<EndorsementAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.endorsement = data ? data.endorsement : {};
    }

    /**
     * When valid passes bacj the new endorsement.
     */
    submit() {
        this.dialogRef.close(this.endorsement);
    }
}
