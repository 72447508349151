import {
    animate,
    group,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { Select } from '@ngxs/store';
import get from 'lodash/get';
import { Observable } from 'rxjs';
import { audit, filter, map, tap } from 'rxjs/operators';
import { LayoutState } from '../store/layout.state';

const fadeInOutAnimation = trigger('routeAnimation', [
    transition('* <=> *', [
        query(
            ':enter, :leave',
            style({
                position: 'absolute',
                width: '100%',
                top: '0',
                // transform: 'translateX(0)'
            }),
            { optional: true },
        ),
        group([
            query(
                ':enter',
                [
                    style({ opacity: '0' }),
                    animate('0.4s ease-in', style({ opacity: '1' })),
                ],
                { optional: true },
            ),
            query(
                ':leave',
                [
                    style({ opacity: '1' }),
                    animate('0.4s ease-out', style({ opacity: '0' })),
                ],
                { optional: true },
            ),
        ]),
    ]),
]);

@Component({
    selector: 'outlet',
    template: `
        <ng-progress></ng-progress>
        <spinner
            *ngIf="isLoading$ | async"
            style="position: absolute; top: 40px; width: 100%; z-index:999"
        ></spinner>
        <div [@routeAnimation]="(isMobile$ | async) ? false : getState(o)">
            <router-outlet #o="outlet"></router-outlet>
        </div>
    `,
    animations: [fadeInOutAnimation],
})
export class OutletComponent implements OnInit {
    isLoading$: Observable<boolean>;

    @Select(LayoutState.isMobile)
    isMobile$: Observable<boolean>;

    constructor(private router: Router) {}

    ngOnInit() {
        // Simply detect if an event is one we deem to be loading.
        const detectLoading = event =>
            !(
                event instanceof NavigationEnd ||
                event instanceof NavigationError ||
                event instanceof NavigationCancel
            );

        let doneOnce = false;
        const auditPromise = () =>
            new Promise<boolean>(resolve => {
                if (doneOnce) {
                    resolve(true);
                } else {
                    setTimeout(() => {
                        doneOnce = true;
                        resolve(true);
                    }, 3000);
                }
            });

        this.isLoading$ = this.router.events.pipe(
            filter(
                event =>
                    event instanceof NavigationEnd ||
                    event instanceof NavigationError ||
                    event instanceof NavigationCancel ||
                    event instanceof NavigationStart,
            ),
            tap(v => {
                if (v instanceof NavigationStart && doneOnce) {
                    doneOnce = false;
                }
            }),
            audit(auditPromise),
            map(detectLoading),
        );
    }

    getState(outlet) {
        return get(
            outlet.activatedRouteData,
            'animationState',
            outlet.activatedRouteData,
        );
    }
}
