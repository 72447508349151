<mat-select
    name="location"
    id="location"
    [disabled]="readonly"
    [(ngModel)]="locationId"
    (openedChange)="onTouched()"
>
    <mat-option *ngIf="!readonly && !hideBlank"></mat-option>
    <ng-container *ngFor="let location of locations$ | async">
        <mat-option
            [value]="location.id"
            *ngIf="!readonly || location.id == locationId"
        >
            {{ location.name }}
        </mat-option>
    </ng-container>
</mat-select>
