<topbar>
    <h1 topbar-title>Bookings</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="content-section">
            <div class="wrapper wrapper--large">
                <div class="content-card">
                    <div class="content-list booking-list">
                        <div class="content-list-toolbar">
                            <ul
                                class="content-list-toolbar__list list-horizontal"
                            >
                                <li
                                    class="content-list-toolbar__item list--item__right"
                                >
                                    <div
                                        class="content-list-toolbar__input input-group input-group--accent"
                                    >
                                        <div class="input-group__prepend">
                                            <label
                                                class="input-group__text"
                                                for="search"
                                            >
                                                <fa-icon
                                                    icon="search"
                                                ></fa-icon>
                                            </label>
                                        </div>
                                        <input
                                            type="search"
                                            name="search"
                                            id="search"
                                            class="input-group__input"
                                            [ngModel]="pagingData.filter"
                                            (input)="
                                                filterUpdate(
                                                    $event.target,
                                                    'filter'
                                                )
                                            "
                                        />
                                    </div>
                                </li>

                                <li
                                    class="content-list-toolbar__item list--item__right"
                                    *ngIf="bookingStatus"
                                >
                                    <div
                                        class="btn btn--dropdown"
                                        (click)="status.open()"
                                    >
                                        <span for="status" class="btn__label"
                                            >Status</span
                                        >
                                        <mat-select
                                            class="btn__select"
                                            name="status"
                                            [value]="pagingData.status"
                                            (selectionChange)="
                                                filterUpdate($event, 'status')
                                            "
                                            #status
                                        >
                                            <mat-option
                                                [value]="status.id"
                                                *ngFor="
                                                    let status of bookingStatusItems
                                                "
                                            >
                                                {{ status.label }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </li>

                                <li
                                    class="content-list-toolbar__item list--item__right"
                                    *ngIf="locations$ | async; let locations"
                                >
                                    <div
                                        class="btn btn--dropdown"
                                        (click)="location.open()"
                                    >
                                        <span for="status" class="btn__label"
                                            >Location</span
                                        >
                                        <mat-select
                                            class="btn__select"
                                            name="location"
                                            [value]="pagingData.location"
                                            (selectionChange)="
                                                filterUpdate($event, 'location')
                                            "
                                            #location
                                        >
                                            <mat-option [value]="-1"
                                                >All</mat-option
                                            >
                                            <mat-option
                                                [value]="location.id"
                                                *ngFor="
                                                    let location of locations
                                                "
                                            >
                                                {{ location.name }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="!pagingLoading && pages > 0">
                            <div class="content-list__list">
                                <bookings-list-item
                                    *ngFor="let booking of bookings"
                                    [booking]="booking"
                                ></bookings-list-item>
                            </div>
                            <mat-paginator
                                [length]="totalItems"
                                [pageSize]="pagingData.perpage"
                                (page)="pageChange($event)"
                                [pageIndex]="pagingData.page - 1"
                            ></mat-paginator>
                        </div>

                        <div
                            class="content-list__no-results"
                            *ngIf="!pagingLoading && pages < 1"
                        >
                            No bookings found.
                        </div>

                        <loader *ngIf="pagingLoading"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
