<div class="widget utilisation-widget">
    <div class="widget__header">
        <h4 class="widget__header-heading">Most Under Utilised Groups</h4>
        <ul
            class="widget__header-actions ng-star-inserted"
            *ngIf="utilisationData != null && !!utilisationData['0']"
        >
            <li>
                <a
                    class="utilisation-widget__date"
                    (click)="changeLengthClick(l)"
                    *ngFor="let l of lengths"
                    [ngClass]="{
                        'utilisation-widget__date--active': length == l
                    }"
                    >{{ l }}</a
                >
            </li>
        </ul>
    </div>

    <ng-container
        *ngIf="loading < 1"
        [ngSwitch]="utilisationData != null && !!utilisationData['0']"
    >
        <div class="widget__content" *ngSwitchCase="true">
            <ng-container *ngIf="locations$ | async; let locations">
                <table class="table table--fixed table--default table--center">
                    <thead class="table__thead">
                        <tr>
                            <th *ngFor="let location of locations">
                                {{ location.name }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of [0, 1, 2, 3, 4]">
                            <td
                                *ngFor="let location of locations"
                                [class]="
                                    'utilisation-widget__table-value utilisation-widget__table-value--' +
                                    utilisationWeights[
                                        utilisationData[location.id][i].group_id
                                    ]
                                "
                            >
                                {{ utilisationData[location.id][i].group_id }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
        <div class="widget__content widget__content--center" *ngSwitchDefault>
            <div class="widget__content-error">No utilisation data found.</div>
        </div>
    </ng-container>

    <div class="widget__content widget__content--center" *ngIf="loading > 0">
        <loader></loader>
    </div>
</div>
