import { Component } from '@angular/core';
import { Breadcrumb, BreadcrumbService } from 'src/app/universal/breadcrumbs/breadcrumb.service';

@Component({
    selector: 'topbar-breadcrumbs',
    templateUrl: './topbar-breadcrumbs.component.html',
    styleUrls: ['./topbar-breadcrumbs.component.scss'],
})
export class TopbarBreadcrumbsComponent {
    breadcrumbs: Breadcrumb[];

    constructor(breadcrumbService: BreadcrumbService) {
        breadcrumbService.breadcrumbChanged.subscribe(
            (crumbs: Breadcrumb[]) => {
                this.onBreadcrumbChange(crumbs);
            },
        );
    }

    private onBreadcrumbChange(crumbs: Breadcrumb[]) {
        this.breadcrumbs = crumbs;
    }
}
