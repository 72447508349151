import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstant } from '../../configs/api.constant';
import { ICustomerViewModel, ISageCustomerCheckViewModel, ISageCustomerDto, ISageCustomersViewModel } from '../../county-api/src/customers';
import { IApiResponse, IBaseApiResponse } from '../../county-api/src/responses';
import { ApiHttpClientService } from '../../universal/api/apiHttpClient.service';

@Injectable()
export class CustomersSageService extends ApiHttpClientService {
    /**
     * Consructs injected angular dependencies.
     * @param service The service used to get, post, put and delete people.
     */
    constructor(private service: HttpClient) {
        super();
    }

    get(params: any) {
        return this.service.get<
            IApiResponse<ISageCustomersViewModel>
        >(`${ApiConstant.url}customers/sage`, {
            params: this.filterRequestParams(params),
        });
    }

    getById(sageAccountId: number) {
        return this.service.get<
            IApiResponse<
                ICustomerViewModel<ISageCustomerDto>
            >
        >(`${ApiConstant.url}customers/sage/${sageAccountId}`, {});
    }

    link(customerId: string, sageAccountId: number) {
        return this.service.post<
            IApiResponse<
                ICustomerViewModel<ISageCustomerDto>
            >
        >(`${ApiConstant.url}customers/sage/link`, {
            customer_id: customerId,
            sage_account_id: sageAccountId,
        });
    }

    unlink(customerId: string) {
        return this.service.post<IBaseApiResponse>(
            `${ApiConstant.url}customers/sage/unlink`,
            {
                customer_id: customerId,
            },
        );
    }

    check(sageAccountId: number) {
        return this.service.get<
            IApiResponse<ISageCustomerCheckViewModel>
        >(`${ApiConstant.url}customers/sage/${sageAccountId}/check`, {});
    }
}
