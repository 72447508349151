import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
    HubConnectionBuilder,
    HubConnection,
    LogLevel,
} from '@microsoft/signalr';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import clone from 'lodash/clone';
import find from 'lodash/find';
import { Subject, timer, firstValueFrom } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SetDisplayNotifications } from '../../layout/store/layout.actions';
import { PushNotificationService } from '../../universal/browser-notifications/push-notification.service';
import { ForceReloadDialogComponent } from '../../universal/dialogs/force-reload-dialog.component';
import { CurrentUserService } from '../current-user.service';
import {
    ForceReload,
    GetNextNotifications,
    MarkAllNotificationRead,
    MarkNotificationRead,
    NewNotification,
    NotificationDisableSocket,
    NotificationsDestroy,
    NotificationSocket,
    ReadNotification,
    UnreadNotifications,
} from './notifications.actions';
import { LoggedIn } from './user.actions';
import { INotification } from 'county-api/users';
import { SilentRequest } from '@azure/msal-browser';
import { EventMessage, EventType } from '@azure/msal-browser';

export interface NotificationsStateModel {
    notifications: INotification[];
    loadingNotifications: boolean;
    unreadNotifications: string[];
    hasMoreNotifications?: boolean;
    disableSignalr: boolean;
}

/**
 * Users notification state, handles all interactions will notifications. Also
 * manages the signalr connection for realtime notifications.
 */
@State<NotificationsStateModel>({
    name: 'notifications',
    defaults: {
        notifications: [],
        loadingNotifications: false,
        hasMoreNotifications: null,
        unreadNotifications: [],
        disableSignalr: false,
    },
})
@Injectable()
export class NotificationsState {
    private connection: HubConnection;

    /**
     * State construct, handle DI.
     * @param currentUserService Service to interact with the current user data.
     * @param pushNotifications Service to send native browser notification.
     * @param store Access the current store data.
     * @param dialog Mat dialog service to display the force refresh dialog.
     * @param signalR Service to connect to the signalr api and subscribe to
     * various events.
     */
    constructor(
        private currentUserService: CurrentUserService,
        private pushNotifications: PushNotificationService,
        private store: Store,
        private dialog: MatDialog,
        private msalService: MsalService,
        private broadcastService: MsalBroadcastService,
    ) {
        // Globally handle the click of any notification.
        this.pushNotifications.click$.subscribe(({ notification }) =>
            this.notificationClick(notification),
        );
    }

    /**
     * Get the current unread notifications count.
     * @param state The current state.
     */
    @Selector()
    static unreadCount(state: NotificationsStateModel) {
        return state.unreadNotifications.length;
    }

    /**
     * Get the current stored notifications.
     * @param state The current state.
     */
    @Selector()
    static notifications(state: NotificationsStateModel) {
        return state.notifications;
    }

    /**
     * Determine if notifications are loading.
     * @param state The current state.
     */
    @Selector()
    static notificationsLoading(state: NotificationsStateModel) {
        return state.loadingNotifications;
    }

    /**
     * Return whether there are anymore notifications to load or not.
     * @param state The current state.
     */
    @Selector()
    static notificationsHasMore(state: NotificationsStateModel) {
        return state.hasMoreNotifications;
    }

    @Selector()
    static notificationsDisabled(state: NotificationsStateModel) {
        return state.disableSignalr;
    }

    /**
     * Get the users initial unread notification count when they first auth.
     * @param param0 The state context for this state.
     * @param param1 The action that was fired, just get its payload.
     */
    @Action(LoggedIn, { cancelUncompleted: true })
    getUnreadCount({
        getState,
        dispatch,
    }: StateContext<NotificationsStateModel>) {
        const state = getState();
        if (!state.disableSignalr) {
            dispatch(new NotificationSocket());
        }

        return this.currentUserService
            .getUnreadNotifications()
            .pipe(
                tap(response =>
                    dispatch(
                        new UnreadNotifications(response.result.notifications),
                    ),
                ),
            );
    }

    /**
     * Add a collection of unread notifications to the unread notifications list.
     * @param param0 The state context for this state.
     * @param param1 The unread notifications action.
     */
    @Action(UnreadNotifications)
    addUnreadNotifications(
        { getState, setState }: StateContext<NotificationsStateModel>,
        { payload }: UnreadNotifications,
    ) {
        const state = getState();
        const notificationIds = payload.map(n => n.id);

        setState({
            ...state,
            unreadNotifications: [
                ...notificationIds,
                ...state.unreadNotifications,
            ],
        });
    }

    /**
     * Handle a new notification being added, add the notification to state and
     * then send a browser notification to the user.
     */
    @Action(NewNotification)
    addNewNotification(
        { getState, setState }: StateContext<NotificationsStateModel>,
        { payload }: NewNotification,
    ) {
        const state = getState();

        setState({
            ...state,
            unreadNotifications: [payload.id, ...state.unreadNotifications],
            notifications: [payload, ...state.notifications],
        });

        this.pushNotifications.show(
            `${payload.title} | County Labs`,
            {
                tag: payload.id,
                body: payload.message,
                data: {
                    uri: payload.uri,
                    url: payload.url,
                },
                icon: '/assets/logos/tile_large.png',
            },
            200,
        );
    }

    /**
     * Get the next set of sequential notifications to display to the user.
     * @param param0 Current state context.
     */
    @Action(GetNextNotifications)
    getMoreNotifications({
        getState,
        patchState,
    }: StateContext<NotificationsStateModel>) {
        const state = getState();
        const lastNotification =
            state.notifications[state.notifications.length - 1];
        const lastNotificationIndex = lastNotification
            ? lastNotification.index
            : null;
        patchState({
            loadingNotifications: true,
        });

        // Delay the retrieval request then make the request for the next set of notiications.
        return timer(1000).pipe(
            mergeMap(() => {
                return this.currentUserService.getSequentialNotifications(
                    lastNotificationIndex,
                );
            }),
            tap(response => {
                const currentNotifications = clone(getState().notifications);
                currentNotifications.push(...response.result.notifications);
                // Update the state, storing the new notifications and
                // setting loading and has more props.
                patchState({
                    hasMoreNotifications: response.result.has_more,
                    notifications: currentNotifications,
                    loadingNotifications: false,
                });
            }),
        );
    }

    /**
     * Mark a single notification as read.
     */
    @Action(MarkNotificationRead, { cancelUncompleted: true })
    markReadNotifications(
        {
            getState, setState
        }: StateContext<NotificationsStateModel>,
        { payload }: MarkNotificationRead,
    ) {
        this.currentUserService.postReadNotification(payload)
        .subscribe(
            x => {
                const unreadNotifications = x.result.notifications;
                const unreadNotificationsIds = unreadNotifications.map(x => x.id);
                const hasMore = x.result.has_more;

                const state = getState();

                const updatedNotificationList = this.getUpdatedNotificationsForSingleReadNotification(state.notifications, payload);
                let updatedState = this.generateReadNotificationState(state, updatedNotificationList, unreadNotificationsIds, hasMore);
                setState(updatedState);
            }
        );
    }

    getUpdatedNotificationsForSingleReadNotification(notifications: INotification[], targetNotificationId: string): INotification[]{
        const targetNotification = notifications.find(x => x.id == targetNotificationId);
        const targetNotificationIndex = notifications.findIndex(x => x.id == targetNotification.id);

        let updatedNotification = { ...targetNotification, read_date: new Date() };
        let updatedNotificationList = [ ...notifications.filter(x => x.id != targetNotification.id) ];
        updatedNotificationList.splice(targetNotificationIndex, 0, updatedNotification);

        return updatedNotificationList;
    }

    getUpdatedNotificationsForReadNotifications(notifications: INotification[], targetNotificationIds: string[]): INotification[]{
        let updatedNotificationList: INotification[] = [ ...notifications ];

        for(let id of targetNotificationIds){
            updatedNotificationList = this.getUpdatedNotificationsForSingleReadNotification(updatedNotificationList, id);
        }

        return updatedNotificationList;
    }

    generateReadNotificationState(oldState, notifications : INotification[], unreadNotifications: string[], hasMore: boolean): NotificationsStateModel{
        let updatedState : NotificationsStateModel = {
            ...oldState,
            notifications: notifications,
            unreadNotifications: [
                ...unreadNotifications,
            ],
            hasMoreNotifications: hasMore
        };

        return updatedState;
    }

    /**
     * Mark all notification as read event, this will make the api call to mark
     * all currently tracked unread notification as read. We dont update state
     * here as we wait for the websocket to feed back on the status update.
     */
    @Action(MarkAllNotificationRead, { cancelUncompleted: true })
    markAllReadNotifications({
        getState, setState
    }: StateContext<NotificationsStateModel>) {
        const notificationIds = getState().unreadNotifications;

        if (notificationIds.length > 0) {
            return this.currentUserService.postReadNotifications(
                notificationIds,
            ).subscribe(x => {

                const unreadNotifications = x.result.notifications;
                const unreadNotificationsIds = unreadNotifications.map(x => x.id);
                const hasMore = x.result.has_more;

                const state = getState();
                const updatedNotificationList = this.getUpdatedNotificationsForReadNotifications(state.notifications, notificationIds);
                let updatedState = this.generateReadNotificationState(state, updatedNotificationList, unreadNotificationsIds, hasMore);
                setState(updatedState);

            });
        }
    }

    /**
     * Handle the websocket feeding back to the client that a set of
     * notifications have been set to read.
     */
    @Action(ReadNotification)
    readNotifications(
        { getState, setState }: StateContext<NotificationsStateModel>,
        { payload }: ReadNotification,
    ) {

        const state = getState();

        // Get the ids of the read notifications.
        const readIds = payload.map(n => n.id);

        // Update the locally loaded notifications. Updating the locally stored
        // notification with that returned from the websocket.
        const notifications = state.notifications.map(item => {
            if (readIds.indexOf(item.id) !== -1) {
                return find(payload, { id: item.id });
            }
            return item;
        });

        // Update the unread ids and the local notifications.
        setState({
            ...state,
            unreadNotifications: state.unreadNotifications.filter(
                n => readIds.indexOf(n) === -1,
            ),
            notifications: notifications,
        });
    }

    /**
     * Display a dialog informing the user of the need to reload their browser
     * window, this isnt very clean but is sometimes needed for bugs etc.
     */
    @Action(ForceReload)
    forceReload() {
        const dialogRef = this.dialog.open(ForceReloadDialogComponent, {
            width: '300px',
        });

        // Upon closing the dialog reload the window.
        dialogRef.afterClosed().subscribe(() => {
            window.location.reload();
        });
    }

    /**
     * Register the websocket related to the users notifications.
     */
    @Action(NotificationSocket, { cancelUncompleted: true })
    registerWebsocket({ patchState }: StateContext<NotificationsStateModel>) {
        patchState({ disableSignalr: false });

        if (!this.connection) {
            this.buildConnection();
        }
        this.connection.start().catch(err => console.warn(err));
    }

    @Action(NotificationDisableSocket)
    disableNotifications({
        patchState,
        dispatch,
    }: StateContext<NotificationsStateModel>) {
        patchState({
            disableSignalr: true,
        });

        dispatch(new NotificationsDestroy());
    }

    /**
     * Action to trigger the cleanup of the signalr related observabled.
     * Currently triggered by the app component.
     */
    @Action(NotificationsDestroy)
    destroyNotifications() {
        if (!!this.connection) {
            this.connection.stop();
        }
    }

    private async buildConnection() {
        const silentRequest: SilentRequest = {
            account: this.msalService.instance.getAllAccounts()[0],
            scopes: [environment.apiScope],
        };

        this.connection = new HubConnectionBuilder()
            .withUrl(environment.apiUrl + 'hubs/notifications', {
                accessTokenFactory: () =>
                firstValueFrom(this.msalService.acquireTokenSilent(silentRequest))
                        .then(response => response.accessToken),
                withCredentials: false,
                logger: environment.debug ? LogLevel.Debug : LogLevel.Error,
            })
            .withAutomaticReconnect()
            .build();

        this.configureListener(this.connection);
    }

    /**
     * Handle the click event on a html 5 notification.
     */
    private notificationClick(notification: Notification) {
        // Focus the window that sent the notification.
        parent.focus();
        window.focus();
        this.store.dispatch(new SetDisplayNotifications(true));
    }

    private configureListener(connection: HubConnection) {
        connection.on(
            'notification',
            (newNotifications: INotification) => {
                this.store.dispatch(new NewNotification(newNotifications));
            },
        );

        connection.on(
            'readNotifications',
            (readNotifications: INotification[]) => {
                this.store.dispatch(new ReadNotification(readNotifications));
            },
        );

        connection.on('reload', () => {
            this.store.dispatch(new ForceReload());
        });
    }
}
