<h3 class="mat-dialog-title">Platform Changelog</h3>

<div mat-dialog-content>
    <changelog-list></changelog-list>
</div>

<div class="btns btns--horizontal" mat-dialog-actions>
    <button type="button" class="btn" mat-dialog-close>
        Close
    </button>
</div>

<button
    aria-label="Dismiss"
    class="dialog-close"
    mat-dialog-close
    tabindex="-1"
></button>
