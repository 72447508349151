import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { DeleteDialogComponent } from '../../../universal/dialogs/delete-dialog/delete-dialog.component';
import { CompanyPersonAddDialogComponent } from './company-person-add-dialog.component';
import { IEmployee, IPerson } from '../../../county-api/src/customers';

/**
 * The person add iput used to store employees until submit.
 */
@Component({
    selector: 'company-people-add-input',
    templateUrl: './company-people-add-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CompanyPeopleAddInputComponent),
            multi: true,
        },
    ],
})
export class CompanyPeopleAddInputComponent implements ControlValueAccessor {
    /**
     * The cloned people.
     */
    private _people: IEmployee[] = [];

    /**
     * The get to get the array of people.
     */
    get people(): IEmployee[] {
        return this._people;
    }

    /**
     * The set to set the array of people.
     */
    set people(personDetails: IEmployee[]) {
        this._people = personDetails;
        this.onChange(this._people);
    }

    /**
     * Construct injecting angular dependencies.
     * @param dialog The way to get the dialog functionality.
     */
    constructor(private dialog: MatDialog) {}

    /**
     * Calls when people change.
     */
    onChange = (personDetails: IEmployee[]) => {};

    /**
     * Calls when the item gets touched.
     */
    onTouched = () => {};

    /**
     * Sends the people data back to the parent component.
     */
    writeValue(personDetails: IEmployee[]) {
        this._people = personDetails ? personDetails : [];
    }

    /**
     * Calls the onchange method when something changes in the input.
     */
    registerOnChange(fn: (personDetails: IEmployee[]) => void) {
        this.onChange = fn;
    }

    /**
     * Calls the ontouched method when something gets touched in the input.
     */
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /**
     * Opens the add dialog and pushes the new person to the people array.
     */
    addPersonClick() {
        this.onTouched();
        const openDialog = this.dialog.open(CompanyPersonAddDialogComponent, {
            width: '1000px',
            data: {
                currentPeople: this.getCurrentPeople(),
            },
            panelClass: 'dialog',
        });

        // Pushes the new person data to the people array.
        openDialog.afterClosed().subscribe((result: IEmployee) => {
            if (result) {
                const person = Object.assign([], this._people);
                person.push(result);
                this.people = person;
            }
        });
    }

    /**
     * Opens the add dialog and updates an existing person.
     */
    editPersonClick(index: number, employees) {
        const employee = Object.assign({}, employees);
        this.onTouched();
        const openDialog = this.dialog.open(CompanyPersonAddDialogComponent, {
            width: '1000px',
            data: {
                employee,
                currentPeople: this.getCurrentPeople(),
            },
            panelClass: 'dialog',
        });

        // Overrides the previous person with the edited perosn data.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                const person = Object.assign([] as IEmployee[], this._people);
                person[index] = result;
                this.people = person;
            }
        });
    }

    /**
     * Opens the delete dialog and deletes the person from the people array.
     */
    deletePersonClick(index: number) {
        this.onTouched();
        const openDialog = this.dialog.open(DeleteDialogComponent, {
            width: '500px',
            panelClass: 'dialog',
        });
        // Splices the person by its index from the array of people.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                const person = Object.assign([], this._people);
                person.splice(index, 1);
                this.people = person;
            }
        });
    }

    /**
     * Get the current people ids for the company.
     */
    getCurrentPeople() {
        const currentPeople = new Array<String>();

        for (const person of this.people) {
            if (person.person_id) {
                currentPeople.push(person.person_id);
            }
        }

        return currentPeople;
    }
}
