<div class="dialog-container">
    <div class="dialog-heading">
        <h3 mat-dialog-title>
            {{ title }}
        </h3>
    </div>

    <form (validSubmit)="submit()">
        <div mat-dialog-content>
            <ul class="form" *ngIf="!messageComponent">
                <li class="form__item">
                    <p>{{ message }}</p>
                </li>

                <li class="form__item" *ngIf="reason">
                    <label class="input-label" for="reason">
                        {{ reasonLabel }}
                    </label>
                    <textarea
                        name="reason"
                        id="reason"
                        required
                        [(ngModel)]="result.reason"
                        #reasonInput="ngModel"
                    ></textarea>

                    <div class="input-errors" [ngxErrors]="reasonInput">
                        <label class="input-error" ngxError="required">
                            Please provide a reason.
                        </label>
                    </div>
                </li>
            </ul>

            <ng-container confirm-content-host></ng-container>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" class="btn" [mat-dialog-close]>Cancel</button>

            <input
                type="submit"
                [value]="btnLabel"
                [class]="'btn ' + (btnClass ? btnClass : 'btn--info')"
                *ngIf="!disableConfirm"
            />
        </div>
    </form>

    <button aria-label="Dismiss" class="dialog-close" [mat-dialog-close]></button>
</div>