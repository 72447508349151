import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResponse, Permissions } from 'county-api';
import { ApiConstant } from '../../configs/api.constant';
import { ApiHttpClientService } from '../../universal/api/apiHttpClient.service';
import { IPagingParams } from '../../universal/paging';

/**
 * The permission serviceused to get add and remove users permissions.
 */
@Injectable()
export class PermissionsService extends ApiHttpClientService {
    /**
     * Constructs injected angular dependencies.
     * @param service The injected http service.
     */
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Gets all the user permissions.
     */
    get(params: IPagingParams) {
        return this.service.get<
            IApiResponse<Permissions.IPermissionsViewModel>
        >(ApiConstant.url + 'permissions', {
            params: this.filterRequestParams(params),
        });
    }

    /**
     * Adds one permission to the selected user by there id.
     * @param permissionId The id of the permission to assign the user.
     * @param userId The id of the useer to assign the permission.
     */
    add(permissionId: string, userId: string) {
        return this.service.post<
            IApiResponse<Permissions.IPermissionsViewModel>
        >(
            ApiConstant.url +
                'users/' +
                userId +
                '/permissions/' +
                permissionId,
            { permission_id: permissionId },
        );
    }

    /**
     * Adds multiple permissions to the selected user by there id.
     * @param permissionIds The ids of the permissions to assign the user.
     * @param userId The id of the user to assign the permissions.
     */
    addMultiple(permissionIds: string[], userId: string) {
        return this.service.post<
            IApiResponse<Permissions.IPermissionsViewModel>
        >(ApiConstant.url + 'users/' + userId + '/permissions/', {
            permission_ids: permissionIds,
        });
    }

    /**
     * Removes one permission from the selected user.
     * @param permissionId The id of the permission to remove.
     * @param userId The id of the user to remove the permission from.
     */
    remove(permissionId: string, userId: string) {
        return this.service.delete<
            IApiResponse<Permissions.IPermissionsViewModel>
        >(ApiConstant.url + 'users/' + userId + '/permissions/' + permissionId);
    }

    /**
     * Removes multiple permissions from the selected user.
     * @param permissionIds The ids of the permissions to remove.
     * @param userId The id of the user to remove the permissions from.
     */
    removeMultiple(permissionIds: string[], userId: string) {
        return this.service.request<
            IApiResponse<Permissions.IPermissionsViewModel>
        >('DELETE', ApiConstant.url + 'users/' + userId + '/permissions/', {
            body: { permission_ids: permissionIds },
        });
    }
}
