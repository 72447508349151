import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customers } from 'county-api';
import { PersonOccupationStatus } from 'county-api/enums';
import { PeopleService } from '../../people/people.service';
import { Subject } from 'rxjs';
import {
    takeUntil,
    debounceTime,
    switchMap,
    filter,
    tap,
} from 'rxjs/operators';
import { DestroyController } from 'src/app/universal/components/destroy.controller';
import { NameService } from 'src/app/universal/name/name.service';

/**
 * The component for the person dialog, which gets and passes back the person data.
 */
@Component({
    selector: 'company-person-add-dialog',
    templateUrl: './company-person-add-dialog.component.html',
})
export class CompanyPersonAddDialogComponent extends DestroyController {
    /**
     * Checks whether there is an existing user.
     */
    existingPerson = false;

    /**
     * The array of existing people.
     */
    existingPeople: Customers.IPerson[] = [];

    /**
     * The array of current people.
     */
    currentPeople: string[];

    /**
     * The employee data, with occupation defaulted to employed.
     */
    employee: Customers.IEmployee = {
        person: {
            occupation_status: PersonOccupationStatus.Employed,
        } as Customers.IPerson,
    };

    personSearch$ = new Subject<string>();

    /**
     * Constructs injected angular dependencies.
     */
    constructor(
        private peopleService: PeopleService,
        public dialogRef: MatDialogRef<CompanyPersonAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private nameService: NameService
    ) {
        super();
        // Checks whether data is defined.
        this.employee = data && data.employee ? data.employee : this.employee;

        if (data) {
            // Get the passed employee details from dialog data.
            if (data.employee) {
                this.employee = data.employee;
            }

            this.currentPeople = data.currentPeople || null;
        }

        this.personSearch$
            .pipe(
                tap(() => {
                    this.existingPeople = [];
                }),
                filter(r => !!r),
                debounceTime(500),
                switchMap(r => {
                    return this.peopleService.get({
                        page: 1,
                        perpage: 10,
                        filter: r,
                    });
                }),
                tap(response => {
                    this.existingPeople = response.result.people;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();
    }

    /**
     * Check if the employee exists in the current people list.
     */
    isCurrentPerson(personId: string) {
        return this.currentPeople.indexOf(personId) > -1;
    }

    /**
     * Event to get existing users that match the entered forename/surname.
     */
    onPersonChange() {

        this.employee.person.forename = this.nameService.getFormattedName(this.employee.person?.forename);
        this.employee.person.surname = this.nameService.getFormattedName(this.employee.person?.surname);

        // Build the name to use to search for the name.
        let name = null;
        if (this.employee.person.forename) {
            name = this.employee.person.forename;
        }

        if (this.employee.person.surname) {
            name += ' ' + this.employee.person.surname;
        }

        this.personSearch$.next(name);
    }

    /**
     * Add an existing person to the company.
     * @param person The existing person object.
     */
    useExistingClick(person) {
        if (!this.isCurrentPerson(person.id)) {
            this.peopleService.getById(person.id).subscribe(response => {
                this.employee.person_id = response.result.person.id;
                this.employee.person = response.result.person;
                this.employee.job_title = response.result.person.occupation;
            });
        }
    }

    /**
     * Passes the employee data back to the parent.
     */
    confirm($event) {
        if (this.employee.person_id != null) {
            this.employee.person.occupation = this.employee.job_title;
        } else {
            this.employee.job_title = this.employee.person.occupation;
        }
        this.dialogRef.close(this.employee);
    }
}
