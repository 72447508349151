import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Performance } from 'county-api';
import { Moment } from 'moment';

export interface IRevenueDialogData {
    lastUploadDate: Moment;
}

export enum RevenueDialogResultType {
    Upload = 5,
    Targets = 10,
}

export interface IRevenueDialogResult {
    type: RevenueDialogResultType;

    targets?: Performance.IPerformanceTarget[];

    date?: Moment;

    files?: File[];
}

@Component({
    selector: 'revenue-dialog',
    templateUrl: './revenue-dialog.component.html',
    styleUrls: ['./revenue-dialog.component.scss'],
})
export class RevenueDialogComponent {
    /**
     * The switch case to load the component.
     */
    step: number = 0;

    /**
     * Constructor to set pricate variables and load dependencies.
     * @param dialogRef The dialogs functionality.
     */
    constructor(
        public dialogRef: MatDialogRef<
            RevenueDialogComponent,
            IRevenueDialogResult
        >,
        @Inject(MAT_DIALOG_DATA) public data: IRevenueDialogData,
    ) {}

    changeStep(step: number) {
        this.step = step;
    }

    submit(data: IRevenueDialogResult) {
        this.dialogRef.close(data);
    }
}
