import { environment } from '../../environments/environment';

export interface IApiConstant {
    url: string;
}

export const ApiConstant: IApiConstant = {
    url: environment.apiUrl + 'api/v2/',
};
export const ApiUrl: IApiConstant = {
    url: environment.apiUrl.substring(0, environment.apiUrl.length - 1),
};