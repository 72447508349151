import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bookings, IApiResponse } from 'county-api';
import { ApiConstant } from '../../configs/api.constant';
import { ApiHttpClientService } from '../../universal/api/apiHttpClient.service';
import { IRequestPaging } from '../../universal/paging';

/**
 * The booking customers service, used to get customers, get bookings for a
 * customer and block/unblock customers.
 */
@Injectable()
export class BookingCustomersService extends ApiHttpClientService {
    /**
     * Constructs injected angular dependencies.
     * @param service The http service.
     */
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Retrieve a paged lists of rental bookings.
     * @param params The params to retrieve from the api.
     */
    get(params: IRequestPaging) {
        return this.service.get<
            IApiResponse<Bookings.IBookingCustomersViewModel>
        >(`${ApiConstant.url}bookings/customers`, {
            params: this.filterRequestParams(params),
        });
    }

    /**
     * Gets a single customer by there id.
     * @param customerId The id of the customer to get.
     */
    getById(customerId: string) {
        return this.service.get<
            IApiResponse<Bookings.IBookingCustomerViewModel>
        >(`${ApiConstant.url}bookings/customers/${customerId}`);
    }

    /**
     * Blocks a customer by there id.
     * @param customerId The id of the customer to be blocked.
     */
    blockById(customerId: string) {
        return this.service.post<IApiResponse<void>>(
            `${ApiConstant.url}bookings/customers/${customerId}/block`,
            {},
        );
    }

    /**
     * Unblocks a customer by there id.
     * @param customerId The id of the customer to be unblocked.
     */
    unblockById(customerId: string) {
        return this.service.post<IApiResponse<void>>(
            `${ApiConstant.url}bookings/customers/${customerId}/unblock`,
            {},
        );
    }
}
