import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Users } from 'county-api';
import cloneDeep from 'lodash/cloneDeep';
import { UpdateDashboardLocation } from '../../dashboard/state/dashboard.actions';
import { NotificationsService } from '../../universal/notifications/notifications.service';
import { UpdatedUser } from '../store/user.actions';
import { UserState } from '../store/user.state';
import { UsersService } from '../users.service';
import { UserLocationsChangeDialogComponent } from './locations/user-locations-change-dialog.component';
import { BreadcrumbService } from 'src/app/universal/breadcrumbs/breadcrumb.service';

/**
 * The user component used to display a single users details.
 */
@Component({
    selector: 'user',
    templateUrl: './user.component.html',
})
export class UserComponent implements OnInit {
    /**
     * Store the loaded user details.
     */
    user: Users.IUser;

    /**
     * Store the loaded current user details.
     */
    currentUser: Users.IUser;

    /**
     * Determines the loading state of the component.
     */
    loading = 0;

    /**
     * Mark that the location is being updated.
     */
    loadingLocation = 0;

    /**
     * Mark that the base location is being updated.
     */
    loadingBaseLocation = 0;

    isCurrentUser = false;

    isCurrentAdmin = false;

    /**
     * The safe url to pass the sip url safely.
     */
    sanitizedUrl: SafeUrl;

    /**
     * Constructs injected angular dependencies.
     * @param route The current activated route to get the usert data.
     * @param currentUserService The current user service to get the current user.
     * @param dialog The dialogs functionality.
     * @param usersService The user service to get the users details.
     */
    constructor(
        private usersService: UsersService,
        private notification: NotificationsService,
        private breadcrumbService: BreadcrumbService,
        private dialog: MatDialog,
        private store: Store,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
    ) {}

    /**
     * Calls the resolve to get the users details then calls the current user
     * and locations methods.
     */
    ngOnInit() {
        this.route.data.subscribe((data: { user: Users.IUser }) => {
            this.user = data.user;
            this.currentUser = this.store.selectSnapshot(UserState.user);
            this.isCurrentUser = this.user.id === this.currentUser.id;

            this.breadcrumbService.changeBreadcrumb(
                this.route.snapshot,
                this.user.username,
            );

            // this.getLocations();
            this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(
                'sip:' + this.user.email,
            );
        });
    }

    /**
     * Updates the desired users location.
     */
    updateLocationClick() {
        const openDialog = this.dialog.open(
            UserLocationsChangeDialogComponent,
            {
                width: '500px',
                data: {
                    locationId: this.user.location_id,
                },
                panelClass: 'dialog',
            },
        );

        // Calls the update location to update the users location to the
        // desired location by its id.
        openDialog.afterClosed().subscribe(result => {
            if (this.user.location_id !== result && result) {
                this.loadingLocation++;
                this.usersService
                    .updateLocation(this.user.id, result)
                    .subscribe(
                        response => {
                            this.user = response.result.user;
                            this.notification.success(
                                'The users location updated successfully.',
                            );
                            // Fire the user updated event in order to reload the
                            // page if were updating the current user.
                            this.userUpdated(this.user);
                        },
                        () => {
                            this.notification.error(
                                'The users location failed to update.',
                            );
                        },
                        () => this.loadingLocation--,
                    );
            }
        });
    }

    /**
     * Updates the desired users base location.
     */
    updateBaseLocationClick() {
        const openDialog = this.dialog.open(
            UserLocationsChangeDialogComponent,
            {
                width: '500px',
                data: {
                    locationId: this.user.base_location_id,
                    isBase: true,
                },
                panelClass: 'dialog',
            },
        );

        // Calls the update location to update the users base location to the
        // desired base location by its id.
        openDialog.afterClosed().subscribe(result => {
            if (this.user.base_location_id !== result && result) {
                this.loadingBaseLocation++;
                this.usersService
                    .updateBaseLocation(this.user.id, result)
                    .subscribe(
                        response => {
                            this.user = response.result.user;
                            this.notification.success(
                                'The users base location updated successfully.',
                            );

                            // Fire the user updated event in order to reload the page if were updating
                            // the current user.
                            this.userUpdated(this.user);
                        },
                        () => {
                            this.notification.error(
                                'The users base location failed to update.',
                            );
                        },
                        () => this.loadingBaseLocation--,
                    );
            }
        });
    }

    /**
     * Reloads the pages when your the current user.
     */
    userUpdated(user: Users.IUser) {
        if (this.isCurrentUser) {
            this.store.dispatch([
                new UpdatedUser(cloneDeep(user)),
                new UpdateDashboardLocation(user.location_id),
            ]);
        }
    }
}
