import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { UserGuard } from '../users/user.guard';
import { BookingComponent } from './booking/booking.component';
import { BookingsResolve } from './booking/bookings.resolve';
import { BookingsComponent } from './bookings.component';
import { BookingCustomersComponent } from './customers/booking-customers.component';
import { BookingsCustomerComponent } from './customers/customer/bookings-customer.component';
import { BookingsCustomerResolve } from './customers/customer/bookings-customer.resolve';
import { BookingsReportComponent } from './report/bookings-report.component';

/**
 * Base routes for the bookings section of the app.
 */
const routes: Routes = [
    {
        path: 'bookings',
        canActivate: [MsalGuard],
        data: { breadcrumb: 'Bookings' },
        children: [
            {
                path: '',
                component: BookingsComponent,
                pathMatch: 'full',
                canActivate: [MsalGuard, UserGuard],
            },
            {
                path: 'report',
                component: BookingsReportComponent,
                canActivate: [MsalGuard, UserGuard],
                pathMatch: 'full',
                data: { breadcrumb: 'Report' },
            },
            {
                path: 'customers',
                canActivate: [MsalGuard],
                data: { breadcrumb: 'Customers' },
                children: [
                    {
                        path: '',
                        component: BookingCustomersComponent,
                        pathMatch: 'full',
                        canActivate: [MsalGuard, UserGuard],
                    },
                    {
                        path: ':id',
                        component: BookingsCustomerComponent,
                        resolve: { customer: BookingsCustomerResolve },
                        runGuardsAndResolvers: 'always',
                        canActivate: [MsalGuard, UserGuard],
                        data: {
                            breadcrumb: 'Customer',
                            menuUrl: '/bookings/customers',
                        },
                    },
                ],
            },
            {
                path: ':id',
                component: BookingComponent,
                canActivate: [UserGuard],
                resolve: { booking: BookingsResolve },
                data: { breadcrumb: 'Booking', menuUrl: '/bookings' },
                runGuardsAndResolvers: 'always',
            },
        ],
    },
];

export const routedComponents = [
    BookingsComponent,
    BookingComponent,
    BookingCustomersComponent,
    BookingsCustomerComponent,
    BookingsReportComponent,
];

/**
 * The routing module for the bookings.
 */
@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    declarations: [],
    exports: [RouterModule],
    providers: [],
})
export class BookingsRoutingModule {}
