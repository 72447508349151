import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Users } from 'county-api';

@Component({
    selector: 'user-profile',
    templateUrl: './sidebar-profile.component.html',
    styleUrls: ['./sidebar-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarProfileComponent {
    @Input()
    user: Users.IUser;
}
