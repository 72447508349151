import { Injectable } from '@angular/core';

@Injectable()
export class UriService {
    /**
     * Cache for the already parsed uri.
     */
    private parseUriCache: { [key: string]: string | false } = {};

    /**
     * Key value pair or uri to url.
     */
    private uriUrls = {
        insurance: 'insurance',
        'tariff-rules': 'tariff/rules',
        'fleet-incidents': 'fleet/incidents',
        'fleet-vehicles': 'fleet/vehicles',
        'fleet-servicing-entries': 'fleet/servicing/entries',
    };

    /**
     * Parse an item uri into a url.
     */
    parseUri(uri: string) {
        if (!uri) {
            return false;
        }

        const uriParts = uri.split(':');
        let url = '/';
        const cachedUrl = this.parseUriCache[uri];

        // If we have already calculated the uri just get it from the cache.
        if (cachedUrl !== undefined) {
            return cachedUrl;
        }

        // Ensure the uri is a valid format and that the first coponent is the county prefix.
        if (uriParts.length >= 3 && uriParts[0] === 'county') {
            // Get the start of the uri from the url array.
            const typeUrl = this.uriUrls[uriParts[1]];

            if (typeUrl === undefined) {
                this.parseUriCache[uri] = false;
                return false;
            }

            // Append the rest of the parts to the url and then set it into chave.
            url += typeUrl + '/' + uriParts.splice(2).join('/');
            this.parseUriCache[uri] = url;
            return url;
        }

        return false;
    }
}
