import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    InjectionToken,
    Input,
    QueryList,
} from '@angular/core';
import {
    MatFormFieldControl,
    MatPrefix,
    MatSuffix,
    MAT_PREFIX,
    MAT_SUFFIX,
} from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'form-field',
    templateUrl: './form-field.component.html',

    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.form-field]': 'true',
        '[class.form-field--invalid]': '_control?.errorState',

        '[class.form-field--s-x-narrow]': 'pSize == "x-narrow"',
        '[class.form-field--s-narrow]': 'pSize == "narrow"',
        '[class.form-field--s-wide]': 'pSize == "wide"',
        '[class.form-field--s-full]': 'pSize == "full"',
    },
})
export class FormFieldComponent {
    private static nextUniqueId = 1;

    @Input()
    pLabel: string;

    @Input()
    pHelpLabel: string;

    @Input()
    pErrorLabel: string;

    @Input()
    pSize: string;

    @Input()
    get pOptional() {
        return this._optional;
    }

    set pOptional(value: any) {
        this._optional = coerceBooleanProperty(value);
    }
    private _optional = false;

    _labelId = `form-field-label-${FormFieldComponent.nextUniqueId++}`;

    _helpLabelId = `form-field-help-label-${FormFieldComponent.nextUniqueId++}`;

    @ContentChild(MatFormFieldControl, { static: true })
    _control: MatFormFieldControl<any>;

    @ContentChildren(MAT_PREFIX as any, { descendants: true })
    _prefixChildren: QueryList<MatPrefix>;

    @ContentChildren(MAT_SUFFIX as any, { descendants: true })
    _suffixChildren: QueryList<MatSuffix>;

    private _destroyed = new Subject<void>();

    constructor(
        public _elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngAfterContentInit() {
        const control = this._control;
        if (control) {
            if (control.controlType) {
                this._elementRef.nativeElement.classList.add(
                    `form-field--type-${control.controlType}`,
                );
            }

            control.stateChanges
                .pipe(startWith(null!))
                .subscribe(() => this._changeDetectorRef.markForCheck());

            if (control.ngControl && control.ngControl.valueChanges) {
                control.ngControl.valueChanges
                    .pipe(takeUntil(this._destroyed))
                    .subscribe(() => this._changeDetectorRef.markForCheck());
            }
        }
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }
}
