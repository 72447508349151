import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customers, IApiResponse } from 'county-api';
import { ApiHttpClientService } from 'src/app/universal/api/apiHttpClient.service';
import { ApiConstant } from '../../configs/api.constant';
import { IRequestPaging } from '../../universal/paging';
/**
 * The service to get, post, put and delete jobs.
 */
@Injectable()
export class JobsService extends ApiHttpClientService {
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Get a collection of companies based on the passed in params.
     * @param params Query params to filter vehicles result, null values will be removed.
     */
    get(params: IRequestPaging) {
        return this.service.get<IApiResponse<Customers.ICustomerJobsViewModel>>(
            ApiConstant.url + 'customers/jobs',
            {
                params: this.filterRequestParams(params),
            },
        );
    }

    /**
     * Make post request to create a new job.
     * @param vehicle The job details to create.
     */
    create(job: Customers.ICustomerJob) {
        return this.service.post<IApiResponse<Customers.ICustomerJobViewModel>>(
            ApiConstant.url + 'customers/jobs',
            {
                job,
            } as Customers.ICustomerJobViewModel,
        );
    }

    /**
     * Makes a put request to update the already existing job by its id.
     * @param id The id of the job to update.
     * @param job The new job data to update by.
     */
    edit(id: string, job: Customers.ICustomerJob) {
        return this.service.put<IApiResponse<Customers.ICustomerJobViewModel>>(
            ApiConstant.url + 'customers/jobs/' + id,
            { job } as Customers.ICustomerJobViewModel,
        );
    }

    /**
     * Makes a delete request to delete the job by its id.
     * @param id The id of the job to delete.
     */
    delete(id: string) {
        return this.service.delete<
            IApiResponse<Customers.ICustomerJobViewModel>
        >(ApiConstant.url + 'customers/jobs/' + id);
    }
}
