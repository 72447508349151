import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Users } from 'county-api';
import { MarkNotificationRead } from '../../../../users/store/notifications.actions';
import { LayoutState } from '../../../store/layout.state';
import { SetDisplayNotifications } from '../../../store/layout.actions';
import { INotification } from 'county-api/users';

@Component({
    selector: 'sidebar-notification',
    templateUrl: './sidebar-notification.component.html',
    styleUrls: ['./sidebar-notification.component.scss'],
})
export class SidebarNotificationComponent {
    @Input()
    notification: INotification;

    constructor(private store: Store) {}

    readNotificationClick() {
        const events = [];
        if (!this.store.selectSnapshot(LayoutState.pinState)) {
            events.push(new SetDisplayNotifications(false));
        }

        if (!this.notification.read_date) {
            events.push(new MarkNotificationRead(this.notification.id));
        }

        this.store.dispatch(events);
    }
}
