import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import assign from 'lodash/assign';
import { NewToastNotification } from './notifications.actions';

/**
 * Service to handle display toast notifications to the user.
 */
@Injectable()
export class NotificationsService {
    options: MatSnackBarConfig = {
        duration: 6000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
    };

    /**
     * Inject dependencies.
     * @param snackBar Material snackbar service.
     */
    constructor(private snackBar: MatSnackBar, private actions$: Actions) {
        this.actions$
            .pipe(ofActionSuccessful(NewToastNotification))
            .subscribe(({ payload }: NewToastNotification) => {
                this[payload.type](
                    payload.message,
                    payload.opt,
                    payload.action,
                );
            });
    }

    /**
     * Display toast success message.
     * @param message The message to add to the content of the notification.
     * @param opt Extra options to apply to the notification.
     */
    success(message: string, opt?: any, action?: null) {
        return this.snackBar.open(
            message,
            action,
            assign(
                { panelClass: ['snackbar', 'snackbar--success'] },
                this.options,
                opt,
            ),
        );
    }

    /**
     * Display toast error message.
     * @param message The message to add to the content of the notification.
     * @param opt Extra options to apply to the notification.
     */
    error(message: string, opt?: any, action?: null) {
        return this.snackBar.open(
            message,
            action,
            assign(
                {
                    panelClass: ['snackbar', 'snackbar--error'],
                },
                this.options,
                opt,
            ),
        );
    }

    /**
     * Display toast warning message.
     * @param message The message to add to the content of the notification.
     * @param opt Extra options to apply to the notification.
     */
    warn(message: string, opt?: any, action?: null) {
        return this.snackBar.open(
            message,
            action,
            assign(
                {
                    panelClass: ['snackbar', 'snackbar--warm'],
                },
                this.options,
                opt,
            ),
        );
    }

    /**
     * Display toast informative message.
     * @param message The message to add to the content of the notification.
     * @param opt Extra options to apply to the notification.
     */
    info(message: string, opt?: any, action?: null) {
        return this.snackBar.open(
            message,
            action,
            assign(
                {
                    panelClass: ['snackbar', 'snackbar--info'],
                },
                this.options,
                opt,
            ),
        );
    }

    /**
     * Display toast loading message.
     * @param message The message to add to the content of the notification.
     * @param opt Extra options to apply to the notification.
     */
    wait(message: string, opt?: any, action?: null) {
        return this.snackBar.open(
            message,
            action,
            assign(
                {
                    panelClass: ['snackbar', 'snackbar--info'],
                },
                this.options,
                opt,
            ),
        );
    }
}
