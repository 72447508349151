import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { Users } from 'county-api';
import keyBy from 'lodash/keyBy';
import { tap } from 'rxjs/operators';
import { UsersService } from '../users.service';
import { LoggedIn } from './user.actions';
import { GetUsers, GotUsers } from './users.actions';

export interface UsersStateModel {
    users: { [key: string]: Users.IUser };

    loading: boolean;
}

@State<UsersStateModel>({
    name: 'users',
    defaults: {
        users: null,
        loading: false,
    },
})
@Injectable()
export class UsersState {
    constructor(private usersService: UsersService, private store: Store) {}

    @Action(LoggedIn)
    getUsers(
        { dispatch, patchState, getState }: StateContext<UsersStateModel>,
        action: GetUsers,
    ) {
        const state = getState();

        if (state.users == null && state.loading === false) {
            patchState({ loading: true });
            return this.usersService
                .getAllUsers()
                .pipe(
                    tap(response =>
                        dispatch(new GotUsers(response.result.users)),
                    ),
                );
        }
    }

    @Action(GotUsers)
    gotUsers(
        { patchState }: StateContext<UsersStateModel>,
        { payload }: GotUsers,
    ) {
        patchState({
            loading: false,
            users: keyBy(payload, 'id'),
        });
    }
}
