import { Component, Input } from '@angular/core';
import { Customers } from 'county-api';

/**
 * The person list item component.
 */
@Component({
    selector: 'person-list-item',
    templateUrl: './person-list-item.component.html',
})
export class PersonListItemComponent {
    /**
     * The individual person data, input from the parent.
     */
    @Input() personItem: Customers.IPerson;
}
