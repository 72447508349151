import {
    Directive,
    ElementRef,
    Renderer2,
    OnChanges,
    Input,
} from '@angular/core';

/**
 * This is a hotfix to resolve issues with null router links. See this angular
 * issue @link https://github.com/angular/angular/issues/13980.
 * Bit of a rubbish fix.
 */
@Directive({
    selector: '[routerLink], [routerLinkClick]',
})
export class EmptyRouterLinkDirective implements OnChanges {
    @Input() routerLink: any;

    @Input() routerLinkClick: any;

    constructor(private elRef: ElementRef, private renderer: Renderer2) {}

    ngOnChanges() {
        if (this.routerLink == null && this.routerLinkClick == null) {
            setTimeout(
                () =>
                    this.renderer.removeAttribute(
                        this.elRef.nativeElement,
                        'href',
                    ),
                0,
            );
        }
    }
}
