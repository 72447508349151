import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngxs/store";
import { BreadcrumbService } from "angular-crumbs";
import { Users } from "county-api/index";
import { UserState } from "src/app/users/store/user.state";
import { UsersService } from "src/app/users/users.service";

/**
 * The component holding performance data controls.
 */
@Component({
    selector: 'peformance-data',
    templateUrl: './performance-data.component.html',
})
export class PerformanceDataComponent implements OnInit {

        /**
     * Store the loaded user details.
     */
        user: Users.IUser;
        
    /**
     * Store the loaded current user details.
     */
    currentUser: Users.IUser;

    isCurrentUser = false;


    /**
     * Constructs injected angular dependencies.
     * @param route The current activated route to get the usert data.
     * @param currentUserService The current user service to get the current user.
     * @param dialog The dialogs functionality.
     * @param usersService The user service to get the users details.
     */
    constructor(
        private usersService: UsersService,
        private breadcrumbService: BreadcrumbService,
        private dialog: MatDialog,
        private store: Store,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
    ) {}

        /**
     * Calls the resolve to get the users details then calls the current user
     * and locations methods.
     */
        ngOnInit() {
            this.route.data.subscribe((data: { user: Users.IUser }) => {
                this.user = data.user;
                this.currentUser = this.store.selectSnapshot(UserState.user);
                this.isCurrentUser = this.user != null && this.currentUser != null && this.user.id === this.currentUser.id;
    
                if (this.user != null){
                    this.breadcrumbService.changeBreadcrumb(
                        this.route.snapshot,
                        this.user.username,
                    );
                }

            });
        }
}