<a [routerLink]="['/']" class="site-mobile-navigation__home">
    <div class="site-mobile-navigation__home-logo">
        <svg>
            <use xlink:href="#logo"></use>
        </svg>
    </div>
</a>

<div class="site-mobile-navigation__menu">
    <a
        class="site-mobile-navigation__hamburger hamburger"
        (click)="hamburgerClick()"
    >
        <span class="hamburger__line"></span>
        <span class="hamburger__line"></span>
        <span class="hamburger__line"></span>
    </a>
    <user-profile
        [user]="user$"
        *ngIf="(authState$ | async) == authStates.Authenticated"
    ></user-profile>
</div>
