import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'spinner',
    template: '<div class="spinner__icon"></div>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.spinner]': 'true',

        '[class.spinner--s-x-small]': 'pSize == "x-small"',
        '[class.spinner--s-small]': 'pSize == "small"',
        '[class.spinner--s-large]': 'pSize == "large"',
        '[class.spinner--s-x-large]': 'pSize == "x-large"',

        '[class.spinner--c-current]': 'pColor == "current"',
    },
})
export class SpinnerComponent {
    @Input()
    @HostBinding('class.spinner--inverted')
    get pInverted() {
        return this._inverted;
    }

    set pInverted(value: any) {
        this._inverted = coerceBooleanProperty(value);
    }
    private _inverted: boolean = false;

    @Input()
    pSize: string;

    @Input()
    pColor: string;
}
