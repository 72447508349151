import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';

export type distribution =
    | 'equalSpacing'
    | 'leading'
    | 'trailing'
    | 'center'
    | 'fill'
    | 'fillEvenly';

export type alignment = 'leading' | 'trailing' | 'center' | 'fill' | 'baseline';

@Component({
    selector: 'stack',
    templateUrl: './stack.component.html',
    host: {
        '[class.stack]': 'true',
        '[class.stack--vertical]': 'pVertical',
        '[class.stack--noWrap]': '!pWrap',

        '[class.stack--d-equalSpacing]': 'pDistribution == "equalSpacing"',
        '[class.stack--d-leading]': 'pDistribution == "leading"',
        '[class.stack--d-trailing]': 'pDistribution == "trailing"',
        '[class.stack--d-center]': 'pDistribution == "center"',
        '[class.stack--d-fill]': 'pDistribution == "fill"',

        '[class.stack--a-leading]': 'pAlignment == "leading"',
        '[class.stack--a-trailing]': 'pAlignment == "trailing"',
        '[class.stack--a-center]': 'pAlignment == "center"',
        '[class.stack--a-fill]': 'pAlignment == "fill"',
        '[class.stack--a-baseline]': 'pAlignment == "baseline"',

        '[class.stack--s-none]': 'pSpacing == "none"',
        '[class.stack--s-xxx-small]': 'pSpacing == "xxx-small"',
        '[class.stack--s-xx-small]': 'pSpacing == "xx-small"',
        '[class.stack--s-x-small]': 'pSpacing == "x-small"',
        '[class.stack--s-small]': 'pSpacing == "small"',
        '[class.stack--s-medium]': 'pSpacing == "medium"',
        '[class.stack--s-large]': 'pSpacing == "large"',
        '[class.stack--s-x-large]': 'pSpacing == "x-large"',
        '[class.stack--s-xx-large]': 'pSpacing == "xx-large"',
        '[class.stack--s-xxx-large]': 'pSpacing == "xxx-large"',
    },
})
export class StackComponent {
    @Input()
    pDistribution: distribution;

    @Input()
    pAlignment: alignment;

    @Input()
    pSpacing: string;

    @Input()
    get pVertical(): boolean {
        return this._vertical;
    }
    set pVertical(value: boolean) {
        this._vertical = coerceBooleanProperty(value);
    }
    private _vertical = false;

    @Input()
    get pWrap(): boolean {
        return this._wrap;
    }
    set pWrap(value: boolean) {
        this._wrap = coerceBooleanProperty(value);
    }
    private _wrap = true;

    @Input()
    get pDividers(): boolean {
        return this._dividers;
    }
    set pDividers(value: boolean) {
        this._dividers = coerceBooleanProperty(value);
    }
    private _dividers = false;

    static ngAcceptInputType_vertical: BooleanInput;
    static ngAcceptInputType_wrap: BooleanInput;
}
