import { getLuminance, parseToHsl, parseToRgb, toColorString } from 'polished';

export const isLight = color => {
    const { red, green, blue } = parseToRgb(color);
    const yiq = (red * 299 + green * 587 + blue * 114) / 1000;
    return yiq >= 140;
};

export const getOnTextColor = x => (isLight(x) ? '#343434' : '#FFF');

const smoothSaturation = (saturation, luminance) => {
    const isBright = luminance > 0.6;

    if (isBright) {
        return saturation * 0.8;
    }

    return saturation * 0.45;
};

const smoothLightness = (lightness, luminance) => {
    const isBright = luminance > 0.6;

    if (isBright) {
        return 0.95 - lightness * 0.03;
    }

    return 0.95 - lightness * 0.06;
};

export const getLightVariant = color => {
    const { hue, saturation, lightness } = parseToHsl(color);
    const luminance = getLuminance(color);

    return toColorString({
        hue,
        saturation: smoothSaturation(saturation, luminance),
        lightness: smoothLightness(lightness, luminance),
    });
};
