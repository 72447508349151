import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { UserGuard } from '../users/user.guard';
import { EndorsementComponent } from './endorsement.component';

/**
 * Base routes for the insurance section of the app.
 */
const routes: Routes = [
    {
        path: 'endorsements',
        data: { breadcrumb: 'Endorsements' },
        children: [
            {
                path: '',
                component: EndorsementComponent,
                pathMatch: 'full',
                canActivate: [MsalGuard, UserGuard],
            },
        ],
    },
];

export const routedComponents = [EndorsementComponent];

/**
 * The routing module for the endorsements.
 */
@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    declarations: [],
    exports: [RouterModule],
    providers: [],
})
export class EndorsementRoutingModule {}
