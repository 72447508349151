<topbar>
    <h1 topbar-title>Online Customers</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="content-section">
            <div class="wrapper wrapper--large">
                <div class="content-card">
                    <div class="content-list bookings-customers-list">
                        <form #form="ngForm">
                            <div
                                class="content-list-toolbar booking-customer-search"
                                [ngClass]="{
                                    'booking-customer-search--large': !form.valid
                                }"
                            >
                                <ul
                                    class="content-list-toolbar__list list-horizontal"
                                >
                                    <li
                                        class="content-list-toolbar__item list--item__right"
                                    >
                                        <label
                                            class="booking-customer-search__title"
                                            for="search"
                                            >Search for a Customer</label
                                        >

                                        <div
                                            class="content-list-toolbar__input input-group input-group--accent"
                                        >
                                            <div class="input-group__prepend">
                                                <label
                                                    class="input-group__text"
                                                    for="search"
                                                >
                                                    <fa-icon
                                                        [icon]="[
                                                            'far',
                                                            'search'
                                                        ]"
                                                    ></fa-icon>
                                                </label>
                                            </div>
                                            <input
                                                type="search"
                                                name="search"
                                                id="search"
                                                class="input-group__input"
                                                required
                                                [ngModel]="pagingData.filter"
                                                (input)="
                                                    filterUpdate(
                                                        $event.target,
                                                        'filter'
                                                    )
                                                "
                                                #search="ngModel"
                                            />
                                        </div>

                                        <div
                                            class="input-errors"
                                            [ngxErrors]="search"
                                        >
                                            <label
                                                for="search"
                                                ngxError="required"
                                                class="input-error"
                                            >
                                                Please enter a search term.
                                            </label>
                                            <label
                                                for="search"
                                                ngxError="minLength"
                                                class="input-error"
                                            >
                                                Please enter at least 3 letters
                                                to search for an online
                                                customer.
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </form>

                        <div *ngIf="loading < 1 && totalItems > 0">
                            <div class="content-list__list">
                                <booking-customers-list-item
                                    *ngFor="let customer of customers"
                                    [customer]="customer"
                                >
                                </booking-customers-list-item>
                            </div>
                            <!-- <mat-paginator
                                [length]="totalItems"
                                [pageSize]="pagingData.perpage"
                                (page)="pageChange($event)"
                                [pageIndex]="pagingData.page - 1"

                            ></mat-paginator> -->
                        </div>

                        <div
                            class="content-list__no-results"
                            *ngIf="
                                loading < 1 &&
                                totalItems < 1 &&
                                pagingData.filter
                            "
                        >
                            No online customers found.
                        </div>

                        <loader *ngIf="loading > 0"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
