import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Users } from 'county-api';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    IPagingParams,
    IRequestPaging,
    PagingController,
} from '../universal/paging';
import { UsersService } from './users.service';

/**
 * The users component used to display the list of users.
 */
@Component({
    selector: 'users',
    templateUrl: './users.component.html',
})
export class UsersComponent extends PagingController<IRequestPaging>
    implements OnInit {
    /**
     * The array of users.
     */
    users: Users.IUser[];

    /**
     * Query details for collecting the users from the api.
     */
    pagingData: IRequestPaging = {
        page: 1,
        filter: null,
        perpage: null,
    };

    /**
     * Determine the loading state of the page.
     */
    loading = 0;

    /**
     * The last active request subscription used for cancelling of the request.
     */
    private request: Subscription;

    /**
     * Constructs injecting angular dependencies.
     * @param usersService The users service used toi get all the users.
     * @param router Router service used to update the url with the new query strings.
     * @param route Needed for the paging component to gather the current query string params.
     */
    constructor(
        private usersService: UsersService,
        router: Router,
        route: ActivatedRoute,
    ) {
        super(router, route);
    }

    /**
     * Update the local paging data from the url params.
     * @param params The query params from the url.
     */
    updateLocalParams(params: IPagingParams) {
        this.users = [];
        this.pages = 0;

        // Gather the paging information from the url.
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            filter: params['filter'] ? params['filter'] : null,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
        } as IRequestPaging;

        // Floor the page number to 1.
        if (this.pagingData.page < 1) {
            this.pagingData.page = 1;
        }

        return this.pagingData;
    }

    /**
     * Load the latest set of data based on the passed in pagingData.
     * @param pagingData The data returned from updateLocalParams.
     */
    updateData(pagingData: IRequestPaging) {
        this.loading++;

        // If any active request cancel it and then decrease the loader.
        if (this.request && !this.request.closed) {
            this.request.unsubscribe();
            this.loading--;
        }

        // Make a new request for the users details.
        // Handle both success and error states.
        this.request = this.usersService
            .get(pagingData)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                response => {
                    this.users = response.result.users;
                    this.pages = response.result.paging.total_pages;
                    this.totalItems = response.result.paging.total;
                },
                () => {
                    this.pages = 0;
                },
                () => this.loading--,
            );
    }
}
