<a
    class="user-details__notifications"
    title="View recent notifications"
    (click)="openNotificationsClick()"
    [ngClass]="{
        'user-details__notifications--unread': unreadNotificationCount > 0,
        'user-details__notifications--animate': animateCount > 0
    }"
>
    <span
        class="user-details__notifications-count"
        data-ng-if="unreadNotificationCount > 0"
        >{{ unreadNotificationCount }}</span
    >
    <fa-icon
        class="user-details__notifications-bell"
        [icon]="['fas', 'bell']"
    ></fa-icon>
</a>
