export enum IncidentItemType {
    Dent = 'Dent',
    Scratch = 'Scratch',
    Scuff = 'Scuff',
    Crease = 'Crease',
    Chip = 'Chip',
    Interior = 'Interior',
    Other = 'Other',
}

export enum IncidentItemTypeLabels {
    Dent = 'Dent',
    Scratch = 'Scratch',
    Scuff = 'Scuff',
    Crease = 'Crease',
    Chip = 'Chip In Glass',
    Interior = 'Interior',
    Other = 'Other',
}
