<ng-container *ngIf="(authState$ | async) == authStates.Authenticated">
    <user-profile
        [user]="user$ | async"
        *ngIf="(isMobile$ | async) === false"
    ></user-profile>

    <div class="site-sidebar__mobile-menu" *ngIf="isMobile$ | async">
        <h5 class="site-sidebar__mobile-menu-title">Menu</h5>
        <a
            class="site-sidebar__mobile-menu-link"
            (click)="toggleSidebarClick()"
        >
            Close <fa-icon icon="times"></fa-icon>
        </a>
    </div>

    <div class="site-menus micro-scroller">
        <div class="site-menu" [ngClass]="{ 'site-menu--active': menuActive }">
            <ng-container *ngFor="let menu of menus">
                <sidebar-menu
                    [menu]="menu"
                    *hasPermission="menu.permission"
                    (sidebarMenuHideMenus)="hideMenu()"
                >
                </sidebar-menu>
            </ng-container>
        </div>
        <sidebar-footer></sidebar-footer>
    </div>
</ng-container>
