import { Directive } from '@angular/core';

@Directive({
  selector: '[pCardActions]'
})
export class CardActionsDirective {

  constructor() { }

}
