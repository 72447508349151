/* tslint:disable:no-conflicting-lifecycle */
import {
    AfterContentInit,
    ContentChildren,
    Directive,
    DoCheck,
    Host,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    QueryList,
    SimpleChanges,
    SkipSelf,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormGroupDirective,
    NgForm,
} from '@angular/forms';
import castArray from 'lodash/castArray';
import isEqual from 'lodash/isEqual';
import { FormFieldComponent } from 'platform-ui/form-field';
import { merge as observableMerge, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { NgxErrorDefaultDirective } from './ngxerror-default.directive';
import { NgxErrorElement } from './ngxerror-element';

export type ErrorOptions = string | string[];

export function controlPath(
    name: string | null,
    parent: ControlContainer,
): string[] {
    return [...parent.path, name];
}

/**
 * Forked version of https://github.com/UltimateAngular/ngx-errors/ as that only
 * functions with model defined forms, we use template defined. Changes were
 * made to pass the control itself.
 */
@Directive({
    selector: '[ngxErrors]',
    exportAs: 'ngxErrors',
})
export class NgxErrorsDirective
    implements AfterContentInit, DoCheck, OnChanges, OnDestroy {
    @Input()
    ngxErrors: AbstractControl | string;

    @Input()
    set ngxErrorsWhen(value: ErrorOptions) {
        this._rules = castArray(value);
    }

    private _ready: boolean = false;

    private _subscription: Subscription;

    private _states$ = new Subject<string[]>();

    private _rules: string[] = ['dirty', 'touched'];

    get invalid() {
        return this._control.invalid || !!this._control.errors;
    }

    @ContentChildren(NgxErrorElement)
    private _errorChildren: QueryList<NgxErrorElement>;

    @ContentChildren(NgxErrorDefaultDirective)
    private _errorDefaultChildren: QueryList<NgxErrorDefaultDirective>;

    private _control: AbstractControl;

    private _form: NgForm | FormGroupDirective;

    constructor(
        @Optional() @Host() @SkipSelf() private _parent: ControlContainer,
        @Optional() @Host() form?: NgForm,
        @Optional() @Host() formGroup?: FormGroupDirective,

        @Optional() @Host() private _formField?: FormFieldComponent,
    ) {
        if (form) {
            this._form = form;
        } else if (formGroup) {
            this._form = formGroup;
        }
    }

    ngAfterContentInit() {
        this._ready = true;
        this.buildSubscrition();
        this.checkStatus();
        this.setControlIds();
    }

    ngDoCheck() {
        if (this._control && this._ready) {
            this._states$.next(this._rules.filter(rule => this._control[rule]));
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['ngxErrors']) {
            let control: AbstractControl;
            if (typeof this.ngxErrors === 'string') {
                if (
                    this.ngxErrors === '' &&
                    this._formField &&
                    this._formField._control
                ) {
                    control = this._formField._control.ngControl as any;
                } else {
                    control = this._form.form.get(
                        controlPath(this.ngxErrors, this._parent),
                    );
                }
            } else {
                control = this.ngxErrors;
            }

            if (control != this._control) {
                this._control = control;
                // If the control has changed we need to rebuild the subscription
                // for the new control.
                if (this._ready) {
                    this.ngAfterContentInit();
                }
            }
        }
    }

    ngOnDestroy() {
        this._states$.complete();
        this._subscription.unsubscribe();
    }

    private hasError(name: string) {
        return this._control.hasError(name);
    }

    private getError(name: string) {
        return this._control.getError(name);
    }

    private validate() {
        const errorCount =
            this._errorChildren.length + this._errorDefaultChildren.length;

        if (this._control == null) {
            throw new Error('ngxErrors directive requires a valid control.');
        }
        if (errorCount === 0) {
            throw new Error(
                'ngxErrors directive requires at least one child ngxError, ngtError or ngxErrorDefault directive.',
            );
        }
        if (this._errorDefaultChildren.length > 1) {
            throw new Error(
                'ngxErrors does not support more than one ngxErrorDefault directive.',
            );
        }
    }

    /**
     * Build the subscription to listen to all the needed events to show errors.
     */
    private buildSubscrition() {
        if (this._subscription !== null && this._subscription !== undefined) {
            this._subscription.unsubscribe();
        }

        this.validate();

        const state = this._states$.asObservable().pipe(
            filter(r => r.length > 0),
            distinctUntilChanged((a, b) => isEqual(a.sort(), b.sort())),
        );
        const states = [state, this._control.statusChanges];
        if (this._form) {
            states.push(this._form.ngSubmit);
        }
        this._subscription = observableMerge(...states).subscribe(d =>
            this.checkStatus(),
        );
    }

    private setControlIds() {
        if (this._formField && this._formField._control) {
            this._errorChildren.forEach(t => {
                t.controlId = this._formField._control.id;
            });

            this._errorDefaultChildren.forEach(t => {
                t.controlId = this._formField._control.id;
            });
        }
    }

    /**
     * Check the validaty of the control then show the relevant error messages.
     */
    private checkStatus() {
        const shouldCheck =
            this.invalid &&
            (this._rules.some(rule => this._control[rule]) ||
                (this._form && this._form.submitted));
        if (shouldCheck) {
            let errorDisplayed = false;

            this._errorChildren.forEach(t => {
                if (!errorDisplayed) {
                    const activeErrors = t.errorNames.filter(en =>
                        this.hasError(en),
                    );

                    if (activeErrors.length > 0) {
                        t.show({
                            errorName: activeErrors[0],
                            control: this._control,
                            data: this.getError(activeErrors[0]),
                        });
                        errorDisplayed = true;
                        return;
                    }
                }
                t.hide();
            });

            this._errorDefaultChildren.forEach(x => {
                if (!errorDisplayed) {
                    x.show();
                    errorDisplayed = true;
                } else {
                    x.hide();
                }
            });
        } else {
            this.hideAllErrors();
        }
    }

    /**
     * Hide all error labels.
     */
    private hideAllErrors() {
        this._errorChildren.forEach(x => x.hide());
        this._errorDefaultChildren.forEach(x => x.hide());
    }
}
