export * from './vehicles.enums';
export * from './servicing.enums';
export * from './fleet.enums';

export enum GenericStatus {
    Deleted = 'Deleted',
    Draft = 'Draft',
    Active = 'Active',
}

export enum GroupStatus {
    Deleted = 'Deleted',
    Draft = 'Draft',
    Published = 'Published',
}

export enum InsuranceType {
    Individual = 'Individual',
    Business = 'Business',
}

export enum InsuranceStatus {
    All = 'All',
    Active = 'Active',

    Cancelled = 'Cancelled',
    Declined = 'Declined',
    Postponed = 'Postponed',
    Added = 'Added',
    Open = 'Open',
    Approved = 'Approved',
}

export enum BookingStatus {
    Failed = 'Failed',
    Cancelled = 'Cancelled',
    Review = 'Review',
    Pending = 'Pending',
    AwaitingPayment = 'AwaitingPayment',
    AwaitingDeposit = 'AwaitingDeposit',
    Reserved = 'Reserved',
    Open = 'Open',
    Incomplete = 'Incomplete',
    Completed = 'Completed',
    FailedDeposit = 'FailedDeposit',
}

export enum BookingPaymentType {
    OnlineSave = 'OnlineSave',
    Collection = 'Collection',
    Online = 'Online',
}

export enum BookingCancellationType {
    NoShow = 'NoShow',
    CustomerCancelled = 'CustomerCancelled',
    CountyCancelled = 'CountyCancelled',
    CustomerChange = 'CustomerChange',
    CustomerCoiFailure = 'CustomerCoiFailure',
}

export enum BookingRefundType {
    None = 'None',
    Total = 'Total',
    Deposit = 'Deposit',
    Custom = 'Custom',
    DepositExtras = 'DepositExtras',
}

export enum BookingTransactionType {
    Refund = 'Refund',
    Pending = 'Pending',
    Payment = 'Payment',
    Deposit = 'Deposit',
}

export enum BookingTransactionMethod {
    Stripe = 'Stripe',
    Card = 'Card',
}

export enum RuleStatus {
    Deleted = 'Deleted',
    Draft = 'Draft',
    Published = 'Published',
}

export enum RuleCompletionMethod {
    Stop = 'Stop',
    PassThrough = 'PassThrough',
}

export enum TariffPriceChargeLevel {
    All = 'All',
    Standard = 'Standard',
    Online = 'Online',
}

export enum TariffOptionalDisplayLocation {
    Personal = 'Personal',
    Business = 'Business',
    All = 'All',
}

export enum CorporateStatus {
    Active = 'Active',
    Deleted = 'Deleted',
    Draft = 'Draft',
    Completed = 'Completed',
    Approved = 'Approved',
    Deployed = 'Deployed',
}

export enum IncidentStatus {
    All = 'All',
    AllButDeleted = 'AllButDeleted',
    Active = 'Active',
    Deleted = 'Deleted',
    Draft = 'Draft',
    AwaitingCSS = 'AwaitingCSS',
    AwaitingBranch = 'AwaitingBranch',
    Reconciled = 'Reconciled',
    AwaitingAudatexEstimate = 'AwaitingAudatexEstimate',
}

export enum IncidentRepairStatus {
    All = 'All',
    Unknown = 'Unknown',
    Unrepaired = 'Unrepaired',
    PartiallyRepaired = 'PartiallyRepaired',
    FullyRepaired = 'FullyRepaired',
    Deferred = 'Deferred',
}

export enum IncidentRepairStatusLabels {
    All = 'All',
    Unknown = 'Unknown',
    Unrepaired = 'Unrepaired',
    PartiallyRepaired = 'Partially Repaired',
    FullyRepaired = 'Fully Repaired',
    Deferred = 'Deferred Till Defleet',
}

export enum IncidentMergeAction {
    Delete = 'Delete',
    Keep = 'Keep',
    Complete = 'Complete',
}

export enum IncidentOutcomeType {
    External = 'External',
    Branch = 'Branch',
    Fleet = 'Fleet',
    ThirdParty = 'ThirdParty',
}

export enum IncidentOutcomeRechargeType {
    Personal = 'Personal',
    Business = 'Business',
    Fleet = 'Fleet',
    Directors = 'Directors',
    Insurance = 'Insurance',
    ThirdParty = 'ThirdParty',
}

export enum IncidentEstimateType {
    Trade = 'Trade',
    Retail = 'Retail',
}

export enum IncidentEstimateChargeType {
    Parts = 'Parts',
    PaintAndMaterials = 'PaintAndMaterials',
    Labour = 'Labour',
    Admin = 'Admin',
    Other = 'Other',
}

export enum IncidentTransferRequestStatus {
    All = 'All',
    Deleted = 'Deleted',
    Declined = 'Declined',
    Draft = 'Draft',
    Active = 'Active',
    Approved = 'Approved',
}

export enum IncidentTransferRequestType {
    To = 'To',
    From = 'From',
    All = 'All',
}

export enum IncidentImageType {
    Initial = 'Initial',
    Additional = 'Additional',
}

export enum VatType {
    Zero = 'Zero',
    Standard = 'Standard',
}

export enum VatTypeLabels {
    Zero = 'Zero 0%',
    Standard = 'Standard 20%',
}

export class VatTypeAmounts {
    static Zero = 0;
    static Standard = 0.2;
}

export enum ResourceType {
    Standard = 'Standard',
    Private = 'Private',
}

export enum InsuranceTypes {
    coi = 'coi',
    standard = 'standard',
    softrestricted = 'softrestricted',
    hardrestricted = 'hardrestricted',
}

export enum PersonOccupationStatus {
    Unemployed = 'Unemployed',
    Employed = 'Employed',
    SelfEmployed = 'SelfEmployed',
    Retired = 'Retired',
}

export enum DistanceUnit {
    Miles = 'Miles',
    Kilometres = 'Kilometres',
}

export enum DistanceUnitShortLabels {
    Miles = 'ml',
    Kilometres = 'km',
}

export enum BaseBodyType {
    Car = 'Car',
    Van = 'Van',
    Truck = 'Truck',
    Other = 'Other',
}

export enum ResourceCategory {
    Unknown = 'Unknown',
    BodyTypeMeshes = 'BodyTypeMeshes',
}

export enum CustomerType {
    Person = 'Person',
    Company = 'Company',
}
