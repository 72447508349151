import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Bookings } from 'county-api';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    IPagingParams,
    IRequestPaging,
    PagingController,
} from '../../universal/paging';
import { BookingCustomersService } from './booking-customers.service';

/**
 * The booking customers component used to get and output all the customers.
 */
@Component({
    selector: 'booking-customers',
    templateUrl: './booking-customers.component.html',
})
export class BookingCustomersComponent extends PagingController<IRequestPaging>
    implements OnInit {
    /**
     * Current loaded customers from the entered search term.
     */
    customers: Bookings.IBookingCustomer[] = [];

    /**
     * Query details for collecting the customers from the api.
     */
    pagingData: IRequestPaging = {
        page: 1,
        filter: null,
        perpage: null,
    };

    /**
     * Determines the loading state of the page.
     */
    loading = 0;

    /**
     * The last active request subscription used for cancelling of the request.
     */
    private request: Subscription;

    /**
     * Constructs injected angular dependencies.
     * @param router The router to handle the routing for the page.
     * @param route The activated route which passes the current route.
     * @param bookingCustomersService The booking customer service to get all customers.
     */
    constructor(
        router: Router,
        route: ActivatedRoute,
        private bookingCustomersService: BookingCustomersService,
    ) {
        super(router, route);
    }

    /**
     * Sets the title for the customers page.
     */
    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Update the local paging data from the url params.
     * @param params The query params from the url.
     */
    updateLocalParams(params: IPagingParams) {
        this.customers = [];
        this.pages = 0;

        // Gather the paging information from the url.
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            filter: params['filter'] ? params['filter'] : null,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
        } as IRequestPaging;

        // Floor the page number to 1.
        if (this.pagingData.page < 1) {
            this.pagingData.page = 1;
        }

        return this.pagingData;
    }

    /**
     * Load the latest set of data based on the passed in pagingData.
     * @param pagingData The data returned from updateLocalParams.
     */
    updateData(pagingData: IRequestPaging) {
        if (pagingData.filter != null && pagingData.filter.length >= 3) {
            this.loading++;

            // If any active request cancel it and then decrease the loader.
            if (this.request && !this.request.closed) {
                this.request.unsubscribe();
                this.loading--;
            }

            // Make a new request for the customers details. Handle both success and error states.
            this.request = this.bookingCustomersService
                .get(pagingData)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    response => {
                        this.customers = response.result.customers;
                        this.totalItems = response.result.customers.length;
                    },
                    () => {
                        this.pages = 0;
                    },
                    () => this.loading--,
                );
        }
    }
}
