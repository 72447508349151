import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from '../companies.service';
import { NotificationsService } from '../../../universal/notifications/notifications.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../../universal/forms/custom.validators';
import { InvalidValueValidationDirective } from '../../../universal/forms/invalid-value-validation.directive';

/**
 * The Company add component which passes the new company data to the companies services.
 */
@Component({
    selector: 'company-add',
    templateUrl: './company-add.component.html',
})
export class CompanyAddComponent implements OnInit {
    /**
     * The array of company types.
     */
    addCompanyForm = this.fb.group({
        name: ['', Validators.required],
        registered_type: [
            '',
            [Validators.required, CustomValidators.invalidValues('other')],
        ],
        registered_number: ['', Validators.maxLength(10)],
        vat_number: [
            '',
            [
                Validators.minLength(8),
                Validators.maxLength(12),
                Validators.pattern('^[a-zA-Z0-9]+$'),
            ],
        ],
        established: [
            0,
            [
                Validators.required,
                CustomValidators.numberMinLength(4),
                CustomValidators.numberMaxLength(4),
            ],
        ],
        nature_of_business: ['', Validators.required],
        contact_details: [null, Validators.required],
        addresses: [null, Validators.required],
        people: [null, Validators.required],
    });

    companyTypes = [
        {
            id: 'ltd',
            label: 'Private Limited Company (LTD)',
        },
        {
            id: 'plc',
            label: 'Public Limited Company (PLC)',
        },
        {
            id: 'rc',
            label: 'Registered Charity',
        },
        {
            id: 'cio',
            label: 'Charitable Incorporated Organisation',
        },
        {
            id: 'cic',
            label: 'Community Interest Company',
        },
        {
            id: 'llp',
            label: 'Limited Liability Partnership (LLP)',
        },
        {
            id: 'other',
            label: 'Other',
        },
    ];

    /**
     * The url in which to pass the new data.
     */
    returnUrl: string;

    /**
     * Constructs inject of angular dependencies
     * @param companiesService The service to get and post the comapny data.
     * @param router The navigation when subscribed.
     * @param route The current active route.
     */
    constructor(
        private companiesService: CompaniesService,
        private router: Router,
        private route: ActivatedRoute,
        private notifications: NotificationsService,
        private fb: FormBuilder,
    ) {}

    /**
     * Gets the params from the current url and sets it to return url.
     */
    ngOnInit() {
        this.route.queryParams
            .pipe(filter(params => params.returnUrl))
            .subscribe(params => {
                this.returnUrl = params.returnUrl;
            });
    }

    buildUrl = (query: string) => {
        const url = { url: new Array<string>(), query: {} as { [key: string]: string } };
        const urlParts = query.split('?');
        url.url.push(urlParts[0]);

        if (urlParts[1]) {
            url.query = urlParts[1].split('&').reduce((params, param) => {
                const [key, value] = param.split('=');
                params[key] = value
                    ? decodeURIComponent(value.replace(/\+/g, ' '))
                    : '';
                return params;
            }, {});
        }

        return url;
    };

    /**
     * Subscribes the new company data and redirects to the desired url.
     */
    create() {
        const companyForm = { insurance_code99: false, ...this.addCompanyForm.value };
        this.companiesService.create(companyForm).subscribe(
            response => {
                // If return url is true it redirects to the desired url, else to the comapny
                // details page.
                if (this.returnUrl) {
                    const url = this.buildUrl(this.returnUrl);
                    url.query.company_id = response.result.company.id ?? "";
                    if (response.result.company.people?.length === 1) {
                        const personId = response.result.company.people ? response.result.company.people[0].person?.id : null; 
                        url.query.person_id = personId ?? "";
                    }
                    this.router.navigate(url.url, { queryParams: url.query });
                } else {
                    this.router.navigate([
                        '/customers',
                        'companies',
                        response.result.company.id,
                    ]);
                }
                this.notifications.success('Successfully created new company.');
            },
            () => {
                this.notifications.error('Failed to create new company.');
            },
        );
    }

    /**
     * Navigates to the customer add page.
     */
    cancelClick() {
        this.router.navigateByUrl('/customers/add');
    }
}
