<topbar>
    <h1 topbar-title>Add Customer</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="wrapper">
            <div class="content-section customer-form">
                <div class="content-card">
                    <div
                        class="content-card__header content-card__header--blank"
                    >
                        <h3 class="content-card__heading">
                            Customer Type
                        </h3>
                    </div>
                    <div class="content-card__body">
                        <div class="customer-form__types">
                            <a
                                class="customer-form__type"
                                [routerLink]="['../companies/add']"
                            >
                                Company
                            </a>

                            <a
                                class="customer-form__type"
                                [routerLink]="['../people/add']"
                            >
                                Person
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
