import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IContactDetail } from '../../county-api/src/customers';

/**
 * The dialog to add or edit an existing contact.
 */
@Component({
    selector: 'customers-contacts-dialog',
    templateUrl: './customers-contacts-dialog.component.html',
})
export class CustomersContactsDialogComponent {
    /**
     * The contacts details.
     */
    contactDetail: IContactDetail;

    /**
     * The types of contacts available.
     */
    contactTypes = [
        {
            label: 'Email',
            pattern: /\S+@\S+\.\S+/,
        },
        {
            label: 'Telephone',
            pattern: /^[0-9()-+]+$/,
        },
    ];

    /**
     * The number which determines the index from the array contactsTypes.
     */
    type: number;

    /**
     * Construct injecting angular dependencies. Also checks whether contact details is defined.
     * @param dialogRef The way to get the dialog functionality.
     * @param data The data injected from the parent.
     */
    constructor(
        public dialogRef: MatDialogRef<CustomersContactsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.contactDetail = data.contactDetails || {
            is_primary: false,
        };

        if (this.contactDetail.type) {
            this.type = this.contactTypes.findIndex(
                ct => ct.label === this.contactDetail.type,
            );
        }
    }

    /**
     * When clicked emits the data back to the parent component and closes the dialog.
     */
    confirm($event) {
        if (this.contactDetail) {
            this.contactDetail.type = this.contactTypes[this.type].label;
            this.dialogRef.close(this.contactDetail);
        }
    }
}
