<div class="dialog-container">
    <button
        aria-label="Dismiss"
        class="dialog-close"
        mat-dialog-close
        tabindex="-1"
    ></button>

    <div class="dialog-heading">
        <h3>Add Company Employee</h3>
    </div>

    <form (validSubmit)="confirm($event)">
        <div [ngSwitch]="employee.person_id != null" mat-dialog-content>
            <div *ngSwitchCase="false">
                <div class="row">
                    <div class="col-half">
                        <person-add-details
                            [person]="employee.person"
                            (personChange)="onPersonChange()"
                            [employee]="true"
                        ></person-add-details>
                    </div>
                    <div class="col" *ngIf="existingPeople.length > 0">
                        <h4>Existing Customers</h4>

                        <ul class="list-styled simple-scroller">
                            <li *ngFor="let person of existingPeople">
                                <a
                                    class="list-styled__link"
                                    (click)="useExistingClick(person)"
                                    [ngClass]="{
                                        'list-styled__link--disabled': isCurrentPerson(
                                            person.id
                                        )
                                    }"
                                >
                                    {{ person.forename }} {{ person.surname }} -
                                    <small
                                        >DOB:
                                        {{
                                            person.date_of_birth
                                                | date: 'dd/MM/yyyy'
                                        }}</small
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="dialog__divider"></div>

                <ul class="form">
                    <li class="form__item">
                        <label class="input-label">Personal Contact Details</label>
                        <customers-contacts-input
                            name="person_contact_details"
                            required
                            [(ngModel)]="employee.person.contact_details"
                            #contactsInput="ngModel"
                        ></customers-contacts-input>
                        <div class="input-errors" [ngxErrors]="contactsInput">
                            <label
                                for="input-error"
                                ngxError="required"
                                class="input-error"
                                >Please enter a valid contact.</label
                            >
                            <label
                                for="input-error"
                                ngxError="primaryContact"
                                class="input-error"
                                >Please enter one primary contact.</label
                            >
                            <label
                                for="input-error"
                                ngxError="duplicateContacts"
                                class="input-error"
                                >You can not have duplicate contacts.</label
                            >
                        </div>
                    </li>
                    <li class="form__item">
                        <div class="dialog__divider"></div>

                        <label class="input-label">Personal Address Details</label>
                        <customers-address-input
                            name="company_person_addresses"
                            required
                            [(ngModel)]="employee.person.addresses"
                            [requireDate]="true"
                            requireOneCurrent
                            #addressessInput="ngModel"
                        ></customers-address-input>

                        <div class="input-errors" [ngxErrors]="addressessInput">
                            <label class="input-error" ngxError="required"
                                >Please add an address.</label
                            >
                            <label class="input-error" ngxError="requireOneCurrent"
                                >Please add one current address, by leaving 'Move
                                Out Date' blank.</label
                            >
                        </div>
                    </li>
                </ul>
            </div>

            <div *ngSwitchDefault>
                <ul class="form">
                    <li>
                        <label class="input-label">Existing Customer</label>
                        <div class="customer-card">
                            <div class="customer-card__name">
                                {{ employee.person.forename }}
                                {{ employee.person.surname }}
                            </div>
                            <div class="customer-card__sub">
                                DOB:
                                {{
                                    employee.person.date_of_birth
                                        | date: 'dd/MM/yyyy'
                                }}
                            </div>
                        </div>
                    </li>
                    <li class="form__item">
                        <label class="input-label">Job Title</label>
                        <input
                            type="text"
                            name="person_job_title"
                            id="person_job_title"
                            required
                            [(ngModel)]="employee.job_title"
                        />
                    </li>
                </ul>
            </div>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" class="btn" mat-dialog-close>
                Cancel
            </button>

            <input
                type="submit"
                value="Add Employee"
                class="btn btn--primary btn--right"
            />
        </div>
    </form>
</div>