<ng-container *ngIf="person">
    <topbar>
        <h1 topbar-title>{{ person.forename + ' ' + person.surname }}</h1>
    </topbar>

    <div class="container-content">
        <div class="content">
            <div class="content-section">
                <div class="wrapper">
                    <div class="row">
                        <div class="m-col-3">
                            <div class="content-card">
                                <div
                                    class="content-card__header content-card__header--blank"
                                >
                                    <h3 class="content-card__heading">
                                        Basic Details
                                    </h3>
                                </div>
                                <div class="content-card__body">
                                    <div class="content-card-detail">
                                        <label
                                            class="content-card-detail__label"
                                            >Date of Birth</label
                                        >
                                        <div class="content-card-detail__value">
                                            {{
                                                person.date_of_birth
                                                    | date: 'dd/MM/yyyy'
                                            }}
                                        </div>
                                    </div>

                                    <div class="content-card-detail">
                                        <label
                                            class="content-card-detail__label"
                                        >
                                            Occupation Status
                                        </label>
                                        <div class="content-card-detail__value">
                                            {{ person.occupation_status }}
                                        </div>
                                    </div>

                                    <div class="content-card-detail">
                                        <label
                                            class="content-card-detail__label"
                                            >Occupation</label
                                        >
                                        <div class="content-card-detail__value">
                                            {{ person.occupation }}
                                        </div>
                                    </div>
                                    <div
                                        class="content-card-detail"
                                        *ngIf="person.licence_type"
                                    >
                                        <label
                                            class="content-card-detail__label"
                                            >Licence Number</label
                                        >
                                        <div class="content-card-detail__value">
                                            {{ person.licence_type }}
                                            {{ person.licence_number }}
                                            {{
                                                person.licence_date
                                                    | date: 'dd/MM/yyyy'
                                            }}
                                        </div>
                                    </div>

                                    <div
                                        class="content-card-detail"
                                        *ngIf="person.passport_number"
                                    >
                                        <label
                                            class="content-card-detail__label"
                                            >Passport Number</label
                                        >
                                        <div class="content-card-detail__value">
                                            {{ person.passport_number }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="m-col-7">
                            <div
                                class="content-alert content-alert--warn"
                                *ngIf="person.insurance_code99"
                            >
                                <h2 class="content-alert__heading">Code 99</h2>
                                <div class="content-alert__body">
                                    <p>
                                        This person has been flagged with a
                                        code99 in the past.
                                    </p>
                                </div>
                            </div>

                            <div class="content-card">
                                <div
                                    class="content-card__header content-card__header--blank"
                                >
                                    <h3 class="content-card__heading">
                                        Contact Details
                                    </h3>
                                </div>

                                <div class="content-card__body">
                                    <div class="content-card-table">
                                        <ul class="list-styled">
                                            <li
                                                class="list-styled__item"
                                                *ngFor="
                                                    let contactDetail of person.contact_details
                                                "
                                            >
                                                <a
                                                    class="list-styled__link"
                                                    href="{{
                                                        getContactLink(
                                                            contactDetail
                                                        )
                                                    }}"
                                                >
                                                    <small class="badge">{{
                                                        contactDetail.type
                                                    }}</small>
                                                    {{ contactDetail.value }}

                                                    <ul
                                                        class="list-styled__actions"
                                                        *ngIf="
                                                            contactDetail.is_primary
                                                        "
                                                    >
                                                        <li
                                                            class="list-styled__action"
                                                        >
                                                            <fa-icon
                                                                icon="key"
                                                                matTooltip="Primary contact information."
                                                            ></fa-icon>
                                                        </li>
                                                    </ul>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="content-card">
                                <div
                                    class="content-card__header content-card__header--blank"
                                >
                                    <h3 class="content-card__heading">
                                        Address Details
                                    </h3>
                                </div>

                                <div class="content-card__body">
                                    <table class="content-card-table">
                                        <tr
                                            *ngFor="
                                                let addressDetails of person.addresses
                                            "
                                        >
                                            <td>
                                                {{ addressDetails.line1
                                                }}{{
                                                    addressDetails.line2
                                                        ? ', ' +
                                                          addressDetails.line2
                                                        : ''
                                                }}{{
                                                    addressDetails.line3
                                                        ? ', ' +
                                                          addressDetails.line3
                                                        : ''
                                                }}
                                            </td>
                                            <td>{{ addressDetails.city }}</td>
                                            <td>
                                                {{ addressDetails.country }}
                                            </td>
                                            <td>
                                                {{ addressDetails.postcode }}
                                            </td>
                                            <td>
                                                {{
                                                    addressDetails.from_date
                                                        | date: 'dd/MM/yyyy'
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    addressDetails.to_date
                                                        | date: 'dd/MM/yyyy'
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div
                                class="content-card"
                                *ngIf="person.companies.length > 0"
                            >
                                <div
                                    class="content-card__header content-card__header--blank"
                                >
                                    <h3 class="content-card__heading">
                                        Employers
                                    </h3>
                                </div>

                                <div class="content-card__body">
                                    <ul class="list-styled">
                                        <li
                                            class="list-styled__item"
                                            *ngFor="
                                                let employee of person.companies
                                            "
                                        >
                                            <a
                                                class="list-styled__link"
                                                href="#/customers/companies/{{
                                                    employee.company.id
                                                }}"
                                            >
                                                {{ employee.company.name }}
                                                <small>{{
                                                    employee.job_title
                                                }}</small>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <card pTitle="Accounting Details">
                                <card-section>
                                    <stack pDistribution="equalSpacing">
                                        <stack-item>
                                            Sage account link
                                        </stack-item>

                                        <button
                                            pButton
                                            pSize="small"
                                            type="button"
                                            *hasPermission="
                                                'customer-sage-link';
                                                andIf: !person.sage_account_id
                                            "
                                            (click)="openLinkClick()"
                                        >
                                            Link
                                        </button>

                                        <button
                                            pButton
                                            pVariant="error"
                                            pSize="small"
                                            type="button"
                                            *hasPermission="
                                                'customer-sage-unlink';
                                                andIf: !!person.sage_account_id
                                            "
                                            (click)="openUnlinkClick()"
                                        >
                                            Unlink
                                        </button>
                                    </stack>
                                </card-section>

                                <card-section *ngIf="person.sage_account_id">
                                    <customers-sage-summary
                                        [sageAccountId]="person.sage_account_id"
                                    ></customers-sage-summary>
                                </card-section>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <loader *ngIf="loading > 0"></loader>
</ng-container>
