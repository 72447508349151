import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    HostBinding,
    Input,
} from '@angular/core';
import { CardActionsDirective } from './directives/card-actions.directive';
import { CardFooterDirective } from './directives/card-footer.directive';
import { CardMetaDataDirective } from './directives/card-meta-data.directive';

@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.card]': 'true',
    },
})
export class CardComponent {
    @Input()
    pTitle: string;

    @Input()
    pSubTitle: string;

    @Input()
    get pHideHeader(): boolean {
        return this._hideHeader;
    }
    set pHideHeader(value: boolean) {
        this._hideHeader = coerceBooleanProperty(value);
    }
    private _hideHeader = false;

    @Input()
    @HostBinding('class.card--secondary')
    get pSecondary(): boolean {
        return this._secondary;
    }
    set pSecondary(value: boolean) {
        this._secondary = coerceBooleanProperty(value);
    }
    private _secondary = false;

    @Input()
    get pSectioned(): boolean {
        return this._sectioned;
    }
    set pSectioned(value: boolean) {
        this._sectioned = coerceBooleanProperty(value);
    }
    private _sectioned = false;

    @ContentChild(CardActionsDirective)
    pCardActions: CardActionsDirective;

    @ContentChild(CardFooterDirective)
    pCardFooter: CardFooterDirective;

    @ContentChild(CardMetaDataDirective)
    pCardMetaData: CardMetaDataDirective;
}
