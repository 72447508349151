import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UserState, AuthState } from '../../../users/store/user.state';
import { Observable } from 'rxjs';
import { Users } from 'county-api';
import { ToggleSidebar } from '../../store/layout.actions';

@Component({
    selector: 'mobile-nav',
    templateUrl: './mobile-nav.component.html',
    styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent {
    @Select(UserState.user)
    user$: Observable<Users.IUser>;

    @Select(UserState.state)
    authState$: Observable<AuthState>;

    authStates = AuthState;

    constructor(private store: Store) {}

    hamburgerClick() {
        this.store.dispatch(new ToggleSidebar());
    }
}
