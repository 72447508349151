import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StackComponent } from './stack.component';
import { StackItemComponent } from './components/stack-item.component';

@NgModule({
    declarations: [StackComponent, StackItemComponent],
    exports: [StackComponent, StackItemComponent],
    imports: [CommonModule],
})
export class StackModule {}
