<div class="dialog-container">
    <div class="dialog-heading">
        <h3>Link Sage Account</h3>
    </div>

    <form (validSubmit)="submit()">
        <div mat-dialog-content>
            <stack pVertical pSpacing="x-small">
                <form-field pLabel="Search">
                    <input
                        type="text"
                        [value]="pagingData.filter"
                        (input)="filterUpdate($event.target, 'filter')"
                    />
                </form-field>

                <ul
                    class="list-styled simple-scroller"
                    *ngIf="!pagingLoading; else loader"
                >
                    <li
                        class="list-styled__item"
                        *ngFor="let company of sageCustomers"
                    >
                        <a
                            class="list-styled__link"
                            (click)="selectAccount(company)"
                            [ngClass]="{
                                'list-styled__link--selected':
                                    selectedCustomer == company
                            }"
                        >
                            <stack pVertical pSpacing="x-small">
                                <stack-item>
                                    {{ company.customer_account_number }}
                                    <badge
                                        pVariant="error"
                                        *ngIf="company.account_is_on_hold"
                                    >
                                        On Hold
                                    </badge>
                                </stack-item>

                                <stack-item>
                                    {{ company.customer_account_name }}
                                </stack-item>
                            </stack>
                        </a>
                    </li>

                    <li
                        class="list-styled__more-result"
                        *ngIf="pages && pages < pagingData.page"
                    >
                        <button
                            type="button"
                            class="btn btn--default btn--block"
                            (click)="nextPage()"
                            [disabled]="pagingLoading"
                        >
                            More Results
                        </button>
                    </li>
                </ul>

                <alert
                    *ngIf="existingLink"
                    pVariant="error"
                    pTitle="Already linked"
                >
                    This Sage account is already linked to a Platform account.
                    Please select another or use the correct customer for the
                    invoice.
                </alert>
            </stack>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" pButton mat-dialog-close>
                Cancel
            </button>

            <button
                type="submit"
                pButton
                pVariant="info"
                [disabled]="!selectedCustomer || loadingLink || existingLink"
            >
                Submit
            </button>
        </div>
    </form>

    <button aria-label="Dismiss" class="dialog-close" mat-dialog-close></button>

    <ng-template #loader>
        <spinner></spinner>
    </ng-template>
</div>