<div class="not-found-wrapper">
    <h1 class="not-found-wrapper__heading">Unauthorised</h1>
    <p class="not-found-wrapper__message">
        An error occured when trying to gather your user information, this could
        happen if you are a ghost or if the api is down.
    </p>

    <div class="not-found-wrapper__btns">
        <a class="btn" onclick="window.location.reload()">Reload</a>
        <a href="/" class="not-found-wrapper__btn btn">Go To Homepage</a>
    </div>
</div>
