import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstant } from '../../configs/api.constant';
import { ApiHttpClientService } from '../../universal/api/apiHttpClient.service';
import { IRequestPaging } from '../../universal/paging';
import { ICompaniesViewModel, ICompany, ICompanyViewModel } from '../../county-api/src/customers';
import { IApiResponse } from '../../county-api/src/responses';

/**
 * The comapnies service, used to get companies and add, update and delete passed in
 * companies.
 */
@Injectable()
export class CompaniesService extends ApiHttpClientService {
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Get a collection of companies based on the passed in params.
     * @param params Query params to filter vehicles result, null values will be removed.
     */
    get(params: IRequestPaging) {
        return this.service.get<IApiResponse<ICompaniesViewModel>>(
            ApiConstant.url + 'companies',
            {
                params: this.filterRequestParams(params),
            },
        );
    }

    /**
     * Get the company based on its passed in id.
     * @param id The id of the company to call for.
     */
    getById(id: string) {
        return this.service.get<IApiResponse<ICompanyViewModel>>(
            ApiConstant.url + `companies/${id}`,
        );
    }

    /**
     * Recieves new company data and posts it to the api.
     */
    create(company: ICompany) {
        return this.service.post<IApiResponse<ICompanyViewModel>>(
            ApiConstant.url + 'companies',
            {
                company,
            },
        );
    }
}
