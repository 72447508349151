import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { interval, of, Subject } from 'rxjs';
import { map, takeUntil, startWith } from 'rxjs/operators';

@Pipe({
    name: 'timeago',
})
export class TimeagoPipe implements PipeTransform {
    private _finish = new Subject();

    transform(value: moment.MomentInput, args?: any): any {
        const dateMoment = moment(value);
        const now = moment();
        if (now.isSame(dateMoment, 'day')) {
            return interval(10000).pipe(
                takeUntil(this._finish),
                startWith(0),
                map(d => dateMoment.fromNow()),
            );
        } else {
            const timeString = args ? ' [at] HH:mm' : '';
            const dateString = now.isSame(dateMoment, 'year')
                ? 'D MMMM'
                : 'D MMMM YYYY';

            return of(dateMoment.format(dateString + timeString));
        }
    }
}
