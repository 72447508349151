import { UserGuard } from './user.guard';
import { UsersComponent } from './users.component';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UserResolve } from './user/user.resolve';
import { UserComponent } from './user/user.component';
import { UsersActivitesPageComponent } from './user/activities/page/users-activites-page.component';
import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';

/**
 * Base routes for the users section of the app.
 */
const routes: Routes = [
    {
        path: 'users',
        canActivate: [MsalGuard],
        data: { breadcrumb: 'Users' },
        children: [
            {
                path: '',
                component: UsersComponent,
                pathMatch: 'full',
                canActivate: [MsalGuard, UserGuard],
            },
            {
                path: ':id',
                data: {
                    breadcrumb: 'User',
                    menuUrl: '/users',
                    canActivate: [MsalGuard],
                },
                children: [
                    {
                        path: '',
                        component: UserComponent,
                        pathMatch: 'full',
                        canActivate: [MsalGuard, UserGuard],
                        resolve: { user: UserResolve },
                    },
                    {
                        path: 'activities',
                        component: UsersActivitesPageComponent,
                        data: { full: true, breadcrumb: 'Activities' },
                        canActivate: [MsalGuard, UserGuard],
                        resolve: { user: UserResolve },
                    },
                ],
            },
        ],
    },
];

export const routedComponents = [
    UsersComponent,
    UserComponent,
    UsersActivitesPageComponent,
];

/**
 * The routing module for the users.
 */
@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    declarations: [],
    exports: [RouterModule],
    providers: [UserResolve],
})
export class UsersRoutingModule {}
