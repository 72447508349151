<table class="content-card-table" *ngIf="people.length > 0">
    <thead>
        <tr>
            <th>Name</th>
            <th>D.O.B</th>
            <th>Job Title</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let employee of people; let i = index">
            <td>
                {{ employee.person.forename + ' ' + employee.person.surname }}
            </td>
            <td>{{ employee.person.date_of_birth | date: 'dd/MM/yyyy' }}</td>
            <td>{{ employee.job_title }}</td>
            <td class="align-right content-card-table__modifiers">
                <a (click)="editPersonClick(i, employee)">
                    <fa-icon icon="pencil"></fa-icon>
                </a>
                <a (click)="deletePersonClick(i)">
                    <fa-icon icon="times"></fa-icon>
                </a>
            </td>
        </tr>
    </tbody>
</table>

<div class="btns btns--horizontal btns--small">
    <a class="btn btn--right" (click)="addPersonClick()">Add</a>
</div>
