import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { Bookings } from 'county-api';
import { DeleteDialogComponent } from '../../../universal/dialogs/delete-dialog/delete-dialog.component';
import { NotificationsService } from '../../../universal/notifications/notifications.service';
import { BookingCustomersService } from '../booking-customers.service';
import { BookingsService } from '../../bookings.service';

/**
 * The booking customer page gets and outputs the customers details and bookings.
 */
@Component({
    selector: 'bookings-customer',
    templateUrl: './bookings-customer.component.html',
})
export class BookingsCustomerComponent implements OnInit {
    /**
     * The customers data.
     */
    customer: Bookings.IBookingCustomer;

    /**
     * The loading state of the page.
     */
    loading = 0;

    /**
     * The loading state of the customer bookings section.
     */
    bookingsLoading = 0;

    /**
     * The array of bookings for the passed user.
     */
    bookings: Bookings.IBooking[] = [];

    /**
     * The page of the customers bookings.
     */
    page = 1;

    /**
     * The amount of pages of the customers bookings.
     */
    pages: number;

    /**
     * How many customer bookings to show perpage.
     */
    perpage = 10;

    /**
     * The total number of bookings.
     */
    length: number;

    /**
     * Constucts injected angular dependencies.
     * @param bookingCustomersService The booking customer service used to get the customers bookings.
     * @param route The current active route.
     * @param notifications Handles the success and error notifications.
     * @param dialog The dialogs functionality.
     */
    constructor(
        private bookingCustomersService: BookingCustomersService,
        private bookingsService: BookingsService,
        private route: ActivatedRoute,
        private notifications: NotificationsService,
        private dialog: MatDialog,
        private breadcrumbService: BreadcrumbService,
    ) {}

    /**
     * Uses the activated route to get id of the customer to get from the api.
     */
    ngOnInit() {
        this.loading++;
        this.route.data.subscribe(
            (data: { customer: Bookings.IBookingCustomerViewModel }) => {
                this.customer = data.customer.customer;

                this.breadcrumbService.changeBreadcrumb(
                    this.route.snapshot,
                    data.customer.customer.forename +
                        ' ' +
                        data.customer.customer.surname,
                );

                // When the data has been got, calls to get the bookings for
                // that customer.
                this.getBookings();
                this.loading--;
            },
        );
    }

    /**
     * Gets all the bookings for the given customer.
     */
    getBookings() {
        this.bookingsLoading++;

        // Calls the getbookings method to get all bookings according to the
        // given params.
        this.bookingsService
            .get({
                page: this.page,
                perpage: this.perpage,
                customer_ids: [this.customer.id],
            })
            .subscribe(
                response => {
                    this.bookings = response.result.bookings;
                    this.page = response.result.paging.page;
                    this.pages = response.result.paging.total_pages;
                    this.length = response.result.paging.total;
                },
                () => {},
                () => {
                    this.bookingsLoading--;
                },
            );
    }

    /**
     * Changes the page number when called.
     * @param event The angular event fired when called.
     */
    pageChange(event: PageEvent) {
        // If its the page that is changing then update that property.
        if (event.pageIndex + 1 !== this.page) {
            this.page = event.pageIndex + 1;

            this.getBookings();
        }
    }

    /**
     * Opens the dialog then calls the block service call to block the customer.
     */
    block() {
        const openDialog = this.dialog.open(DeleteDialogComponent, {
            width: '600px',
            panelClass: 'dialog',
        });

        // If true then the customer id gets sent to be blocked.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                this.bookingCustomersService
                    .blockById(this.customer.id)
                    .subscribe(
                        () => {
                            this.customer.blocked = true;
                            this.notifications.success(
                                `Successfully blocked ${this.customer.forename} ${this.customer.surname}.`,
                            );
                        },
                        () => {
                            this.notifications.error(
                                'There was an error blocking this customer.',
                            );
                        },
                    );
            }
        });
    }

    /**
     * When clicked calls the unblocked method passing in the customer id to be
     * unblocked.
     */
    unblock() {
        this.bookingCustomersService.unblockById(this.customer.id).subscribe(
            () => {
                this.customer.blocked = false;
                this.notifications.success(
                    `Successfully unblocked ${this.customer.forename} ${this.customer.surname}.`,
                );
            },
            () => {
                this.notifications.error(
                    'There was an error unblocking this customer.',
                );
            },
        );
    }
}
