<div class="dialog-container">
    <form [formGroup]="formGroup" (validSubmit)="submit()">
        <div class="dialog-heading">
            <h3>Confirm deletion {{ name ? 'of ' + name : '' }}</h3>
        </div>

        <div mat-dialog-content>
            <ul class="form">
                <li class="form__item">
                    Are you sure you wish to delete this {{ label }}?
                </li>

                <li class="form__item" *ngIf="alert">
                    <div [class]="alertClass">
                        {{ alert }}
                    </div>
                </li>

                <li class="form__item" *ngIf="confirm">
                    <label class="input-label" for="confirm">
                        Type <strong>DELETE</strong> to confirm deletion
                    </label>
                    <input
                        type="text"
                        name="confirm"
                        id="confirm"
                        formControlName="confirm"
                    />

                    <ng-container ngxErrors="confirm">
                        <label class="input-error" for="confirm" *ngxErrorDefault>
                            Please enter <strong>DELETE</strong> in all caps to
                            delete this {{ label }}.
                        </label>
                    </ng-container>
                </li>
            </ul>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" class="btn" [mat-dialog-close]>
                Cancel
            </button>

            <button type="submit" class="btn btn--error btn--right">
                Delete
            </button>
        </div>
    </form>

    <button aria-label="Dismiss" class="dialog-close" [mat-dialog-close]></button>
</div>