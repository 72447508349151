import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import isString from 'lodash/isString';

@Injectable()
export class NameService {

    constructor() {}

    getFormattedName(name: string | null): string{
        if (name == null || name.length == 0){
            return "";
        }

        const formattedNameComponents: string[] = this.getNameComponents(name).map(x => this.getFormattedNameComponent(x));
        return formattedNameComponents.join(" ");
    }

    private getNameComponents(name: string | null) : string[] {
        if (name == null || name.length == 0){
            return [];
        }

        return name.split(/\s+/);
    }

    private upperCaseFirstLetter(name: string) : string{
        if (name == null || name.length == 0){
            return "";
        }
        
        let lowercasedName = name.toLowerCase();
        let nameWithFirstLetterUppercased = `${lowercasedName.charAt(0).toUpperCase()}${lowercasedName.slice(1)}`;

        return nameWithFirstLetterUppercased;
    }

    private getFormattedNameComponent(name: string | null): string{
        if (name == null || name.length == 0){
            return name;
        }

        const separator = "-";

        var containsSeparator = name.indexOf(separator) > -1;

        if (containsSeparator){
            const formattedComponentItems = name.split(separator).map(x => this.upperCaseFirstLetter(x));
            return formattedComponentItems.join(separator);
        }
        else {
           return this.upperCaseFirstLetter(name);
        }
    }

}
