import { BasePagingController } from './base-paging.controller';
import { Subject, Observable } from 'rxjs';
import { IRequestPaging } from './IRequestPaging';

export abstract class SimplePagingController<
    T = IRequestPaging
> extends BasePagingController<T> {
    private simplePagingData$ = new Subject<{ [key: string]: string }>();

    private simplePagingData: { [key: string]: string } = {};

    getParams() {
        return this.simplePagingData$ as Observable<{
            [key: string]: string;
        }>;
    }

    storeParams(params: { [key: string]: string }) {
        this.simplePagingData = Object.assign(
            {},
            this.simplePagingData,
            params,
        );
        this.simplePagingData$.next(this.simplePagingData);
    }
}
