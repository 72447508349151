<topbar>
    <h1 topbar-title>Dashboard</h1>
    <ul class="dashboard-controls list-horizontal" topbar-menu>
        <li>
            <location-selection-input
                [value]="locationId$ | async"
                (locationChange)="updateLocationId($event)"
                hideBlank="true"
            ></location-selection-input>
        </li>
    </ul>
</topbar>

<div class="wrapper wrapper--large dashboard">
    <div class="content-section">
        <mat-grid-list
            class="dashboard__grid"
            cols="12"
            rowHeight="75px"
            gutterSize="15px"
        >
            <mat-grid-tile
                class="dashboard__widget"
                *ngFor="let widget of dashboardWidgets"
                [colspan]="getWidgetCol(widget)"
                [rowspan]="getWidgetRow(widget)"
            >
                <dashboard-manager [type]="widget.id"></dashboard-manager>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
