import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListItemComponent } from './components/list-item.component';
import { ListComponent } from './list.component';
import { ListLabelDirective } from './directives/list-label.directive';

@NgModule({
    declarations: [ListComponent, ListItemComponent, ListLabelDirective],
    exports: [ListComponent, ListItemComponent, ListLabelDirective],
    imports: [CommonModule],
})
export class ListModule {}
