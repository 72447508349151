<ng-container *ngIf="pCollapse; else stackedTemplate">
    <button
        [matMenuTriggerFor]="menu"
        class="action-menu__trigger"
        pButton
        pPlain
        pConnected
    >
        Actions
    </button>

    <mat-menu #menu="matMenu" class="action-menu__panel">
        <ng-template matMenuContent>
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </ng-template>
    </mat-menu>
</ng-container>

<ng-template #contentTemplate>
    <ng-content></ng-content>
</ng-template>

<ng-template #stackedTemplate>
    <stack pSpacing="small">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </stack>
</ng-template>
