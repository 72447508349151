<ng-container *ngIf="!pLoading; else loading">
    <div
        class="item-list__controls"
        *ngIf="(pSelectable || _headerTemplate) && _data.length >= 1"
    >
        <div class="item-list__select-all" *ngIf="pSelectable">
            <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
        </div>
        <div
            class="item-list__select-controls"
            *ngIf="selection.selected.length > 0"
        >
            <span>
                Selected {{ selection.selected.length }} of
                {{ _data.length }} items
            </span>

            <div class="item-list__select-actions" *ngIf="_actionsTemplate">
                <ng-container
                    *ngTemplateOutlet="
                        _actionsTemplate;
                        context: { $implicit: selection.selected }
                    "
                ></ng-container>
            </div>
        </div>
        <div
            class="item-list__header"
            *ngIf="_headerTemplate && selection.selected.length < 1"
        >
            <ng-container *ngTemplateOutlet="_headerTemplate"></ng-container>
        </div>
    </div>

    <ng-container *ngFor="let item of _data; trackBy: trackBy">
        <ng-container
            *ngTemplateOutlet="_itemTemplate; context: { $implicit: item }"
        ></ng-container>
    </ng-container>

    <div
        class="list-item__empty"
        *ngIf="(!_data || _data.length < 1) && !pLoading"
    >
        <ng-container *ngTemplateOutlet="defaultEmpty"></ng-container>
    </div>
</ng-container>

<ng-template #loading>
    <div class="item-list__spinner" *ngIf="pLoading">
        <spinner pSize="large"></spinner>
    </div>
</ng-template>

<ng-template #defaultEmpty>
    <p class="list-item__empty-default">No items to display.</p>
</ng-template>
