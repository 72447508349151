import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Inject } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ImagePreviewOverlayRef } from './image-preview-overlay-ref';
import {
    ImagePreviewData,
    ImagePreviewImage,
} from './image-preview-overlay.service';
import { IMAGE_PREVIEW_DIALOG_DATA } from './image-preview-overlay.tokens';

export enum ImagePreviewDownloadingState {
    None,
    Downloading,
    Failed,
    Success,
}

@Component({
    selector: 'image-preview-overlay',
    templateUrl: './image-preview-overlay.component.html',
    styleUrls: ['./image-preview-overlay.component.scss'],
})
export class ImagePreviewOverlayComponent {
    image: ImagePreviewImage;

    images?: ImagePreviewImage[];

    currentIndex = 0;

    hasMultiple = false;

    hasNext = false;

    hasPrev = false;

    downloading = ImagePreviewDownloadingState.None;

    downloadingState = ImagePreviewDownloadingState;

    constructor(
        public dialogRef: ImagePreviewOverlayRef,
        private httpClient: HttpClient,
        @Inject(IMAGE_PREVIEW_DIALOG_DATA) public data: ImagePreviewData,
    ) {
        this.image = data.image;
        this.images = data.images;

        if (this.images != null && this.images.length > 1) {
            this.hasMultiple = true;
            this.updateIndex(this.images.indexOf(this.image));
        }
    }

    moveClick(index: number) {
        this.moveByIndex(index);
    }

    downloadClick(url: string, filename?: string) {
        if (this.downloading == ImagePreviewDownloadingState.Downloading) {
            return;
        }

        this.downloading = ImagePreviewDownloadingState.Downloading;
        // Shortcut to handle cdn images.
        if (url.indexOf('platform-cdn.azureedge.net/') != -1) {
            window.location.href = url + '?download=true';
            this.downloading = ImagePreviewDownloadingState.Success;
        } else {
            if (!filename) {
                filename = url
                    .split('\\')
                    .pop()
                    .split('/')
                    .pop();
            }

            this.httpClient
                .get(url, {
                    responseType: 'blob',
                })
                .pipe(
                    tap(blob => {
                        const blobUrl = window.URL.createObjectURL(blob);
                        this.forceDownload(blobUrl, filename);
                    }),
                    catchError(() => {
                        this.downloading = ImagePreviewDownloadingState.Failed;
                        return EMPTY;
                    }),
                )
                .subscribe();
        }
    }

    @HostListener('document:keyup', ['$event'])
    onKeyup(event?: KeyboardEvent) {
        if (event) {
            event.preventDefault();
        }
        switch (event.key) {
            case 'Right':
            case 'ArrowRight':
                if (this.hasNext) {
                    this.moveByIndex(1);
                }
                break;
            case 'Left': // IE/Edge specific value
            case 'ArrowLeft':
                if (this.hasPrev) {
                    this.moveByIndex(-1);
                }
                break;
            case 'Esc': // IE/Edge specific value
            case 'Escape':
                this.dialogRef.close();
                break;
        }
    }

    private moveByIndex(index: number) {
        this.image = this.images[this.currentIndex + index];
        this.updateIndex(this.currentIndex + index);
    }

    private updateIndex(index: number) {
        this.currentIndex = index;
        this.hasPrev = this.currentIndex > 0;
        this.hasNext = this.currentIndex < this.images.length - 1;
        this.downloading = ImagePreviewDownloadingState.None;
    }

    private forceDownload(blob: any, filename: string) {
        const a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.downloading = ImagePreviewDownloadingState.Success;
    }
}
