import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResponse, Notes } from 'county-api';
import { ApiConstant } from '../configs/api.constant';
import { ApiHttpClientService } from '../universal/api/apiHttpClient.service';
import { IRequestPaging } from '../universal/paging';

@Injectable()
export class NotesService extends ApiHttpClientService {
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Get a paged collection of notes given a uri prefix, id and paging
     * details.
     * @param prefix The prefix for the uri of the item to get.
     * @param id The id of the uri.
     * @param paging Query param data for the request.
     */
    getByUri(prefix: string, id: string, paging: IRequestPaging) {
        return this.service.get<IApiResponse<Notes.INotesViewModel>>(
            `${ApiConstant.url}notes/${prefix}/${id}`,
            {
                params: this.filterRequestParams(paging),
            },
        );
    }

    /**
     * Create a new note with the passed in data, it is up to the client to form
     * all the details aside from create_* data of the note.S
     * @param note Details of the new note.
     */
    create(note: Notes.INote) {
        return this.service.post<IApiResponse<Notes.INoteViewModel>>(
            `${ApiConstant.url}notes`,
            { note: note },
        );
    }

    /**
     * Delete a note by its id.
     * @param id Id of the note to delete.
     */
    delete(id: string) {
        return this.service.delete<IApiResponse<Notes.INoteViewModel>>(
            `${ApiConstant.url}notes/${id}`,
        );
    }
}
