import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Optional,
    Output,
    Self,
    Input,
} from '@angular/core';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { takeUntil, tap, filter } from 'rxjs/operators';
import { DestroyController } from '../../components/destroy.controller';
import isFunction from 'lodash/isFunction';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

/**
 * The type for formControls used within directives that need to access both
 * template driven and reactive form control.
 */
export type ValidSubmitControl = NgForm & FormGroupDirective;

/**
 * Directive to handle calling a method only when the form is valid and no
 * validators are currently prending.
 */
@Directive({
    selector: '[validSubmit]',
})
export class ValidSubmitDirective extends DestroyController {
    /**
     * The event that will be triggered when the form is submitted and is valid.
     */
    @Output()
    validSubmit = new EventEmitter<any>();

    /**
     * Add ng-submitted to a form when it has been submitted.
     */
    @HostBinding('class.ng-submitted')
    elementClass = false;

    @Input()
    set whenDirty(v: boolean) {
        const value = coerceBooleanProperty(v);
        if (value != this._whenDirty) {
            this._whenDirty = value;
        }
    }
    get whenDirty() {
        return this._whenDirty;
    }
    private _whenDirty: boolean = false;

    /**
     * The form control element, will be either an NgForm or FormGroupDirective.
     */
    private formControl: NgForm | FormGroupDirective;

    /**
     * Construct the directive, gathering the NgForm or FormGroupDirective.
     * Doesnt currently handle deep nested forms. NgForm take precedence over an
     * FormGroupDirective.
     */
    constructor(
        @Optional() @Self() form?: NgForm,
        @Optional() @Self() formGroup?: FormGroupDirective,
    ) {
        super();

        // Find the form control, ensure ther is a valid control.
        if (form) {
            this.formControl = form;
        } else if (formGroup) {
            this.formControl = formGroup;
        } else {
            throw new Error(
                'validSubmit directive requires either a NgForm or FormGroupDirective to be present.',
            );
        }

        // Subscribe to the submit event of the form control, this allows
        // angular to handle the submit input and then this directive check the
        // valid state of the control.
        this.formControl.ngSubmit
            .pipe(
                filter(() => !this.whenDirty || this.formControl.dirty),
                takeUntil(this.unsubscribe$),
            )
            .subscribe({ next: (e: Event) => this.handleSubmit(e) });
    }

    /**
     * Handle the form submission checking the valid state of the form control.
     * @param event The submission event.
     */
    private handleSubmit(event?: Event) {
        // Add the ng-submitted class to the form control.
        this.elementClass = true;

        // Check the submitted state. If it is a custom event we emit the
        // details of the event. This allows multiple submit buttons each
        // passing data, @see button/valid-submit-button.directive.ts.
        if (this.formControl.valid && !this.formControl.pending) {
            this.validSubmit.emit(
                event?.type == 'validSubmitEvent'
                    ? (event as CustomEvent).detail
                    : null,
            );
        } else if (event.target && this.formControl.invalid) {
            const formGroupInvalid = (event.target as HTMLElement).querySelectorAll(
                '.ng-invalid',
            );
            if (
                formGroupInvalid[0] &&
                isFunction((formGroupInvalid[0] as any).focus)
            ) {
                (<HTMLInputElement>formGroupInvalid[0]).focus();
            }
        }
    }

    /**
     * Host listener to allow ctrl+enter submission.
     */
    @HostListener('keypress', ['$event'])
    enterKeypress(e: KeyboardEvent) {
        if (e && e.ctrlKey == true && e.keyCode == 10) {
            this.formControl.onSubmit(null);
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadPage(event: any) {
        if (this.formControl.dirty) {
            event.returnValue = false;
        }
    }
}
