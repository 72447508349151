import { Component, OnInit, forwardRef, Input } from '@angular/core';
import {
    NG_VALUE_ACCESSOR,
    Validator,
    NG_VALIDATORS,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { coerceBooleanProperty } from '../../utils';
import toNumber from 'lodash/toNumber';

/**
 * The control value accessor.
 */
export const TIME_PICKER_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerInputComponent),
    multi: true,
};

/**
 * The time picker validation provider.
 */
export const TIME_PICKER_VALIDATORS: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => TimePickerInputComponent),
    multi: true,
};

/**
 * The time picker input used to get and set a time.
 */
@Component({
    selector: 'time-picker-input',
    templateUrl: './time-picker-input.component.html',
    providers: [TIME_PICKER_VALUE_ACCESSOR, TIME_PICKER_VALIDATORS],
})
export class TimePickerInputComponent implements OnInit, Validator {
    /**
     * The array of times.
     */
    times: string[] = [];

    /**
     * Data for the time that is being edited.
     */
    _value: string;

    /**
     * The get to get the time.
     */
    get value(): string {
        return this._value;
    }

    /**
     * The set to set the time.
     */
    @Input()
    set value(timeDetails: string) {
        this._value = timeDetails;
        this.onChange(this._value);
    }

    /**
     * The minimum time that can be set.
     */
    _minTime: string;

    /**
     * Gets the minimum time.
     */
    get minTime(): string {
        return this._minTime;
    }

    /**
     * Sets the minimum time.
     */
    @Input()
    set minTime(minTime: string) {
        this._minTime = minTime;
        this._validatorOnChange();
    }

    /**
     * The max time that can be set.
     */
    _maxTime: string;

    /**
     * Gets the max time.
     */
    get maxTime(): string {
        return this._maxTime;
    }

    /**
     * Sets the max time.s
     */
    @Input()
    set maxTime(maxTime: string) {
        this._maxTime = maxTime;
        this._validatorOnChange();
    }

    /**
     * The default units of time.
     */
    _defaultMinuteUnits = ['00', '30'];

    /**
     * The chosen units of time.
     */
    _minuteUnits: string[] = ['00', '30'];

    /**
     * Gets the minute units.
     */
    get minuteUnits(): string[] {
        return this._minuteUnits;
    }

    /**
     * Sets the chosen minute units.
     */
    @Input()
    set minuteUnits(minuteUnits: string[]) {
        if (minuteUnits) {
            this._minuteUnits = minuteUnits;
        } else {
            this._minuteUnits = this._defaultMinuteUnits;
        }
        this.generateTimes();
    }

    @Input()
    get disabled(): boolean {
        return !!this._disabled;
    }
    set disabled(value: boolean) {
        const newValue = coerceBooleanProperty(value);

        if (this._disabled !== newValue) {
            this._disabled = newValue;
        }
    }
    private _disabled: boolean;

    /**
     * Calls the genrated time method.
     */
    ngOnInit() {
        this.generateTimes();
    }

    /**
     * Calls when rule change.
     */
    onChange = (ruleDetails: string) => {};

    /**
     * Calls when the item gets touched.
     */
    onTouched = () => {};

    /**
     * The change to check the validation.
     */
    _validatorOnChange = () => {};

    /**
     * @inheritDoc
     */
    writeValue(timeDetails: string) {
        this._value = timeDetails ? timeDetails : null;
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (timeDetails: string) => void) {
        this.onChange = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /**
     * Gets and pushes all the possible times based on the passed in minute
     * units provided.
     */
    private generateTimes() {
        const times = [];
        const quarterHours = this._minuteUnits;
        for (let i = 0; i < 24; i++) {
            for (const time of quarterHours) {
                times.push((i < 10 ? '0' : '') + i + ':' + time);
            }
        }

        this.times = times;
    }

    /**
     * Checks that the time isnt lower that the minumum value.
     * @param min The minimum time set.
     * @param time The time that has been passed in.
     */
    minCheck(min: string, time: string) {
        const minDecimal = this.timeToDecimal(min);
        const timeDecimal = this.timeToDecimal(time);
        return (
            minDecimal === null ||
            timeDecimal === null ||
            minDecimal <= timeDecimal
        );
    }

    /**
     * Checks that the time isnt higher that the maximum value.
     * @param max The maximum time set.
     * @param time The time that has been passed in.
     */
    maxCheck(max: string, time: string) {
        const maxDecimal = this.timeToDecimal(max);
        const timeDecimal = this.timeToDecimal(time);
        return (
            maxDecimal === null ||
            timeDecimal === null ||
            maxDecimal >= timeDecimal
        );
    }

    /**
     * Converts the passed in tome to a decimal number.
     * @param time The time that has been passed in.
     */
    timeToDecimal(time: string) {
        if (!time) {
            return null;
        }

        const timeDecimal = toNumber(time.replace(':', '.'));
        return !Number.isNaN(timeDecimal) ? timeDecimal : null;
    }

    /**
     * Checks wether the validation has been changed.
     */
    registerOnValidatorChange(fn: () => void): void {
        this._validatorOnChange = fn;
    }

    /** @docs-private */
    validate(c: AbstractControl): ValidationErrors | null {
        return this._validator ? this._validator(c) : null;
    }

    /** The form control validator for the min date. */
    private _minValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        return this.minCheck(this.minTime, control.value)
            ? null
            : { timePickerMin: { min: this.minTime, actual: control.value } };
    };

    /** The form control validator for the max date. */
    private _maxValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        return this.maxCheck(this.maxTime, control.value)
            ? null
            : { timePickerMax: { max: this.maxTime, actual: control.value } };
    };

    /** The combined form control validator for this input. */
    private _validator: ValidatorFn | null = Validators.compose([
        this._minValidator,
        this._maxValidator,
    ]);
}
