import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EMPTY, of, Subject } from 'rxjs';
import {
    debounceTime,
    filter,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    IPagingParams,
    IRequestPaging,
    SimplePagingController,
} from '../../../universal/paging';;
import { CustomersSageService } from '../customers-sage.service';
import { ISageCustomerDto } from '../../../county-api/src/customers';

@Component({
    selector: 'customers-sage-link-dialog',
    templateUrl: './customers-sage-link-dialog.component.html',
})
export class CustomersSageLinkDialogComponent extends SimplePagingController {
    pagingData: IRequestPaging = {
        page: 1,
        filter: null,
    };
    sageCustomers: ISageCustomerDto[] = [];

    selectedCustomer: ISageCustomerDto | null;

    existingLink = false;

    loadingLink = false;

    checkSubject$ = new Subject<ISageCustomerDto>();

    constructor(
        private customersSageService: CustomersSageService,
        private dialogRef: MatDialogRef<CustomersSageLinkDialogComponent>,
    ) {
        super();

        this.checkSubject$
            .pipe(
                tap(() => this.clearSelected()),
                tap(r => {
                    this.selectedCustomer = r;
                    this.loadingLink = true;
                }),
                debounceTime(500),
                switchMap(r => {
                    if (r) {
                        return this.customersSageService.check(
                            r.sl_customer_account_id,
                        );
                    } else {
                        return EMPTY;
                    }
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(r => {
                this.existingLink = r?.result?.linked;
                this.loadingLink = false;
            });
    }

    updateLocalParams(params: IPagingParams) {
        // Clear loading states amd linked status.
        const emptyCustomer = {
            customer_account_number: "",
            customer_account_name: "",
            date_of_last_transaction: "",
            account_is_on_hold: false,
            account_balance: 0,
            credit_limit: 0,
            sl_customer_account_id: 0,
        };
        this.checkSubject$.next(emptyCustomer);

        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) : 1,
            filter: params['filter'] || '',
        };
        return this.pagingData;
    }

    updateData(data: IRequestPaging) {
        if (!data.filter) {
            this.sageCustomers = [];
            this.pages = 0;
            return of({});
        }

        if (this.pagingData.page == 1) {
            this.sageCustomers = [];
        }

        return this.customersSageService
            .get({
                page: data.page,
                query: data.filter,
            })
            .pipe(
                tap(r => {
                    this.sageCustomers = [
                        ...this.sageCustomers,
                        ...r.result.customers,
                    ];
                    this.pages = r.result.pages;
                }),
            );
    }

    selectAccount(account: ISageCustomerDto) {
        this.checkSubject$.next(account);
    }

    submit() {
        this.dialogRef.close({ customer: this.selectedCustomer });
    }

    private clearSelected() {
        this.selectedCustomer = null;
        this.existingLink = false;
        this.loadingLink = false;
    }
}
