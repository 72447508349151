<div class="dialog-container">
    <button
        aria-label="Dismiss"
        class="dialog-close"
        mat-dialog-close
        tabindex="-1"
    ></button>

    <div class="dialog-heading">
        <h3>Job Details</h3>
    </div>

    <form (validSubmit)="submit()">
        <div mat-dialog-content>
            <ul class="form">
                <li class="form__item">
                    <label class="input-label" for="endorsement_code">Code</label>

                    <input
                        type="text"
                        name="endorsement_code"
                        id="endorsement_code"
                        required
                        [(ngModel)]="endorsement.code"
                        capitalise
                        #endorsementCode="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="endorsementCode">
                        <label
                            for="endorsement_code"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter a endorsment code.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="endorsement_group">Group</label>

                    <input
                        type="text"
                        name="endorsement_group"
                        id="endorsement_group"
                        required
                        [(ngModel)]="endorsement.group"
                        #endorsementGroup="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="endorsementGroup">
                        <label
                            for="endorsement_group"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter a endorsment group.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="endorsement_offence"
                        >Offence Description</label
                    >

                    <textarea
                        name="endorsement_offence"
                        id="endorsement_offence"
                        required
                        [(ngModel)]="endorsement.offence"
                        #endorsementOffence="ngModel"
                    ></textarea>
                    <div class="input-errors" [ngxErrors]="endorsementOffence">
                        <label
                            for="endorsement_offence"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter a endorsment offence description.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="endorsement_length"
                        >Length</label
                    >

                    <input
                        type="number"
                        name="endorsement_length"
                        id="endorsement_length"
                        required
                        [min]="0"
                        [(ngModel)]="endorsement.length"
                        #endorsementLength="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="endorsementLength">
                        <label
                            for="endorsement_length"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter the length this endorsement stays on a
                            licence.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="endorsement_min_points"
                        >Min Points</label
                    >

                    <input
                        type="number"
                        name="endorsement_min_points"
                        id="endorsement_min_points"
                        required
                        [min]="0"
                        [(ngModel)]="endorsement.min_points"
                        #endorsementMinPoints="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="endorsementMinPoints">
                        <label
                            for="endorsement_min_points"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter the minimum number of points gained with
                            this endorsement.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="endorsement_max_points"
                        >Max Points</label
                    >

                    <input
                        type="number"
                        name="endorsement_max_points"
                        id="endorsement_max_points"
                        required
                        [min]="0"
                        [(ngModel)]="endorsement.max_points"
                        #endorsementMaxPoints="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="endorsementMaxPoints">
                        <label
                            for="endorsement_max_points"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter the maximum number of points gained with
                            this endorsement.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="endorsement_insurance_rating"
                        >Insurance Rating</label
                    >

                    <input
                        type="number"
                        name="endorsement_insurance_rating"
                        id="endorsement_insurance_rating"
                        required
                        [min]="0"
                        [max]="100"
                        [(ngModel)]="endorsement.insurance_rating"
                        #endorsementInsuranceRating="ngModel"
                    />
                    <div
                        class="input-errors"
                        [ngxErrors]="endorsementInsuranceRating"
                    >
                        <label
                            for="endorsement_insurance_rating"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter a insurance rating.
                        </label>
                        <label
                            for="endorsement_insurance_rating"
                            [ngxError]="['min', 'max']"
                            class="input-error"
                        >
                            Insurance rating must be between 0-100.
                        </label>
                    </div>
                </li>
            </ul>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" mat-dialog-close class="btn">
                Cancel
            </button>

            <input
                type="submit"
                value="Confirm"
                class="btn btn--success btn--right"
            />
        </div>
    </form>
</div>