import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customers } from 'county-api';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    IPagingParams,
    IRequestPaging,
    PagingController,
} from '../../universal/paging';
import { PeopleService } from './people.service';

/**
 * The component to get the people and output the people in a list.
 */
@Component({
    selector: 'people',
    templateUrl: './people.component.html',
})
export class PeopleComponent extends PagingController<IRequestPaging> {
    /**
     * List of companies to display within the page.
     */
    people: Customers.IPerson[] = [];

    /**
     * Query details for collecting the companies from the api.
     */
    pagingData: IRequestPaging = {
        page: 1,
        filter: null,
        perpage: null,
    };

    /**
     * The last active request subscription used for cancelling of the request.
     */
    private request: Subscription;

    /**
     * Constructs injected angular dependencies.
     * @param peopleService The people service to get the list of people.
     * @param router Router service used to update the url with the new query strings.
     * @param route Needed for the paging component to gather the current query string params.
     */
    constructor(
        private peopleService: PeopleService,
        router: Router,
        route: ActivatedRoute,
    ) {
        super(router, route);
    }

    /**
     * Update the local paging data from the url params.
     * @param params The query params from the url.
     */
    updateLocalParams(params: IPagingParams) {
        this.people = [];
        this.pages = 0;

        // Gather the paging information from the url.
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            filter: params['filter'] ? params['filter'] : null,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
        } as IRequestPaging;

        // Floor the page number to 1.
        if (this.pagingData.page < 1) {
            this.pagingData.page = 1;
        }

        return this.pagingData;
    }

    /**
     * Load the latest set of data based on the passed in pagingData.
     * @param pagingData The data returned from updateLocalParams.
     */
    updateData(pagingData: IRequestPaging) {
        return this.peopleService.get(pagingData).pipe(
            tap(response => {
                this.people = response.result.people;
                this.pages = response.result.paging.total_pages;
                this.totalItems = response.result.paging.total;
            }),
        );
    }
}
