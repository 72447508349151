import { IEnvironment } from './environment.interface';
import { version } from './version';

const apiUrl = 'https://web2.county-rental.co.uk/';
const apiScope = 'api://a30e423d-b247-40e3-9052-9836f000c11a/User.Read';

export const environment: IEnvironment = {
    production: true,
    debug: false,
    tenant: 'luteaim2.onmicrosoft.com',
    clientId: '20a1d13e-4c5f-4ea7-9845-56263b937024',
    baseUrl: 'https://platform.county-rental.co.uk/',
    apiUrl,
    apiScope,
    version,
    protectedResource: [[apiUrl, [apiScope]]],
    appinsightConfig: {
        instrumentationKey: 'aa16e861-bfaa-4514-9a08-ac16b9b6c0cf',
    },
};
