import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import clone from 'lodash/clone';
import { LocationUpdated } from '../../users/store/user.actions';
import { UserState } from '../../users/store/user.state';
import {
    DashboardInit,
    UpdateDashboardLocation,
    UpdateDashboardTrigger,
    UpdateDashboardTriggerType,
} from './dashboard.actions';

export interface DashboardStateModel {
    // items: CartItem[];
    locationId: number;

    updateCounts: { [type: number]: number };
}

@State<DashboardStateModel>({
    name: 'dashboard',
    defaults: {
        locationId: null,
        updateCounts: {},
    },
    // children: [CartSavedState]
})
@Injectable()
export class DashboardState {
    constructor(private store: Store) {}

    @Selector()
    static locationId(state: DashboardStateModel) {
        return state.locationId;
    }

    @Selector()
    static updateCounts(state: DashboardStateModel) {
        return state.updateCounts;
    }

    @Action(DashboardInit)
    init({ getState, patchState }: StateContext<DashboardStateModel>) {
        const state = getState();
        if (state.locationId == null) {
            const user = this.store.selectSnapshot(UserState.user);
            patchState({
                locationId: user.location_id,
            });
        }
    }

    @Action([LocationUpdated, UpdateDashboardLocation])
    updateDashboardLocation(
        { getState, patchState }: StateContext<DashboardStateModel>,
        { payload }: LocationUpdated | UpdateDashboardLocation,
    ) {
        const currentLocationId = getState().locationId;

        if (currentLocationId != payload) {
            patchState({
                locationId: payload,
            });
        }
    }

    @Action(UpdateDashboardTrigger)
    updateDashboardRevenue(
        { getState, patchState }: StateContext<DashboardStateModel>,
        action: UpdateDashboardTrigger,
    ) {
        const updateTriggers = clone(getState().updateCounts);

        if (action.payload != UpdateDashboardTriggerType.All) {
            const currentCount = updateTriggers[action.payload] || 0;
            updateTriggers[action.payload] = currentCount + 1;
        }

        patchState({
            updateCounts: updateTriggers,
        });
    }
}
