<div class="user-details" *ngIf="user">
    <a class="user-details__link" [routerLink]="['/', 'users', user.id]">
        <img
            *ngIf="!!user.thumbnail"
            [src]="user.thumbnail"
            class="user-details__image"
        />
        <div class="user-details__information">
            <div class="user-details__name" [matTooltip]="user.username">
                {{ user.username }}
            </div>
            <location-label
                class="user-details__position"
                [locationId]="user.location_id"
            ></location-label>
        </div>
    </a>

    <sidebar-notification-toggle></sidebar-notification-toggle>
</div>
