import { Component, Input } from '@angular/core';

@Component({
    selector: 'dashboard-manager',
    templateUrl: './dashboard-manager.component.html',
    styleUrls: ['./dashboard-manager.component.scss'],
})
export class DashboardManagerComponent {
    @Input()
    type: string;
}
