<a class="item-list__link" *ngIf="pHref" [href]="pHref"></a>
<a class="item-list__link" *ngIf="pLink" [routerLink]="pLink"></a>

<div class="item-list-item__wrapper">
    <div class="item-list-item__prefix" *ngIf="itemList.pSelectable">
        <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? itemList.selection.toggle(pItem) : null"
            [checked]="_selected"
        >
        </mat-checkbox>
    </div>
    <div class="item-list-item__content">
        <ng-content></ng-content>
    </div>
</div>
