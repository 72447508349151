<topbar>
    <h1 topbar-title>Activities</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="content-section">
            <div class="wrapper wrapper--large">
                <div class="content-card">
                    <div class="content-list activities-list">
                        <!-- <div class="content-list-toolbar">
                            <ul
                                class="content-list-toolbar__list list-horizontal"
                            >
                                <li
                                    class="content-list-toolbar__item list--item__right"
                                >
                                    &nbsp;
                                    <div class="btn btn--dropdown" (click)="statusInput.open()">
                                        <span class="btn__label">Status</span>
                                        <mat-select class="btn__select" name="status" [value]="pagingData['status']" (selectionChange)="filterUpdate($event, 'status')"
                                            #statusInput>
                                            <mat-option [value]="status" *ngFor="let status of status">
                                                {{ status }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </li>
                            </ul>
                        </div> -->

                        <div *ngIf="loading < 1">
                            <ng-container *ngIf="activities?.length > 0">
                                <div class="content-list__list">
                                    <user-activities-list-item
                                        *ngFor="let activity of activities"
                                        [activity]="activity"
                                        [showUser]="true"
                                    >
                                    </user-activities-list-item>
                                </div>

                                <mat-paginator
                                    [length]="totalItems"
                                    [pageSize]="pagingData.perpage"
                                    (page)="pageChange($event)"
                                    [pageIndex]="pagingData.page - 1"
                                ></mat-paginator>
                            </ng-container>

                            <div
                                class="content-list__no-results"
                                *ngIf="activities?.length < 1"
                            >
                                No activities found for the entered resource.
                            </div>
                        </div>

                        <loader *ngIf="loading > 0"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
