<form (validSubmit)="submit()">
    <ul class="form note-list__form">
        <li class="form__item">
            <textarea
                name="message"
                id="message"
                required
                [(ngModel)]="note.message"
                placeholder="Note"
                #noteInput="ngModel"
            ></textarea>

            <div class="input-errors" [ngxErrors]="noteInput">
                <label class="input-error" ngxError="required"
                    >Please enter a note.</label
                >
            </div>
        </li>

        <li class="form__item btns btns--horizontal">
            <a class="btn btn--small" (click)="cancelClick()">Cancel</a>
            <input
                class="btn btn--primary btn--right btn--small"
                type="submit"
                value="Add"
            />
        </li>
    </ul>
</form>
