<button
    aria-label="Dismiss"
    class="dialog-close"
    mat-dialog-close
    tabindex="-1"
></button>

<h3 mat-dialog-title>Refund {{ charge.description }}</h3>

<form (validSubmit)="submit()">
    <div mat-dialog-content>
        <p>Please confirm you want to refund this charge.</p>

        <ul class="form" *ngIf="amountList.length > 1">
            <li class="form__item">
                <label class="input-label" for="charge_refund_count"
                    >How many items from this charge do you want to
                    refund</label
                >

                <div class="input-select">
                    <mat-select
                        id="refund_type"
                        name="refund_type"
                        [(ngModel)]="refundCount"
                        required
                        #chargeRefundCount="ngModel"
                    >
                        <mat-option></mat-option>
                        <mat-option
                            [value]="counter"
                            *ngFor="let counter of amountList"
                            >{{ counter }}</mat-option
                        >
                    </mat-select>
                </div>

                <div class="input-errors" [ngxErrors]="chargeRefundCount">
                    <label
                        for="charge_refund_count"
                        ngxError="required"
                        class="input-error"
                    >
                        Please select how many items from the charge you want to
                        refund.
                    </label>
                </div>
            </li>
        </ul>
    </div>

    <div class="btns btns--horizontal" mat-dialog-actions>
        <button type="button" class="btn" mat-dialog-close>
            Cancel
        </button>

        <input
            class="btn btn--primary btn--right"
            type="submit"
            value="Refund"
        />
    </div>
</form>
