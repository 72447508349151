import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook';
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faCar } from '@fortawesome/pro-solid-svg-icons/faCar';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCaretLeft';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExternalLinkSquare } from '@fortawesome/pro-solid-svg-icons/faExternalLinkSquare';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faGavel } from '@fortawesome/pro-solid-svg-icons/faGavel';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faShoppingBag } from '@fortawesome/pro-solid-svg-icons/faShoppingBag';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart';
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faCloudBinary } from '@fortawesome/pro-solid-svg-icons';

export const registerSolidIcons = (library: FaIconLibrary) => {
    library.addIcons(
        faAddressBook,
        faAddressCard,
        faBell,
        faCar,
        faCaretLeft,
        faCaretRight,
        faCheck,
        faExclamationCircle,
        faExternalLinkSquare,
        faFileAlt,
        faGavel,
        faHome,
        faKey,
        faLock,
        faPencil,
        faPlus,
        faQuestionCircle,
        faShoppingBag,
        faShoppingCart,
        faTag,
        faTimes,
        faCloudBinary
    );
};
