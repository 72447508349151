<a
    class="content-list__link content-list__item booking-list-item grid"
    [routerLink]="[booking.id]"
>
    <div class="row">
        <div class="content-list__status">
            <div
                class="content-list__status-label status-label"
                [ngClass]="{
                    'status-label--success': booking.status == 'Completed',
                    'status-label--warn': booking.status == 'Review',
                    'status-label--error': booking.status == 'Failed',
                    'status-label--primary': booking.status == 'Reserved',
                    'status-label--info': booking.status == 'Open'
                }"
            >
                <span class="status-label__dot"></span>
                {{ booking.status }}
            </div>
            <div class="booking-list-item__booking-ref">
                {{ booking.reference }}
            </div>
        </div>

        <div class="content-list__details m-col">
            <img
                class="booking-list-item__image user-thumbnail user-thumbnail--small"
                [src]="booking.customer.thumbnail"
            />
            <h4 class="content-list__heading">
                {{ booking.customer.forename }} {{ booking.customer.surname }}
            </h4>
            <h5 class="booking-list-item__vehicle">{{ booking.group_name }}</h5>
        </div>

        <div class="booking-list-item-info booking-list-item__length m-col-1">
            <h5 class="booking-list-item-info__label">Location</h5>
            <div class="booking-list-item-info__value">
                <location-label
                    [locationId]="booking.location_id"
                ></location-label>
            </div>
        </div>
        <div class="booking-list-item-info booking-list-item__length m-col-1">
            <h5 class="booking-list-item-info__label">Length</h5>
            <div class="booking-list-item-info__value">
                {{ booking.length }}
            </div>
        </div>
        <div class="booking-list-item-info booking-list-item__length m-col-1">
            <h5 class="booking-list-item-info__label">Mileage</h5>
            <div class="booking-list-item-info__value">
                {{ booking.mileage }}
            </div>
        </div>
        <div class="booking-list-item-info booking-list-item__length m-col-1">
            <h5 class="booking-list-item-info__label">
                Price
                <fa-icon
                    [icon]="['fab', 'cc-stripe']"
                    class="booking-stripe-icon"
                    matTooltip="This booking was paid for via stripe."
                    *ngIf="booking.paid_via_stripe"
                ></fa-icon>
            </h5>
            <div class="booking-list-item-info__value">
                {{ booking.sub_total | gbp }}
            </div>
        </div>
    </div>
</a>
