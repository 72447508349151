import { Component, Input } from '@angular/core';
import { Customers } from 'county-api';

/**
 * The component for the company list item.
 */
@Component({
    selector: 'company-list-item',
    templateUrl: './company-list-item.component.html',
})
export class CompanyListItemComponent {
    /**
     * The individal company data.
     */
    @Input()
    companyItem: Customers.ICompany;
}
