import { Component, Input } from '@angular/core';
import { Bookings } from 'county-api';

/**
 * The booking list item used to output each booking.
 */
@Component({
    selector: 'bookings-list-item',
    templateUrl: './bookings-list-item.component.html',
})
export class BookingsListItemComponent {
    /**
     * The booking data input from the parent.
     */
    @Input()
    booking: Bookings.IBooking;
}
