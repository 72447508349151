<div mat-dialog-content>
    <iframe
        src="https://forms.office.com/Pages/ResponsePage.aspx?id=laEJypsYF0CWhteG_YqKR806iOjblShOm1-XfwgAkrFUMDZHNUk1QTM5VTBBUTFHNzhLQUdHSFYxUC4u&embed=true"
        frameborder="0"
        marginwidth="0"
        marginheight="0"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        msallowfullscreen
    >
    </iframe>
</div>

<button aria-label="Dismiss" class="dialog-close" [mat-dialog-close]></button>
