import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ActionMenuComponent } from './action-menu.component';
import { ActionMenuItemComponent } from './components/action-menu-item.component';
import { ButtonModule } from 'platform-ui/button';
import { StackModule } from 'platform-ui/stack';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    declarations: [ActionMenuComponent, ActionMenuItemComponent],
    exports: [ActionMenuComponent, ActionMenuItemComponent],
    imports: [
        CommonModule,
        MatMenuModule,
        ButtonModule,
        StackModule,
        A11yModule,
    ],
})
export class ActionMenuModule {}
