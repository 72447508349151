import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Performance, Users } from 'county-api';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import * as moment from 'moment';
import { Moment } from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { NotificationsService } from '../../../universal/notifications/notifications.service';
import { UserState } from '../../../users/store/user.state';
import { PerformanceDataService } from '../../perfomance/performance-data.service';
import {
    UpdateDashboardTrigger,
    UpdateDashboardTriggerType,
} from '../../state/dashboard.actions';
import { DashboardState } from '../../state/dashboard.state';
import {
    IRevenueDialogData,
    IRevenueDialogResult,
    RevenueDialogComponent,
    RevenueDialogResultType,
} from './dialog/revenue-dialog.component';

export interface IPerformanceMonthlyWidths
    extends Performance.IPerformanceMonthly {
    revenue_width: number;
    estimate_width: number;
    good_amount: number;
    good_width: number;
    great_width: number;
}

/**
 * Dashboard widget for display rental revenue performance.
 */
@Component({
    selector: 'revenue-widget',
    templateUrl: './revenue-widget.component.html',
    styleUrls: ['./revenue-widget.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RevenueWidgetComponent implements OnInit, OnDestroy {
    /**
     * Performance data passed down from parent controller, used to output the json data to
     * the page.
     */
    performanceData: Performance.IPerformanceMonthly[];

    /**
     * Current location performance data including calculated widths for the
     * display graph.
     */
    locationPerformanceData: IPerformanceMonthlyWidths;

    /**
     * Date of the last uploaded date. Used to get data for the last date uploaded.
     */
    lastUploadDate: Moment;

    /**
     * Current date. Used to return the data for the current date.
     */
    currentDate: Moment;

    /**
     * Current location to display performance data for.
     */
    locationId = 0;

    /**
     * Loading state of the widget.
     */
    loading = 0;

    /**
     * Stores if the current selected month is the same month as the last upload
     * date.
     */
    isLastMonth = false;

    /**
     * Stores if the user is eligible to view the estimate, 1 by either being
     * admin or if we a >= 7 days into the month.
     */
    showEstimate = false;

    /**
     * Subject used to clean up the component on destroy.
     */
    unsubscribe$ = new Subject();

    /**
     * A moment that represents the
     */
    currentDate$ = new Subject<Moment>();

    user: Users.IUser;

    @Select(DashboardState.locationId)
    locationId$: Observable<number>;

    @Select(DashboardState.updateCounts)
    updateCounts$: Observable<{ [type: number]: number }>;

    constructor(
        private performanceService: PerformanceDataService,
        private notificationsService: NotificationsService,
        private store: Store,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.user = this.store.selectSnapshot(UserState.user);

        this.updateCounts$
            .pipe(
                takeUntil(this.unsubscribe$),
                map(d => d[UpdateDashboardTriggerType.Revenue]),
                distinctUntilChanged(),
                switchMap(() => this.getLastUploadDate()),
            )
            .subscribe();

        combineLatest(this.currentDate$, this.locationId$)
            .pipe(
                tap(data => {
                    this.loading++;
                    this.locationId = data[1];
                    this.currentDate = data[0];
                    this.isLastMonth = this.currentDate.isSame(
                        this.lastUploadDate,
                        'month',
                    );
                    this.showEstimate =
                        this.lastUploadDate.date() >= 7 ||
                        !this.isLastMonth ||
                        this.user.is_admin;
                }),
                switchMap(data =>
                    this.getLocationsData(data[0].format('YYYY-MM')),
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(data => {
                this.locationPerformanceData = data;
                this.loading--;
            });
    }

    getLastUploadDate() {
        this.loading++;
        return this.performanceService.getLastUploadData().pipe(
            map(data => {
                this.lastUploadDate = moment.utc(data.result.last_upload_date);
                const currentDate = this.lastUploadDate
                    .clone()
                    .startOf('month');
                this.currentDate$.next(currentDate);
                this.loading--;
                return currentDate;
            }),
        );
    }

    getLocationsData(date: string) {
        return this.performanceService.getMonthlyPerformance(date).pipe(
            map(data => {
                this.performanceData = orderBy(
                    data.result.performance_data,
                    'estimate',
                    'desc',
                );
                return this.getCurrentLocationPerformance();
            }),
        );
    }

    getCurrentLocationPerformance() {
        let locationPerformanceData = find(this.performanceData, {
            location_id: this.locationId,
        }) as IPerformanceMonthlyWidths;

        if (locationPerformanceData) {
            locationPerformanceData = this.calculateWidths(
                locationPerformanceData,
            );
        }

        return locationPerformanceData;
    }

    calculateWidths(locationPerformanceData: IPerformanceMonthlyWidths) {
        const orbitTarget = locationPerformanceData.orbit_target;
        let max =
            orbitTarget > locationPerformanceData.adjusted_estimate_amount
                ? orbitTarget
                : locationPerformanceData.adjusted_estimate_amount;
        max = max * 1.2;
        const good =
            orbitTarget - (orbitTarget - locationPerformanceData.target) * 0.6;

        locationPerformanceData.revenue_width =
            (locationPerformanceData.revenue / max) * 100;
        locationPerformanceData.estimate_width =
            (locationPerformanceData.adjusted_estimate_amount / max) * 100;
        locationPerformanceData.good_amount = good;
        locationPerformanceData.good_width = (good / max) * 100;
        locationPerformanceData.great_width = (orbitTarget / max) * 100;

        return locationPerformanceData;
    }

    /**
     * Adjust month when the user clicks the navigational arrows.
     */
    monthClick(month: number) {
        this.currentDate$.next(this.currentDate.clone().add(month, 'months'));
    }

    /**
     * Open the revenue actions dialog on user click.
     */
    updateClick() {
        const dialogRef = this.dialog.open<
            RevenueDialogComponent,
            IRevenueDialogData,
            IRevenueDialogResult
        >(RevenueDialogComponent, {
            width: '560px',
            data: {
                lastUploadDate: this.lastUploadDate,
            },
        });

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.dialogCallback(data);
            }
        });
    }

    /**
     * Method to perform actions based on the data passed back from the
     * RevenueDialogComponent.
     * @param data Data passed from the dialog.
     */
    dialogCallback(data: IRevenueDialogResult) {
        this.loading++;

        // Completed callback for all results of actions fired from the dialog.
        const completed = () => {
            // this.getLastUploadDate();
            this.store.dispatch(
                new UpdateDashboardTrigger(UpdateDashboardTriggerType.Revenue),
            );
            this.loading--;
        };

        switch (data.type) {
            case RevenueDialogResultType.Upload:
                // Make the service call that will upload the selected file to the api.
                this.performanceService.uploadData(data.files[0]).subscribe(
                    response => {
                        this.notificationsService.success(
                            'Performance data uploaded successfully.',
                        );
                        completed();
                    },
                    er => {
                        this.notificationsService.error(
                            'There was an error uploading the performance data.',
                        );
                        completed();
                    },
                );
                break;
            case RevenueDialogResultType.Targets:
                // Handle making the service call to save the targets for the
                // selected month.
                this.performanceService
                    .setPerformanceTarget(
                        data.date.format('YYYY-MM'),
                        data.targets,
                    )
                    .subscribe(
                        response => {
                            this.notificationsService.success(
                                'Performance targets updated successfully.',
                            );
                            completed();
                        },
                        er => {
                            this.notificationsService.error(
                                'There was an error updating the targets.',
                            );
                            completed();
                        },
                    );
                break;
        }
    }

    /**
     * Clean up the component firing the unsubscribe subject.
     */
    ngOnDestroy() {
        this.unsubscribe$.next("");
        this.unsubscribe$.complete();
    }
}
