<button
    aria-label="Dismiss"
    class="dialog-close"
    mat-dialog-close
    tabindex="-1"
></button>

<h3 mat-dialog-title>Open</h3>

<form (validSubmit)="submit()">
    <div mat-dialog-content>
        <p>
            Please confirm that you intend to open this booking, this step
            should only be taken during the vehicle checkout stage when the
            customer is at the rental desk.
        </p>

        <div class="dialog__divider"></div>
        <ul class="form">
            <li class="form__item" *ngIf="booking.paid_via_stripe">
                <label class="input-label">
                    Last 4 Digits of Card Used For Online Payment
                </label>
                <input
                    type="text"
                    id="last4_digits"
                    name="last4_digits"
                    [(ngModel)]="result.last4_digits"
                    [minlength]="4"
                    [maxlength]="4"
                    required
                    #last4Digits="ngModel"
                />

                <div class="input-errors" [ngxErrors]="last4Digits">
                    <label
                        for="last4_digits"
                        [ngxError]="['required', 'minLength', 'maxLength']"
                        class="input-error"
                    >
                        Please enter the last 4 digits of the card used for
                        payment.
                    </label>
                </div>
            </li>

            <li class="form__item">
                <label class="input-label" for="agreement_number">
                    Agreement Number
                </label>
                <input
                    type="text"
                    id="agreement_number"
                    name="agreement_number"
                    required
                    [(ngModel)]="result.agreement_number"
                    [maxlength]="20"
                    pattern="^[A-z]{2}[0-9]{3,}$"
                    capitalise
                    #agreementNumber="ngModel"
                />

                <div class="input-errors" [ngxErrors]="agreementNumber">
                    <label
                        for="agreement_number"
                        [ngxError]="['required', 'pattern', 'maxLength']"
                        class="input-error"
                    >
                        Please enter a valid rental agreement number.
                    </label>
                </div>
            </li>

            <li class="form__item" *ngIf="requireDepositAuth">
                <label class="input-label" for="deposit_authorisation_number">
                    Deposit Authorisation Number
                </label>
                <input
                    type="text"
                    id="deposit_authorisation_number"
                    name="deposit_authorisation_number"
                    required
                    [(ngModel)]="result.deposit_authorisation_number"
                    [maxlength]="6"
                    pattern="^[a-zA-Z0-9]{6,}$"
                    uppercase
                    #depositAuthorisationNumber="ngModel"
                />

                <div
                    class="input-errors"
                    [ngxErrors]="depositAuthorisationNumber"
                >
                    <label
                        for="deposit_authorisation_number"
                        [ngxError]="['required', 'pattern', 'maxLength']"
                        class="input-error"
                    >
                        Please enter a valid 6 digit sales authorisation number.
                    </label>
                </div>
                <label class="input-comment" for="deposit_authorisation_number"
                    >Deposit has to be taken manually, this is the auth code
                    from the deposit transaction.</label
                >
            </li>

            <li class="form__item" *ngIf="isDepositFailed">
                <div class="dialog__divider"></div>

                <p class="dialog__alert dialog__alert--warn">
                    We failed to retrieve the deposit for this booking, inorder
                    for the rental to continue you are required to take the
                    deposit at the desk.
                </p>
            </li>

            <li class="form__item" *ngIf="isDepositFailed">
                <label class="input-label" for="deposit_payment_made"
                    >Confirm that the deposit was taken by other means</label
                >

                <label class="input-checkbox">
                    <input
                        type="checkbox"
                        name="deposit_payment_made"
                        value="true"
                        required
                        [(ngModel)]="result.deposit_payment_made"
                        #depositPaymentMade="ngModel"
                    />
                    <span class="input-checkbox__label">Yes</span>
                </label>

                <div class="input-errors" [ngxErrors]="depositPaymentMade">
                    <label
                        for="deposit_payment_made"
                        ngxError="required"
                        class="input-error"
                    >
                        Please confirm that the deposit has been taken for this
                        rental.
                    </label>
                </div>
            </li>
        </ul>
    </div>

    <div class="btns btns--horizontal" mat-dialog-actions>
        <button type="button" class="btn" mat-dialog-close>
            Cancel
        </button>

        <input type="submit" value="Open" class="btn btn--primary btn--right" />
    </div>
</form>
