import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { UserGuard } from '../users/user.guard';
import { ActivitiesComponent } from './activities.component';

/**
 * Base routes for the tariff section of the app.
 */
const routes: Routes = [
    {
        path: 'activities/:prefix/:id',
        data: { breadcrumb: 'Activities' },
        children: [
            {
                path: '',
                component: ActivitiesComponent,
                pathMatch: 'full',
                canActivate: [MsalGuard, UserGuard],
            },
        ],
    },
];

export const routedComponents = [ActivitiesComponent];

/**
 * The routing module for the tariff.
 */
@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    declarations: [],
    exports: [RouterModule],
    providers: [],
})
export class ActivitiesRoutingModule {}
