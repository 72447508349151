import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Users } from 'county-api';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { Subscription } from 'rxjs';
import { UserState } from './store/user.state';
import { UsersService } from './users.service';

/**
 * Has permissions directive to remove an element from the page when the user doesnt have
 * the correct permission.
 */
@Directive({ selector: '[hasPermission]' })
export class HasPermissionDirective implements OnInit, OnChanges, OnDestroy {
    user: Users.IUser;

    /**
     * The occupation given the permission
     */
    permissions: string | string[];

    /**
     * Sets who has the permission.
     */
    @Input()
    set hasPermission(permissions: string | string[]) {
        this.permissions = permissions;
    }

    /**
     * Determines the condition of the permission.
     */
    andCondition = true;

    /**
     * Sets the permission when the condition is met.
     */
    @Input()
    @Optional()
    set hasPermissionAndIf(condition: boolean) {
        this.andCondition = condition;
    }

    /**
     * Determines the condition of the permission.
     */
    orCondition = false;

    /**
     * Sets the permission when the condition is met.
     */
    @Input()
    @Optional()
    set hasPermissionOrIf(condition: boolean) {
        this.orCondition = condition;
    }

    /**
     * Determines the condition of the permission.
     */
    excludeAdmin = false;

    /**
     * Sets the permission when the condition is met.
     */
    @Input()
    @Optional()
    set hasPermissionExcludeAdmin(excludeAdmin: boolean) {
        this.excludeAdmin = excludeAdmin;
    }

    /**
     * Store a ref of the created element so we dont recreate it when the
     * user updates but the permission does not.
     */
    private elementRef: EmbeddedViewRef<any>;

    private userSubscription: Subscription;

    /**
     * Constructs injected angular dependencies.
     */
    constructor(
        private usersService: UsersService,
        private store: Store,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit() {
        this.getUser();
    }

    /**
     * Gets the user and determines whether the user gets to see the page or not.
     */
    validatePermission() {
        if (
            isEmpty(this.permissions) ||
            this.orCondition ||
            (this.user &&
                this.usersService.hasPermission(
                    this.user,
                    this.permissions,
                    this.excludeAdmin,
                ) &&
                this.andCondition !== false)
        ) {
            if (!this.elementRef) {
                this.elementRef = this.viewContainer.createEmbeddedView(
                    this.templateRef,
                );
            }
        } else {
            this.viewContainer.clear();
            this.elementRef = null;
        }
    }

    /**
     * Handle revalidating the users permissions.
     * @param changes When any of the props update we need to re-validate the
     * users permissions.
     */
    ngOnChanges(changes: SimpleChanges) {
        if (
            !isUndefined(changes['hasPermission']) ||
            !isUndefined(changes['hasPermissionAndIf']) ||
            !isUndefined(changes['hasPermissionOrIf'])
        ) {
            this.validatePermission();
        }
    }

    /**
     * Set up getting the user from the store.
     */
    getUser() {
        this.userSubscription = this.store
            .select(UserState.user)
            .subscribe(user => {
                this.user = user;
                this.validatePermission();
            });
    }

    /**
     * Clean up the user sub when the component is destroyed.
     */
    ngOnDestroy() {
        if (this.userSubscription && !this.userSubscription.closed) {
            this.userSubscription.unsubscribe();
        }
    }
}
