import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'action-menu',
    templateUrl: './action-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.action-menu]': 'true',
    },
})
export class ActionMenuComponent {
    @Input()
    pCollapse: boolean;
}
