import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { SpinnerModule } from 'platform-ui/spinner';
 import { LocationsModule } from '../locations/locations.module';
import { UniversalModule } from '../universal';
import { UsersComponentsModule } from '../users/users-components.module';
import { AppComponent } from './app.component';
import { FooterNoteComponent } from './footer/footer-note/footer-note.component';
import { NoContentComponent } from './no-content/no-content.component';
 import { OutletComponent } from './outlet/outlet.component';
import { ChangelogDialogComponent } from './sidebar/changelog/dialog/changelog-dialog.component';
import { ChangelogListComponent } from './sidebar/changelog/list/changelog-list.component';
import { BugReportDialogComponent } from './sidebar/dialogs/bug-report-dialog.component';
 import { SidebarFooterComponent } from './sidebar/footer/sidebar-footer.component';
 import { SidebarMenuComponent } from './sidebar/menu/sidebar-menu.component';
import { MobileNavComponent } from './sidebar/mobile/mobile-nav.component';
import { SidebarNotificationComponent } from './sidebar/notifications/notification/sidebar-notification.component';
import { SidebarNotificationsComponent } from './sidebar/notifications/notifications/sidebar-notifications.component';
import { SidebarNotificationToggleComponent } from './sidebar/notifications/sidebar-notifications-toggle.component';
  import { SidebarProfileComponent } from './sidebar/profile/sidebar-profile.component';
 import { SidebarComponent } from './sidebar/sidebar.component';
import { LayoutState } from './store/layout.state';
 import { TopbarBreadcrumbsComponent } from './topbar/breadcrumbs/topbar-breadcrumbs.component';
 import { TopbarComponent } from './topbar/topbar.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
    imports: [
         UniversalModule,
        RouterModule,
        UsersComponentsModule,
        NgxsModule.forFeature([LayoutState]),
         LocationsModule,
         MatTooltipModule,
        SpinnerModule,
    ],
    declarations: [
         AppComponent,
         SidebarComponent,
          SidebarProfileComponent,
         SidebarFooterComponent,
         SidebarMenuComponent,
         OutletComponent,
        TopbarComponent,
        TopbarBreadcrumbsComponent,
        SidebarNotificationToggleComponent,
        SidebarNotificationsComponent,
        SidebarNotificationComponent,
        MobileNavComponent,
        BugReportDialogComponent,
        UnauthorisedComponent,
        ChangelogDialogComponent,
        ChangelogListComponent,
        NoContentComponent,
        FooterNoteComponent,
    ],
    exports: [
        AppComponent,
        TopbarComponent,
        UnauthorisedComponent,
        OutletComponent,
        NoContentComponent,
        FooterNoteComponent,
        MobileNavComponent,
        SidebarComponent
    ],
})
export class LayoutModule {}
