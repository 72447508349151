<topbar>
    <h1 topbar-title>Add Person</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="wrapper">
            <form #myForm="ngForm" (validSubmit)="submit($event)">
                <div class="content-section customer-form">
                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">
                                Personal Details
                            </h3>
                        </div>

                        <div class="content-card__body">
                            <person-add-details
                                [person]="person"
                            ></person-add-details>
                        </div>
                    </div>

                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">
                                Contact Details
                            </h3>
                        </div>

                        <div class="content-card__body">
                            <customers-contacts-input
                                name="person_contact_details"
                                required
                                [(ngModel)]="person.contact_details"
                                #contactsInput="ngModel"
                            ></customers-contacts-input>

                            <div
                                class="input-errors"
                                [ngxErrors]="contactsInput"
                            >
                                <label
                                    for="input-error"
                                    ngxError="required"
                                    class="input-error"
                                    >Please enter a valid contact.</label
                                >
                                <label
                                    for="input-error"
                                    ngxError="primaryContact"
                                    class="input-error"
                                    >Please enter one primary contact.</label
                                >
                                <label
                                    for="input-error"
                                    ngxError="duplicateContacts"
                                    class="input-error"
                                    >You can not have duplicate contacts.</label
                                >
                            </div>
                        </div>
                    </div>

                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">
                                Address Details
                            </h3>
                        </div>

                        <div class="content-card__body">
                            <customers-address-input
                                name="person_addresses"
                                required
                                [(ngModel)]="person.addresses"
                                [requireDate]="true"
                                requireOneCurrent
                                #addressessInput="ngModel"
                            ></customers-address-input>

                            <div
                                class="input-errors"
                                [ngxErrors]="addressessInput"
                            >
                                <label class="input-error" ngxError="required"
                                    >Please add an address.</label
                                >
                                <label
                                    class="input-error"
                                    ngxError="requireOneCurrent"
                                    >Please add one current address, by leaving
                                    'Move Out Date' blank.</label
                                >
                            </div>
                        </div>
                    </div>

                    <div class="content-card">
                        <div class="content-card__footer">
                            <div class="btns btns--horizontal">
                                <a class="btn" (click)="cancelClick()"
                                    >Cancel</a
                                >
                                <input
                                    type="submit"
                                    value="Create Person"
                                    class="btn btn--primary btn--right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
