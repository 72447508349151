import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'card-section',
    templateUrl: './card-section.component.html',
    styleUrls: ['./card-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.card__section]': 'true',
    },
})
export class CardSectionComponent {
    @Input()
    @HostBinding('class.card__section--secondary')
    get pSecondary(): boolean {
        return this._secondary;
    }
    set pSecondary(value: boolean) {
        this._secondary = coerceBooleanProperty(value);
    }
    private _secondary = false;

    @Input()
    pTitle: string;
}
