<div class="user-notifications__header">
    <h2 class="user-notifications__header-heading">Notifications</h2>

    <a
        class="user-notifications__header-icon"
        (click)="toggleNotificationsSocketClick()"
        [ngSwitch]="notificationsDisabled$ | async"
        *hasPermission="'admin'"
    >
        <fa-icon icon="bell-slash" *ngSwitchCase="true"></fa-icon>
        <fa-icon icon="bell" *ngSwitchDefault></fa-icon>
    </a>

    <a
        class="user-notifications__header-icon"
        (click)="markAllReadClick()"
        *ngIf="unreadNotificationsCount$ | async"
    >
        <fa-icon icon="envelope-open"></fa-icon>
    </a>

    <a class="user-notifications__header-icon" (click)="pinClick()">
        <fa-icon
            icon="thumbtack"
            [transform]="(pinNotifications$ | async) ? 'rotate-180' : ''"
        ></fa-icon>
    </a>

    <a class="user-notifications__header-icon" (click)="closeClick()">
        <fa-icon icon="times"></fa-icon>
    </a>
</div>

<div class="user-notifications__body micro-scroller" cdkScrollable>
    <ul class="user-notifications__list">
        <li
            class="user-notifications__list-item"
            *ngFor="let notification of notifications"
        >
            <sidebar-notification
                [notification]="notification"
            ></sidebar-notification>
        </li>

        <li *ngIf="notificationsLoading$ | async">
            <loader></loader>
        </li>

        <li
            class="user-notifications__list-item user-notifications__list-item--more"
            *ngIf="
                (notificationsLoading$ | async) !== true &&
                (notificationsMore$ | async)
            "
        >
            <a class="user-notifications__list-item" (click)="nextPageClick()"
                >Load More</a
            >
        </li>
    </ul>
</div>
