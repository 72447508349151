import { Directive, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import clone from 'lodash/clone';
import isUndefined from 'lodash/isUndefined';
import toString from 'lodash/toString';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { IPagingParams } from './IPagingParams';
import { IRequestPaging } from './IRequestPaging';

/**
 * Simple controller class to aid with updating a url for paged, sorted and/or
 * filtered items.
 */
@Directive()
export abstract class BasePagingController<T> implements OnInit, OnDestroy {
    /**
     * Stored the latest changed to the paging query information, this is to be
     * updated by the the inheriting component.
     */
    pagingData$ = new BehaviorSubject<{ [name: string]: string }>({});

    /**
     * Local values for the paging information.
     */
    pagingData = {} as IRequestPaging;

    pagingLoading = false;

    pagingSizes = [10, 25, 50];

    /**
     * Total count of available pages.
     * @deprecated Use total items instead.
     */
    pages = 0;

    /**
     * Keep a count of all active items in the list.
     */
    totalItems = 0;

    unsubscribe$ = new Subject();

    reload$ = new BehaviorSubject<boolean>(true);

    /**
     * Setup the observables to handle paging when the component inits.
     */
    ngOnInit(): void {
        const setLoading = () => (this.pagingLoading = true);
        const clearLoading = () => (this.pagingLoading = false);

        // Listen to changes in the query params to update the data on update.
        combineLatest(this.getParams(), this.reload$)
            .pipe(
                tap(setLoading),
                map(d => this.updateLocalParams(d[0])),
                switchMap(d => {
                    const data = clone(d);
                    const obvs$ = this.updateData(data) || of(data);
                    // Catch any error that may occur when requesting the data.
                    return obvs$.pipe(catchError(() => of(data)));
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe({
                next: clearLoading,
                error: clearLoading,
                complete: clearLoading,
            });

        // Implemented simple buffered debounce, in order to handle the users
        // input of possible params to add to the url. Will debounce all changes
        // then merge them to form the latest values.
        let buffer: Array<{ [name: string]: string }> = [];
        this.pagingData$
            .pipe(
                tap(x => {
                    buffer.push(x);
                }),
                //debounceTime(500), - Temporarily removed, will apply alternative implementation if needed
                takeUntil(this.unsubscribe$),
                map(
                    () =>
                        Object.assign({}, ...buffer) as {
                            [key: string]: string;
                        },
                ),
                tap(() => (buffer = [])),
            )
            .subscribe(c => this.storeParams(c));
    }

    /**
     * Method called to store the query params for the paging logic.
     * @param params The params that have been changed and their new value.
     */
    abstract storeParams(params: { [key: string]: string }): void;

    abstract getParams(): Observable<{ [key: string]: string }>;

    /**
     * Method that needs to be implemented by the extending component in order
     * to retrieve its required values from the query string. Its return value
     * is passed to the updateData method.
     * @param data The latest query values grabbed from the queryParams.
     */
    abstract updateLocalParams(params: IPagingParams): T;

    /**
     * Method called when a item has changes on the url and the data should be updated on the
     * page.
     * @param data The data from the url.
     */
    abstract updateData(data: T): Observable<any> | void;

    /**
     * Handle updating the query strings for the passes in property type.
     * @param $event The event that contains the value for the new query string.
     * @param type The query string name.
     * @param ignorePage Whether to not reset the page count to null or not.
     */
    filterUpdate(
        $event: { value: any },
        type: string,
        ignorePage: boolean = false,
    ) {
        const queryParams = {};

        // Set the new query string key value props. If no value exists we set
        // it to null and then the updateLocalParams will handle the data.
        queryParams[type] =
            $event.value !== null &&
            !isUndefined($event.value) &&
            $event.value !== ''
                ? toString($event.value)
                : null;

        // If we dont set to ignore updating the page number we reset it to
        // null.
        if (!ignorePage) {
            queryParams['page'] = null;
        }

        // Add the paging information to the observable.
        this.pagingData$.next(queryParams);
    }

    /**
     * Reload the content on the page with the current information.
     */
    reload(resetPage = false) {
        if (resetPage && this.pagingData.page != 1) {
            this.filterUpdate({ value: 1 }, 'page', false);
        } else {
            this.reload$.next(true);
        }
    }

    /**
     * Increment the page number by one.
     */
    nextPage() {
        // Validate that we have another page that can be loaded and then update
        // the filter string.
        if (
            this.pagingData.page < this.pages ||
            this.pagingData.page < this.totalItems / this.pagingData.perpage
        ) {
            this.filterUpdate(
                { value: this.pagingData.page + 1 },
                'page',
                true,
            );
        }
    }

    /**
     * Decrease the page count by 1.
     */
    prevPage() {
        if (this.pagingData.page > 1) {
            this.filterUpdate(
                { value: this.pagingData.page - 1 },
                'page',
                true,
            );
        }
    }

    /**
     * Handle the page change event that triggers from the mat paginator.
     */
    pageChange(event: PageEvent) {
        // If its the page that is changing then update that property.
        if (event.pageIndex + 1 !== this.pagingData.page) {
            this.filterUpdate({ value: event.pageIndex + 1 }, 'page', true);
        }

        // If the page size has changed update that.
        if (event.pageSize !== this.pagingData.perpage) {
            this.filterUpdate({ value: event.pageSize }, 'perpage');
        }
    }

    /**
     * Clean up the observables.
     */
    ngOnDestroy() {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
        this.pagingData$.complete();
        this.reload$.complete();
    }
}
