<div class="form-field__label-wrapper" *ngIf="pLabel">
    <label class="form-field__label" [id]="_labelId" [attr.for]="_control?.id">
        {{ pLabel }}

        <span class="form-field__optional-label" *ngIf="pOptional">
            optional
        </span>
    </label>
</div>

<div class="form-field__content">
    <div class="form-field__content-flex">
        <div class="form-field__prefix" *ngIf="_prefixChildren.length">
            <ng-content select="[matPrefix]"></ng-content>
        </div>

        <ng-content></ng-content>

        <div
            class="form-field__content--arrow"
            *ngIf="_control?.controlType == 'mat-native-select'"
        ></div>
        <div class="form-field__suffix" *ngIf="_suffixChildren.length">
            <ng-content select="[matSuffix]"></ng-content>
        </div>
    </div>
</div>

<ng-content select="[ngxErrors]"></ng-content>

<div
    class="form-field__help-wrapper"
    *ngIf="pHelpLabel && (!pErrorLabel || !_control?.errorState)"
>
    <label
        class="form-field__help-label"
        [id]="_helpLabelId"
        [attr.for]="_control?.id"
    >
        {{ pHelpLabel }}
    </label>
</div>

<div
    class="form-field__error-wrapper"
    *ngIf="_control?.errorState && !!pErrorLabel"
>
    <label class="form-field__error-label" [attr.for]="_control?.id">
        {{ pErrorLabel }}
    </label>
</div>
