import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Performance } from 'county-api';
import { IncidentRepairStatus, IncidentStatus } from 'county-api/enums';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { DestroyController } from 'src/app/universal/components/destroy.controller';
import { PerformanceDataService } from '../../perfomance/performance-data.service';
import { DashboardState } from '../../state/dashboard.state';

/**
 * Dashboard widget to display incident data summary.
 */
@Component({
    selector: 'incidents-widget',
    templateUrl: './incidents-widget.component.html',
    styleUrls: ['./incidents-widget.component.scss'],
})
export class IncidentsWidgetComponent extends DestroyController
    implements OnInit {
    /**
     * The data to display in the widget.
     */
    incidentData: Performance.IPerformanceIncidentsViewModel;

    /**
     * Whether the component is loading or not.
     */
    loading = true;

    /**
     * The users current location id.
     */
    locationId = 0;

    /**
     * The possible incident statuses.
     */
    incidentStatus = IncidentStatus;

    /**
     * The repair statuses of an incident.
     */
    incidentRepairStatus = IncidentRepairStatus;

    /**
     * Construct the component and import required deps.
     * @param performanceService Perfomance api service.
     * @param store Ngxs store.
     */
    constructor(
        private performanceService: PerformanceDataService,
        private store: Store,
    ) {
        super();
    }

    /**
     * Watch the dashboard location id,
     */
    ngOnInit() {
        this.store
            .select(DashboardState.locationId)
            .pipe(
                filter(l => l !== null),
                tap(locationId => {
                    this.loading = true;
                    this.locationId = locationId;
                }),
                switchMap(locationId =>
                    this.performanceService.getIncidentsData(locationId),
                ),
                map(response => response.result),
                takeUntil(this.unsubscribe$),
                catchError(() => of(null)),
            )
            .subscribe(incidentData => {
                this.incidentData = incidentData;
                this.loading = false;
            });
    }
}
