<topbar>
    <h1 topbar-title>Endorsements</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="wrapper wrapper--small">
            <div class="content-section">
                <div class="content-card">
                    <div class="content-card__body">
                        <ul class="form">
                            <li class="form__item">
                                <label class="input-label">Search</label>

                                <input
                                    type="search"
                                    name="endorsements_search"
                                    id="endorsements_search"
                                    [(ngModel)]="pagingData.filter"
                                    (input)="
                                        filterUpdate($event.target, 'filter')
                                    "
                                />

                                <a
                                    class="btn btn--primary"
                                    (click)="addEndorsementClick()"
                                    >Add New</a
                                >
                            </li>
                        </ul>

                        <div *ngIf="loading < 1 && pages > 0">
                            <ul class="list-styled">
                                <li
                                    class="list-styled__item"
                                    *ngFor="
                                        let endorsement of endorsements;
                                        let i = index
                                    "
                                >
                                    <div class="list-styled__label">
                                        {{ endorsement.code }}
                                        <small [title]="endorsement.offence">{{
                                            endorsement.offence
                                        }}</small>
                                    </div>

                                    <ul class="list-styled__actions">
                                        <li class="list-styled__action">
                                            <a
                                                (click)="
                                                    editEndorsementClick(
                                                        i,
                                                        endorsement
                                                    )
                                                "
                                                class="list-styled__action-link"
                                            >
                                                <fa-icon
                                                    icon="pencil"
                                                ></fa-icon>
                                            </a>
                                        </li>
                                        <li class="list-styled__action">
                                            <a
                                                (click)="
                                                    deleteEndorsementClick(
                                                        i,
                                                        endorsement
                                                    )
                                                "
                                                class="list-styled__action-link"
                                            >
                                                <fa-icon icon="times"></fa-icon>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <mat-paginator
                                [length]="totalItems"
                                [pageSize]="pagingData.perpage"
                                (page)="pageChange($event)"
                                [pageIndex]="pagingData.page - 1"
                            ></mat-paginator>
                        </div>
                    </div>

                    <loader *ngIf="loading > 0"></loader>
                </div>
            </div>
        </div>
    </div>
</div>
