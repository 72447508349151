import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Bookings } from 'county-api';
import {
    BookingRefundType,
    BookingTransactionMethod,
    BookingTransactionType,
} from 'county-api/enums';
import isNumber from 'lodash/isNumber';
import { UserState } from '../../../users/store/user.state';
import { UsersService } from '../../../users/users.service';
import { IFilterItem } from '../../bookings.component';

/**
 * The type for the callback to the parent.
 */
interface IBookingComplete {
    type: BookingRefundType;
    amount: number;
    agreement_number: string;
    deposit_payment_made: boolean;
}

/**
 * The booking complete dialog used to submit the complete.
 */
@Component({
    selector: 'booking-complete-dialog',
    templateUrl: './booking-complete-dialog.component.html',
})
export class BookingCompleteDialogComponent implements OnInit {
    /**
     * The booking data passed from the parent.
     */
    booking: Bookings.IBooking;

    /**
     * The booking refund options.
     */
    bookingRefunds = BookingRefundType;

    /**
     * The booking refund items.
     */
    bookingRefundItems: IFilterItem[] = [];

    /**
     * Determines wether the deposit has failed.
     */
    isDepositFailed = false;

    /**
     * The max refund amount.
     */
    max: number;

    /**
     * The min refund amount.
     */
    min: number;

    /**
     * The data passed back to the parent.
     */
    result = {} as IBookingComplete;

    /**
     * Detemines wether refund custom is true.
     */
    canRefundCustom = false;

    /**
     * Used to display the "includes terminal action" message.
     */
    depositPaidByTerminal: boolean;

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialog references funtionality.
     * @param data The data passed from the parent.
     */
    constructor(
        private usersService: UsersService,
        private store: Store,
        private dialogRef: MatDialogRef<BookingCompleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: Bookings.IBookingViewModel,
    ) {
        this.booking = data ? data.booking : null;
    }

    /**
     * Gets the vurrent user and sets the deposit failed and result type.
     */
    ngOnInit() {
        if (this.booking) {
            this.min = 0.01;
            this.max = this.booking.paid_total;
            this.result.agreement_number = this.booking.agreement_number;

            this.depositPaidByTerminal =
                this.booking.transactions.filter(
                    bt =>
                        bt.method == BookingTransactionMethod.Card &&
                        bt.type == BookingTransactionType.Deposit,
                ).length > 0;

            if (this.booking.paid_total !== this.booking.total) {
                this.isDepositFailed = true;
                this.result.type = this.bookingRefunds.None;
            } else {
                this.result.type = this.bookingRefunds.Deposit;
            }
            const user = this.store.selectSnapshot(UserState.user);
            this.canRefundCustom = this.usersService.hasPermission(
                user,
                'booking-refund',
            );
            this.foramatBookingRefundTypes();
        }
    }

    /**
     * When true pushes the refund type to the items array.
     */
    foramatBookingRefundTypes() {
        for (const key in BookingRefundType) {
            if (
                BookingRefundType.hasOwnProperty(key) &&
                !isNumber(key) &&
                (BookingRefundType[key] !== BookingRefundType.Custom ||
                    this.canRefundCustom) &&
                (BookingRefundType[key] !== BookingRefundType.Deposit ||
                    !this.isDepositFailed)
            ) {
                this.bookingRefundItems.push({
                    id: key,
                    label: BookingRefundType[key],
                });
            }
        }
    }

    /**
     * Checks that the type isnt custom.
     */
    selectChange(data: IBookingComplete) {
        if (data.type !== BookingRefundType.Custom && data.amount != null) {
            data.amount = 0;
        }
    }

    /**
     * When valid passes back the complete data.
     */
    submit() {
        this.dialogRef.close(this.result);
    }
}
