import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import get from 'lodash/get';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutState } from '../layout/store/layout.state';
import {
    DashboardInit,
    UpdateDashboardLocation,
} from './state/dashboard.actions';
import { DashboardState } from './state/dashboard.state';

interface IDashboardWidgetItem {
    id: string;
    col?: number;
    row?: number;
    tablet?: { col?: number; row?: number };
    mobile?: { col?: number; row?: number };
}

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {
    dashboardWidgets: IDashboardWidgetItem[] = [
        {
            id: 'dashboard-office-widget',
            col: 12,
            row: 1,
            tablet: { col: 12, row: 1 },
            mobile: { col: 12, row: 1 },
        },
        { id: 'dashboard-utilisation-widget', col: 7, tablet: { col: 6 } },
        { id: 'dashboard-incidents-widget', col: 5, tablet: { col: 6 } },
    ];

    isMobile = false;

    isTablet = false;

    @Select(DashboardState.locationId)
    locationId$: Observable<number>;

    unsubscribe$ = new Subject();

    constructor(private store: Store) {
        this.store
            .select(LayoutState.isMobile)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isMobile => {
                this.isMobile = isMobile;
            });

        this.store
            .select(LayoutState.isTablet)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isTablet => {
                this.isTablet = isTablet;
            });
    }

    ngOnInit() {
        this.store.dispatch(new DashboardInit());
    }

    /**
     * Get the coloumns to cover for a given widget, with sain defaults.
     * @param widget The widget to get the col for.
     */
    getWidgetCol(widget: IDashboardWidgetItem) {
        if (this.isMobile) {
            return get(widget, 'mobile.col', 12);
        }
        if (this.isTablet) {
            return get(widget, 'tablet.col', 4);
        }
        return get(widget, 'col', 3);
    }

    /**
     *
     * @param widget The widget to get the rows for.
     */
    getWidgetRow(widget: IDashboardWidgetItem) {
        if (this.isMobile) {
            return get(widget, 'mobile.row', 8);
        }
        if (this.isTablet) {
            return get(widget, 'tablet.row', 4);
        }
        return get(widget, 'row', 4);
    }

    updateLocationId(locationId: number) {
        this.store.dispatch(new UpdateDashboardLocation(locationId));
    }

    ngOnDestroy() {
        this.unsubscribe$.next("");
        this.unsubscribe$.complete();
    }
}
