import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ItemListComponent } from '../item-list.component';

@Component({
    selector: 'item-list-item',
    templateUrl: './item-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.item-list-item]': 'true',
    },
})
export class ItemListItemComponent implements OnInit, OnDestroy {
    @Input()
    pHref: string;

    @Input()
    pLink: any;

    @Input()
    pItem: any;

    _selected = false;

    private _destroyed = new Subject<void>();

    constructor(
        public itemList: ItemListComponent,
        private _changeRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.itemList.selection.changed
            .pipe(takeUntil(this._destroyed))
            .subscribe(v => {
                this._selected = v.source.isSelected(this.pItem);
                this._changeRef.detectChanges();
            });
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }
}
