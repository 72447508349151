import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { UserGuard } from '../users/user.guard';
import { CustomerAddComponent } from './add/customer-add.component';
import { CompanyAddComponent } from './companies/add/company-add.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyDetailsComponent } from './company/company-details.component';
import { CompanyResolve } from './company/company.resolve';
import { JobsComponent } from './jobs/jobs.component';
import { PersonAddComponent } from './people/add/person-add.component';
import { PeopleComponent } from './people/people.component';
import { PersonDetailsComponent } from './person/person-details.component';
import { PersonResolve } from './person/person.resolve';

/**
 * Base routes for the customers section of the app.
 */
const routes: Routes = [
    {
        path: 'customers',
        canActivate: [MsalGuard],
        data: { breadcrumb: 'Customers' },
        children: [
            {
                path: 'companies',
                data: { breadcrumb: 'Companies' },
                children: [
                    {
                        path: '',
                        component: CompaniesComponent,
                        canActivate: [MsalGuard, UserGuard],
                        pathMatch: 'full',
                    },
                    {
                        path: 'add',
                        component: CompanyAddComponent,
                        data: {
                            breadcrumb: 'Add',
                            menuUrl: '/customers/companies',
                        },
                        canActivate: [MsalGuard, UserGuard],
                    },
                    {
                        path: ':id',

                        resolve: { company: CompanyResolve },
                        runGuardsAndResolvers: 'always',
                        data: {
                            breadcrumb: 'Company',
                            menuUrl: '/customers/companies',
                        },
                        component: CompanyDetailsComponent,
                        canActivate: [MsalGuard, UserGuard],
                        pathMatch: 'full',
                    },
                ],
            },
            {
                path: 'people',
                data: { breadcrumb: 'People' },
                children: [
                    {
                        path: '',
                        component: PeopleComponent,
                        pathMatch: 'full',
                        canActivate: [MsalGuard, UserGuard],
                    },
                    {
                        path: 'add',
                        component: PersonAddComponent,
                        data: {
                            breadcrumb: 'Add',
                            menuUrl: '/customers/people',
                        },
                        canActivate: [MsalGuard, UserGuard],
                    },
                    {
                        path: ':id',
                        resolve: { person: PersonResolve },

                        runGuardsAndResolvers: 'always',
                        data: {
                            breadcrumb: 'Person',
                            menuUrl: '/customers/people',
                        },
                        component: PersonDetailsComponent,
                        pathMatch: 'full',
                        canActivate: [MsalGuard, UserGuard],
                    },
                ],
            },
            {
                path: 'jobs',
                data: { breadcrumb: 'Jobs', menuUrl: '/customers/settings' },
                component: JobsComponent,
                pathMatch: 'full',
                canActivate: [MsalGuard, UserGuard],
            },
            {
                path: 'add',
                component: CustomerAddComponent,
                data: { breadcrumb: 'Add' },
                pathMatch: 'full',
                canActivate: [MsalGuard, UserGuard],
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('./settings/customer-settings.module').then(
                        m => m.CustomerSettingsModule,
                    ),
            },
        ],
    },
];

export const routedComponents = [
    CompaniesComponent,
    CompanyDetailsComponent,
    PeopleComponent,
    PersonDetailsComponent,
    JobsComponent,
    CustomerAddComponent,
    CompanyAddComponent,
    PersonAddComponent,
];

/**
 * The module to define the routes of the customers page.
 */
@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    declarations: [],
    exports: [RouterModule],
    providers: [],
})
export class CustomersRoutingModule {}
