<h3 class="dialog__heading" mat-dialog-title>Reload Required</h3>

<div mat-dialog-content>
    <p>
        There has been a major change deployed that requires you to reload the
        page. Sorry for the inconvenience.
    </p>
</div>

<div class="btns btns--horizontal" mat-dialog-actions>
    <button type="button" class="btn btn--primary btn--right" mat-dialog-close>
        Reload
    </button>
</div>
