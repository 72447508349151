import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { Bookings } from 'county-api';
import { BookingStatus } from 'county-api/enums';
import { NotificationsService } from '../../universal/notifications/notifications.service';
import { BookingsService } from '../bookings.service';
import { BookingChargesRefundDialogComponent } from '../charges/booking-charges-refund-dialog.component';
import {
    BookingCancelDialogComponent,
    IBookingCancellation,
} from './cancel/booking-cancel-dialog.component';
import { BookingCompleteDialogComponent } from './complete/booking-complete-dialog.component';
import { BookingConfirmDialogComponent } from './confirm/booking-confirm-dialog.component';
import { BookingOpenDialogComponent } from './open/booking-open-dialog.component';

/**
 * The booking page used to output the booking details and change
 * the status of the booking.
 */
@Component({
    selector: 'booking',
    templateUrl: './booking.component.html',
})
export class BookingComponent implements OnInit {
    /**
     * The bookings data.
     */
    booking: Bookings.IBooking;

    /**
     * Determines the loading state of the page.
     */
    loading = 0;

    /**
     * The booking statuses.
     */
    bookingStatus = BookingStatus;

    /**
     * Detemines wether to show the additional extras.
     */
    showExtraAdditional: boolean;

    /**
     * Determines whether to show the transactions.
     */
    showTransactions: boolean;

    /**
     * Determines whether to show the logs.
     */
    showLogs: boolean;

    /**
     * Constructs injected angular dependencies.
     * @param route The route to get the id from the url.
     * @param dialog The dialogs functionality.
     * @param bookingsService The booking service used to change the status of the booking.
     * @param notifications The notifications used when success or error is called.
     */
    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private bookingsService: BookingsService,
        private notifications: NotificationsService,
        private breadcrumbService: BreadcrumbService,
    ) {}

    /**
     * Gets the data using the id from the route url.
     */
    ngOnInit() {
        this.loading++;
        this.route.data.subscribe((data: { booking: Bookings.IBooking }) => {
            this.booking = data.booking;
            this.breadcrumbService.changeBreadcrumb(
                this.route.snapshot,
                this.booking.customer.forename +
                    ' ' +
                    this.booking.customer.surname,
            );
            this.loading--;
        });
    }

    /**
     * determines wether it has the correct status in order to refund.
     */
    canRefundExtras() {
        return (
            this.booking.status !== this.bookingStatus.Completed &&
            this.booking.status !== this.bookingStatus.Cancelled &&
            this.booking.status !== this.bookingStatus.Reserved
        );
    }

    /**
     * Gets the optional extras.
     */
    getOptionalExtras() {
        return this.booking.charges.filter(c => {
            return c.type === 'OptionalExtra';
        });
    }

    /**
     * Opens the refund dialog and passes back the amount of charges to refund.
     */
    refundCharge(charge: Bookings.IBookingCharge) {
        const options = [];
        for (let i = 1; i <= charge.count - charge.refunded_count; i++) {
            options.push(i);
        }

        const openDialog = this.dialog.open(
            BookingChargesRefundDialogComponent,
            {
                width: '600px',
                data: {
                    charge,
                    amountList: options,
                },
                panelClass: 'dialog',
            },
        );

        // Passes back the refund count and calls the refund charge service call.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                let count = 1;
                if (result.count) {
                    count = result.count;
                }

                this.bookingsService
                    .refundCharge(this.booking.id, charge.id, count)
                    .subscribe(
                        response => {
                            this.booking = response.result.booking;
                            this.notifications.success('Booking updated.');
                        },
                        () =>
                            this.notifications.error(
                                'Booking failed to update.',
                            ),
                    );
            }
        });
    }

    /**
     * Opens the cancel dialog and passes back the cancel data.
     */
    cancelBooking() {
        const openDialog = this.dialog.open(BookingCancelDialogComponent, {
            width: '600px',
            data: {
                booking: this.booking,
            },
            panelClass: 'dialog',
        });

        // Passes back the cancel data and calls the cancel booking service call.
        openDialog.afterClosed().subscribe((result: IBookingCancellation) => {
            if (result) {
                this.bookingsService.cancel(this.booking.id, result).subscribe(
                    response => {
                        this.booking = response.result.booking;
                        this.notifications.success('Booking cancelled.');
                    },
                    () => {
                        this.notifications.error('Booking failed to cancel.');
                    },
                );
            }
        });
    }

    /**
     * Opens the confirm dialog and passes back the confirm data.
     */
    confirmBooking() {
        const openDialog = this.dialog.open(BookingConfirmDialogComponent, {
            width: '600px',
            panelClass: 'dialog',
        });

        // Passes back the confirm data and calls the confirm booking service call.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                this.bookingsService.confirm(this.booking.id, result).subscribe(
                    response => {
                        this.booking = response.result.booking;
                        this.notifications.success('Booking confirmed.');
                    },
                    () => {
                        this.notifications.error('Booking failed to confirm.');
                    },
                );
            }
        });
    }

    /**
     * Opens the complete dialog and passes back the complete data.
     */
    completeBooking() {
        const completionPostData = {
            type: 'Deposit',
            amount: 0,
            agreement_number: this.booking.agreement_number,
        };

        const openDialog = this.dialog.open(BookingCompleteDialogComponent, {
            width: '600px',
            data: {
                booking: this.booking,
            },
            panelClass: 'dialog',
        });

        // Passes back the complete data and calls the complete booking service call.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                this.bookingsService
                    .complete(this.booking.id, result)
                    .subscribe(
                        response => {
                            this.booking = response.result.booking;
                            this.notifications.success('Booking completed.');
                        },
                        () => {
                            this.notifications.error(
                                'Booking failed to complete.',
                            );
                        },
                    );
            }
        });
    }

    /**
     * Opens the open dialog and passes back the open data.
     */
    openBooking() {
        const openDialog = this.dialog.open(BookingOpenDialogComponent, {
            width: '600px',
            data: {
                booking: this.booking,
                isDepositFailed:
                    this.booking.status === this.bookingStatus.FailedDeposit,
            },
            panelClass: 'dialog',
        });

        // Passes back the open data and calls the open booking service call.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                this.bookingsService
                    .open(this.booking.id, {
                        last4_digits: result.last4_digits,
                        agreement_number: result.agreement_number,
                        deposit_authorisation_number:
                            result.deposit_authorisation_number,
                    })
                    .subscribe(
                        response => {
                            this.booking = response.result.booking;
                            this.notifications.success(
                                'Booking opened successfully.',
                            );
                        },
                        () =>
                            this.notifications.error(
                                'Booking failed to open, invalid card number entered.',
                            ),
                    );
            }
        });
    }
}
