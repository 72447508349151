<div class="content-card">
    <div class="content-card__header content-card__header--blank">
        <h3 class="content-card__heading">Permissions</h3>
        <div class="btns btns--horizontal btns--small" *ngIf="isAdmin">
            <a
                class="btn btn--error"
                *ngIf="selectedPermissionIds.length > 0"
                (click)="removeAllPermissionsClick()"
            >
                Remove Selected
            </a>
            <a
                class="btn btn--default"
                *ngIf="user.permissions.length > 0"
                (click)="selectAllPermissionsClick()"
            >
                {{
                    selectedPermissionIds.length != user.permissions.length
                        ? 'Select'
                        : 'Deselect'
                }}
                All
            </a>
            <a class="btn btn--default" (click)="addPermissionsClick()">
                Assign
            </a>
        </div>
    </div>

    <div
        class="content-list permissions-list"
        *ngIf="user.permissions.length > 0 && loading < 1"
    >
        <div class="content-list__list">
            <div
                class="content-list__item content-list__item--simple content-list__link"
                (click)="selectPermissionClick(permission.id)"
                *ngFor="let permission of user.permissions"
            >
                <!-- orderby name -->
                <div class="row row--align-center">
                    <div class="m-col-auto" *ngIf="isAdmin">
                        <div
                            class="content-list__check"
                            [ngClass]="{
                                'content-list__check--checked':
                                    selectedPermissionIds.indexOf(
                                        permission.id
                                    ) != -1
                            }"
                        ></div>
                    </div>

                    <h4 class="content-list__title m-col-2">
                        {{ permission.name }}
                    </h4>
                    <div class="content-list__description m-col">
                        {{ permission.description }}
                    </div>
                    <div
                        class="content-list__actions content-list__actions--hidden m-col-auto"
                        *ngIf="isAdmin"
                    >
                        <a
                            class="btn btn--error btn--small"
                            (click)="removePermissionClick(permission.id)"
                            >Remove</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>

    <loader *ngIf="loading > 0"></loader>

    <div class="content-list__no-results" *ngIf="user.permissions.length < 1">
        User has no permissions assigned.
    </div>
</div>
