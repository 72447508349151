import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bookings } from 'county-api';

/**
 * The booking confirm dialog used to submit the confirm.
 */
@Component({
    selector: 'booking-confirm-dialog',
    templateUrl: './booking-confirm-dialog.component.html',
})
export class BookingConfirmDialogComponent {
    /**
     * The booking data passed from the parent.
     */
    booking: Bookings.IBooking;

    /**
     * The data passed back to the parent.
     */
    result = {
        reservation_number: '',
    };

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialog referenced functionality.
     * @param data The data passed from the parent.
     */
    constructor(
        private dialogRef: MatDialogRef<BookingConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: Bookings.IBookingViewModel,
    ) {
        this.booking = data ? data.booking : null;
    }

    /**
     * When valid passes back the confirm data.
     */
    submit(reservationNumber: string) {
        this.dialogRef.close(reservationNumber);
    }
}
