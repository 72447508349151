<div class="note-item">
    <div class="note-item__actions">
        <a
            (click)="deleteClick()"
            class="note-item__action"
            *hasPermission="'admin'; orIf: currentUserId == note.created_by"
        >
            <small>hide</small> &times;
        </a>
    </div>
    <div class="note-item__message">{{ note.message }}</div>
    <div class="note-item__details">
        <span title="{{ note.created_date | date: 'dd/MM/yyyy h:mm a' }}">{{
            note.created_date | timeago | async
        }}</span>
        |
        <user-label [userId]="note.created_by"></user-label>
    </div>
</div>
