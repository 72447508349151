import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Bookings, Locations } from 'county-api';
import clone from 'lodash/clone';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocationsState } from '../locations/store/locations.state';
import { IPagingParams, PagingController } from '../universal/paging';
import { UserState } from '../users/store/user.state';
import { UsersService } from '../users/users.service';
import { bookingStatuses } from './bookingStatuses';
import { IBooking } from 'county-api/bookings';
import { BookingsService, IBookingsPaging } from './bookings.service';

/**
 * Simple interface for dropdown item selections.
 */
export interface IFilterItem {
    id: string;
    label: string;
}

/**
 * The booking component used to get and filter all 
 */
@Component({
    selector: 'bookings',
    templateUrl: './bookings.component.html',
})
export class BookingsComponent extends PagingController<IBookingsPaging>
    implements OnInit, OnDestroy {
    /**
     * List of companies to display within the page.
     */
    bookings: IBooking[] = [];

    /**
     * Query details for collecting the companies from the api.
     */
    pagingData: IBookingsPaging = {
        page: 1,
        filter: null,
        perpage: null,
        orderby: null,
        status: null,
        location: null,
    };

    /**
     * The status array used to store all the statuses in an array.
     */
    bookingStatus = bookingStatuses;

    /**
     * The array of locations.
     */
    @Select(LocationsState.locations)
    locations$: Observable<Locations.ILocation[]>;

    /**
     * Booking statuses used for filter selection.
     */
    bookingStatusItems: IFilterItem[] = [];

    /**
     * The id of the users default location.
     */
    userDefaultLocation: number;

    /**
     * The last active request subscription used for cancelling of the request.
     */
    private request: Subscription;

    /**
     * Constructs injected angular dependencies.
     * @param router Router service used to update the url with the new query strings.
     * @param route Needed for the paging component to gather the current query string params.
     * @param bookingsService The booking service used to get all 
     * @param locationService The location service used to get all locations.
     * @param currentUserService The current user service used to get the current user.
     */
    constructor(
        router: Router,
        route: ActivatedRoute,
        private bookingsService: BookingsService,
        private store: Store,
    ) {
        super(router, route);
    }

    /**
     * Gets the locations and the current user then formats the locations
     * and statuses.
     */
    ngOnInit() {
        const user = this.store.selectSnapshot(UserState.user);

        this.userDefaultLocation = user.is_admin ? -1 : user.location_id;

        this.formatBookingStatuses();
        super.ngOnInit();
    }

    /**
     * Update the local paging data from the url params.
     * @param params The query params from the url.
     */
    updateLocalParams(params: IPagingParams) {
        this.bookings = [];
        this.pages = 0;

        // Gather the paging information from the url.
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            filter: params['filter'] ? params['filter'] : null,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
            status: params['status'] ? params['status'] : 'Incomplete',
            location: params['location']
                ? parseInt(params['location'], 10)
                : this.userDefaultLocation,
        } as IBookingsPaging;

        // Floor the page number to 1.
        if (this.pagingData.page < 1) {
            this.pagingData.page = 1;
        }

        return this.pagingData;
    }

    /**
     * Load the latest set of data based on the passed in pagingData.
     * @param pagingData The data returned from updateLocalParams.
     */
    updateData(pagingData: IBookingsPaging) {
        const params = clone(pagingData);

        if (params.status === 'all') {
            params.status = null;
        }

        if (params.location === -1) {
            params.location = null;
        }

        // Make a new request for the company details. Handle both success and error states.
        return this.bookingsService.get(params).pipe(
            tap(response => {
                this.bookings = response.result.bookings;
                this.pages = response.result.paging.total_pages;
                this.totalItems = response.result.paging.total;
            }),
        );
    }

    /**
     * Format booking statuses into a format that can be used by the select.
     */
    formatBookingStatuses() {
        this.bookingStatusItems.push({ label: 'All', id: 'all' });
        for (const statusKey of this.bookingStatus) {
            this.bookingStatusItems.push({
                label: statusKey.label,
                id: statusKey.id,
            });
        }
    }
}
