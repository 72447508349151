<div class="widget widget--counter widget--blue" [ngSwitch]="loading < 1">
    <ng-container *ngSwitchCase="true">
        <div class="widget__count">
            <h4 class="widget__count-title">
                Rental Days Target for
                <location-label
                    [locationId]="locationId$ | async"
                ></location-label>
            </h4>

            <div class="widget__count-value">{{ revenueDays }}</div>
        </div>
        <div class="widget__footer">
            The number of rental days that need to be sold
            <strong>today</strong> to be on-target.
        </div>
    </ng-container>
    <div class="widget__content widget__content--center" *ngSwitchDefault>
        <loader></loader>
    </div>
</div>
