import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Users } from 'county-api';
import { EMPTY } from 'rxjs';
import { catchError, filter, switchMap, tap, map } from 'rxjs/operators';
import { DeleteDialogComponent } from '../../../universal/dialogs/delete-dialog/delete-dialog.component';
import { NotificationsService } from '../../../universal/notifications/notifications.service';
import { PermissionsService } from '../../permissons/permissions.service';
import { UserState } from '../../store/user.state';
import { UsersService } from '../../users.service';
import { UserPermissionAddDialogComponent } from './add/user-permission-add-dialog.component';

/**
 * The user permission component used to display, add and remove a users
 * permissions.
 */
@Component({
    selector: 'user-permission',
    templateUrl: './user-permission.component.html',
})
export class UserPermissionComponent implements OnInit {
    /**
     * The user data input from the parent.
     */
    @Input()
    user: Users.IUser;

    @Input()
    isCurrentUser: boolean;

    /**
     * Determine if the users is able to add permissions or not.
     */
    isAdmin: boolean;

    /**
     * Array of currently checked permissions.
     */
    selectedPermissionIds: string[] = [];

    /**
     * Store if the permissions are loading or not. > 0 means state loading.
     */
    loading = 0;

    /**
     * Change event passed from parent, in the case of the user page this will
     * call a reload if you are editing yourself.
     */
    @Output()
    permissionChange = new EventEmitter<Users.IUser>();

    /**
     * Constructs injected angular dependencies.
     * @param dialog The dialogs functionality.
     * @param permissionsService The permission servcice used to add and remove permissions.
     * @param notifications Used to show the success or error notifications.
     */
    constructor(
        private usersService: UsersService,
        private dialog: MatDialog,
        private permissionsService: PermissionsService,
        private notifications: NotificationsService,
        private store: Store,
    ) {}

    /**
     * Calls sort permissions method.
     */
    ngOnInit() {
        this.sortPermissions();
        this.isAdmin = this.store.selectSnapshot(UserState.userIsAdmin);
    }

    /**
     * Handle selecting a permission from the existing user permission list, do
     * nothing if this user isnt admin.
     * @param permissionId Id of the permission that has been selected.
     */
    selectPermissionClick(permissionId: string) {
        if (this.isAdmin) {
            // If the item is already selected remove it from the selected
            // permissions else add it.
            const index = this.selectedPermissionIds.indexOf(permissionId);
            if (index === -1) {
                this.selectedPermissionIds.push(permissionId);
            } else {
                this.selectedPermissionIds.splice(index, 1);
            }
        }
    }

    /**
     * Click event to select/deselect all the current user permissions.
     */
    selectAllPermissionsClick() {
        // If all permissions are already selected remove them else select all.
        if (
            this.selectedPermissionIds.length === this.user.permissions.length
        ) {
            this.selectedPermissionIds = [];
        } else {
            this.selectedPermissionIds = this.user.permissions.map(
                perm => perm.id,
            );
        }
    }

    /**
     * Open add permissions dialog to allow admin user to assign a permission
     * to a user.
     */
    addPermissionsClick() {
        const currentPermissionIds = this.user.permissions.map(perm => perm.id);

        const openDialog = this.dialog.open(UserPermissionAddDialogComponent, {
            width: '750px',
            data: currentPermissionIds,
            panelClass: 'dialog',
        });

        // Gets the new data and pushes it to the addresses array.
        openDialog
            .afterClosed()
            .pipe(
                filter(r => r && r.length > 0),
                switchMap(result =>
                    this.permissionsService.addMultiple(result, this.user.id),
                ),
                map(response => response.result.permissions),
            )
            .subscribe(
                addedPermissions => {
                    this.selectedPermissionIds = [];
                    this.user.permissions.push(...addedPermissions);
                    this.user.is_admin = this.usersService.hasPermission(
                        this.user,
                        ['admin'],
                        true,
                    );

                    if (this.isCurrentUser) {
                        this.isAdmin = this.user.is_admin;
                    }

                    this.permissionChange.emit(this.user);

                    // Display the success notification and clear any currently
                    // selected permissions.
                    this.notifications.success(
                        `Successfully assigned ${
                            addedPermissions.length > 1
                                ? addedPermissions.length + ' permissions'
                                : ' a permission'
                        } to ${this.user.username}.`,
                    );
                    this.loading--;
                },
                () => {
                    this.notifications.error(
                        `There was an error assigning new permissions to ${this.user.username}.`,
                    );
                    this.loading--;
                    this.permissionChange.emit(this.user);
                },
            );
    }

    /**
     * Removes the selected permission by it's id, by calling remove
     * permissions.
     * @param permissionId The id of the permission being removed.
     */
    removePermissionClick(permissionId: string) {
        this.dialog
            .open(DeleteDialogComponent, {
                width: '500px',
                panelClass: 'dialog',
            })
            .afterClosed()
            .pipe(
                filter(r => !!r),
                switchMap(() => this.removePermissions([permissionId])),
            )
            .subscribe();
    }

    /**
     * Removes multiple selected permissions by their id's by calling remove
     * permissions.
     */
    removeAllPermissionsClick() {
        this.dialog
            .open(DeleteDialogComponent, {
                width: '500px',
                panelClass: 'dialog',
            })
            .afterClosed()
            .pipe(
                filter(r => !!r),
                switchMap(() =>
                    this.removePermissions(this.selectedPermissionIds),
                ),
            )
            .subscribe();
    }

    /**
     * Calls the remove multiple service method to remove all the desired
     * permissions.
     */
    private removePermissions(permissionIds: string[]) {
        this.loading++;
        return this.permissionsService
            .removeMultiple(this.selectedPermissionIds, this.user.id)
            .pipe(
                tap(() => {
                    // when success it shows the success notification.

                    this.notifications.success(
                        `Successfully removed ${
                            permissionIds.length > 1
                                ? permissionIds.length + ' permissions'
                                : ' a permission'
                        } from ${this.user.username}.`,
                    );
                    this.selectedPermissionIds = [];

                    this.user.permissions = this.user.permissions.filter(
                        perm => {
                            return permissionIds.indexOf(perm.id) === -1;
                        },
                    );
                    this.user.is_admin = this.usersService.hasPermission(
                        this.user,
                        ['admin'],
                        true,
                    );
                    if (this.isCurrentUser) {
                        this.isAdmin = this.user.is_admin;
                    }
                    this.permissionChange.emit(this.user);

                    this.loading--;
                }),
                catchError(() => {
                    this.notifications.error(
                        `There was an error removing ${
                            this.selectedPermissionIds.length > 1
                                ? this.selectedPermissionIds.length +
                                  ' permissions'
                                : ' a permission'
                        } from ${this.user.username}.`,
                    );
                    this.loading--;
                    return EMPTY;
                }),
            );
    }

    /**
     * Sorts all the permissions by there name in alaphabetical order.
     */
    private sortPermissions() {
        this.user.permissions = this.user.permissions.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }
}
