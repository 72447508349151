<div class="bookings-customers-list__item">
    <a
        [routerLink]="[customer.id]"
        class="content-list__item
    content-list__item--simple content-list__link"
        style="display: block"
    >
        <div class="row">
            <div class="m-col-auto">
                <img
                    class="user-thumbnail user-thumbnail--small"
                    [src]="customer.thumbnail"
                />
            </div>
            <h4 class="content-list__title m-col">
                {{ customer.forename }} {{ customer.surname }}
            </h4>
        </div>
    </a>
    <ul
        class="bookings-customers-list__actions list-unstyled"
        *hasPermission="'booking-block'"
    >
        <li *ngIf="!customer.blocked">
            <a class="btn btn--link btn--small" (click)="block()">Block</a>
        </li>
        <li *ngIf="customer.blocked">
            <a class="btn btn--link btn--small" (click)="unblock()">Unblock</a>
        </li>
    </ul>
</div>
