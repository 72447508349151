import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash/get';
import { CustomValidators } from '../../forms/custom.validators';

export interface IDeleteDialogData {
    /**
     * Type of the item being deleted.
     */
    label?: string;

    /**
     * Name of the item that is being deleted.
     */
    name?: string;

    /**
     * Require the user to type "DELETE" to confirm deletion.
     */
    confirm?: boolean;

    /**
     * Simple warning text to display to the user.
     */
    alert?: string;

    /**
     * Css classes to add to the alert text.
     */
    alertClass?: string;
}

/**
 * Generic component to display as a delete item dialog.
 */
@Component({
    selector: 'delete-dialog',
    templateUrl: './delete-dialog.component.html',
})
export class DeleteDialogComponent {
    /**
     * Label to display as the item that is being deleted.
     */
    label: string;

    name?: string;

    alert?: string;

    confirm: boolean;

    formGroup: FormGroup;

    alertClass: string;

    /**
     * When constructing the dialog see if there is a label set in the data if
     * not use 'item' as the default.
     */
    constructor(
        public dialogRef: MatDialogRef<DeleteDialogComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data: IDeleteDialogData,
    ) {
        this.label = data && data.label ? data.label : 'item';
        this.name = get(data, 'name', null);
        this.alert = get(data, 'alert', null);
        this.alertClass = get(
            data,
            'alertClass',
            'dialog__alert dialog__alert--error',
        );
        this.confirm = get(data, 'confirm', false);

        this.formGroup = this.fb.group({
            confirm: [
                '',
                this.confirm
                    ? CustomValidators.requireEqual('DELETE', true)
                    : null,
            ],
        });
    }

    submit() {
        this.dialogRef.close(true);
    }
}
