import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SetSeenVersion } from '../../store/layout.actions';
import { LayoutState } from '../../store/layout.state';
import { ChangelogDialogComponent } from '../changelog/dialog/changelog-dialog.component';
import { BugReportDialogComponent } from '../dialogs/bug-report-dialog.component';

@Component({
    selector: 'sidebar-footer',
    templateUrl: './sidebar-footer.component.html',
    styleUrls: ['./sidebar-footer.component.scss'],
})
export class SidebarFooterComponent {
    version = environment.version;

    @Select(LayoutState.seenVersion)
    seenVersion$: Observable<string>;

    constructor(private dialog: MatDialog, private store: Store) {}

    openBugReportClick() {
        this.dialog.open(BugReportDialogComponent, {
            panelClass: ['dialog', 'dialog--feedback'],
        });
    }

    openChangeLogClick() {
        this.store.dispatch(new SetSeenVersion(this.version));
        this.dialog.open(ChangelogDialogComponent, {
            width: '650px',
        });
    }
}
