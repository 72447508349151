<div *ngIf="addresses.length > 0">
    <table class="content-card-table">
        <thead>
            <tr>
                <th>Address</th>
                <th>City</th>
                <th>Country</th>
                <th>Postcode</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let addressDetails of addresses; let i = index">
                <td>
                    {{ addressDetails.line1
                    }}{{
                        addressDetails.line2 ? ', ' + addressDetails.line2 : ''
                    }}{{
                        addressDetails.line3 ? ', ' + addressDetails.line3 : ''
                    }}
                </td>
                <td>{{ addressDetails.city }}</td>
                <td>{{ addressDetails.country }}</td>
                <td>{{ addressDetails.postcode }}</td>
                <td>{{ addressDetails.from_date.toString() | date: 'dd/MM/yyyy' }}</td>
                <td>
                    {{
                        addressDetails.to_date
                            ? (addressDetails.to_date.toString() | date: 'dd/MM/yyyy')
                            : ''
                    }}
                </td>
                <td class="align-right content-card-table__modifiers">
                    <a (click)="editAddressClick(i, addressDetails)">
                        <fa-icon icon="pencil"></fa-icon>
                    </a>
                    <a (click)="deleteAddressClick(i)">
                        <fa-icon icon="times"></fa-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="btns btns--horizontal btns--small">
    <a class="btn btn--right" (click)="addAddressClick()">Add</a>
</div>
