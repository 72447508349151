<div class="widget">
    <div class="widget__header">
        <h4 class="widget__header-heading">
            Incident Status for
            <location-label [locationId]="locationId"></location-label>
        </h4>
    </div>

    <div class="widget__content" *ngIf="!loading">
        <div class="incidents-list grid">
            <a
                class="incidents-list__item row row--align-center"
                [routerLink]="['/', 'fleet', 'incidents']"
                [queryParams]="{
                    location_id: locationId,
                    status: incidentStatus.AwaitingCSS
                }"
            >
                <div class="col-auto">
                    <div
                        class="incidents-list__count"
                        [ngClass]="{
                            'incidents-list__count--error':
                                incidentData.awaiting_estimate > 0
                        }"
                    >
                        {{ incidentData.awaiting_estimate }}
                    </div>
                </div>

                <div class="incidents-list__label col">
                    Awaiting estimate > 7 days
                </div>
            </a>

            <a
                class="incidents-list__item row row--align-center"
                [routerLink]="['/', 'fleet', 'incidents']"
                [queryParams]="{
                    status: incidentStatus.AwaitingBranch,
                    location_id: locationId
                }"
            >
                <div class="col-auto">
                    <div
                        class="incidents-list__count"
                        [ngClass]="{
                            'incidents-list__count--error':
                                incidentData.estimated > 10,
                            'incidents-list__count--warn':
                                incidentData.estimated >= 5
                        }"
                    >
                        {{ incidentData.estimated }}
                    </div>
                </div>

                <div class="incidents-list__label col">
                    Estimated but not invoiced
                </div>
            </a>

            <a
                class="incidents-list__item row row--align-center"
                [routerLink]="['/', 'fleet', 'incidents']"
                [queryParams]="{
                    insurance_claim: true,
                    location_id: locationId
                }"
            >
                <div class="col-auto">
                    <div class="incidents-list__count">
                        {{ incidentData.insurance_claims }}
                    </div>
                </div>

                <div class="incidents-list__label col">
                    Active insurance claims
                </div>
            </a>
        </div>

        <div class="incidents-missed" *ngIf="incidentData.missed_damage > 0">
            {{ incidentData.missed_damage | gbp }} missed damage this month.
        </div>
    </div>

    <div class="widget__content widget__content--center" *ngIf="loading">
        <loader></loader>
    </div>
</div>
