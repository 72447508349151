import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstant } from '../../configs/api.constant';
import { IContactDetail, IContactDetailViewModel } from 'county-api/customers';
import { IApiResponse } from 'county-api/responses';

/**
 * The service to get and post the customers contacts, also to set whether the link is a
 * phone or email.
 */
@Injectable()
export class CustomerContactsService {
    /**
     * The url to call to the api.
     */
    customersApiUrl: string;

    /**
     * Contructs injected angular dependencies.
     */
    constructor(private service: HttpClient) {}

    /**
     * Make post request to create a new job.
     * @param vehicle The job details to create.
     */
    create(contact: IContactDetail, customerId: string) {
        return this.service.post<
            IApiResponse<IContactDetailViewModel>
        >(ApiConstant.url + 'customers/' + customerId + '/contact', {
            contact_detail: contact,
        } as IContactDetailViewModel);
    }

    /**
     * Sets the link to the value passed in by contactDetail.
     */
    getLink(contactDetail: IContactDetail) {
        let link = contactDetail.value;

        switch (contactDetail.type) {
            case 'Email':
                link = 'mailto:' + link;
                break;
            case 'Telephone':
                link = 'tel:' + link;
                break;
        }

        return link;
    }
}
