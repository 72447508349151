<div class="dialog-container">
    <button
        aria-label="Dismiss"
        class="dialog-close"
        mat-dialog-close
        tabindex="-1"
    ></button>

    <div>
        <h3 class="dialog-heading">Job Details</h3>
    </div>

    <form #JobDialog="ngForm" (validSubmit)="submitJob($event)">
        <div mat-dialog-content>
            <ul class="form">
                <li class="form__item">
                    <label class="input-label" for="agreement_number">Title</label>

                    <input
                        type="text"
                        name="job_title"
                        id="job_title"
                        required
                        [(ngModel)]="job.title"
                        #jobTitle="ngModel"
                        titlecase
                    />
                    <div class="input-errors" [ngxErrors]="jobTitle">
                        <label
                            for="input-error"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter a valid job title.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="agreement_number"
                        >Insurance Rating</label
                    >

                    <input
                        type="number"
                        name="job_insurance_rating"
                        id="job_insurance_rating"
                        required
                        min="0"
                        max="100"
                        [(ngModel)]="job.insurance_rating"
                        #jobInsuranceRating="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="jobInsuranceRating">
                        <label
                            for="input-error"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter a valid job insurance rating.
                        </label>
                    </div>
                </li>
            </ul>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" mat-dialog-close class="btn">
                Cancel
            </button>

            <input
                type="submit"
                value="submit"
                class="btn btn--success btn--right"
            />
        </div>
    </form>
</div>