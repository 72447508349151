import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Locations } from 'county-api';
import { Observable } from 'rxjs';
import { LocationsService } from '../../../locations/locations.service';
import { LocationsState } from '../../../locations/store/locations.state';

/**
 * The interface for the the type of data being passed from the parent.
 */
export interface IUserLocationsChangeDialogData {
    locationId: number;
    isBase: boolean;
}

/**
 * The user location change dialog used to change a users location.
 */
@Component({
    selector: 'user-locations-change-dialog',
    templateUrl: './user-locations-change-dialog.component.html',
})
export class UserLocationsChangeDialogComponent {
    /**
     * List of selectable locations for the select input.
     */
    @Select(LocationsState.locations)
    locations$: Observable<Locations.ILocation[]>;

    /**
     * The currently selected location id, ng model on the select input.
     */
    locationId: number;

    /**
     * Mark if the dialog is loading or not.
     */
    loading = 0;

    /**
     * Determine if we are updating the users base location.
     */
    isBase = false;

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialogs reference functionality.
     * @param data The data passed from the parent.
     * @param locationService The location service used to get all locations.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IUserLocationsChangeDialogData,
    ) {
        this.locationId = data.locationId;
        this.isBase = data.isBase;
    }
}
