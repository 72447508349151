import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Locations } from 'county-api';
import clone from 'lodash/clone';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import { tap } from 'rxjs/operators';
import { LogIn } from '../../users/store/user.actions';
import { LocationsService } from '../locations.service';
import { GotLocations } from './locations.actions';

export interface LocationsStateModel {
    locations: Locations.ILocation[];
    locationsMap: { [key: number]: Locations.ILocation };
}

@State<LocationsStateModel>({
    name: 'locations',
    defaults: {
        locations: [],
        locationsMap: {},
    },
})
@Injectable()
export class LocationsState {
    constructor(private locationsService: LocationsService) {}

    @Selector()
    static locations(state: LocationsStateModel) {
        const l = clone(state.locations);

        return l.filter(ll => {
            return ll.id <= 5;
        });
    }

    @Selector()
    static allLocations(state: LocationsStateModel) {
        const l = clone(state.locations);
        return l;
    }

    @Selector()
    static locationsMap(state: LocationsStateModel) {
        if (!state.locationsMap) {
            return null;
        }
        return pickBy(
            state.locationsMap,
            (location: Locations.ILocation, id) => {
                return location.id <= 5;
            },
        );
    }

    @Selector()
    static allLocationsMap(state: LocationsStateModel) {
        return state.locationsMap;
    }

    @Action(GotLocations)
    locationsLoaded(
        { patchState }: StateContext<LocationsStateModel>,
        { payload }: GotLocations,
    ) {
        const locations = orderBy(payload, 'name');

        patchState({
            locations: locations,
            locationsMap: keyBy(locations, 'id'),
        });
    }

    @Action(LogIn, { cancelUncompleted: true })
    loadLocations({ dispatch }: StateContext<LocationsStateModel>) {
        return this.locationsService
            .get()
            .pipe(
                tap(response =>
                    dispatch(new GotLocations(response.result.locations)),
                ),
            );
    }
}
