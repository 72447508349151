<div class="image-preview-overlay__content">
    <div class="image-container">
        <img [src]="image.url" />
    </div>
    <div class="btns btns--small btns--horizontal image-preview-overlay__btns">
        <a
            class="btn btn--link"
            [href]="image.url"
            target="_Blank"
            matTooltip="Open the full sized image in a new tab."
        >
            New Tab <fa-icon icon="external-link"></fa-icon>
        </a>

        <a class="btn btn--link" (click)="downloadClick(image.url)">
            Download
            <ng-container [ngSwitch]="downloading">
                <loader
                    [inline]="true"
                    *ngSwitchCase="downloadingState.Downloading"
                ></loader>
                <fa-icon
                    [icon]="['fas', 'times']"
                    *ngSwitchCase="downloadingState.Failed"
                    matTooltip="Download failed try Right Clicking the image > Save Image As..."
                ></fa-icon>
                <fa-icon
                    [icon]="['fas', 'check']"
                    *ngSwitchCase="downloadingState.Success"
                ></fa-icon>
            </ng-container>
        </a>
    </div>
</div>

<button
    class="image-preview-overlay__control image-preview-overlay__prev"
    (click)="moveClick(-1)"
    [disabled]="!hasPrev"
    *ngIf="hasMultiple"
>
    <fa-icon icon="chevron-left"></fa-icon>
</button>

<button
    class="image-preview-overlay__control image-preview-overlay__next"
    (click)="moveClick(1)"
    [disabled]="!hasNext"
    *ngIf="hasMultiple"
>
    <fa-icon icon="chevron-right"></fa-icon>
</button>

<button
    class="btn btn--link image-preview-overlay__close"
    aria-label="Dismiss"
    tabindex="-1"
    (click)="dialogRef.close()"
>
    Close
</button>
