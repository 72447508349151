import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'stack-item',
    template: `
        <ng-content></ng-content>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.stack-item]': 'true',
        '[class.stack-item--fill]': 'pFill',
    },
})
export class StackItemComponent {
    @Input()
    get pFill(): boolean {
        return this._fill;
    }
    set pFill(value: boolean) {
        this._fill = coerceBooleanProperty(value);
    }
    private _fill = false;
}
