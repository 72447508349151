<stack pVertical pSpacing="x-small" *ngIf="pTitle; else content">
    <h4 class="card__section-title">{{ pTitle }}</h4>

    <stack-item>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </stack-item>
</stack>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
