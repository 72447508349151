import { Injectable } from '@angular/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthState, UserState } from './store/user.state';

/**
 * Simple guard used to prevent angular 5 routes from loading until the user has
 * been loaded.
 */
@Injectable()
export class UserGuard  {
    /**
     * Auth state from the users store.
     */
    @Select(UserState.state)
    authState$: Observable<AuthState>;

    /**
     * Called by the router, will make a request to the user server to get the
     * user and return an observable of the result.
     */
    canActivate() {
        // Get the auth state and if the user is unauthed redirect accordingly,
        // and then return a Observable<bool> depending on if the user is authed
        // or not.
        return this.authState$.pipe(
            filter(state => state !== AuthState.Unknown),
            map(s => s === AuthState.Authenticated),
        );
    }

    canActivateChild() {
        return this.canActivate();
    }
}
