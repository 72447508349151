import { Directive, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface IConfirmContentComponent {
    data: any;

    disabled$?: Subject<boolean>;
}

@Directive({
    selector: '[confirm-content-host]',
})
export class ConfirmContentDirective {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
