import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryCodesService } from '../../universal/services/country-codes.service';
import { IAddress } from '../../county-api/src/customers';

/**
 * The component for the address dialog, which gets and passes back the address data.
 */
@Component({
    selector: 'customers-address-dialog',
    templateUrl: './customers-address-dialog.component.html',
})
export class CustomersAddressDialogComponent implements OnInit {
    /**
     * The address details data.
     */
    addressDetail: IAddress;

    startFromDate: any = null;
    startToDate: Date = null;

    /**
     * The array of countries.
     */
    countries: string[];

    /**
     * Determines whether the date is required or not.
     */
    requireDate = false;

    /**
     * The constructs injecting angular dependencies.
     * @param countyCodesList The countries service.
     * @param dialogRef The dialog functionality.
     * @param data The data passed from the parent.
     */
    constructor(
        private countyCodesList: CountryCodesService,
        public dialogRef: MatDialogRef<CustomersAddressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        // Checks whether address details is defined otherwise sets it to an empty object.
        this.addressDetail =
            data && data.addressDetails ? data.addressDetails : {};
        // Checks whether the required date is true.
        this.requireDate = data && data.requireDate ? data.requireDate : true;

        this.startToDate = data.startToDate;
    }

    /**
     * Calls the get countries service to get all the countries.
     */
    ngOnInit() {
        this.countyCodesList.getCountries().subscribe(response => {
            this.countries = response;
        });
    }

    /**
     * Passes the new address details back to the parent.
     */
    confirm($event) {
        this.dialogRef.close(this.addressDetail);
    }
}
