<div class="content-card">
    <div
        class="content-card__header content-card__header--blank"
        (click)="showActivitiesClick()"
    >
        <h3 class="content-card__heading">Activities</h3>
        <fa-icon [icon]="show ? 'chevron-up' : 'chevron-down'"></fa-icon>
    </div>

    <div class="content-list" [hidden]="!show" [ngSwitch]="loading < 1">
        <div class="content-list__list" *ngSwitchCase="true">
            <user-activities-list-item
                *ngFor="let activity of activities"
                [activity]="activity"
                [showUser]="true"
            ></user-activities-list-item>

            <div
                class="content-list__no-results"
                *ngIf="!activities || activities.length < 1"
            >
                No Activities found.
            </div>

            <a
                class="content-list__item content-list__item--more"
                [routerLink]="['/activities/' + prefix + '/' + id]"
                *ngIf="pages > 1"
                >More Activities</a
            >
        </div>

        <loader *ngSwitchDefault></loader>
    </div>
</div>
