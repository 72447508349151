import {
    Directive,
    EventEmitter,
    Output,
    HostListener,
    Input,
    Renderer2,
    ElementRef,
    Attribute,
} from '@angular/core';
import { RouterLinkWithHref, ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Directive({
    selector: 'a[routerLinkClick]',
})
export class RouterLinkClickDirective extends RouterLinkWithHref {
    @Input()
    set routerLinkClick(r: any) {
        this.routerLink = r;
        this.localUpdateTargetUrlAndHref();
    }

    get routerLinkClick() {
        return this.routerLink;
    }

    @Output()
    routerLinkClickRouterClick = new EventEmitter<MouseEvent>();

    constructor(
        private router2: Router,
        route: ActivatedRoute,
        renderer: Renderer2,
        el: ElementRef<any>,
        private locations: LocationStrategy,
    ) {
        super(router2, route, "", renderer, el, locations);
    }

    @HostListener('click', [
        '$event.button',
        '$event.ctrlKey',
        '$event.metaKey',
        '$event.shiftKey',
        '$event.altKey',
        '$event',
    ])
    onClick(
        button: number,
        ctrlKey: boolean,
        metaKey: boolean,
        shiftKey: boolean,
        altKey: boolean,
        event?: MouseEvent,
    ) {
        this.routerLinkClickRouterClick.emit(event);
        return super.onClick(button, ctrlKey, shiftKey, altKey, metaKey);
    }

    private localUpdateTargetUrlAndHref(): void {
        this.href = this.routerLink
            ? this.locations.prepareExternalUrl(
                  this.router2.serializeUrl(this.urlTree),
              )
            : null;
    }
}
