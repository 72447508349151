import { NgModule } from '@angular/core';
import { ActivitiesModule } from '../activities/activities.module';
import { LayoutModule } from '../layout/layout.module';
import { LocationsModule } from '../locations/locations.module';
import { NotesModule } from '../notes/notes.module';
import { UniversalModule } from '../universal';
import { UsersModule } from '../users/users.module';
import { BookingsResolve } from './booking/bookings.resolve';
import { BookingCancelDialogComponent } from './booking/cancel/booking-cancel-dialog.component';
import { BookingCompleteDialogComponent } from './booking/complete/booking-complete-dialog.component';
import { BookingConfirmDialogComponent } from './booking/confirm/booking-confirm-dialog.component';
import { BookingOpenDialogComponent } from './booking/open/booking-open-dialog.component';
import {
    BookingsRoutingModule,
    routedComponents,
} from './bookings-routing.module';
import { BookingsService } from './bookings.service';
import { BookingChargesRefundDialogComponent } from './charges/booking-charges-refund-dialog.component';
import { BookingCustomersService } from './customers/booking-customers.service';
import { BookingsCustomerBookingListItemComponent } from './customers/customer/booking-list/bookings-customer-booking-list-item.component';
import { BookingsCustomerResolve } from './customers/customer/bookings-customer.resolve';
import { BookingCustomersListItemComponent } from './customers/list/booking-customers-list-item.component';
import { BookingsListItemComponent } from './list/bookings-list-item.component';

/**
 * The booking module used to handle the declartions and provides for the
 * booking page.
 */
@NgModule({
    imports: [
        UniversalModule,
        BookingsRoutingModule,
        LayoutModule,
        LocationsModule,
        NotesModule,
        UsersModule,
        ActivitiesModule,
    ],
    declarations: [
         ...routedComponents,
        BookingsListItemComponent,
        BookingOpenDialogComponent,
        BookingConfirmDialogComponent,
        BookingCompleteDialogComponent,
        BookingCancelDialogComponent,
        BookingChargesRefundDialogComponent,
        BookingCustomersListItemComponent,
        BookingsCustomerBookingListItemComponent,
    ],
    providers: [
        BookingsService,
        BookingsResolve,
        BookingCustomersService,
        BookingsCustomerResolve,
    ],
})
export class BookingsModule {}
