import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Notes } from 'county-api';
import { MatDialog } from '@angular/material/dialog';
import {
    DeleteDialogComponent,
    IDeleteDialogData,
} from '../../../universal/dialogs/delete-dialog/delete-dialog.component';
import { UserState } from '../../../users/store/user.state';
import { Store } from '@ngxs/store';

@Component({
    selector: 'notes-list-item',
    templateUrl: './notes-list-item.component.html',
    styleUrls: ['./notes-list-item.component.scss'],
})
export class NotesListItemComponent {
    @Input()
    note: Notes.INote;

    @Output()
    noteDelete = new EventEmitter<string>();

    currentUserId: string = null;

    constructor(private dialog: MatDialog, private store: Store) {
        this.currentUserId = this.store.selectSnapshot(UserState.user).id;
    }

    deleteClick() {
        const dialogRef = this.dialog.open<
            DeleteDialogComponent,
            IDeleteDialogData
        >(DeleteDialogComponent, { data: { label: 'Note' } });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.noteDelete.emit(this.note.id);
            }
        });
    }
}
