import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { UserResolve } from "../users/user/user.resolve";
import { UserGuard } from "../users/user.guard";
import { PerformanceDataComponent } from "./performance-data/performance-data.component";

/**
 * Base routes for the data exchanges section of the app.
 */
const routes: Routes = [
    {
        path: 'data-exchange',
        canActivate: [MsalGuard],
        data: { breadcrumb: 'Data Exchange' },
        children: [
            {
                data: { breadcrumb: 'Performance Data' },
                path: 'performance-data',
                component: PerformanceDataComponent,
                pathMatch: 'full',
                canActivate: [MsalGuard, UserGuard],
            },
        ],
    },
];

export const routedComponents = [
    PerformanceDataComponent,
];

/**
 * The routing module for data exchange.
 */
@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    declarations: [],
    exports: [RouterModule],
    providers: [UserResolve],
})
export class DataExchangeRoutingModule {}