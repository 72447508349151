import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bookings } from 'county-api';
import { BookingStatus } from 'county-api/enums';

/**
 * The interface in which data is passed from the parent.
 */
interface IBookingOpen {
    last4_digits: string;
    agreement_number: string;
    deposit_payment_made: boolean;

    deposit_authorisation_number?: string;
}

/**
 * The type for the result.
 */
interface IBookingOpenData {
    booking: Bookings.IBooking;
    isDepositFailed: boolean;
}

/**
 * The booking open dialog used to submit the open of the booking.
 */
@Component({
    selector: 'booking-open-dialog',
    templateUrl: './booking-open-dialog.component.html',
})
export class BookingOpenDialogComponent {
    /**
     * The data passed back to the parent.
     */
    result: IBookingOpen = {} as IBookingOpen;

    /**
     * The data passed from the parent.
     */
    booking: Bookings.IBooking;

    /**
     * Determines wether the deposit has failed.
     */
    isDepositFailed: boolean;

    requireDepositAuth = false;

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialog referenced functionality.
     * @param data The data passed from the parent.
     */
    constructor(
        private dialogRef: MatDialogRef<BookingOpenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: IBookingOpenData,
    ) {
        this.booking = data ? data.booking : null;
        this.isDepositFailed = data ? data.isDepositFailed : false;
        this.requireDepositAuth =
            this.booking.status == BookingStatus.AwaitingDeposit;
    }

    /**
     * When valid passes back the open booking data.
     */
    submit() {
        this.dialogRef.close(this.result);
    }
}
