<div *ngIf="contacts.length > 0">
    <table class="content-card-table">
        <thead>
            <tr>
                <th>Type</th>
                <th>Value</th>
                <th>Primary</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let contact_detail of contacts; let i = index">
                <td>{{ contact_detail.type }}</td>
                <td>{{ contact_detail.value }}</td>
                <td>{{ contact_detail.is_primary }}</td>
                <td class="align-right content-card-table__modifiers">
                    <a (click)="editContactClick(i, contact_detail)">
                        <fa-icon icon="pencil"></fa-icon>
                    </a>
                    <a (click)="deleteContactClick(i)">
                        <fa-icon icon="times"></fa-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="btns btns--horizontal btns--small">
    <a class="btn btn--right" (click)="addContactClick()">Add</a>
</div>
