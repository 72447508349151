import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'platform-ui/button';
import { StackModule } from 'platform-ui/stack';
import { AlertComponent } from './alert.component';

@NgModule({
    declarations: [AlertComponent],
    exports: [AlertComponent],
    imports: [CommonModule, StackModule, ButtonModule, FontAwesomeModule],
})
export class AlertModule {}
