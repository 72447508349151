import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { UniversalModule } from '../universal';
import { UserGuard } from '../users/user.guard';
import { UsersModule } from '../users/users.module';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [MsalGuard, UserGuard],
        data: { breadcrumb: 'Home' },
    },

    {
        path: 'test',
        component: DashboardComponent,
        canActivate: [MsalGuard, UserGuard],
        data: { breadcrumb: 'Home' },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes), UsersModule],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
