import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    CollectionViewer,
    DataSource,
    isDataSource,
    SelectionModel,
} from '@angular/cdk/collections';
import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    Input,
    OnInit,
    Output,
    TemplateRef,
    TrackByFunction,
} from '@angular/core';
import {
    BehaviorSubject,
    isObservable,
    Observable,
    of,
    Subject,
    Subscription,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ItemListActionsDefDirective } from './directives/item-list-actions-def.directive';
import { ItemListDefDirective } from './directives/item-list-def.directive';
import { ItemListHeaderDefDirective } from './directives/item-list-header-def.directive';

/**
 * Union of the types that can be set as the data source for a `CdkTable`.
 * @docs-private
 */
type ItemListDataSourceInput<T> =
    | DataSource<T>
    | Observable<ReadonlyArray<T> | T[]>
    | ReadonlyArray<T>
    | T[];

@Component({
    selector: 'item-list',
    templateUrl: './item-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.item-list]': 'true',
        '[class.item-list--selected]': '!selection.isEmpty()',

        '[class.item-list--borderless]': 'pBorderless',
    },
})
export class ItemListComponent<T = any>
    implements OnInit, AfterContentChecked, CollectionViewer {
    _data: T[] | ReadonlyArray<T>;

    @ContentChild(ItemListHeaderDefDirective, {
        static: true,
        read: TemplateRef,
    })
    _headerTemplate: TemplateRef<any>;

    @ContentChild(ItemListDefDirective, { static: true, read: TemplateRef })
    _itemTemplate: TemplateRef<any>;

    @ContentChild(ItemListActionsDefDirective, {
        static: true,
        read: TemplateRef,
    })
    _actionsTemplate: TemplateRef<any>;

    @Input()
    get pDataSource(): ItemListDataSourceInput<T> {
        return this._dataSource;
    }
    set pDataSource(dataSource: ItemListDataSourceInput<T>) {
        if (this._dataSource !== dataSource) {
            this._switchDataSource(dataSource);
        }
    }
    private _dataSource: ItemListDataSourceInput<T>;

    /**
     * Allow items in the list to be selectable. You will need to listen to the
     * itemSelected output or provide an pItemListActionsDef template with
     * actions to render.
     */
    @Input()
    get pSelectable(): boolean {
        return this._selectable;
    }
    set pSelectable(value: boolean) {
        this._selectable = coerceBooleanProperty(value);
    }
    private _selectable = false;

    @Input()
    get pLoading(): boolean {
        return this._loading;
    }
    set pLoading(value: boolean) {
        this._loading = coerceBooleanProperty(value);
    }
    private _loading = false;

    @Input()
    get pBorderless(): boolean {
        return this._borderless;
    }
    set pBorderless(value: boolean) {
        this._borderless = coerceBooleanProperty(value);
    }
    private _borderless = false;

    selection = new SelectionModel<any>(true, [], true);

    @Output()
    itemSelected = this.selection.changed;

    trackBy: TrackByFunction<any>;

    viewChange: BehaviorSubject<{
        start: number;
        end: number;
    }> = new BehaviorSubject<{ start: number; end: number }>({
        start: 0,
        end: Number.MAX_VALUE,
    });

    private _destroyed = new Subject<void>();

    private _renderChangeSubscription: Subscription;

    constructor(private _changeRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.selection.changed
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => {
                this._changeRef.detectChanges();
            });
    }

    ngAfterContentChecked() {}

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();

        if (isDataSource(this.pDataSource)) {
            this.pDataSource.disconnect(this);
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this._data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this._data.forEach(row => this.selection.select(row));
    }

    private _switchDataSource(dataSource: ItemListDataSourceInput<T>) {
        this._data = [];

        if (isDataSource(this.pDataSource)) {
            this.pDataSource.disconnect(this);
        }

        // Stop listening for data from the previous data source.
        if (this._renderChangeSubscription) {
            this._renderChangeSubscription.unsubscribe();
            this._renderChangeSubscription = null;
        }

        this._dataSource = dataSource;

        if (this.pDataSource && !this._renderChangeSubscription) {
            this._observeRenderChanges();
        }
    }

    /** Set up a subscription for the data provided by the data source. */
    private _observeRenderChanges() {
        // If no data source has been set, there is nothing to observe for changes.
        if (!this.pDataSource) {
            return;
        }

        let dataStream: Observable<T[] | ReadonlyArray<T>> | undefined;

        if (isDataSource(this.pDataSource)) {
            dataStream = this.pDataSource.connect(this);
        } else if (isObservable(this.pDataSource)) {
            dataStream = this.pDataSource;
        } else if (Array.isArray(this.pDataSource)) {
            dataStream = of(this.pDataSource);
        }

        if (dataStream === undefined) {
            throw new Error();
        }

        this._renderChangeSubscription = dataStream
            .pipe(takeUntil(this._destroyed))
            .subscribe(data => {
                // clear selection everytime data changes.
                this.selection.clear();
                this._data = data || [];
            });
    }
}
