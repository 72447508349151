import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResponse, Performance } from 'county-api';
import { ApiConstant } from '../../configs/api.constant';
import { ApiHttpClientService } from '../../universal/api/apiHttpClient.service';

@Injectable()
export class PerformanceDataService extends ApiHttpClientService {
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Get permformance summary for every location given a specific month.
     * @param month The month to return the information for.
     */
    getMonthlyPerformance(month: string) {
        return this.service.get<
            IApiResponse<Performance.IPerformanceMonthlyViewModel>
        >(`${ApiConstant.url}performance/monthly/${month}`);
    }

    /**
     * Gets the data from the previuous month.
     */
    getLastUploadData() {
        return this.service.get<
            IApiResponse<Performance.IPerformanceLastUploadViewModel>
        >(`${ApiConstant.url}performance/lastuploaddate`);
    }

    /**
     * Get the amount of days required per day to be sold in order to reach the great target.
     * @param locationId Id of the location whos target to get.
     */
    getPerformanceRequiredDays(locationId: number) {
        return this.service.get<
            IApiResponse<Performance.IPerformanceRentalDaysViewModel>
        >(`${ApiConstant.url}performance/requireddays/${locationId}`);
    }

    getUnderUtilisedGroups(length: number) {
        return this.service.get<
            IApiResponse<Performance.IPerformanceUnderUtilisationViewModel>
        >(`${ApiConstant.url}performance/utilisation/underutilised`, {
            params: {
                days: length.toString(),
            },
        });
    }

    /**
     * Gets the target data from the targets json.
     * @param date The current date.
     */
    getPerformanceTarget(month: string) {
        return this.service.get<
            IApiResponse<Performance.IPerformanceTargetsViewModel>
        >(`${ApiConstant.url}performance/targets/${month}`);
    }

    /**
     * Pushes new targets into the targets array.
     * @param date New targets date.
     * @param targets The new targets data.
     */
    setPerformanceTarget(
        month: string,
        targets: Performance.IPerformanceTarget[],
    ) {
        return this.service.post<
            IApiResponse<Performance.IPerformanceTargetsViewModel>
        >(`${ApiConstant.url}performance/targets`, {
            month,
            targets,
        });
    }

    /**
     * Pushes new targets into the targets array.
     * @param date New targets date.
     * @param targets The new targets data.
     */
    getIncidentsData(locationId: number) {
        return this.service.get<
            IApiResponse<Performance.IPerformanceIncidentsViewModel>
        >(`${ApiConstant.url}performance/incidents/${locationId}`);
    }

    /**
     * Submit a file to be uploaded as performance data.
     * @param file The file to be submitted to the api.
     */
    uploadData(file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.service.post<
            IApiResponse<Performance.IPerformanceDataViewModel>
        >(`${ApiConstant.url}performance`, formData);
    }
}
