import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { LayoutModule } from '../layout/layout.module';
import { LocationsModule } from '../locations/locations.module';
import { UniversalModule } from '../universal';
import { UsersModule } from '../users/users.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { IncidentsWidgetComponent } from './incidents/widget/incidents-widget.component';
import { DashboardManagerComponent } from './manager/dashboard-manager.component';
import { OfficeWidgetComponent } from './office/widget/office-widget.component';
import { PerformanceDataService } from './perfomance/performance-data.service';
import { RevenueDaysWidgetComponent } from './revenue/days-widget/revenue-days-widget.component';
import { RevenueDialogComponent } from './revenue/widget/dialog/revenue-dialog.component';
import { RevenueDialogTargetsComponent } from './revenue/widget/dialog/targets/revenue-dialog-targets.component';
import { RevenueDialogUploadComponent } from './revenue/widget/dialog/upload/revenue-dialog-upload.component';
import { RevenueLeaderboardComponent } from './revenue/widget/leaderboard/revenue-leaderboard.component';
import { DashboardState } from './state/dashboard.state';
import { UtilisationWidgetComponent } from './utilisation/widget/utilisation-widget.component';
import { RevenueWidgetComponent } from './revenue/widget/revenue-widget.component';

@NgModule({
    imports: [
        UniversalModule,
        NgxsModule.forFeature([DashboardState]),
        DashboardRoutingModule,
        UsersModule,
        LayoutModule,
        LocationsModule,
    ],
    declarations: [
        DashboardComponent,
        DashboardManagerComponent,
        RevenueLeaderboardComponent,
        RevenueDaysWidgetComponent,
        RevenueDialogComponent,
        RevenueDialogTargetsComponent,
        UtilisationWidgetComponent,
        OfficeWidgetComponent,
        IncidentsWidgetComponent,
        RevenueDialogUploadComponent,
        RevenueWidgetComponent
    ],
    exports: [
        RevenueWidgetComponent,
    ],
    providers: [PerformanceDataService],
})
export class DashboardModule {}
