import { Component, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Bookings, Locations } from 'county-api';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { LocationsState } from '../../locations/store/locations.state';
import { BookingsService } from '../bookings.service';

/**
 * The interface for the filter items.
 */
export interface IFilterItemtmp<T = any> {
    id: T;
    label: string;
}

/**
 * The bookings report component used to get the bookings according to the
 * values passed as the params.
 */
@Component({
    selector: 'bookings-report',
    templateUrl: './bookings-report.component.html',
})
export class BookingsReportComponent implements OnDestroy {
    /**
     * The booking report data got from the api.
     */
    bookingReport: Bookings.IBookingTransactionsViewModel;

    /**
     * The latest date in which to get the reports.
     */
    endDate: moment.MomentInput;

    /**
     * The earliest date in which to get the reports.
     */
    startDate: moment.MomentInput;

    /**
     * The array of locations.
     */
    @Select(LocationsState.locations)
    locations$: Observable<Locations.ILocation[]>;

    /**
     * The location in which the reports were.
     */
    locationItem = -1;

    /**
     * The current date.
     */
    date = new Date();

    /**
     * The loading state of the report page.
     */
    loading = 0;

    /**
     * The request to get the booking reports.
     */
    request: Subscription;

    /**
     * Constructs injected angular dependencies.
     * @param locationsService The loaction service to get all locations.
     */
    constructor(private bookingsService: BookingsService) {}

    /**
     * When the form changes and is valid calls get report.
     */
    filterUpdate() {
        if (this.startDate && this.endDate) {
            this.getReport();
        }
    }

    /**
     * Gets all the reports according to the passed in params.
     */
    getReport() {
        this.loading++;
        this.bookingReport = null;

        this.request = this.bookingsService
            .getReport(this.startDate, this.endDate, this.locationItem)
            .subscribe(
                response => {
                    this.bookingReport = response.result;
                },
                null,
                () => {
                    this.loading--;
                },
            );
    }

    /**
     * Unsubscribes the report api call when the page is destroyed.
     */
    ngOnDestroy() {
        if (this.request) {
            this.request.unsubscribe();
        }
    }
}
