export enum LengthUnit {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Quarter = 'Quarter',
    Year = 'Year',
}

export enum ServicingSchedulesDueStatus {
    All = 'All',
    Upcoming = 'Upcoming',
    Overdue = 'Overdue',
}

export enum ServicingSchedulePattern {
    LastOccurrence = 'LastOccurrence',
    FixedOccurrence = 'FixedOccurrence',
    FixedForwardOccurrence = 'FixedForwardOccurrence',
}

export enum ServicingScheduleBaseValues {
    Registered = 'Registered',
    Onfleet = 'Onfleet',
}
