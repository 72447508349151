import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'platform-ui/alert';
import { BadgeModule } from 'platform-ui/badge';
import { ButtonModule } from 'platform-ui/button';
import { CardModule } from 'platform-ui/card';
import { FormFieldModule } from 'platform-ui/form-field';
import { ItemListModule } from 'platform-ui/item-list';
import { LayoutModule as pLayoutModule } from 'platform-ui/layout';
import { ListModule } from 'platform-ui/list';
import { PageModule } from 'platform-ui/page';
import { SpinnerModule } from 'platform-ui/spinner';
import { StackModule } from 'platform-ui/stack';
import { ActivitiesModule } from '../activities/activities.module';
import { LayoutModule } from '../layout/layout.module';
import { UniversalModule } from '../universal';
import { CustomersAddressDialogComponent } from './address/customers-address-dialog.component';
import { CustomersAddressInputComponent } from './address/customers-address-input.component';
import { CompanyPeopleAddInputComponent } from './companies/add/company-people-add-input.component';
import { CompanyPersonAddDialogComponent } from './companies/add/company-person-add-dialog.component';
import { CompaniesService } from './companies/companies.service';
import { CompanyListItemComponent } from './companies/list/company-list-item.component';
import { CustomersCompaniesPeopleSelectionInputComponent } from './companies/people/customers-companies-people-selection-input.component';
import { CustomersCompaniesSelectionDialogComponent } from './companies/selection/customers-companies-selection-dialog.component';
import { CustomersCompaniesSelectionInputComponent } from './companies/selection/customers-companies-selection-input.component';
import { CompanyResolve } from './company/company.resolve';
import { CustomersContactsDialogComponent } from './contacts/customers-contacts-dialog.component';
import { CustomersContactsInputComponent } from './contacts/customers-contacts-input.component';
import { CustomerContactsService } from './contacts/customers-contacts.service';
import {
    CustomersRoutingModule,
    routedComponents,
} from './customers-routing.module';
import { EndorsementsAddDialogComponent } from './endorsements/endorsements-add-dialog.component';
import { EndorsementsAddInputComponent } from './endorsements/endorsements-add-input.component';
import { JobsDialogComponent } from './jobs/jobs-dialog.component';
import { JobsService } from './jobs/jobs.service';
import { PersonAddDialogComponent } from './people/add/dialog/person-add-dialog.component';
import { PersonAddDetailsComponent } from './people/add/person-add-details.component';
import { PersonListItemComponent } from './people/list/person-list-item.component';
import { PeopleService } from './people/people.service';
import { CustomersPeopleSelectionDialogComponent } from './people/selection/customers-people-selection-dialog.component';
import { CustomersPeopleSelectionInputComponent } from './people/selection/customers-people-selection-input.component';
import { PersonResolve } from './person/person.resolve';
import { CustomersSageService } from './sage/customers-sage.service';
import { CustomersSageLinkDialogComponent } from './sage/link/customers-sage-link-dialog.component';
import { CustomersSageSummaryComponent } from './sage/summary/customers-sage-summary.component';

/**
 * The module to import and declare all dependencies for the customers page.
 */
@NgModule({
    imports: [
        UniversalModule,
        LayoutModule,
        ReactiveFormsModule,
        CustomersRoutingModule,
        ActivitiesModule,

        BadgeModule,
        StackModule,
        ButtonModule,
        ItemListModule,
        ListModule,
        CardModule,
        SpinnerModule,
        FormFieldModule,
        pLayoutModule,
        PageModule,
        AlertModule,
    ],
    providers: [
        CompaniesService,
        CompanyResolve,
        PersonResolve,
        CustomerContactsService,
        PeopleService,
        JobsService,
        CustomersSageService,
    ],
    declarations: [
        ...routedComponents,
        CompanyListItemComponent,
        PersonListItemComponent,
        JobsDialogComponent,
        CustomersContactsInputComponent,
        CustomersContactsDialogComponent,
        CustomersAddressInputComponent,
        CustomersAddressDialogComponent,
        PersonAddDetailsComponent,
        PersonAddDialogComponent,
        CompanyPeopleAddInputComponent,
        CompanyPersonAddDialogComponent,
        CustomersCompaniesSelectionInputComponent,
        CustomersCompaniesSelectionDialogComponent,
        CustomersCompaniesPeopleSelectionInputComponent,
        CustomersPeopleSelectionInputComponent,
        CustomersPeopleSelectionDialogComponent,
        EndorsementsAddInputComponent,
        EndorsementsAddDialogComponent,
        CustomersSageLinkDialogComponent,
        CustomersSageSummaryComponent,
    ],
    exports: [
        CustomersCompaniesSelectionInputComponent,
        CustomersCompaniesPeopleSelectionInputComponent,
        CustomersPeopleSelectionInputComponent,
        CustomersPeopleSelectionDialogComponent,
        CustomersContactsInputComponent,
        CustomersAddressInputComponent,
        EndorsementsAddInputComponent,
    ],
})
export class CustomersModule {}
