import { Component, OnInit, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GetUsers } from '../store/users.actions';
import { Users } from 'county-api';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Component({
    selector: 'user-label',
    templateUrl: './user-label.component.html',
    styleUrls: ['./user-label.component.scss'],
})
export class UserLabelComponent implements OnInit {
    @Input()
    userId: string;

    user$: Observable<Users.IUser>;

    constructor(private store: Store) {}

    ngOnInit() {
        this.user$ = this.store.select(state =>
            state.users.users ? state.users.users[this.userId] : null,
        );
    }
}
