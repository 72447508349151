<ng-container *ngIf="multiple" [ngSwitch]="files != null">
    <ul class="file-list list-styled" *ngSwitchCase="true">
        <li
            class="file-list__item list-styled__item"
            *ngFor="let file of files; let i = index"
        >
            <div class="file-list__name list-styled__label">
                <fa-icon [icon]="getIcon(file.type)" fixedWidth></fa-icon>
                {{ file.name }}
            </div>

            <ul class="list-styled__actions">
                <li class="list-styled__action">
                    <a (click)="clearFile(i)">
                        <fa-icon icon="times" fixedWidth></fa-icon>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    <div class="sub-text file-list-message" *ngSwitchDefault>
        No files selected.
    </div>
</ng-container>

<div class="file-input">
    <label class="file-input__label">
        <input
            type="file"
            id="file"
            class="file-input__input"
            [multiple]="multiple"
            (change)="handleFileInput($event.target.files)"
            #input
        />
        <span class="btn btn--info file-input__button">{{
            multiple ? 'Add files' : 'Select a file'
        }}</span>
        <span class="file-input__desc" *ngIf="!multiple">{{
            files ? files[0].name : 'No file selected.'
        }}</span>
    </label>
    <span
        class="file-input__clear btn btn--extra-small btn--error"
        *ngIf="!multiple && files"
        (click)="clearFiles()"
    >
        <fa-icon icon="times" fixedWidth></fa-icon>
    </span>
</div>
