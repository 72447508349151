export class NotificationPayload {
    constructor(
        public type: NotificationType,
        public message: string,
        public action?: null,
        public opt?: any,
    ) {}
}

export type NotificationType = 'success' | 'error' | 'warn' | 'info' | 'wait';

export class NewToastNotification {
    static readonly type = '[Notifications] New notifications';

    constructor(public payload: NotificationPayload) {}
}
