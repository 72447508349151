import {
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    Inject
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, InteractionType, AuthenticationResult, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Select, Store } from '@ngxs/store';
// import * as Sentry from '@sentry/angular-ivy';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, takeUntil, tap, takeWhile, first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
// import { appInsightInitializer } from '../universal/appInsight/appInsightInitializer'; TODO: restore after app run check
import { DestroyController } from '../universal/components/destroy.controller';
 import { NotificationsDestroy } from '../users/store/notifications.actions';
import { LogIn, LogInFailed } from '../users/store/user.actions';
 import { AuthState, UserState } from '../users/store/user.state';
import { ToggleNotifications, ToggleSidebar } from './store/layout.actions';
import { LayoutState } from './store/layout.state';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends DestroyController
    implements OnInit, OnDestroy {
    debug = environment.debug;

    authStates = AuthState;

    isInFrame = true;

     @Select(UserState.state)
     authState$: Observable<AuthState>;

    @Select(LayoutState.notificationsState)
    openNotifications$: Observable<boolean>;

    @Select(LayoutState.sidebarState)
    openSidebar$: Observable<boolean>;

    isMobile = false;

    pinned = false;

    loggedIn = false;

    authHandled = false;

    @ViewChild('nav')
    private _nav: any;

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private broadcastService: MsalBroadcastService,
        private authService: MsalService,
        private store: Store,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {

        this.isInFrame = window !== window.parent && !window.opener;

        this.handleLoginSuccess();
        this.registerLoginFailureSubscription();

        this.configureListeners();

        this.handleRegularLogin();
    }

    //This handles regular logins on a browser with cached tokens
    handleRegularLogin(){
        const accounts = this.authService.instance.getAllAccounts();
        if (accounts == null || accounts.length == 0){
            console.log("no accounts were found");
        }
        else {
            console.log("at least one account was found");
            this.authService.instance.setActiveAccount(accounts[0]);
            this.store.dispatch(new LogIn());
       }
    }

    //This handles first-time logins on a browser with no cached tokens
    handleLoginSuccess(){
        this.broadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
        ).subscribe(_ => {
            console.log("first-time login succeeded");
            if (this.loggedIn == false) {
                this.store.dispatch(new LogIn());
            }
            this.authService.instance.setActiveAccount(this.authService.instance.getActiveAccount());
            this.loggedIn = true;
        });
    }

    registerLoginFailureSubscription() {
        this.broadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)
        )
        .subscribe((result: EventMessage) => {
            console.log("login failed");
            console.log(result);
            if (this.loggedIn == true) {
                this.store.dispatch(new LogInFailed());
            }

            this.loggedIn = false;

        });
    }

    /**
     * Destroy event to clean various subscribes, bount to beforeunload to stop
     * the signalR observable attempting to reconnect 20 times on reload.
     */
    @HostListener('window:beforeunload')
    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(new NotificationsDestroy());
    }

    /**
     * Close all sidebars from within the view.
     */
    closeSidebarsClick() {
        const openNotifications = this.store.selectSnapshot(
            LayoutState.notificationsState,
        );
        const openSidebar = this.store.selectSnapshot(LayoutState.sidebarState);
        if (openNotifications) {
            this.store.dispatch(new ToggleNotifications());
        }
        if (openSidebar) {
            this.store.dispatch(new ToggleSidebar());
        }
    }

    reloadClick() {
        location.reload();
    }

    private configureListeners() {
        this.store
            .select(LayoutState.isMobile)
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(isMobile => {
                     this.isMobile = isMobile;
                    this.changeDetectorRef.detectChanges();
                    this._forceResize();
                }),
            )
            .subscribe();

        this.store
            .select(LayoutState.pinState)
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(r => (this.pinned = r)),
                debounceTime(1),
                tap(() => this._forceResize()),
            )
            .subscribe();
    }

    private _forceResize() {
        if (this._nav) {
            this._nav._doCheckSubject.next();
        }
    }
}
