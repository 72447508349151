import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    IPagingParams,
    IRequestPaging,
    PagingController,
} from '../../universal/paging';
import { CompaniesService } from './companies.service';
import { ICompany } from '../../county-api/src/customers';

/**
 * The compnaies page component used to show the company list.
 */
@Component({
    selector: 'companies',
    templateUrl: './companies.component.html',
})
export class CompaniesComponent extends PagingController<IRequestPaging>
    implements OnInit {
    /**
     * List of companies to display within the page.
     */
    companies: ICompany[] = [];

    /**
     * Query details for collecting the companies from the api.
     */
    pagingData: IRequestPaging = {
        page: 1,
        filter: null,
        perpage: null,
    };

    /**
     * Determine the loading state of the page.
     */
    loading = 0;

    /**
     * The last active request subscription used for cancelling of the request.
     */
    private request: Subscription;

    /**
     * Constructs injecting angular dependencies.
     */
    constructor(
        private companiesService: CompaniesService,
        router: Router,
        route: ActivatedRoute,
    ) {
        super(router, route);
    }

    /**
     * Update the local paging data from the url params.
     * @param params The query params from the url.
     */
    updateLocalParams(params: IPagingParams) {
        this.companies = [];
        this.pages = 0;

        // Gather the paging information from the url.
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            filter: params['filter'] ? params['filter'] : null,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
        } as IRequestPaging;

        // Floor the page number to 1.
        if (this.pagingData.page < 1) {
            this.pagingData.page = 1;
        }

        return this.pagingData;
    }

    /**
     * Load the latest set of data based on the passed in pagingData.
     * @param pagingData The data returned from updateLocalParams.
     */
    updateData(pagingData: IRequestPaging) {
        this.loading++;

        // If any active request cancel it and then decrease the loader.
        if (this.request && !this.request.closed) {
            this.request.unsubscribe();
            this.loading--;
        }

        // Make a new request for the company details. Handle both success and error states.
        this.request = this.companiesService
            .get(pagingData)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                response => {
                    this.companies = response.result.companies;
                    this.pages = response.result.paging.total_pages;
                    this.totalItems = response.result.paging.total;
                },
                () => {
                    this.pages = 0;
                },
                () => this.loading--,
            );
    }
}
