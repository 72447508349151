import {
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Locations } from 'county-api';
import isFunction from 'lodash/isFunction';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { coerceBooleanProperty } from '../../universal/utils';
import { LocationsState } from '../store/locations.state';

/**
 * The location selection used to select the desired location.
 */
@Component({
    selector: 'location-selection-input',
    templateUrl: './location-selection-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LocationSelectionInputComponent),
            multi: true,
        },
    ],
})
export class LocationSelectionInputComponent
    implements OnInit, ControlValueAccessor {
    /**
     * All the locations from the api.
     */
    locations$: Observable<Locations.ILocation[]>;

    /**
     * A single location id.
     */
    @Input()
    value: number;

    @HostBinding('class.input-select')
    inputClass = true;

    /**
     * The get to get the array of selected categories.
     */
    get locationId(): number {
        return this.value;
    }

    /**
     * The set to set the array of selected categories.
     */
    set locationId(locationId: number) {
        if (locationId !== this.value) {
            this.value = locationId;

            this.onChange(locationId);
            this.locationChange.emit(locationId);
        }
    }

    @Output()
    locationChange = new EventEmitter<number>();

    private _includeAllLocations = false;

    @Input()
    set allLocations(include: any) {
        const value = coerceBooleanProperty(include);

        if (value != this._includeAllLocations) {
            this._includeAllLocations = value;
            this.updateLocationSub();
        }
    }

    private _locationsFilter: (l: Locations.ILocation) => boolean;

    @Input()
    set locationsFilter(filter: (l: Locations.ILocation) => boolean) {
        this._locationsFilter = filter;
        this.updateLocationSub();
    }

    _disabled = false;

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
    }

    _readonly = false;

    @Input()
    get readonly() {
        return this._readonly;
    }

    set readonly(value: any) {
        this._readonly = coerceBooleanProperty(value);
    }

    _hideBlank = false;

    @Input()
    get hideBlank() {
        return this._hideBlank;
    }

    set hideBlank(value: any) {
        this._hideBlank = coerceBooleanProperty(value);
    }

    /**
     * Constructs injected angular dependencies.
     * @param locationService The location service used to get the locations.
     */
    constructor(private store: Store) {}

    /**
     * Calls get locations.
     */
    ngOnInit() {
        this.updateLocationSub();
    }

    /**
     * Calls when contacts change.
     */
    onChange = (locationId: number) => {};

    /**
     * Calls when the item gets touched.
     */
    onTouched = () => {};

    /**
     * @inheritdoc
     */
    writeValue(locationId: number) {
        this.value = locationId;
    }

    /**
     * @inheritdoc
     */
    registerOnChange(fn: (locationId: number) => void) {
        this.onChange = fn;
    }

    /**
     * @inheritdoc
     */
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    /**
     * Set the locations sub depending on the passed in inputs.
     */
    updateLocationSub() {
        this.locations$ = this.store
            .select(
                this._includeAllLocations
                    ? LocationsState.allLocations
                    : LocationsState.locations,
            )
            .pipe(
                map(d => {
                    return isFunction(this._locationsFilter)
                        ? d.filter(this._locationsFilter)
                        : d;
                }),
            );
    }
}
