import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    QueryList,
} from '@angular/core';
import { LayoutTopComponent } from './components/layout-top.component';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { '[class.layout]': 'true' },
})
export class LayoutComponent implements AfterContentInit {
    @ContentChildren(LayoutTopComponent)
    layoutTop: QueryList<LayoutTopComponent>;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    ngAfterContentInit() {
        this.layoutTop.changes.subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });
    }
}
