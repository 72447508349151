import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Locations } from 'county-api';
import { Observable } from 'rxjs';
import { LocationsState } from '../store/locations.state';

/**
 *  The location label component used to create a universal component to output
 * the location as a label.
 */
@Component({
    selector: 'location-label',
    templateUrl: './location-label.component.html',
})
export class LocationLabelComponent {
    /**
     * The location id passed from the parent.
     */
    @Input()
    locationId: number;

    /**
     * The location in which to output.
     */
    @Select(LocationsState.allLocationsMap)
    locations$: Observable<{ [key: number]: Locations.ILocation }>;
}
