import { Component, forwardRef, Input } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Customers } from 'county-api';
import orderBy from 'lodash/orderBy';
import * as moment from 'moment';
import { DeleteDialogComponent } from '../../universal/dialogs/delete-dialog/delete-dialog.component';
import { coerceBooleanProperty } from '../../universal/utils';
import { CustomersAddressDialogComponent } from './customers-address-dialog.component';

/**
 * The address input provider.
 */
export const CUSTOMERS_ADDRESS_INPUT_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomersAddressInputComponent),
    multi: true,
};

/**
 * Validator provider.
 */
export const CUSTOMERS_ADDRESS_INPUT_VALIDATORS: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomersAddressInputComponent),
    multi: true,
};

/**
 * The address custom input component used to store addresses until submit.
 */
@Component({
    selector: 'customers-address-input',
    templateUrl: './customers-address-input.component.html',
    providers: [
        CUSTOMERS_ADDRESS_INPUT_ACCESSOR,
        CUSTOMERS_ADDRESS_INPUT_VALIDATORS,
    ],
})
export class CustomersAddressInputComponent
    implements ControlValueAccessor, Validator {
    /**
     * Whether a new address requires a from_date.
     */
    @Input()
    requireDate = false;

    /**
     * How many months of addresses are required.
     */
    _requireLength: number;

    @Input()
    get requireLength() {
        return this._requireLength;
    }
    set requireLength(value: number) {
        const newValue = value;

        if (this._requireLength !== newValue) {
            this._requireLength = newValue;
            this._validatorOnChange();
        }
    }

    /**
     * If one current address is required.
     */
    _requireOneCurrent: boolean;

    @Input()
    get requireOneCurrent() {
        return !!this._requireOneCurrent;
    }
    set requireOneCurrent(value: boolean) {
        const newValue = coerceBooleanProperty(value);

        if (this._requireOneCurrent !== newValue) {
            this._requireOneCurrent = newValue;
            this._validatorOnChange();
        }
    }

    /**
     * The cloned addresses.
     */
    private _addresses: Customers.IAddress[] = [];

    /**
     * Checks that the values are valid when there changed.
     */
    private _validatorOnChange = () => {};

    /**
     * The get to get the array of addresses.
     */
    get addresses(): Customers.IAddress[] {
        return this._addresses;
    }

    /**
     * The set to set the array of addresses.
     */
    set addresses(addressDetails: Customers.IAddress[]) {
        this._addresses = orderBy(addressDetails, 'from_date');
        this.onChange(this._addresses);
    }

    /**
     * Construct injecting angular dependencies.
     * @param dialog The way to get the dialog functionality.
     */
    constructor(private dialog: MatDialog) {}

    /**
     * Calls when addresses change.
     */
    onChange = (addressDetails: Customers.IAddress[]) => {};

    /**
     * Calls when the item gets touched.
     */
    onTouched = () => {};

    /**
     * Sends the addresses data back to the parent component.
     */
    writeValue(addressDetails: Customers.IAddress[]) {
        this._addresses = addressDetails ? addressDetails : [];
    }

    /** @docs-private */
    registerOnChange(fn: (addressDetails: Customers.IAddress[]) => void) {
        this.onChange = fn;
    }

    /** @docs-private */
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /** @docs-private */
    registerOnValidatorChange(fn: () => void): void {
        this._validatorOnChange = fn;
    }

    /**
     * Opens the add dialog and pushes the new address to the address array.
     */
    addAddressClick() {
        this.onTouched();
        const openDialog = this.dialog.open(CustomersAddressDialogComponent, {
            width: '500px',
            data: {
                requireDate: this.requireDate,
            },
            panelClass: 'dialog',
        });

        // Gets the new data and pushes it to the addresses array.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                const address = Object.assign([], this._addresses);
                address.push(result);
                this.addresses = address;
            }
        });
    }

    /**
     * Opens the edit dialog and edits an already existing address.
     */
    editAddressClick(index: number, addressDetail: Customers.IAddress) {
        this.onTouched();
        const addressDetails = Object.assign({}, addressDetail);
        const openDialog = this.dialog.open(CustomersAddressDialogComponent, {
            width: '500px',
            data: {
                addressDetails,
                requireDate: this.requireDate,
            },
            panelClass: 'dialog',
        });

        // Overrides the previous address with the edited address.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                const address = Object.assign([], this._addresses);
                address[index] = result;
                this.addresses = address;
            }
        });
    }

    /**
     * Opens the delete dialog and deletes the address from the addresses array.
     */
    deleteAddressClick(index: number) {
        this.onTouched();
        const openDialog = this.dialog.open(DeleteDialogComponent, {
            width: '500px',
            panelClass: 'dialog',
        });

        // Splices the contact by its index from the array of contacts.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                const address = Object.assign([], this._addresses);
                address.splice(index, 1);
                this.addresses = address;
            }
        });
    }

    /**
     * Validator to ensure there is at least {this.requireLength} of address
     * history.
     */
    private _requireLengthValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const value: Customers.IAddress[] = control.value ? control.value : [];
        const today = moment().startOf('day');
        let months = 0;

        for (let a = 0; a < value.length; a++) {
            const address = value[a];
            months += moment(address.to_date ? address.to_date : today).diff(
                address.from_date,
                'months',
                true,
            );
        }

        return !this.requireLength ||
            value.length < 1 ||
            months >= this.requireLength
            ? null
            : { requireLength: true };
    };

    /**
     * Validator to ensure there is one current address.
     */
    private _requireOneValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const value: Customers.IAddress[] = control.value ? control.value : [];
        const currentAddresses = value.filter(a => !a.to_date);
        return !this.requireOneCurrent ||
            value.length < 1 ||
            currentAddresses.length == 1
            ? null
            : { requireOneCurrent: true };
    };

    /** @docs-private */
    validate(c: AbstractControl): ValidationErrors | null {
        return this._validator ? this._validator(c) : null;
    }

    /** The combined form control validator for this input. */
    private _validator: ValidatorFn | null = Validators.compose([
        this._requireOneValidator,
        this._requireLengthValidator,
    ]);
}
