import { Directive } from '@angular/core';

@Directive({
  selector: '[pCardMetaData]'
})
export class CardMetaDataDirective {

  constructor() { }

}
