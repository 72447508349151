import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { UniversalModule } from "../universal";
import { CurrentUserService } from "../users/current-user.service";
import { PermissionsService } from "../users/permissons/permissions.service";
import { NotificationsState } from "../users/store/notifications.state";
import { UserState } from "../users/store/user.state";
import { UsersState } from "../users/store/users.state";
import { UserGuard } from "../users/user.guard";
import { UsersService } from "../users/users.service";
import { routedComponents, DataExchangeRoutingModule } from "./data-exchange-routing.module";
import { DashboardModule } from "../dashboard/dashboard.module";

@NgModule({
    imports: [
        CommonModule,
        UniversalModule,
        LayoutModule,
        NgxsModule.forFeature([UsersState, UserState, NotificationsState]),
        DataExchangeRoutingModule,
        DashboardModule,
    ],
    declarations: [
        ...routedComponents,
    ],
    providers: [
        CurrentUserService,
        UserGuard,
        UsersService,
        PermissionsService,
    ],
    exports: [],
})
export class DataExchangeModule {}