<div class="content-card">
    <div class="content-card__header content-card__header--blank">
        <h3 class="content-card__heading">Activities</h3>
    </div>

    <div
        class="content-list activities-list"
        *ngIf="activities.length > 0 && loading < 1"
    >
        <div class="content-list__list">
            <user-activities-list-item
                *ngFor="let activity of activities"
                [activity]="activity"
            ></user-activities-list-item>

            <a
                class="content-list__item content-list__item--more"
                [routerLink]="['./activities']"
            >
                More Activities
            </a>
        </div>
    </div>

    <loader *ngIf="loading > 0"></loader>

    <div
        class="content-list__no-results"
        *ngIf="activities.length < 1 && loading < 1"
    >
        User has no activities to display.
    </div>
</div>
