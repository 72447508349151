import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Locations, Performance } from 'county-api';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { LocationsState } from '../../../locations/store/locations.state';
import { PerformanceDataService } from '../../perfomance/performance-data.service';
import { UpdateDashboardTriggerType } from '../../state/dashboard.actions';
import { DashboardState } from '../../state/dashboard.state';

@Component({
    selector: 'utilisation-widget',
    templateUrl: './utilisation-widget.component.html',
    styleUrls: ['./utilisation-widget.component.scss'],
})
export class UtilisationWidgetComponent implements OnInit, OnDestroy {
    /**
     * The utilisation locations data and the utilisation colours.
     */
    utilisationData: {
        [location_id: number]: Performance.IPerformanceUtilisation[];
    } = null;

    /**
     * Weights from the api response.
     */
    utilisationWeights: { [group_id: string]: number };

    loading = 0;

    length = 3;

    lengths = [3, 7, 14, 28];

    unsubscribe$ = new Subject<boolean>();

    @Select(LocationsState.locations)
    locations$: Observable<Locations.ILocation[]>;

    @Select(DashboardState.updateCounts)
    updateCounts$: Observable<{ [type: number]: number }>;

    constructor(private performanceService: PerformanceDataService) {}

    ngOnInit() {
        this.updateCounts$
            .pipe(
                takeUntil(this.unsubscribe$),
                map(d => d[UpdateDashboardTriggerType.Revenue]),
                distinctUntilChanged(),
            )
            .subscribe(() => this.getUtilisationData());
    }

    /**
     * Gets the utilisation data and sets the keys from the utilisation array to the
     * utilisationGridHeading array.
     */
    getUtilisationData() {
        this.loading++;

        return this.performanceService
            .getUnderUtilisedGroups(this.length)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                this.utilisationData = data.result.utilisation;
                this.utilisationWeights = data.result.weights;
                this.loading--;
            });
    }

    changeLengthClick(length: number) {
        this.length = length;
        this.getUtilisationData();
    }

    ngOnDestroy() {
        this.unsubscribe$.next(false);
        this.unsubscribe$.complete();
    }
}
