import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { UserState } from '../../../users/store/user.state';
import { UsersService } from '../../../users/users.service';
import { PerformanceDataService } from '../../perfomance/performance-data.service';
import { UpdateDashboardTriggerType } from '../../state/dashboard.actions';
import { DashboardState } from '../../state/dashboard.state';

@Component({
    selector: 'revenue-days-widget',
    templateUrl: './revenue-days-widget.component.html',
    styleUrls: ['./revenue-days-widget.component.scss'],
})
export class RevenueDaysWidgetComponent implements OnDestroy {
    /**
     * Current loading state of the component.
     */
    loading = 0;

    revenueDays = 0;

    locationId$: Observable<number>;

    unsubscribe$ = new Subject();

    constructor(
        private usersService: UsersService,
        private performanceService: PerformanceDataService,
        private store: Store,
    ) {
        // The update event trigger to force the component to reload its data
        // when new performance data is uploaded.
        const updateEvent$ = this.store
            .select(DashboardState.updateCounts)
            .pipe(
                map(d => d[UpdateDashboardTriggerType.Revenue]),
                distinctUntilChanged(),
            );

        // Set up the observable for the id of the location data to get, only
        // admin users can view performance data for all locations.
        const user = this.store.selectSnapshot(UserState.user);
        this.locationId$ = of(user.location_id);
        if (this.usersService.hasPermission(user, 'admin')) {
            this.locationId$ = this.store.select(DashboardState.locationId);
        }

        // Compine the two observables updating the performance data when both
        // have emitted at least once.
        combineLatest(updateEvent$, this.locationId$)
            .pipe(
                switchMap(data => {
                    this.loading++;
                    return this.performanceService.getPerformanceRequiredDays(
                        data[1],
                    );
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(
                response => {
                    this.revenueDays = response.result.required_days;
                    this.loading--;
                },
                () => {
                    this.revenueDays = 0;
                    this.loading--;
                },
            );
    }

    /**
     * Clean up observables when the component is destroyed.
     */
    ngOnDestroy() {
        this.unsubscribe$.next("");
        this.unsubscribe$.complete();
    }
}
