import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
        path: 'fleet',
        data: { breadcrumb: 'Fleet' },
        canActivate: [MsalGuard],
        children: [
            {
                path: 'incidents',
                canActivate: [MsalGuard],
                loadChildren: () =>
                    import('./incidents/incidents.module').then(
                        m => m.IncidentsModule,
                    ),
                data: { breadcrumb: 'Incidents' },
            },
            {
                path: 'vehicles',
                canActivate: [MsalGuard],
                loadChildren: () =>
                    import('./vehicles/vehicles.module').then(
                        m => m.VehiclesModule,
                    ),
                data: { breadcrumb: 'Vehicles' },
            },
            {
                path: 'servicing',
                canActivate: [MsalGuard],
                loadChildren: () =>
                    import('./servicing/servicing.module').then(
                        m => m.ServicingModule,
                    ),
                data: { breadcrumb: 'Servicing' },
            },
            {
                path: 'groups',
                canActivate: [MsalGuard],
                loadChildren: () =>
                    import('./groups/fleet-groups.module').then(
                        m => m.FleetGroupsModule,
                    ),
                data: { breadcrumb: 'Groups' },
            },
            {
                path: 'bodytypes',
                canActivate: [MsalGuard],
                loadChildren: () =>
                    import('./body-types/body-types.module').then(
                        m => m.BodyTypesModule,
                    ),
                data: { breadcrumb: 'Body Types' },
            },
            {
                path: 'settings',
                canActivate: [MsalGuard],
                loadChildren: () =>
                    import('./settings/fleet-settings.module').then(
                        m => m.FleetSettingsModule,
                    ),
                data: { breadcrumb: 'Settings' },
            },
        ],
    },
];

export const routedComponents = [];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FleetRoutingModule {}
