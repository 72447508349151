import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Bookings } from 'county-api';

import { DeleteDialogComponent } from '../../../universal/dialogs/delete-dialog/delete-dialog.component';
import { BookingCustomersService } from '../booking-customers.service';
import { NotificationsService } from '../../../universal/notifications/notifications.service';

/**
 * The booking customer list item used to output each individual booking as well
 * as handle the blocking and unblocking of a customer.
 */
@Component({
    selector: 'booking-customers-list-item',
    templateUrl: './booking-customers-list-item.component.html',
})
export class BookingCustomersListItemComponent {
    /**
     * The customer data input from the parent.
     */
    @Input()
    customer: Bookings.IBookingCustomer;

    /**
     * Constructs injected angular dependencies.
     * @param dialog The dialogs functionality.
     * @param bookingCustomersService The customer booking service used to block and unblock customers.
     * @param notifications Handles the success and error notifications.
     */
    constructor(
        private dialog: MatDialog,
        private bookingCustomersService: BookingCustomersService,
        private notifications: NotificationsService,
    ) {}

    /**
     * Opens the dialog then calls the block service call to block the customer.
     */
    block() {
        const openDialog = this.dialog.open(DeleteDialogComponent, {
            width: '600px',
            panelClass: 'dialog',
        });

        // If true then the customer id gets sent to be blocked.
        openDialog.afterClosed().subscribe(result => {
            if (result) {
                this.bookingCustomersService
                    .blockById(this.customer.id)
                    .subscribe(
                        () => {
                            this.customer.blocked = true;
                            this.notifications.success(
                                `Successfully blocked ${this.customer.forename} ${this.customer.surname}.`,
                            );
                        },
                        () => {
                            this.notifications.error(
                                'There was an error blocking this customer.',
                            );
                        },
                    );
            }
        });
    }

    /**
     * When clicked calls the unblocked method passing in the customer id to be
     * unblocked.
     */
    unblock() {
        this.bookingCustomersService.unblockById(this.customer.id).subscribe(
            () => {
                this.customer.blocked = false;
                this.notifications.success(
                    `Successfully unblocked ${this.customer.forename} ${this.customer.surname}.`,
                );
            },
            () => {
                this.notifications.error(
                    'There was an error unblocking this customer.',
                );
            },
        );
    }
}
