import { Component } from '@angular/core';

@Component({
    selector: 'no-content',
    template: `
        <div class="not-found-wrapper">
            <h1 class="not-found-wrapper__heading">Hmm.</h1>
            <p class="not-found-wrapper__message">
                It seems that you've taken a wrong turn. The destination you
                were looking for could <strong>Not Be Found</strong>.
            </p>

            <div class="not-found-wrapper__btns">
                <a class="btn" onclick="window.history.back()">Back</a>
                <a routerLink="/" class="not-found-wrapper__btn btn"
                    >Go To Homepage</a
                >
            </div>
        </div>
    `,
})
export class NoContentComponent {}
