import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.alert]': 'true',
        '[class.alert--v-success]': 'pVariant == "success"',
        '[class.alert--v-primary]': 'pVariant == "primary"',
        '[class.alert--v-error]': 'pVariant == "error"',
        '[class.alert--v-info]': '!pVariant || pVariant == "info"',
        '[class.alert--v-warn]': 'pVariant == "warn"',
        '[class.alert--v-extra]': 'pVariant == "extra"',
        '[class.alert--v-neutral]': 'pVariant == "neutral"',

        '[class.alert--flat]': 'pFlat',
    },
})
export class AlertComponent {
    @Input()
    pTitle: string;

    @Input()
    pVariant: string;

    @Input()
    pActionLabel: string;

    @Output()
    pActionClick = new EventEmitter<void>();

    @Input()
    pSecondaryActionLabel: string;

    @Output()
    pSecondaryActionClick = new EventEmitter<void>();

    @Output()
    pCloseClick = new EventEmitter<void>();

    @Input()
    pActions: TemplateRef<any>;

    @Input()
    get pFlat() {
        return this._flat;
    }

    set pFlat(value: any) {
        this._flat = coerceBooleanProperty(value);
    }
    private _flat: boolean = false;

    @Input()
    get pClosable() {
        return this._closable;
    }

    set pClosable(value: any) {
        this._closable = coerceBooleanProperty(value);
    }
    private _closable: boolean = false;
}
