import { Component, OnInit, Input } from '@angular/core';
import { CustomersSageService } from '../customers-sage.service';
import { ISageCustomerDto } from '../../../county-api/src/customers';

@Component({
    selector: 'customers-sage-summary',
    templateUrl: './customers-sage-summary.component.html',
})
export class CustomersSageSummaryComponent {
    private _sageAccountId: number;

    loading: boolean;
    customer: ISageCustomerDto;

    @Input()
    set sageAccountId(value: number) {
        if (this._sageAccountId != value) {
            this._sageAccountId = value;
            this.loadSageCustomer();
        }
    }

    get sageAccountId() {
        return this._sageAccountId;
    }

    constructor(private customersSageService: CustomersSageService) {}

    loadSageCustomer() {
        this.loading = true;

        this.customersSageService.getById(this.sageAccountId).subscribe(r => {
            this.customer = r.result.customer;
            this.loading = false;
        });
    }
}
