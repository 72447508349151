import {
    Directive,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
    selector: 'textarea[pAutoSize]',
})
export class AutoSizeDirective implements OnDestroy {
    /**
     * Used to default all textarea row counts.
     */
    @Input()
    @HostBinding()
    rows = 3;

    @Input()
    pMaxHeight = 400;

    private _destroyed = new Subject<void>();

    constructor(public _elementRef: ElementRef) {
        // This will result in one listener per pAutoSize.
        fromEvent(window, 'resize')
            .pipe(debounceTime(500), takeUntil(this._destroyed))
            .subscribe(() => this.resize());

        fromEvent(_elementRef.nativeElement, 'input')
            .pipe(debounceTime(200), takeUntil(this._destroyed))
            .subscribe(() => this.resize());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    private resize() {
        const textarea = this._elementRef.nativeElement as HTMLTextAreaElement;
        const borderSize = textarea.offsetHeight - textarea.clientHeight;

        textarea.style.height = 'auto';

        const height = textarea.scrollHeight + borderSize;
        textarea.style.height = `${
            !this.pMaxHeight || height <= this.pMaxHeight
                ? height
                : this.pMaxHeight
        }px`;
    }
}
