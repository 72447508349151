import { CdkScrollable } from '@angular/cdk/scrolling';
import {
    AfterViewInit,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Users } from 'county-api';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
    GetNextNotifications,
    MarkAllNotificationRead,
    NotificationDisableSocket,
    NotificationSocket,
} from '../../../../users/store/notifications.actions';
import { NotificationsState } from '../../../../users/store/notifications.state';
import { ToggleNotifications, TogglePin } from '../../../store/layout.actions';
import { LayoutState } from '../../../store/layout.state';
import { INotification } from 'county-api/users';

/**
 * Component to display the current users notifications collection.
 */
@Component({
    selector: 'sidebar-notifications',
    templateUrl: './sidebar-notifications.component.html',
    styleUrls: ['./sidebar-notifications.component.scss'],
})
export class SidebarNotificationsComponent
    implements OnInit, OnDestroy, AfterViewInit {
    /**
     * The unread notifications count to toggle the read all button.
     */
    @Select(NotificationsState.unreadCount)
    unreadNotificationsCount$: Observable<number>;

    /**
     * The list of notifications currently loaded into the store.
     */
    notifications: INotification[];
    notificationSubscription: Subscription;

    /**
     * The loading state of the notifications, this will be true when a request
     * is in process.
     */
    @Select(NotificationsState.notificationsLoading)
    notificationsLoading$: Observable<boolean>;

    /**
     * State to determin if there is more notifications to load.
     */
    @Select(NotificationsState.notificationsHasMore)
    notificationsMore$: Observable<boolean>;

    /**
     * Pin state of the notifications sidebar.
     */
    @Select(LayoutState.pinState)
    pinNotifications$: Observable<boolean>;

    /**
     * The scrollable list ref, to load more notifications as the users scroll
     * through.
     */
    @ViewChild(CdkScrollable, { static: true })
    scrollable: CdkScrollable;

    @Select(NotificationsState.notificationsDisabled)
    notificationsDisabled$: Observable<boolean>;

    /**
     * The subscription for the scrollable list, stored for clean up on destroy.
     */
    private scrollSubscription: Subscription;

    /**
     * Build the component, and get deps.
     * @param store The ngxs store to get the notifications details.
     * @param ngZone Used to step back into zone from the CdkScrollable
     * observable.
     */
    constructor(private store: Store, private ngZone: NgZone) {}

    /**
     * Load the first set of notifications when the component first inits.
     */
    ngOnInit() {
        this.getNotifications();

        //add subscription to store notifications state
        this.notificationSubscription = this.store
        .select(NotificationsState.notifications)
        .subscribe(x => {
            this.notifications = x;
        });
    }

    /**
     * After the view inits set up the scrollable subscription.
     */
    ngAfterViewInit() {
        this.scrollSubscription = this.scrollable
            .elementScrolled()
            .pipe(debounceTime(500))
            .subscribe((scrolled: any) => {
                // If we are less than 75px from the bottom of the list call the
                // on click method within the ngzone.
                if (
                    scrolled.target.scrollHeight -
                        (scrolled.target.clientHeight +
                            scrolled.target.scrollTop) <
                    75
                ) {
                    this.ngZone.run(() => {
                        this.nextPageClick();
                    });
                }
            });
    }

    /**
     * Dispatch to the store to load the next set of notifications depenedent on
     * what is currently in the store.
     */
    getNotifications() {
        this.store.dispatch(new GetNextNotifications());
    }

    /**
     * Dispatch action to the store to mark all known notifications as marked.
     */
    markAllReadClick() {
        this.store.dispatch(new MarkAllNotificationRead());
    }

    /**
     * Load the next page of notifications, only if we're not currently loading
     * notifications and there is more to load.
     */
    nextPageClick() {
        if (
            !this.store.selectSnapshot(
                NotificationsState.notificationsLoading,
            ) &&
            this.store.selectSnapshot(NotificationsState.notificationsHasMore)
        ) {
            this.getNotifications();
        }
    }

    /**
     * Dispatch layout action to toggle the display of the notifications drawer.
     */
    closeClick() {
        this.store.dispatch(new ToggleNotifications());
    }

    /**
     * Dispatch layout action to pin the notifications drawer.
     */
    pinClick() {
        this.store.dispatch(new TogglePin());
    }

    toggleNotificationsSocketClick() {
        const isDisabled = this.store.selectSnapshot(
            NotificationsState.notificationsDisabled,
        );
        this.store.dispatch(
            isDisabled
                ? new NotificationSocket()
                : new NotificationDisableSocket(),
        );
    }

    /**
     * On destroy clean up the scroll subscription.
     */
    ngOnDestroy() {
        if (this.scrollSubscription && !this.scrollSubscription.closed) {
            this.scrollSubscription.unsubscribe();
        }
        this.notificationSubscription.unsubscribe();
    }
}
