<list *ngIf="customer; else loader">
    <list-item pLabel="Account Number">
        {{ customer.customer_account_number }}
    </list-item>

    <list-item pLabel="Account Name">
        {{ customer.customer_account_name }}
    </list-item>

    <list-item pLabel="Last Transaction">
        {{ customer.date_of_last_transaction | date: 'dd/MM/yyyy' }}
    </list-item>

    <list-item pLabel="Status">
        <badge *ngIf="customer.account_is_on_hold" pVariant="error">
            On Hold
        </badge>
        <badge *ngIf="!customer.account_is_on_hold" pVariant="info">
            Active
        </badge>
    </list-item>
</list>

<ng-template #loader>
    <spinner></spinner>
</ng-template>
