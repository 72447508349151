import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
} from '@angular/core';
import { getLightVariant, getOnTextColor } from 'platform-ui';

@Component({
    selector: 'badge',
    templateUrl: './badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.badge]': 'true',
        '[class.badge--v-success]': 'pVariant == "success"',
        '[class.badge--v-primary]': 'pVariant == "primary"',
        '[class.badge--v-error]': 'pVariant == "error"',
        '[class.badge--v-info]': 'pVariant == "info"',
        '[class.badge--v-warn]': 'pVariant == "warn"',
        '[class.badge--v-extra]': 'pVariant == "extra"',
        '[class.badge--v-neutral]':
            '(!pVariant && !pColor) || pVariant == "neutral"',
        '[class.badge--strong]': 'pStrong',
        '[class.badge--current-color]': '!!pColor',
    },
})
export class BadgeComponent {
    @Input()
    pVariant: string;

    @Input()
    get pColor(): string {
        return this._color;
    }
    set pColor(value: string) {
        if (this._color != value) {
            this._color = value;

            if (!!this._color) {
                this.elementRef.nativeElement.style.setProperty(
                    '--foreground-badge',
                    this._color,
                );

                this.elementRef.nativeElement.style.setProperty(
                    '--background-light-badge',
                    getLightVariant(this._color),
                );

                this.elementRef.nativeElement.style.setProperty(
                    '--foreground-on-badge',
                    getOnTextColor(this._color),
                );
            }
        }
    }
    private _color;

    @Input()
    get pStrong(): boolean {
        return this._strong;
    }
    set pStrong(value: boolean) {
        this._strong = coerceBooleanProperty(value);
    }
    private _strong = false;

    constructor(private elementRef: ElementRef) {}

    // static ngAcceptInputType_pVariant: Variant | string;
}
