import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { ApiConstant } from '../configs/api.constant';
import { ApiHttpClientService } from '../universal/api/apiHttpClient.service';
import { IRequestPaging } from '../universal/paging';
import { UriService } from '../universal/uri/uri.service';
import { IApiResponse, IPagingViewModel } from 'county-api';
import { IActivitiesViewModel, IActivity } from 'county-api/activities';

export interface IActivityRendered extends IActivity {
    text_rendered: SafeHtml;
}

export interface IActivityRenderedViewModel extends IPagingViewModel {
    activities: IActivityRendered[];
}

/**
 * The activities service to get and parse activities.
 */
@Injectable()
export class ActivitiesService extends ApiHttpClientService {
    constructor(
        private uriService: UriService,
        private sanitizer: DomSanitizer,
        private service: HttpClient,
    ) {
        super();
    }

    /**
     * Gets all activities from the person with the given id.
     * @param prefix The prefix for the partition key.
     * @param id The id of the person.
     * @param paging The params in which to get the activities.
     */
    get(prefix: string, id: string, paging: IRequestPaging) {
        return this.service.get<IApiResponse<IActivitiesViewModel>>(
            ApiConstant.url + 'activities/' + prefix + '/' + id,
            {
                params: this.filterRequestParams(paging),
            },
        );
    }

    mapActivities() {
        return map((value: IApiResponse<IActivitiesViewModel>) => {
            const activities = value.result.activities;
            for (let a = 0; a < activities.length; a++) {
                (activities[
                    a
                ] as IActivityRendered).text_rendered = this.parseText(
                    activities[a],
                );
            }

            return value as IApiResponse<IActivityRenderedViewModel>;
        });
    }

    /**
     * Parse a activities text.
     * @param activity The activity in which to parse.
     */
    parseText(activity: IActivity) {
        let formattedText = activity.text;
        let offsetDiff = 0;

        // Loop all the ranges in the activity. Replacing the strings with bold strings.
        for (const range of activity.ranges) {
            const word = formattedText.slice(
                range.offset + offsetDiff,
                range.length + range.offset + offsetDiff,
            );
            const uriUrl = this.uriService.parseUri(range.uri);
            const replacementWord =
                '<strong>' +
                (uriUrl ? '<a href="' + uriUrl + '">' : '') +
                word +
                (uriUrl ? '</a>' : '') +
                '</strong>';

            formattedText = this.replaceRange(
                formattedText,
                range.offset + offsetDiff,
                range.length + range.offset + offsetDiff,
                replacementWord,
            );

            // Increase offset by the length of extra characters added to the string.
            offsetDiff += replacementWord.length - word.length;
        }

        // Trust the string as the text that comes from the api will only ever be set in
        // the api and never by a user.
        return this.sanitizer.bypassSecurityTrustHtml(formattedText);
    }

    private replaceRange(
        s: string,
        start: number,
        end: number,
        substitute: string,
    ) {
        return s.substring(0, start) + substitute + s.substring(end);
    }
}
