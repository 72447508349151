<div
    class="notification notification--unread"
    [ngClass]="{ 'notification--unread': !notification.read_date }"
>
    <a
        *ngIf="notification.uri | uri; else notificationUrl"
        class="notification__link"
        [routerLinkClick]="notification.uri | uri"
        (routerLinkClickRouterClick)="readNotificationClick()"
    >
        <ng-container *ngTemplateOutlet="notificationInner"></ng-container>
    </a>
    <ul class="notification__controls">
        <li *ngIf="!notification.read_date">
            <a
                (click)="readNotificationClick()"
                title="Mark notification as read"
            >
                <fa-icon icon="eye"></fa-icon>
            </a>
        </li>
    </ul>
</div>

<ng-template #notificationUrl>
    <a
        class="notification__link"
        [href]="notification.url"
        (routerLinkClickRouterClick)="readNotificationClick()"
    >
        <ng-container *ngTemplateOutlet="notificationInner"></ng-container>
    </a>
</ng-template>

<ng-template #notificationInner>
    <div class="notification__title">
        <div
            class="notification__title-text"
            [attr.title]="notification.title"
            title="Insurance qualification added"
        >
            {{ notification.title }}
        </div>
    </div>
    <div class="notification__description">{{ notification.message }}</div>
    <div class="notification__meta">
        <time-ago
            class="notification__meta-item"
            [time]="notification.added_date"
        ></time-ago>
        <user-label
            class="notification__meta-item"
            *ngIf="notification.added_user_id"
            [userId]="notification.added_user_id"
        ></user-label>
    </div>
</ng-template>
