import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import { ApiConstant } from '../configs/api.constant';
import { filterRequestParams } from '../universal/api/filterRequestParams';
import { IRequestPaging } from '../universal/paging';
import { IActivitiesViewModel } from 'county-api/activities';
import { Users } from 'county-api/index';
import { IApiResponse } from 'county-api/responses';

@Injectable()
export class UsersService {
    constructor(private service: HttpClient) {}

    get(paging: IRequestPaging) {
        // let request =
        return this.service.get<IApiResponse<Users.IUsersViewModel>>(
            ApiConstant.url + 'users',
            {
                params: filterRequestParams(paging),
            },
        );
    }

    /**
     * Get a single user by its id.
     * @param id The id of a single user.
     */
    getById(id: string) {
        return this.service.get<IApiResponse<Users.IUserViewModel>>(
            `${ApiConstant.url}users/${id}`,
        );
    }

    /**
     * Update a users current location by their id.
     * @param id Id of the user whos location we are updating.
     * @param locationId Id of the new location.
     */
    updateLocation(id: string, locationId: number) {
        return this.service.post<IApiResponse<Users.IUserViewModel>>(
            ApiConstant.url + 'users/' + id + '/location',
            { location_id: locationId } as { [key: number]: number },
        );
    }

    /**
     * Update a users base location id by their id.
     * @param id Id of the user whos location is being updated.
     * @param baseLocationId Id of the location to update base_location_id to.
     */
    updateBaseLocation(id: string, baseLocationId: number) {
        return this.service.post<IApiResponse<Users.IUserViewModel>>(
            ApiConstant.url + 'users/' + id + '/baselocation',
            { location_id: baseLocationId } as {
                [key: number]: number;
            },
        );
    }

    /**
     * Gets all the activities for the desired user.
     * @param id The users id.
     * @param paging The params in which to get the activities.
     */
    getActivities(id: string, paging: IRequestPaging) {
        return this.service.get<IApiResponse<IActivitiesViewModel>>(
            ApiConstant.url + 'users/' + id + '/activities',
            {
                params: filterRequestParams(paging),
            },
        );
    }

    getAllUsers() {
        return this.service.get<IApiResponse<Users.IUsersViewModel>>(
            `${ApiConstant.url}users`,
            {
                params: {
                    paging: 'false',
                },
            },
        );
    }

    /***
     * Format the users details.
     * @deprecated All data is now seperated into service methods or calculated
     * on the api.
     */
    formatUser(user: Users.IUser) {
        return user;
    }

    /**
     * Determine if the user has any of the required permissions.
     */
    hasPermission(
        user: Users.IUser,
        permissions: string | string[],
        ignoreAdmin?: boolean,
    ) {
        // This will improve performance if we know the user is already an admin.
        if (
            isUndefined(permissions) ||
            (ignoreAdmin !== true && user.is_admin)
        ) {
            return true;
        }

        if (isString(permissions)) {
            permissions = [permissions];
        }

        for (let p = 0; p < user.permissions.length; p++) {
            const perm = user.permissions[p];

            for (let i = 0; i < permissions.length; i++) {
                const cperm = permissions[i];

                if (
                    perm.name === cperm ||
                    (ignoreAdmin !== true && perm.name === 'admin')
                ) {
                    return true;
                }
            }
        }

        return false;
    }
}
