<a
    class="content-list__item content-list__item--simple content-list__link"
    [routerLink]="[user.id]"
>
    <div class="row row--align-center">
        <div class="m-col-auto">
            <img
                [src]="user.thumbnail"
                class="user-list__thumbnail user-thumbnail user-thumbnail--circle"
            />
        </div>
        <div class="content-list__details m-col">
            <h4 class="content-list__heading">{{ user.username }}</h4>
            <p>{{ user.title }}</p>
        </div>
    </div>
</a>
