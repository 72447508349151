import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bookings } from 'county-api';
import { IFilterItem } from '../../bookings.component';
import { BookingCancellationType, BookingPaymentType } from 'county-api/enums';

/**
 * The type for the cancel booking.
 */
export interface IBookingCancellation {
    type: BookingCancellationType;
}

/**
 * The booking cancel dialog used to submit the cancel.
 */
@Component({
    selector: 'booking-cancel-dialog',
    templateUrl: './booking-cancel-dialog.component.html',
})
export class BookingCancelDialogComponent {
    /**
     * The booking payment type options.
     */
    bookingPaymentTypes = BookingPaymentType;

    /**
     * The booking cancellation type options.
     */
    bookingCancellationTypes = BookingCancellationType;

    /**
     * The booking cancellation type items.
     */
    bookingCancellationTypeItems: IFilterItem[] = [];

    /**
     * The booking data passed from the parent.
     */
    booking: Bookings.IBooking;

    /**
     * The data passed back to the parent.
     */
    result: IBookingCancellation = { type: null };

    /**
     * The booking cancellation type option labels.
     */
    private bookingCancellationTypeLabels = {
        NoShow: 'No show',
        CustomerCancelled: 'Customer cancelled',
        CountyCancelled: 'Unable to fulfill booking',
        CustomerChange: 'Customer Requested Change',
    };

    /**
     * Constructs injected angular dependencies.
     * @param dialogRef The dialog referenced functionality.
     * @param data The data passed up from the parent.
     */
    constructor(
        private dialogRef: MatDialogRef<BookingCancelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Bookings.IBookingViewModel,
    ) {
        this.booking = data ? data.booking : null;

        /**
         * Loops the booking cancellation types and pushes them to the item array.
         */
        for (const key in this.bookingCancellationTypes) {
            if (this.bookingCancellationTypes.hasOwnProperty(key)) {
                const cancellationType = this.bookingCancellationTypes[key];

                // Doesnt add the customer change option if false.
                if (
                    data.booking.payment_type !==
                        this.bookingPaymentTypes.OnlineSave &&
                    key === 'CustomerChange'
                ) {
                    continue;
                }

                this.bookingCancellationTypeItems.push({
                    id: key,
                    label: cancellationType,
                });
            }
        }
    }

    /**
     * When valid passes back the cancel data.
     */
    submit() {
        this.dialogRef.close(this.result);
    }
}
