import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BookingsService } from '../bookings.service';
import { Bookings } from 'county-api';

/**
 * Maps the data from the bookings service for the booking of the given id.
 */
@Injectable()
export class BookingsResolve implements Resolve<Bookings.IBooking | boolean> {
    constructor(
        private bookingsService: BookingsService,
        private router: Router,
    ) {}

    /**
     * Uses the current route and maps the passed in id to the url to get the booking data.
     */
    resolve(route: ActivatedRouteSnapshot) {
        return this.bookingsService.getById(route.paramMap.get('id')).pipe(
            map(data => data.result.booking),
            catchError(error => {
                // Delay the navigation in order to maintain the url states.
                setTimeout(() =>
                    this.router.navigate(['/bookings'], {
                        skipLocationChange: true,
                    }),
                );
                return observableOf(false);
            }),
        );
    }
}
