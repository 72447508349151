<button
    aria-label="Dismiss"
    class="dialog-close"
    mat-dialog-close
    tabindex="-1"
></button>

<h3 mat-dialog-title>Confirm</h3>

<form (validSubmit)="submit(result.reservation_number)">
    <div mat-dialog-content>
        <p>
            A booking should only be marked as confirmed when the rental has
            been made in rentman.
        </p>
        <p>
            When you click confirm an email will be sent to the customer
            confirming the booking on your behalf.
        </p>

        <div class="dialog__divider"></div>

        <ul class="form">
            <li class="form__input">
                <label class="input-label" for="agreement_number"
                    >Reservation Number</label
                >
                <input
                    type="text"
                    id="reservation_number"
                    name="reservation_number"
                    [(ngModel)]="result.reservation_number"
                    [maxlength]="20"
                    required
                    #reservationNumber="ngModel"
                />

                <div class="input-errors" [ngxErrors]="reservationNumber">
                    <label
                        for="reservation_number"
                        ngxError="required"
                        class="input-error"
                    >
                        Please enter a reservation number.
                    </label>
                </div>
            </li>
        </ul>
    </div>

    <div class="btns btns--horizontal" mat-dialog-actions>
        <button type="button" mat-dialog-close class="btn">
            Cancel
        </button>

        <input
            type="submit"
            value="Confirm"
            class="btn btn--success btn--right"
        />
    </div>
</form>
