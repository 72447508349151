import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class DestroyController implements OnDestroy {
    protected unsubscribe$ = new Subject<any>();

    ngOnDestroy() {
        this.unsubscribe$.next("");
        this.unsubscribe$.complete();
    }
}
