<div
    class="site-menu__menu"
    [ngClass]="{
        'site-menu__menu--active': menu.active,
        'site-menu__menu--show': menu.show
    }"
>
    <a
        class="site-menu__link"
        (click)="click(menu, $event)"
        [attr.href]="menu.url ? menu.url : null"
    >
        <span class="site-menu__label">
            <fa-icon class="site-menu__icon" [icon]="menu.icon"></fa-icon>
            {{ menu.label }}
        </span>
    </a>

    <ng-container *ngIf="menu.children">
        <div class="site-menu__chevron-container">
            <fa-icon
                class="site-menu__chevron"
                icon="chevron-down"
                (click)="chevronClick(menu)"
            ></fa-icon>
        </div>

        <ul class="menu-nav">
            <ng-container *ngFor="let menuItem of menu.children">
                <li
                    class="menu-nav__item"
                    [ngClass]="{
                        'menu-nav__item--active': menuItem.active,
                        'menu-nav__item--show': menuItem.show
                    }"
                    *hasPermission="menuItem.permission"
                >
                    <a
                        class="menu-nav__link"
                        (click)="click(menuItem, $event, true)"
                        [attr.href]="menuItem.url ? menuItem.url : null"
                        [attr.target]="menuItem.newTab ? '_Blank' : null"
                    >
                        <span class="menu-nav__label">{{
                            menuItem.label
                        }}</span>
                        <fa-icon
                            class="menu-nav__external"
                            icon="external-link"
                            *ngIf="menuItem.newTab"
                        ></fa-icon>
                    </a>
                    <ng-container *ngIf="menuItem.children">
                        <div class="site-menu__chevron-container">
                            <fa-icon
                                class="site-menu__chevron"
                                icon="chevron-down"
                                (click)="chevronClick(menuItem, true)"
                            ></fa-icon>
                        </div>

                        <ul class="menu-nav menu-nav--sub">
                            <ng-container
                                *ngFor="let innerMenuItem of menuItem.children"
                            >
                                <li
                                    class="menu-nav__item"
                                    [ngClass]="{
                                        'menu-nav__item--active':
                                            innerMenuItem.active
                                    }"
                                    *hasPermission="innerMenuItem.permission"
                                >
                                    <a
                                        class="menu-nav__link"
                                        (click)="
                                            click(innerMenuItem, $event, true)
                                        "
                                        [attr.href]="
                                            innerMenuItem.url
                                                ? innerMenuItem.url
                                                : null
                                        "
                                        [attr.target]="
                                            innerMenuItem.newTab
                                                ? '_Blank'
                                                : null
                                        "
                                    >
                                        <span class="menu-nav__label">{{
                                            innerMenuItem.label
                                        }}</span>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>
                </li>
            </ng-container>
        </ul>
    </ng-container>
</div>
