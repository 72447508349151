<topbar>
    <h1 topbar-title>People</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="content-section">
            <div class="wrapper">
                <div class="content-card">
                    <div class="content-list">
                        <div
                            class="content-list-toolbar list-flex list-flex--align-center list-flex--spaced list-flex--justify-end"
                        >
                            <div class="input-group input-group--accent">
                                <div class="input-group__prepend">
                                    <label
                                        class="input-group__text"
                                        for="search"
                                    >
                                        <fa-icon icon="search"></fa-icon>
                                    </label>
                                </div>
                                <input
                                    type="search"
                                    name="search"
                                    id="search"
                                    class="input-group__input"
                                    [ngModel]="pagingData.filter"
                                    (input)="
                                        filterUpdate($event.target, 'filter')
                                    "
                                />
                            </div>

                            <a class="btn btn--primary" [routerLink]="['add']">
                                Add
                            </a>
                        </div>

                        <ng-container *ngIf="!pagingLoading; else loader">
                            <ng-container
                                *ngIf="this.totalItems != 0; else noResults"
                            >
                                <div class="content-list__list">
                                    <person-list-item
                                        *ngFor="let person of people"
                                        [personItem]="person"
                                    ></person-list-item>
                                </div>
                                <mat-paginator
                                    [length]="totalItems"
                                    [pageSize]="pagingData.perpage"
                                    (page)="pageChange($event)"
                                    [pageIndex]="pagingData.page - 1"
                                ></mat-paginator>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noResults>
    <div class="content-list__no-results">
        No customers found.
    </div>
</ng-template>

<ng-template #loader>
    <loader></loader>
</ng-template>
