import { Breadcrumb } from 'angular-crumbs';

export class TogglePin {
    static readonly type = '[Layout] Notifications Toggle Pin';
}

export class ToggleNotifications {
    static readonly type = '[Layout] Notifications Toggle Menu';
}

export class SetDisplayNotifications {
    static readonly type = '[Layout] Notifications Set Menu';

    constructor(public payload: boolean) {}
}

export class ToggleSidebar {
    static readonly type = '[Layout] Sidebar Toggle';
}

export class UpdateIsMobile {
    static readonly type = '[Layout] Update IsMobile';

    constructor(public payload: boolean) {}
}

export class UpdateIsTablet {
    static readonly type = '[Layout] Update IsTablet';

    constructor(public payload: boolean) {}
}

export class SetSeenVersion {
    static readonly type = '[Layout] Set SeenVersion';

    constructor(public payload: string) {}
}
