<div class="container-menu">
    <a routerLink="/" class="container-menu__home" title="Platform Home">
        <div
            class="container-menu__platform-logo"
            [ngClass]="{ 'container-menu__platform-logo--debug': debug }"
            [ngSwitch]="debug"
        >
            <ng-container *ngSwitchDefault>
                <svg>
                    <use xlink:href="#logo"></use>
                </svg>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <svg>
                    <use xlink:href="#county-labs-logo"></use>
                </svg>
            </ng-container>
        </div>
    </a>

    <ng-container select="topbar-thumbnail"></ng-container>

    <ng-content></ng-content>

    <ng-content select="topbar-menu"></ng-content>
</div>

<topbar-breadcrumbs *ngIf="!hideBreadcrumbs"></topbar-breadcrumbs>
