// Fork of https://github.com/FriOne/ng-push-notification. The es7 dist of this
// doesnt work in our environment.
import { Injectable, Injector, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface PushNotificationSettings {
    body?: string;
    icon?: string;
    sound?: string;
    data?: any;
    tag?: string;
    dir?: NotificationDirection;
    lang?: string;
    renotify?: boolean;
    sticky?: boolean;
    vibrate?: Array<number>;
    noscreen?: boolean;
    silent?: boolean;
    closeDelay?: number;
}

export const DEFAULT_NOTIFICATION_SETTINGS = new InjectionToken<
    PushNotificationSettings
>('DEFAULT_NOTIFICATION_SETTINGS');

@Injectable()
export class PushNotificationService {
    shown$ = new Subject<Notification>();
    closed$ = new Subject<Notification>();
    error$ = new Subject<Notification>();
    click$ = new Subject<{ event: any; notification: Notification }>();

    private defaultCloseDelay: number;
    private defaultSettings: PushNotificationSettings;
    private instances: Notification[] = [];

    constructor(injector: Injector) {
        const defaultSettings = injector.get<PushNotificationSettings>(
            DEFAULT_NOTIFICATION_SETTINGS,
        );
        this.defaultSettings = Object.assign(
            {
                dir: 'auto',
                lang: 'en-GB',
                renotify: false,
                sticky: false,
                noscreen: false,
                silent: false,
            },
            defaultSettings || {},
        );
        this.defaultCloseDelay =
            this.defaultSettings.closeDelay === undefined
                ? 10000
                : this.defaultSettings.closeDelay;
    }

    checkCompatibility() {
        return !!('Notification' in window);
    }

    requestPermission(): Promise<boolean> {
        return Notification.requestPermission().then(
            permission => permission === 'granted',
        );
    }

    show(
        title: string,
        settings: PushNotificationSettings = {},
        closeDelay?: number,
    ): Promise<Notification> {
        if (!this.checkCompatibility()) {
            console.warn('Notification API not available in this browser.');
            return Promise.resolve(null);
        }

        try {
            // Only set to default if the passed value is not set.
            if (closeDelay == null) {
                closeDelay = this.defaultCloseDelay;
            }

            return this.requestPermission().then(permission => {
                if (!permission) {
                    console.warn('Permission was not granted.');
                    return Promise.resolve(null);
                }
                const notificationSettings = {
                    ...this.defaultSettings,
                    ...settings,
                };
                return this.create(title, notificationSettings, closeDelay);
            });
        } catch (error) {
            console.warn(
                "Didn't send push notification because of error",
                error,
            );
            return Promise.resolve(null);
        }
    }

    closeAll(): void {
        this.instances.forEach(notification => notification.close());
        this.instances = [];
    }

    private create(
        title: string,
        settings: PushNotificationSettings,
        closeDelay = 0,
    ) {
        // settings.closeDelay
        const notification = new Notification(title, settings);

        this.instances.push(notification);
        this.attachEventHandlers(notification);

        return notification;
    }

    private attachEventHandlers(notification: Notification): void {
        notification.onshow = () => {
            this.shown$.next(notification);
        };
        notification.onclick = (event: any) => {
            this.click$.next({ event, notification });
        };
        notification.onerror = () => {
            this.error$.next(notification);
        };
        notification.onclose = () => {
            this.closed$.next(notification);
        };
    }
}
