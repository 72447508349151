import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { Users } from 'county-api';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CurrentUserService } from '../current-user.service';
import { UsersService } from '../users.service';
import {
    LocationUpdate,
    LocationUpdated,
    LoggedIn,
    LogIn,
    LogInFailed,
    UpdatedUser,
} from './user.actions';

export enum AuthState {
    Unknown,
    Authenticated,
    Unauthenticated,
}

export interface UserStateModel {
    user: Users.IUser;
    state: AuthState;
}

@State<UserStateModel>({
    name: 'user',
    defaults: {
        user: null,
        state: AuthState.Unknown,
    },
})
@Injectable()
export class UserState {
    constructor(
        private currentUserService: CurrentUserService,
        private usersService: UsersService
    ) {}

    @Selector()
    static state(state: UserStateModel) {
        return state.state;
    }

    @Selector()
    static user(state: UserStateModel) {
        return state.user;
    }

    @Selector()
    static userLocation(state: UserStateModel) {
        return state.user.location_id || 0;
    }

    @Selector()
    static userIsAdmin(state: UserStateModel) {
        return state.user.is_admin || false;
    }

    trimString(value: string) {
        return value.replace(' ', '').replace('-', '');
    }

    /**
     * Attempt to get the users details from the api. This is dispatch after we
     * have authed with AD.
     * @param param0 The current state.
     */
    @Action(LogIn)
    logIn({ dispatch, patchState }: StateContext<UserStateModel>) {
        return this.currentUserService.get().pipe(
            tap(response =>
                dispatch(
                    new LoggedIn(
                        this.usersService.formatUser(response.result.user),
                    ),
                ),
            ),
            catchError(() =>
                of(
                    patchState({
                        state: AuthState.Unauthenticated,
                    }),
                ),
            ),
        );
    }

    @Action(LogInFailed)
    logInFailed({ patchState }: StateContext<UserStateModel>) {
        patchState({ state: AuthState.Unauthenticated });
    }

    /**
     * Store the user details on state when they have logged in.
     */
    @Action([LoggedIn, UpdatedUser])
    loggedIn(
        { patchState }: StateContext<UserStateModel>,
        { payload }: LoggedIn,
    ) {
        patchState({ user: payload, state: AuthState.Authenticated });
    }

    @Action(LocationUpdate, { cancelUncompleted: true })
    locationUpdate(
        { patchState, getState, dispatch }: StateContext<UserStateModel>,
        { payload }: LocationUpdate,
    ) {
        const userId = getState().user.id;

        return this.usersService.updateLocation(userId, payload).pipe(
            tap(response => {
                patchState({
                    user: this.usersService.formatUser(response.result.user),
                });
                dispatch(new LocationUpdated(payload));
            }),
        );
    }
}
