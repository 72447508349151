import { Directive, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePagingController } from './base-paging.controller';

export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    DOWN_ARROW = 40,
}

/**
 * Simple controller class to aid with updating a url for paged, sorted and/or
 * filtered items.
 */
@Directive()
// tslint:disable-next-line
export abstract class PagingController<T> extends BasePagingController<T> {
    constructor(protected router: Router, protected route: ActivatedRoute) {
        super();
    }

    getParams() {
        return this.route.queryParams;
    }

    storeParams(params: { [key: string]: string }) {
        this.router.navigate([], {
            queryParams: params,
            queryParamsHandling: 'merge',
        });
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
            if (event.ctrlKey) {
                let lastPage = 1;
                if (this.totalItems) {
                    lastPage =
                        Math.floor(this.totalItems / this.pagingData.perpage) +
                        1;
                } else if (this.pages) {
                    lastPage = this.pages;
                }
                if (this.pagingData.page !== lastPage) {
                    this.filterUpdate(
                        {
                            value: lastPage,
                        },
                        'page',
                        true,
                    );
                }
            } else {
                this.nextPage();
            }
        }

        if (event.keyCode === KEY_CODE.LEFT_ARROW) {
            if (event.ctrlKey) {
                this.reload(true);
            } else {
                this.prevPage();
            }
        }
    }
}
