import { Users } from 'county-api';

export class GetUsers {
    static readonly type = '[Users] Get';
}

export class GotUsers {
    static readonly type = '[Users] Got';

    constructor(public payload: Users.IUser[]) {}
}
