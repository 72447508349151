<div class="widget">
    <div class="widget__header">
        <!-- <h4 class="widget__header-heading">Progress Towards Great</h4> -->
        <div class="row row--align-center" *ngIf="loading < 1">
            <div class="col-auto">
                {{ currentDate.format('MMM, YYYY') }}
            </div>
            <div class="col-auto btns btns--group btns--small-thin">
                <button
                    class="btn"
                    [disabled]="
                        performanceData == null ||
                        locationPerformanceData == null
                    "
                    (click)="monthClick(-1)"
                >
                    <fa-icon [icon]="['fas', 'caret-left']"></fa-icon>
                </button>
                <button
                    class="btn"
                    [disabled]="isLastMonth"
                    (click)="monthClick(1)"
                >
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                </button>
                <button
                    class="btn btn--small-thin"
                    *hasPermission="
                        'dashboard-revenue-upload';
                        andIf: isLastMonth
                    "
                    (click)="updateClick()"
                >
                    <fa-icon icon="ellipsis-v"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <ng-container
        *ngIf="loading < 1"
        [ngSwitch]="performanceData != null && locationPerformanceData != null"
    >
        <div class="widget__content" *ngSwitchCase="true">
            <div class="grid">
                <div class="row">
                    <div class="m-col-8">
                        <!-- <revenue-location
                            [locationPerformanceData]="locationPerformanceData"
                            [showEstimate]="showEstimate"
                        ></revenue-location> -->

                        <!-- <ng-container
                            [ngSwitch]="this.isLastMonth"
                            *ngIf="showEstimate"
                        >
                            <revenue-locations
                                *ngSwitchCase="true"
                                [performanceData]="performanceData"
                            ></revenue-locations>
                            <revenue-leaderboard
                                *ngSwitchDefault
                                [performanceData]="performanceData"
                            ></revenue-leaderboard>
                        </ng-container> -->

                        <div
                            class="revenue-widget__no-estimate"
                            *ngIf="!showEstimate"
                        >
                            Currently not enough information to display an
                            estimate for this month.
                        </div>
                    </div>
                    <div class="m-col-2">
                        <div class="sales-performance">
                            <div class="sales-performance__value">
                                <small class="sales-performance__title"
                                    >Revenue</small
                                >
                                {{ locationPerformanceData.revenue | gbp }}
                            </div>
                            <ng-container *hasPermission="'admin'">
                                <div class="sales-performance__value">
                                    <small class="sales-performance__title"
                                        >Min Revenue Before Bonus</small
                                    >{{ locationPerformanceData.target | gbp }}
                                </div>
                                <div class="sales-performance__value">
                                    <small class="sales-performance__title"
                                        >Orbit Target</small
                                    >{{
                                        locationPerformanceData.orbit_target
                                            | gbp
                                    }}
                                </div>
                                <div class="sales-performance__value">
                                    <small class="sales-performance__title"
                                        >Estimated amount</small
                                    >{{
                                        locationPerformanceData.estimate_amount
                                            | gbp
                                    }}
                                    <small
                                        >{{
                                            locationPerformanceData.estimate.toFixed(
                                                1
                                            )
                                        }}&#37;</small
                                    >
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="widget__content widget__content--center" *ngSwitchDefault>
            <div class="widget__content-error">No performance data found.</div>
        </div>
    </ng-container>

    <div class="widget__content widget__content--center" *ngIf="loading > 0">
        <loader></loader>
    </div>
</div>
