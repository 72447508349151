import { Component, forwardRef, Input } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Customers } from 'county-api';
import { DeleteDialogComponent } from '../../universal/dialogs/delete-dialog/delete-dialog.component';
import { coerceBooleanProperty } from '../../universal/utils';
import { IContactDetail } from 'county-api/customers';
import { CustomersContactsDialogComponent } from './customers-contacts-dialog.component';

/**
 * The address input provider.
 */
export const CUSTOMERS_CONTACTS_INPUT_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomersContactsInputComponent),
    multi: true,
};

/**
 * Validator provider.
 */
export const CUSTOMERS_CONTACTS_INPUT_VALIDATORS: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomersContactsInputComponent),
    multi: true,
};

/**
 * The customer contact custom input used to store contacts until submit.
 */
@Component({
    selector: 'customers-contacts-input',
    templateUrl: './customers-contacts-input.component.html',
    providers: [
        CUSTOMERS_CONTACTS_INPUT_ACCESSOR,
        CUSTOMERS_CONTACTS_INPUT_VALIDATORS,
    ],
})
/**
 * The custom input to get new contacts.
 */
export class CustomersContactsInputComponent implements ControlValueAccessor {
    /**
     * The cloned contacts;
     */
    private _contacts: IContactDetail[] = [];

    /**
     * The get to get the array of contacts.
     */
    get contacts(): IContactDetail[] {
        return this._contacts;
    }

    /**
     * The set to set the array of contacts.
     */
    set contacts(contactDetails: IContactDetail[]) {
        this._contacts = contactDetails;
        this.propogateChange(this._contacts);
    }

    /**
     * Determine if the input allows duplicated.
     */
    private _allowDuplicates = false;

    /**
     * Determine if the input allows duplicated.
     */
    get allowDuplicates() {
        return this._allowDuplicates;
    }

    /**
     * Set if the input allow duplicates.
     */
    @Input()
    set allowDuplicates(value: any) {
        const allowValue = coerceBooleanProperty(value);
        if (this._allowDuplicates != allowValue) {
            this._allowDuplicates = allowValue;
            this._validatorOnChange();
        }
    }

    /**
     * Determine whether the input requires a primary contact.
     */
    private _requirePrimary = true;

    /**
     * Determine whether the input requires a primary contact.
     */
    get requirePrimary() {
        return this._requirePrimary;
    }

    /**
     * Set whether the input requires a primary contact.
     */
    @Input()
    set requirePrimary(value: any) {
        const requirePrimaryValue = coerceBooleanProperty(value);
        if (this._requirePrimary != requirePrimaryValue) {
            this._requirePrimary = requirePrimaryValue;
            this._validatorOnChange();
        }
    }

    /**
     * Construct injecting angular dependencies.
     * @param dialog The way to get the dialog functionality.
     */
    constructor(private dialog: MatDialog) {}

    /**
     * Calls when contacts change.
     */
    propogateChange = (contactDetails: IContactDetail[]) => {};

    /**
     * Calls when the item gets touched.
     */
    onTouched = () => {};

    /** @docs-private */
    private _validatorOnChange = () => {};

    /**
     * This method calls when the parent updates contact details.
     * @param contactDetails The contact details whiich is being watched for changes.
     */
    writeValue(contactDetails: IContactDetail[]) {
        this._contacts = contactDetails ? contactDetails : [];
    }

    /**
     * Calls the onchange method when something changes in the input.
     * @param fn Angulars registered function which calls when contact details changes.
     */
    registerOnChange(fn: (contactDetails: IContactDetail[]) => void) {
        this.propogateChange = fn;
    }

    /**
     * Calls the ontouched method when something gets touched in the input.
     * @param fn Angulars registered function which calls when its been touched.
     */
    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    /** @docs-private */
    registerOnValidatorChange(fn: () => void): void {
        this._validatorOnChange = fn;
    }

    /**
     * Opens the add dialog and pushes the new contact to the contact array.
     */
    addContactClick() {
        const openDialog = this.dialog.open(CustomersContactsDialogComponent, {
            width: '500px',
            data: {},
            panelClass: 'dialog',
        });
        // Gets the new data and pushes it to the contacts array.
        openDialog.afterClosed().subscribe(result => {
            this.onTouched();

            if (result) {
                const contact = Object.assign([], this._contacts);
                contact.push(result);
                this.contacts = contact;
            }
        });
    }

    /**
     * Opens the edit dialog and overrides the existing data with the new data
     * @param index The index of the contact in the contacts array.
     * @param contactDetail The passed in contact details.
     */
    editContactClick(index: number, contactDetail: IContactDetail) {
        const contactDetails = Object.assign({}, contactDetail);
        const openDialog = this.dialog.open(CustomersContactsDialogComponent, {
            width: '500px',
            data: { contactDetails },
            panelClass: 'dialog',
        });

        // Overrides the previous contact with the edited contact.
        openDialog.afterClosed().subscribe(result => {
            this.onTouched();

            if (result) {
                const contact = Object.assign([], this._contacts);
                contact[index] = result;
                this.contacts = contact;
            }
        });
    }

    /**
     * Opens the delete dialog and deletes the contact from the contacts array.
     * @param index The index of the contact in the contacts array.
     */
    deleteContactClick(index: number) {
        const openDialog = this.dialog.open(DeleteDialogComponent, {
            width: '500px',
            panelClass: 'dialog',
        });

        // Splices the contact by its index from the array of contacts.
        openDialog.afterClosed().subscribe(result => {
            this.onTouched();

            if (result) {
                const contact = Object.assign([], this._contacts);
                contact.splice(index, 1);
                this.contacts = contact;
            }
        });
    }

    /**
     * Validator to determine there is only one primary contact being added.
     */
    private _primaryValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const value: IContactDetail[] = control.value
            ? control.value
            : [];
        const primaryCount = value.filter(c => c.is_primary);
        return !this.requirePrimary ||
            value.length < 1 ||
            primaryCount.length == 1
            ? null
            : { primaryContact: true };
    };

    /**
     * Validator to determine that there are no duplicate contact details being
     * added.
     */
    private _duplicatesValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const value: IContactDetail[] = control.value
            ? control.value
            : [];
        const duplicates = value
            .map(
                (contact: IContactDetail) =>
                    contact.type + ':::' + contact.value,
            )
            .some((item, index, array) => array.indexOf(item) !== index);

        return this.allowDuplicates || value.length < 1 || !duplicates
            ? null
            : { duplicateContacts: true };
    };

    /** @docs-private */
    validate(c: AbstractControl): ValidationErrors | null {
        return this._validator ? this._validator(c) : null;
    }

    /** The combined form control validator for this input. */
    private _validator: ValidatorFn | null = Validators.compose([
        this._duplicatesValidator,
        this._primaryValidator,
    ]);
}
