import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToggleSidebar } from '../../store/layout.actions';
import { IMenuItem } from '../menu-items';

@Component({
    selector: 'sidebar-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent {
    @Input()
    menu: IMenuItem;

    @Output()
    sidebarMenuHideMenus = new EventEmitter<void>();

    constructor(private router: Router, private store: Store) {}

    /**
     * Handle the click of a menu item, this is used to route the user to the
     * menu items path. External urls are pretty much ignored if its not a menu
     * item with children.
     */
    click(menu: IMenuItem, event: MouseEvent, ignoreHide?: boolean) {
        if (!menu.show && menu.children) {
            if (ignoreHide !== true) {
                this.sidebarMenuHideMenus.emit();
            }
            event.preventDefault();
            menu.show = true;
            return false;
        } else if (menu.url) {
            if (ignoreHide !== true) {
                this.sidebarMenuHideMenus.emit();
            }

            this.store.dispatch(new ToggleSidebar());

            if (!menu.newTab) {
                this.router.navigateByUrl(menu.url);
                return false;
            }

            return true;
        } else {
            event.preventDefault();
            menu.show = false;
            return false;
        }
    }

    chevronClick(menu: IMenuItem, ignoreHide = false) {
        if (menu.show) {
            menu.show = false;
        } else {
            if (ignoreHide !== true) {
                this.sidebarMenuHideMenus.emit();
            }
            menu.show = true;
        }
    }
}
