import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';

@Component({
    selector: 'layout-section',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.layout__section]': 'true',
    },
})
export class LayoutSectionComponent {
    @Input()
    @HostBinding('class.layout__section--secondary')
    get pSecondary(): boolean {
        return this._secondary;
    }
    set pSecondary(value: boolean) {
        this._secondary = coerceBooleanProperty(value);
    }
    private _secondary = false;

    @Input()
    @HostBinding('class.layout__section--half')
    get pHalf(): boolean {
        return this._half;
    }
    set pHalf(value: boolean) {
        this._half = coerceBooleanProperty(value);
    }
    private _half = false;

    @Input()
    @HostBinding('class.layout__section--third')
    get pThird(): boolean {
        return this._third;
    }
    set pThird(value: boolean) {
        this._third = coerceBooleanProperty(value);
    }
    private _third = false;
}
