import { Pipe, PipeTransform } from '@angular/core';
import { UriService } from '../uri.service';

@Pipe({
    name: 'uri',
})
export class UriPipe implements PipeTransform {
    constructor(private uriService: UriService) {}

    transform(value: string, args?: any): any {
        return this.uriService.parseUri(value) || null;
    }
}
