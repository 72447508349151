import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';

export enum LabelWidth {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    xLarge = 'xLarge',
}

@Component({
    selector: 'list',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.list]': 'true',
        '[class.list--label-small]': 'pLabelWidth == "Small"',
        '[class.list--label-large]': 'pLabelWidth == "Large"',
        '[class.list--label-x-large]': 'pLabelWidth == "xLarge"',
    },
})
export class ListComponent {
    @Input()
    @HostBinding('class.list--striped')
    get pStriped(): boolean {
        return this._striped;
    }
    set pStriped(value: boolean) {
        this._striped = coerceBooleanProperty(value);
    }
    private _striped = false;

    @Input()
    pLabelWidth: LabelWidth;
}
