<div class="page-header__wrapper">
    <div class="page-header__title-wrapper">
        <div class="page-header__primary-title">
            <h1 class="page-header__title">
                {{ pTitle }}
            </h1>
            <div class="page-header__title-extras" *ngIf="!!pTitleExtras">
                <ng-container *ngTemplateOutlet="pTitleExtras"></ng-container>
            </div>
        </div>
        <div class="page-header__sub-title" *ngIf="pSubTitle">
            {{ pSubTitle }}
        </div>

        <action-menu
            class="page-header__secondary-actions"
            *ngIf="pHeaderActions"
            [pCollapse]="_shouldCollapse$ | async"
        >
            <ng-container *ngTemplateOutlet="pHeaderActions"></ng-container>
        </action-menu>
    </div>

    <stack *ngIf="pHeaderPrimaryActions" [pWrap]="false" pSpacing="x-small">
        <ng-container *ngTemplateOutlet="pHeaderPrimaryActions"></ng-container>
    </stack>
</div>
