import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstant } from '../configs/api.constant';
import { ApiHttpClientService } from '../universal/api/apiHttpClient.service';
import { IRequestPaging } from '../universal/paging';
import { IApiResponse } from '../county-api/src/responses';
import { IEndorsement, IEndorsementViewModel, IEndorsementsViewModel } from '../county-api/src/endorsements';

/**
 * The endorsement service used to get, create, edit and delete endorsments.
 */
@Injectable()
export class EndorsementService extends ApiHttpClientService {
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Get a collection of endorsements based on the passed in params.
     * @param params Query params to filter endorsements result, null values will be removed.
     */
    get(params: IRequestPaging) {
        return this.service.get<
            IApiResponse<IEndorsementsViewModel>
        >(ApiConstant.url + 'endorsements', {
            params: this.filterRequestParams(params),
        });
    }

    /**
     * Make post request to create a new endorsement.
     * @param endorsement The endorsement details to create.
     */
    create(endorsement: IEndorsement) {
        return this.service.post<
            IApiResponse<IEndorsementViewModel>
        >(ApiConstant.url + 'endorsements', {
            endorsement,
        } as IEndorsementViewModel);
    }

    /**
     * Makes a put request to update the already existing endorsement by its id.
     * @param id The id of the endorsement to update.
     * @param endorsement The new endorsement data to update by.
     */
    edit(id: string, endorsement: IEndorsement) {
        return this.service.put<
            IApiResponse<IEndorsementViewModel>
        >(ApiConstant.url + 'endorsements/' + id, {
            endorsement,
        } as IEndorsementViewModel);
    }

    /**
     * Makes a delete request to delete the endorsement by its id.
     * @param id The id of the endorsement to delete.
     */
    delete(id: string) {
        return this.service.delete<
            IApiResponse<IEndorsementViewModel>
        >(ApiConstant.url + 'endorsements/' + id);
    }
}
