<button
    aria-label="Dismiss"
    class="dialog-close"
    mat-dialog-close
    tabindex="-1"
></button>

<h3 class="mat-dialog-title">Location Changed</h3>

<div mat-dialog-content>
    <ul class="form">
        <li class="form__item">
            <p>
                It would appear your location has changed since it was last
                updated. Would you like to update your location?
            </p>
        </li>
        <li class="form__item">
            <label class="input-label" for="charge_refund_count"
                >Previous location</label
            >
            <div>{{ data.previousLocation.name }}</div>
        </li>

        <li class="form__item">
            <label class="input-label" for="charge_refund_count"
                >Detected location</label
            >
            <div>{{ data.newLocation.name }}</div>
        </li>
    </ul>

    <small
        >This can be changed at any time from your profile, click on your
        details at the top of the sidebar.</small
    >
</div>

<div class="btns btns--horizontal" mat-dialog-actions align="end">
    <button type="button" class="btn" [mat-dialog-close]>
        Cancel
    </button>

    <button
        type="button"
        class="btn btn--primary btn--right"
        [mat-dialog-close]="true"
    >
        Update
    </button>
</div>
