<page-header
    *ngIf="!pPageHeader; else customHeader"
    [pTitle]="pTitle"
    [pSubTitle]="pSubTitle"
    [pHeaderActions]="pHeaderActions || _pHeaderActions"
    [pHeaderPrimaryActions]="pHeaderPrimaryActions || _pHeaderPrimaryActions"
    [pTitleExtras]="pTitleExtras || _pHeaderExtras"
>
</page-header>

<div class="page__content">
    <ng-content></ng-content>
</div>

<ng-template #customHeader>
    <ng-container *ngTemplateOutlet="pPageHeader"></ng-container>
</ng-template>
