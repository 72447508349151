import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionMenuModule } from 'platform-ui/action-menu';
import { StackModule } from 'platform-ui/stack';
import { PageHeaderComponent } from './components/page-header.component';
import { PageActionsDefDirective } from './directives/page-actions-def.directive';
import { PagePrimaryActionsDefDirective } from './directives/page-primary-actions-def.directive';
import { PageExtrasDefDirective } from './directives/page-extras-def.directive';
import { PageComponent } from './page.component';

@NgModule({
    declarations: [
        PageComponent,
        PageHeaderComponent,
        PageActionsDefDirective,
        PagePrimaryActionsDefDirective,
        PageExtrasDefDirective,
    ],
    exports: [
        PageComponent,
        PageHeaderComponent,
        PageActionsDefDirective,
        PagePrimaryActionsDefDirective,
        PageExtrasDefDirective,
    ],
    imports: [CommonModule, ActionMenuModule, StackModule, LayoutModule],
})
export class PageModule {}
