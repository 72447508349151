import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResponse, Users } from 'county-api';
import { ApiConstant } from '../configs/api.constant';
import { ApiHttpClientService } from '../universal/api/apiHttpClient.service';
import { IRequestPaging } from '../universal/paging';
import { INotificationViewModel, INotificationSequentialViewModel } from 'county-api/users';

@Injectable()
export class CurrentUserService extends ApiHttpClientService {
    constructor(private service: HttpClient) {
        super();
    }

    get() {
        return this.service.get<IApiResponse<Users.IUserViewModel>>(
            `${ApiConstant.url}users/me`,
        );
    }

    getUnreadNotifications() {
        return this.service.get<
            IApiResponse<INotificationViewModel>
        >(`${ApiConstant.url}users/me/notifications/unread`);
    }

    getNotifications(data: IRequestPaging) {
        return this.service.get<
            IApiResponse<INotificationViewModel>
        >(`${ApiConstant.url}users/me/notifications`, {
            params: this.filterRequestParams(data),
        });
    }

    getSequentialNotifications(index: number) {
        return this.service.get<
            IApiResponse<INotificationSequentialViewModel>
        >(`${ApiConstant.url}users/me/notifications/sequential`, {
            params: this.filterRequestParams({
                notification_index: index,
                index_sort: true,
            }),
        });
    }

    /**
     * Mark a single notification as read.
     * @param id Id of the notification to read.
     */
    postReadNotification(id: string) {
        return this.postReadNotifications([id]);
    }

    /**
     * Mark a collection of notifications as read.
     * @param ids Ids of the notifications to mark as read.
     */
    postReadNotifications(ids: string[]) {
        return this.service.post<IApiResponse<INotificationSequentialViewModel>>(
            `${ApiConstant.url}users/me/notifications/read`,
            {
                notification_ids: ids,
            },
        );
    }
}
