<input
    class="input-group__input input-datetime-picker__date"
    type="text"
    [matDatepicker]="picker"
    [(ngModel)]="dateValue"
    [min]="minDate"
    [max]="maxDate"
    [disabled]="disabled"
    [placeholder]="placeholder"
    #elementToFocus
    #input="ngModel"
    (click)="openCalendar(picker)"
/>
<label class="input-label input-side-label" *ngIf="hideCalendar && getReadableDate != ''">{{ getReadableDate }}</label>

<mat-datepicker class="custom-date-time-picker-calendar" [startAt]="dateValue" #picker (closed)="eventCloseHandler(elementToFocus)">
    <mat-hint>DD/MM/YYYY</mat-hint>
</mat-datepicker>

<div class="input-group__append" [style.visibility]="datepickerVisibility">
    <div class="btn btn--icon input-group__btn" (click)="openCalendar(picker)">
        <fa-icon icon="calendar-alt" class="fa-fw" transform="grow-2"></fa-icon>
    </div>

    <time-picker-input
        class="input-group__select"
        [(ngModel)]="timeValue"
        [minuteUnits]="minuteUnits"
        *ngIf="!hideTimePicker"
        [disabled]="disabled"
    ></time-picker-input>
</div>
