import { Component, Input } from '@angular/core';
import { Users } from 'county-api';

/**
 * The user list item component used to output each user.
 */
@Component({
    selector: 'users-list-item',
    templateUrl: './users-list-item.component.html',
})
export class UsersListItemComponent {
    /**
     * The user data input from the parent.
     */
    @Input()
    user: Users.IUser;
}
