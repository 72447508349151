<div class="widget">
    <div class="widget__content" style="text-align: center;">
        <div class="office-links">
            <ul class="office-links__list list-flex list-flex--nowrap">
                <li class="office-links__item" *ngFor="let link of links">
                    <a
                        [class]="
                            'office-links__link office-links__link--' +
                            link.icon
                        "
                        [href]="link.url"
                        [attr.target]="'_' + link.title"
                    >
                        <span
                            [class]="
                                'office-links__icon ms-Icon ms-Icon--' +
                                link.icon
                            "
                        ></span>
                        <span class="office-links__title">{{
                            link.title
                        }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
