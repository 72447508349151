import { Locations, Users } from 'county-api';

export interface UserLocationChange {
    previousLocation: Locations.ILocation;
    newLocation: Locations.ILocation;
    shouldCheck: boolean;
}

export class LogIn {
    static readonly type = '[User] Log In';
}

export class LogInFailed {
    static readonly type = '[User] Log In Failed';
}

export class LoggedIn {
    static readonly type = '[User] Logged In';

    constructor(public payload: Users.IUser) {}
}

export class UpdatedUser {
    static readonly type = '[User] Update';

    constructor(public payload: Users.IUser) {}
}

export class LoggedInFailed {
    static readonly type = '[User] Logged In Failed';
}

export class LocationUpdate {
    static readonly type = '[User] Update location';

    constructor(public payload: number) {}
}
export class LocationUpdated {
    static readonly type = '[User] Updated location';

    constructor(public payload: number) {}
}
