import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customers } from 'county-api';
import { PersonOccupationStatus } from 'county-api/enums';
import { filter } from 'rxjs/operators';
import { NotificationsService } from '../../../universal/notifications/notifications.service';
import { PeopleService } from '../people.service';

/**
 * The person add component, adds a new person.
 */
@Component({
    selector: 'person-add',
    templateUrl: './person-add.component.html',
})
export class PersonAddComponent implements OnInit {
    /**
     * The person data, occupation status defaulted to unemployed.
     */
    person = {
        occupation_status: PersonOccupationStatus.Unemployed,
    } as Customers.IPerson;

    returnUrl: string;

    /**
     * Constructs injected angular dependencices.
     * @param peopleService The people service to call the post request to add a person.
     * @param notificationsService The notification service used to add notifications on submit.
     * @param router Router service used to update the url with the new query strings.
     */
    constructor(
        private peopleService: PeopleService,
        private notificationsService: NotificationsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    /**
     * Gets the params from the current url and sets it to return url.
     */
    ngOnInit() {
        this.route.queryParams
            .pipe(filter(params => params.returnUrl))
            .subscribe(params => {
                this.returnUrl = params.returnUrl;
            });
    }

    /**
     * Subscribes the new person.
     * @param $event The event passed to the submit.
     */
    submit($event) {
        this.peopleService.create(this.person).subscribe(
            response => {
                // Notifies the user on succes or error.
                this.notificationsService.success(
                    'Customer sucessfully created.',
                );

                let redirectUrl = `/customers/people/${response.result.person.id}`;
                if (this.returnUrl) {
                    redirectUrl =
                        this.returnUrl +
                        '?person_id=' +
                        response.result.person.id;
                }
                this.router.navigateByUrl(redirectUrl);
            },
            e => {
                this.notificationsService.error(
                    'There was an error creating the customer.',
                );
            },
        );
    }

    /**
     * Navigates back to the customer add page.
     */
    cancelClick() {
        if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl);
        } else {
            this.router.navigateByUrl('/customers/add');
        }
    }
}
