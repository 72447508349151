<topbar>
    <h1 topbar-title>Add Company</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="wrapper">
            <form (validSubmit)="create()" [formGroup]="addCompanyForm">
                <div class="content-section customer-form">
                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">
                                Company Details
                            </h3>
                        </div>

                        <div class="content-card__body">
                            <ul class="form">
                                <li class="form__item">
                                    <label
                                        class="input-label"
                                        for="company_name"
                                    >
                                        Company Name
                                    </label>
                                    <input
                                        type="text"
                                        id="company_name"
                                        name="company_name"
                                        titlecase
                                        formControlName="name"
                                    />
                                    <div
                                        class="input-errors"
                                        [ngxErrors]="addCompanyForm.get('name')"
                                    >
                                        <label
                                            for="company_name"
                                            class="input-error"
                                            *ngxErrorDefault
                                        >
                                            Please enter the company name.
                                        </label>
                                    </div>
                                </li>

                                <li class="form__item">
                                    <label class="input-label">
                                        Company Type
                                    </label>
                                    <div class="input-select">
                                        <div class="input-select">
                                            <mat-select
                                                id="company_registered_type"
                                                name="company_registered_type"
                                                formControlName="registered_type"
                                            >
                                                <mat-option></mat-option>
                                                <mat-option
                                                    *ngFor="
                                                        let type of companyTypes
                                                    "
                                                    [value]="type.id"
                                                >
                                                    {{ type.label }}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                    </div>

                                    <div
                                        class="input-errors"
                                        [ngxErrors]="
                                            addCompanyForm.get(
                                                'registered_type'
                                            )
                                        "
                                    >
                                        <label
                                            for="company_registered_type"
                                            class="input-error"
                                            ngxError="required"
                                        >
                                            Please select a registered type.
                                        </label>
                                        <label
                                            for="company_registered_type"
                                            class="input-error"
                                            ngxError="invalidValue"
                                        >
                                            STOP! if the person is acting as a
                                            non-registired business, you're in
                                            the
                                            <a
                                                class="link"
                                                [routerLink]="[
                                                    '/customers',
                                                    'people',
                                                    'add'
                                                ]"
                                            >
                                                wrong place
                                            </a>
                                        </label>
                                    </div>
                                </li>

                                <li class="form__item">
                                    <label
                                        class="input-label"
                                        for="company_registered_number"
                                    >
                                        Company Registered Number
                                        <span class="input-optional">
                                            Optional
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        id="company_registered_number"
                                        name="company_registered_number"
                                        formControlName="registered_number"
                                    />
                                    <span class="input-comment">
                                        Companies House information will be
                                        gathered on save.
                                    </span>

                                    <div
                                        class="input-errors"
                                        [ngxErrors]="
                                            addCompanyForm.get(
                                                'registered_number'
                                            )
                                        "
                                    >
                                        <label
                                            for="company_registered_number"
                                            class="input-error"
                                            *ngxErrorDefault
                                        >
                                            Company Registered Number must be 10
                                            characters or less.
                                        </label>
                                    </div>
                                </li>

                                <li class="form__item">
                                    <label
                                        class="input-label"
                                        for="company_vat_number"
                                    >
                                        VAT Number
                                        <span class="input-optional">
                                            Optional
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        id="company_vat_number"
                                        name="company_vat_number"
                                        formControlName="vat_number"
                                    />
                                    <div
                                        class="input-errors"
                                        [ngxErrors]="
                                            addCompanyForm.get('vat_number')
                                        "
                                    >
                                        <label
                                            for="company_vat_number"
                                            class="input-error"
                                            *ngxErrorDefault
                                        >
                                            Please enter a valid vat number.
                                        </label>
                                        <label
                                            for="company_vat_number"
                                            ngxError="minlength"
                                            class="input-error"
                                        >
                                            Please enter a number at least 8
                                            characters long.
                                        </label>
                                        <label
                                            for="company_vat_number"
                                            ngxError="maxlength"
                                            class="input-error"
                                        >
                                            Please enter a number with no more
                                            than 12 characters.
                                        </label>
                                    </div>
                                </li>

                                <li class="form__item">
                                    <label
                                        class="input-label"
                                        for="company_established"
                                    >
                                        Year Established
                                    </label>
                                    <input
                                        type="number"
                                        id="company_established"
                                        name="company_established"
                                        formControlName="established"
                                    />

                                    <div
                                        class="input-errors"
                                        [ngxErrors]="
                                            addCompanyForm.get('established')
                                        "
                                    >
                                        <label
                                            for="company_established"
                                            ngxError="required"
                                            class="input-error"
                                        >
                                            Please enter an established year.
                                        </label>
                                        <label
                                            for="company_established"
                                            [ngxError]="[
                                                'minlength',
                                                'maxlength'
                                            ]"
                                            class="input-error"
                                        >
                                            Please enter only 4 characters.
                                        </label>
                                    </div>
                                </li>

                                <li class="form__item">
                                    <label
                                        class="input-label"
                                        for="company_nature_of_business"
                                    >
                                        Nature Of Business
                                    </label>
                                    <input
                                        type="text"
                                        id="company_nature_of_business"
                                        name="company_nature_of_business"
                                        titlecase
                                        formControlName="nature_of_business"
                                    />
                                    <div
                                        class="input-errors"
                                        [ngxErrors]="
                                            addCompanyForm.get(
                                                'nature_of_business'
                                            )
                                        "
                                    >
                                        <label
                                            for="company_nature_of_business"
                                            ngxError="required"
                                            class="input-error"
                                        >
                                            Please select a registered type.
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">
                                Company Contact Details
                            </h3>
                        </div>

                        <div class="content-card__body">
                            <customers-contacts-input
                                name="company_contact_details"
                                formControlName="contact_details"
                            ></customers-contacts-input>

                            <div
                                class="input-errors"
                                [ngxErrors]="
                                    addCompanyForm.get('contact_details')
                                "
                            >
                                <label
                                    for="company_contact_details"
                                    ngxError="required"
                                    class="input-error"
                                    >Please enter a valid contact.</label
                                >
                                <label
                                    for="company_contact_details"
                                    ngxError="primaryContact"
                                    class="input-error"
                                    >Please enter one primary contact.</label
                                >
                                <label
                                    for="company_contact_details"
                                    ngxError="duplicateContacts"
                                    class="input-error"
                                    >You can not have duplicate contacts.</label
                                >
                            </div>
                        </div>
                    </div>

                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">
                                Company Address Details
                            </h3>
                        </div>

                        <div class="content-card__body">
                            <customers-address-input
                                name="company_addressess"
                                formControlName="addresses"
                            ></customers-address-input>

                            <div
                                class="input-errors"
                                [ngxErrors]="addCompanyForm.get('addresses')"
                            >
                                <label
                                    for="company_addressess"
                                    ngxError="required"
                                    class="input-error"
                                    >Please enter a valid address.</label
                                >
                            </div>
                        </div>
                    </div>

                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">
                                Company Employees
                            </h3>
                        </div>

                        <div class="content-card__body">
                            <company-people-add-input
                                ngDefaultControl
                                name="company_people"
                                formControlName="people"
                            ></company-people-add-input>

                            <div
                                class="input-errors"
                                [ngxErrors]="addCompanyForm.get('people')"
                            >
                                <label
                                    for="company_people"
                                    ngxError="required"
                                    class="input-error"
                                >
                                    Please enter a valid person.
                                </label>
                            </div>
                        </div>
                    </div>

                    <div
                        class="btns btns--horizontal btns--right content-form__action"
                    >
                        <a class="btn" (click)="cancelClick()">Cancel</a>
                        <input
                            type="submit"
                            value="Create Company"
                            class="btn btn--primary"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
