import { NgModule } from '@angular/core';
import { CardModule } from 'platform-ui/card';
import { ItemListModule } from 'platform-ui/item-list';
import { LayoutModule } from '../layout/layout.module';
import { UniversalModule } from '../universal/universal.module';
import { UsersModule } from '../users/users.module';
import {
    ActivitiesRoutingModule,
    routedComponents
} from './activities-routing.module';
import { ActivitiesService } from './activities.service';
import { ActivitiesShortListComponent } from './list/activities-short-list.component';

/**
 * The module to import and declare neccessary components and services for the
 * activities.
 */
@NgModule({
    imports: [
        UniversalModule,
        LayoutModule,
        UsersModule,
        ActivitiesRoutingModule,

        CardModule,
        ItemListModule,
    ],
    declarations: [ActivitiesShortListComponent, ...routedComponents],
    providers: [ActivitiesService],
    exports: [ActivitiesShortListComponent],
})
export class ActivitiesModule {}
