import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { SpinnerModule } from 'platform-ui/spinner';
import { ItemListItemComponent } from './components/item-list-item.component';
import { ItemListActionsDefDirective } from './directives/item-list-actions-def.directive';
import { ItemListDefDirective } from './directives/item-list-def.directive';
import { ItemListHeaderDefDirective } from './directives/item-list-header-def.directive';
import { ItemListComponent } from './item-list.component';

@NgModule({
    declarations: [
        ItemListComponent,
        ItemListDefDirective,
        ItemListHeaderDefDirective,
        ItemListActionsDefDirective,
        ItemListItemComponent,
    ],
    exports: [
        ItemListComponent,
        ItemListDefDirective,
        ItemListHeaderDefDirective,
        ItemListActionsDefDirective,
        ItemListItemComponent,
    ],
    imports: [CommonModule, MatCheckboxModule, RouterModule, SpinnerModule],
})
export class ItemListModule {}
