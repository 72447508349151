import { Component, Input, OnInit } from '@angular/core';
import { Users } from 'county-api';
import {
    ActivitiesService,
    IActivityRendered,
} from '../../../activities/activities.service';
import { UsersService } from '../../users.service';

/**
 * The user activities component, used to output the most recent activities on
 * the users details page.
 */
@Component({
    selector: 'user-activities',
    templateUrl: './user-activities.component.html',
})
export class UserActivitiesComponent implements OnInit {
    /**
     * The array of the users activities.
     */
    activities: IActivityRendered[] = [];

    /**
     * The user details input from the parent.
     */
    @Input()
    user: Users.IUser;

    /**
     * Detemines the loading state of the page.
     */
    loading = 0;

    /**
     * The current page number.
     */
    page = 1;

    /**
     * The total number of pages.
     */
    totalPages = 0;

    /**
     * Constructs injected angular dependencies.
     * @param usersService The user service used to get the users activities.
     */
    constructor(
        private usersService: UsersService,
        private activitiesService: ActivitiesService,
    ) {}

    /**
     * Calls the get activities method.
     */
    ngOnInit() {
        this.getActivivties();
    }

    /**
     * Gets all the activites for the desired user by there id.
     */
    getActivivties() {
        this.loading++;

        this.usersService
            .getActivities(this.user.id, { page: this.page })
            .pipe(this.activitiesService.mapActivities())
            .subscribe(
                response => {
                    this.activities = response.result.activities;
                    this.totalPages = response.result.paging.total_pages;
                },
                () => {},
                () => this.loading--,
            );
    }
}
