<div class="user-locations-change">
    <div>
        <button aria-label="Dismiss" class="dialog-close" [mat-dialog-close]></button>
    </div>
    <div class="title-container">
        <h3 class="mat-dialog-title">Update {{ isBase ? 'Base Location' : 'Location' }}</h3>
    </div>

    <div *ngIf="loading < 1" mat-dialog-content>
        <ul class="form">
            <li class="form__item">
                <label class="input-label" for="charge_refund_count"
                    >Location</label
                >

                <div class="input-select">
                    <mat-select
                        name="location"
                        id="location"
                        [(ngModel)]="locationId"
                    >
                        <mat-option></mat-option>
                        <mat-option
                            [value]="location.id"
                            *ngFor="let location of locations$ | async"
                            >{{ location.name }}
                        </mat-option>
                    </mat-select>
                </div>
            </li>
        </ul>
    </div>

    <loader *ngIf="loading > 0"></loader>

    <div class="btns btns--horizontal" mat-dialog-actions>
        <button type="button" class="btn" mat-dialog-close>
            Cancel
        </button>

        <button
            type="button"
            class="btn btn--primary btn--right"
            [mat-dialog-close]="locationId"
        >
            Update
        </button>
    </div>
</div>