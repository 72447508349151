<button
    aria-label="Dismiss"
    class="dialog-close"
    mat-dialog-close
    tabindex="-1"
></button>

<h3 mat-dialog-title>Cancel</h3>

<form (validSubmit)="submit()">
    <div mat-dialog-content>
        <p>
            Any refund due to the customer will depend on the terms at time of
            payment together with reason for cancellation.
        </p>

        <div class="dialog__divider"></div>
        <ul class="form">
            <li class="form__item">
                <label class="input-label">Cancellation Type</label>

                <div class="input-select">
                    <mat-select
                        id="cancellation_type"
                        name="cancellation_type"
                        [(ngModel)]="result.type"
                        required
                        #cancellationType="ngModel"
                    >
                        <mat-option></mat-option>
                        <mat-option
                            [value]="value.id"
                            *ngFor="let value of bookingCancellationTypeItems"
                            >{{ value.label }}</mat-option
                        >
                    </mat-select>
                </div>

                <div class="input-errors" [ngxErrors]="cancellationType">
                    <label
                        for="cancellation_type"
                        ngxError="required"
                        class="input-error"
                    >
                        Please select a cancellation type.
                    </label>
                </div>
            </li>
        </ul>

        <div [ngSwitch]="result.type" *ngIf="booking.payment_type">
            <div *ngSwitchCase="bookingCancellationTypes.NoShow">
                <p>
                    The deposit and any additional extras will be returned to
                    the customer.
                </p>
            </div>

            <div
                *ngSwitchCase="
                    result.type == bookingCancellationTypes.CustomerCancelled ||
                    result.type == bookingCancellationTypes.CustomerChange
                        ? result.type
                        : ''
                "
            >
                <div
                    [ngSwitch]="
                        booking.payment_type == bookingPaymentTypes.OnlineSave
                    "
                >
                    <div *ngSwitchCase="true">
                        <p>
                            The deposit and any additional extras will be
                            returned to the customer.
                        </p>
                    </div>
                    <div *ngSwitchCase="false">
                        <p>
                            If less than 3 days to the rental the deposit and
                            any additional extras will be returned to the
                            customer, otherwise the deposit and full rental
                            charge.
                        </p>
                    </div>
                </div>
            </div>

            <div *ngSwitchCase="bookingCancellationTypes.CountyCancelled">
                <p>
                    The deposit and full rental charge will be returned to the
                    customer.
                </p>
            </div>
        </div>
    </div>

    <div class="btns btns--horizontal" mat-dialog-actions>
        <button type="button" class="btn" mat-dialog-close>
            Cancel
        </button>

        <input
            type="submit"
            value="Confirm"
            class="btn btn--primary btn--right"
        />
    </div>
</form>
