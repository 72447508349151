import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notes } from 'county-api';
import { merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { NotificationsService } from '../../universal/notifications/notifications.service';
import {
    IPagingParams,
    IRequestPaging,
    SimplePagingController,
} from '../../universal/paging';
import { NotesService } from '../notes.service';

@Component({
    selector: 'notes-list',
    templateUrl: './notes-list.component.html',
    styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent extends SimplePagingController {
    private _prefix: string;

    @Input()
    set prefix(value: string) {
        if (this._prefix != value) {
            this._prefix = value;
            this.reload(true);
        }
    }

    get prefix() {
        return this._prefix;
    }

    private _id: string;

    @Input()
    set id(value: string) {
        if (this._id != value) {
            this._id = value;
            this.reload(true);
        }
    }

    get id() {
        return this._id;
    }

    @Output()
    noteAdded = new EventEmitter<Notes.INote>();

    @Output()
    noteDeleted = new EventEmitter<Notes.INote>();

    @Output()
    notesChanged = new EventEmitter<any>();

    notes: Notes.INote[] = [];

    showAddForm = false;

    constructor(
        private notesService: NotesService,
        private notificationsService: NotificationsService,
    ) {
        super();

        merge(this.noteAdded, this.noteDeleted)
            .pipe(
                tap(() => this.notesChanged.emit()),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();
    }

    updateLocalParams(params: IPagingParams): IRequestPaging {
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
        };

        return this.pagingData;
    }

    updateData(data: IRequestPaging) {
        return this.notesService.getByUri(this.prefix, this.id, data).pipe(
            tap(response => {
                this.notes = response.result.notes;
                this.totalItems = response.result.paging.total;
            }),
        );
    }

    deleteNote(id: string) {
        this.pagingLoading = true;
        this.notesService
            .delete(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
                this.reload(true);
                this.notificationsService.success(
                    'Successfully deleted a note to this incident.',
                );
                this.noteDeleted.emit(response.result.note);
            });
    }

    addNoteSubmit(note: Notes.INote) {
        this.pagingLoading = true;
        this.showAddForm = false;
        note.uri = `${this.prefix}:${this.id}`;

        // Make the service call to add the note, if success reload notes else
        // we make the add form appear again with the new notes details.
        this.notesService
            .create(note)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: response => {
                    this.reload(true);

                    this.notificationsService.success(
                        'Successfully added a note to this incident.',
                    );
                    this.noteAdded.emit(response.result.note);
                },
                error: () => {
                    this.notificationsService.error(
                        'There was an error adding a note to this incident.',
                    );
                },
                complete: () => {
                    this.reload(true);
                },
            });
    }
}
