import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import isString from 'lodash/isString';

@Injectable()
export class TitleService {
    suffix = 'Platform';

    separator = ' - ';

    private title: string[] = [];

    constructor(private titleService: Title) {}

    /**
     * Build the title including the suffix and prefix.
     */
    getTitle(): string {
        let title = this.title.join(this.separator);

        if (this.suffix) {
            title += (title.length > 0 ? this.separator : '') + this.suffix;
        }

        return title;
    }

    /**
     * Set the title with a new value.
     * @param val Array of strings or string to set the title to.
     */
    setTitle(val: string[] | string) {
        if (isString(val)) {
            val = [val];
        }

        this.title = val;
        this.titleService.setTitle(this.getTitle());
    }

    /**
     * Add to the existing title.
     * @param val Array of strings or string to add to the start of the title.
     */
    prependTitle(val: string[] | string) {}
}
