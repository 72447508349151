import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { StackModule } from 'platform-ui/stack';
import { CardComponent } from './card.component';
import { CardSectionComponent } from './components/card-section.component';
import { CardActionsDirective } from './directives/card-actions.directive';
import { CardFooterDirective } from './directives/card-footer.directive';
import { CardMetaDataDirective } from './directives/card-meta-data.directive';

@NgModule({
    declarations: [
        CardComponent,
        CardSectionComponent,
        CardActionsDirective,
        CardFooterDirective,
        CardMetaDataDirective,
    ],
    exports: [
        CardComponent,
        CardSectionComponent,
        CardActionsDirective,
        CardFooterDirective,
        CardMetaDataDirective,
    ],
    imports: [CommonModule, MatMenuModule, StackModule],
})
export class CardModule {}
