import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../universal/api/apiHttpClient.service';
import { ApiConstant } from '../../configs/api.constant';
import { IRequestPaging } from '../../universal/paging';
import { IPeopleViewModel, IPerson, IPersonViewModel } from 'county-api/customers';
import { IApiResponse } from 'county-api/responses';

/**
 * Simple service to interact with people api endpoints.
 */
@Injectable()
export class PeopleService extends ApiHttpClientService {
    /**
     * Consructs injected angular dependencies.
     * @param service The service used to get, post, put and delete people.
     */
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Get a collection of people based on the passed in params.
     * @param params Query params to filter vehicles result, null values will be removed.
     */
    get(params: IRequestPaging) {
        return this.service.get<IApiResponse<IPeopleViewModel>>(
            ApiConstant.url + 'people',
            {
                params: this.filterRequestParams(params),
            },
        );
    }

    /**
     * Get the person based on its passed in id.
     * @param id The id of the person to call for.
     */
    getById(id: string) {
        return this.service.get<IApiResponse<IPersonViewModel>>(
            ApiConstant.url + `people/${id}`,
        );
    }

    /**
     * Recieves the person data and posts to the api.
     * @param person The data passed to create the new person.
     */
    create(person: IPerson) {
        return this.service.post<IApiResponse<IPersonViewModel>>(
            ApiConstant.url + 'people',
            {
                person,
            } as IPersonViewModel,
        );
    }

    /**
     * Recieves the person data and puts to the api.
     * @param person The data passed to updated the new person.
     */
    update(id: string, person: IPerson) {
        return this.service.put<IApiResponse<IPersonViewModel>>(
            ApiConstant.url + 'people/' + id,
            {
                person,
            } as IPersonViewModel,
        );
    }
}
