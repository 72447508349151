import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.debug === false && environment.production) {
    enableProdMode();
}

function bootstrap(boot: () => Promise<NgModuleRef<any>>) {
    if (module.hot) {
        if (window.self !== window.top) {
            module.hot.decline();
        } else {
            return import('@ngxs/hmr-plugin').then(plugin => {
                return plugin.hmr(module, boot);
            });
        }
    }

    return boot();
}

function main() {
    // Handle redirecting old county-labs links to the new platform. This will also
    // remove the hashbang from the url.
    const locationHref = window.location.href;
    if (locationHref.indexOf('county-labs.azurewebsites.net') != -1) {
        try {
            window.location.replace(
                locationHref
                    .replace(
                        'county-labs.azurewebsites.net',
                        'platform.county-rental.co.uk',
                    )
                    .replace('#/', ''),
            );
        } catch (e) {}
    } else {
        bootstrap(() => platformBrowserDynamic().bootstrapModule(AppModule))
            // tslint:disable-next-line
            .catch((err: Error) => console.log(err));
    }
}

// Remove service worker - TODO: Add production url when all the dev testing is complete
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
            for(let registration of registrations) {
               if(registration.active.scriptURL.includes('https://county-labs-v5.azurewebsites.net')){ registration.unregister(); }
            }
        });
}

main();
