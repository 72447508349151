import { Directive, Input, HostListener } from '@angular/core';

/**
 * The print element directive used to output and print the passed in data.
 */
@Directive({
    selector: '[printElement]',
})
export class PrintElementDirective {
    /**
     * The id of the passed in element from the parent.
     */
    @Input()
    elementId: string;

    /**
     * The click to get the template for the print pop up.
     */
    @HostListener('click')
    printClick() {
        
        const element = document.getElementById(this.elementId);

        if (element) {
            const popupWindow = window.open(
                '',
                '_blank',
                'width=700,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no',
            );

            popupWindow.onload = function(){
                popupWindow.document.open();

                popupWindow.document.write('<html><head><title>Print Dialog - County Labs</title><link rel="stylesheet" type="text/css"  href="/assets/css/print-page.css"></head><body>');
                
                popupWindow.document.write(element.innerHTML);

                popupWindow.document.write('</body></html>');

                popupWindow.document.close();

                var btn = popupWindow.document.createElement("button");
                btn.innerHTML = "Close Window";
                btn.className = "print-close-banner";
                btn.onclick = function() {
                    popupWindow.close();
                };

                var printBtn = popupWindow.document.createElement("button");
                printBtn.innerHTML = "Print";
                printBtn.className = "print-print-banner";
                printBtn.onclick = function() {
                    popupWindow.print();
                };

                popupWindow.document.body.appendChild(btn);
                popupWindow.document.body.appendChild(printBtn);

                setTimeout(() => {
                    popupWindow.print();
                }, 1000);
            };
        }
    }
}
