import { Component } from '@angular/core';

/**
 * The component for the customers add page.
 */
@Component({
    selector: 'customer-add',
    templateUrl: './customer-add.component.html',
})
export class CustomerAddComponent {}
