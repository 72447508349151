import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notes } from 'county-api';

@Component({
    selector: 'notes-list-add-form',
    templateUrl: './notes-list-add-form.component.html',
})
export class NotesListAddFormComponent {
    @Input()
    note: Notes.INote = {} as Notes.INote;

    @Output()
    noteSubmit = new EventEmitter<Notes.INote>();

    @Output()
    noteCancel = new EventEmitter<any>();

    submit() {
        this.noteSubmit.emit(this.note);
    }

    cancelClick() {
        this.noteCancel.emit();
    }
}
