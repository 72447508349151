import { BreakpointObserver } from '@angular/cdk/layout';
import {
    Component,
    Input,
    OnDestroy,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'page-header',
    templateUrl: './page-header.component.html',
    host: {
        '[class.page-header]': 'true',
    },
})
export class PageHeaderComponent implements OnDestroy {
    @Input()
    pTitle: string;

    @Input()
    pSubTitle: string;

    @Input()
    pTitleExtras: TemplateRef<any>;

    @Input()
    pHeaderActions: TemplateRef<any>;

    @Input()
    pHeaderPrimaryActions: TemplateRef<any>;

    _shouldCollapse$: Observable<boolean>;

    private _destroyed = new Subject<void>();

    constructor(breakpointObserver: BreakpointObserver) {
        this._shouldCollapse$ = breakpointObserver
            .observe('(max-width: 720px)')
            .pipe(
                map(r => r.matches),
                takeUntil(this._destroyed),
            );
    }
    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }
}
