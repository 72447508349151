import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';
import { ImagePreviewOverlayRef } from './image-preview-overlay-ref';
import { ImagePreviewOverlayComponent } from './image-preview-overlay.component';
import { IMAGE_PREVIEW_DIALOG_DATA } from './image-preview-overlay.tokens';

export interface ImagePreviewImage {
    name: string;
    url: string;
}

export interface ImagePreviewData {
    image?: ImagePreviewImage;

    images?: ImagePreviewImage[];
}

interface ImagePreviewDialogConfig {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
    closeOnNavigation?: boolean;
    data?: ImagePreviewData;
}

const DEFAULT_CONFIG: ImagePreviewDialogConfig = {
    hasBackdrop: true,
    backdropClass: 'cdk-overlay-solid-backdrop',
    panelClass: 'image-preview-overlay',

    closeOnNavigation: true,

    data: null,
};

@Injectable()
export class ImagePreviewOverlayService {
    constructor(private injector: Injector, private overlay: Overlay) {}

    open(config: ImagePreviewDialogConfig = {}) {
        // Override default configuration
        const dialogConfig = { ...DEFAULT_CONFIG, ...config };

        // Returns an OverlayRef which is a PortalHost
        const overlayRef = this.createOverlay(dialogConfig);

        // Instantiate remote control
        const dialogRef = new ImagePreviewOverlayRef(overlayRef);

        const overlayComponent = this.attachDialogContainer(
            overlayRef,
            dialogConfig,
            dialogRef,
        );

        overlayRef.backdropClick().subscribe(_ => dialogRef.close());

        return dialogRef;
    }

    private createOverlay(config: ImagePreviewDialogConfig) {
        const overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    }

    private attachDialogContainer(
        overlayRef: OverlayRef,
        config: ImagePreviewDialogConfig,
        dialogRef: ImagePreviewOverlayRef,
    ) {
        const injector = this.createInjector(config, dialogRef);

        const containerPortal = new ComponentPortal(
            ImagePreviewOverlayComponent,
            null,
            injector,
        );
        const containerRef: ComponentRef<ImagePreviewOverlayComponent> = overlayRef.attach(
            containerPortal,
        );

        return containerRef.instance;
    }

    private createInjector(
        config: ImagePreviewDialogConfig,
        dialogRef: ImagePreviewOverlayRef,
    ): PortalInjector {
        const injectionTokens = new WeakMap();

        injectionTokens.set(ImagePreviewOverlayRef, dialogRef);
        injectionTokens.set(IMAGE_PREVIEW_DIALOG_DATA, config.data);

        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(config: ImagePreviewDialogConfig): OverlayConfig {
        const positionStrategy = this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically();

        return new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy,
            disposeOnNavigation: config.closeOnNavigation,
        });
    }
}
