import { NgModule } from '@angular/core';
import { LayoutModule } from '../layout/layout.module';
import { UniversalModule } from '../universal';
import { EndorsementAddDialogComponent } from './add/endorsement-add-dialog.component';
import {
    EndorsementRoutingModule,
    routedComponents,
} from './endorsement-routing.module';
import { EndorsementService } from './endorsement.service';

/**
 * The module to import and declare neccessary components and services for the
 * endorsements.
 */
@NgModule({
    imports: [UniversalModule, LayoutModule, EndorsementRoutingModule],
    declarations: [...routedComponents, EndorsementAddDialogComponent],
    providers: [EndorsementService],
})
export class EndorsementModule {}
