export class DashboardInit {
    static readonly type = '[Dashboard] Init';
}

export class UpdateDashboardLocation {
    static readonly type = '[Dashboard] Update location';

    constructor(public payload: number) {}
}

export enum UpdateDashboardTriggerType {
    All,
    Revenue = 5,
}

export class UpdateDashboardTrigger {
    static readonly type = '[Dashboard] Update trigger';

    constructor(public payload: UpdateDashboardTriggerType) {}
}
