import { HttpParams } from '@angular/common/http';
import isNil from 'lodash/isNil';
import { IRequestPaging } from '../paging';

export function filterRequestParams(params: IRequestPaging | any) {
    let httpParams = new HttpParams();

    for (const p in params) {
        if (params.hasOwnProperty(p)) {
            const val = params[p];
            if (val !== '' && !isNil(val)) {
                httpParams = httpParams.set(p, val);
            }
        }
    }

    return httpParams;
}
