<button
    aria-label="Dismiss"
    class="dialog-close"
    mat-dialog-close
    tabindex="-1"
></button>

<ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="0">
        <h3 class="dialog__heading" mat-dialog-title>Select Action</h3>

        <div class="dialog-selection" mat-dialog-content>
            <!-- <div class="dialog-selection__item" *hasPermission="'admin'">
                <a class="dialog-selection__link" (click)="changeStep(1)">
                    <div class="dialog-selection__title">Enter Targets</div>
                    <div class="dialog-selection__details">
                        Enter monthly targets.
                    </div>
                    <fa-icon
                        class="dialog-selection__chevron"
                        icon="chevron-right"
                    ></fa-icon>
                </a>
            </div> -->
            <div class="dialog-selection__item">
                <a class="dialog-selection__link" (click)="changeStep(2)">
                    <div class="dialog-selection__title">Upload</div>
                    <div class="dialog-selection__details">
                        Upload performance data files.
                    </div>
                    <fa-icon
                        class="dialog-selection__chevron"
                        icon="chevron-right"
                    ></fa-icon>
                </a>
            </div>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" class="btn" mat-dialog-close>Cancel</button>
        </div>
    </ng-container>

    <!-- <revenue-dialog-targets
        *ngSwitchCase="1"
        (dialogChangeStep)="changeStep($event)"
        (dialogSubmit)="submit($event)"
    ></revenue-dialog-targets> -->

    <revenue-dialog-upload
        *ngSwitchCase="2"
        (dialogChangeStep)="changeStep($event)"
        (dialogSubmit)="submit($event)"
        [lastUploadDate]="data.lastUploadDate"
    ></revenue-dialog-upload>
</ng-container>
