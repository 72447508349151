import { Locations } from 'county-api';

export class GotLocations {
    static readonly type = '[Locations] Got';

    constructor(public payload: Locations.ILocation[]) {}
}

export class FailedLocations {
    static readonly type = '[Locations] Failed';
}
