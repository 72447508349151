import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SpinnerModule } from 'platform-ui/spinner';
import { ButtonComponent } from './button.component';

@NgModule({
    declarations: [ButtonComponent],
    exports: [ButtonComponent],
    imports: [CommonModule, SpinnerModule, FontAwesomeModule],
})
export class ButtonModule {}
