import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IActivityRendered } from '../../../../activities/activities.service';
import { UriService } from '../../../../universal/uri/uri.service';

/**
 * The user activity list item used to output a users activity.
 */
@Component({
    selector: 'user-activities-list-item',
    templateUrl: './user-activities-list-item.component.html',
})
export class UserActivitiesListItemComponent {
    /**
     * The activity data input from the parent.
     */
    @Input()
    activity: IActivityRendered;

    /**
     * Determines whether to show the user.
     */
    @Input()
    showUser?: any;

    /**
     * Constructs injected angular dependencies.
     * @param uriService The uri service used to parse the uri passed in.
     * @param activitiesService Activities service used to get the activity.
     * @param router The router used to handle navigation in this page.
     */
    constructor(private uriService: UriService, private router: Router) {}

    /**
     * Format an activities
     */
    getUrl(activity: IActivityRendered) {
        if (activity.uri) {
            return this.uriService.parseUri(activity.uri);
        } else if (activity.url) {
            return activity.url;
        }

        return false;
    }

    /**
     * Handle the click of an activity.
     */
    activityClick(activity: IActivityRendered, $event) {
        // Need to make sure were not clicking an inner link as this is formatted in
        // activitiesService as a html string so cant stop propogation itself.
        if ($event.target.tagName !== 'A') {
            // Get the url for the uri.
            const uriUrl = this.getUrl(activity);

            // Dont do anything if the uri/url doesnt have any value.
            if (uriUrl !== false) {
                this.router.navigateByUrl(uriUrl.replace('#/', '/'));
            }
        }
    }
}
