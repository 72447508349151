<footer class="primary-footer">
    <div class="primary-footer__links">
        <a
            class="primary-footer__link"
            (click)="openChangeLogClick()"
            [matTooltip]="
                (seenVersion$ | async) < version
                    ? 'There has been an update since you last visited.'
                    : ''
            "
        >
            <fa-icon icon="clipboard-list"></fa-icon> Changelog
            <span
                class="changelog-badge"
                *ngIf="(seenVersion$ | async) < version"
            ></span>
        </a>
        <a class="primary-footer__link" (click)="openBugReportClick()">
            <fa-icon icon="bug"></fa-icon> Report a Bug
        </a>
    </div>
    <div class="primary-footer__logo">
        <svg class="primary-footer__logo-img">
            <use xlink:href="#county-logo"></use>
        </svg>
    </div>
    <div class="primary-footer__version">Platform - {{ version }}</div>
</footer>
