<topbar>
    <h1 topbar-title>Booking Report</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="content-section">
            <div class="wrapper wrapper--large">
                <div class="content-card">
                    <div class="content-list-toolbar" *ngIf="loading < 1">
                        <ul class="content-list-toolbar__list list-horizontal">
                            <li
                                class="content-list-toolbar__item list--item__right"
                            >
                                <custom-date-time-picker
                                    class="content-list-toolbar__input-datepicker"
                                    name="end_date"
                                    id="end_date"
                                    required
                                    [minDate]="startDate"
                                    [(ngModel)]="endDate"
                                    (ngModelChange)="filterUpdate()"
                                    [hideTimePicker]="true"
                                ></custom-date-time-picker>
                            </li>
                            <li
                                class="content-list-toolbar__item content-list-toolbar__item--label list--item__right"
                            >
                                to
                            </li>
                            <li
                                class="content-list-toolbar__item list--item__right"
                            >
                                <custom-date-time-picker
                                    class="content-list-toolbar__input-datepicker"
                                    name="start_date"
                                    id="start_date"
                                    required
                                    [(ngModel)]="startDate"
                                    (ngModelChange)="filterUpdate()"
                                    [hideTimePicker]="true"
                                ></custom-date-time-picker>
                            </li>

                            <li
                                class="content-list-toolbar__item list--item__right"
                            >
                                <div
                                    class="btn btn--dropdown"
                                    (click)="locationSelect.open()"
                                >
                                    <span class="btn__label">Location</span>
                                    <mat-select
                                        class="btn__select"
                                        name="location"
                                        [(ngModel)]="locationItem"
                                        (selectionChange)="filterUpdate()"
                                        #locationSelect
                                    >
                                        <mat-option [value]="-1"
                                            >All</mat-option
                                        >
                                        <mat-option
                                            [value]="location.id"
                                            *ngFor="
                                                let location of locations$
                                                    | async
                                            "
                                        >
                                            {{ location.name }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </li>

                            <li class="content-list-toolbar__item">
                                <a
                                    class="btn btn--primary print-hidden"
                                    printElement
                                    elementId="transactions-table"
                                    >Print</a
                                >
                            </li>
                        </ul>
                    </div>

                    <loader *ngIf="loading"></loader>

                    <div class="content-card__body" *ngIf="bookingReport">
                        <div
                            id="transactions-table"
                            *ngIf="bookingReport.transactions.length > 0"
                        >
                            <h3 class="print-only">
                                Transactions - {{ date | date: 'dd/MM/yyyy' }}
                            </h3>

                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <span class="print-only">Ref</span>
                                        </th>
                                        <th>
                                            <span class="print-only">Type</span>
                                        </th>
                                        <th>
                                            <span class="print-only"
                                                >Customer</span
                                            >
                                        </th>
                                        <th>
                                            <span class="print-only"
                                                >Reservation</span
                                            >
                                        </th>
                                        <th>
                                            <span class="print-only"
                                                >Agreement</span
                                            >
                                        </th>
                                        <!--<th><span class="print-only">User</span></th>-->
                                        <th class="align-right">Amount</th>
                                        <th class="align-right">
                                            Transaction Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let bookingTransaction of bookingReport.transactions
                                        "
                                    >
                                        <td>
                                            <a
                                                class="link"
                                                [routerLink]="[
                                                    '/bookings',
                                                    bookingTransaction.booking
                                                        .id
                                                ]"
                                                >{{
                                                    bookingTransaction.booking
                                                        .reference
                                                }}</a
                                            >
                                        </td>
                                        <td>{{ bookingTransaction.type }}</td>
                                        <td>
                                            <img
                                                class="user-thumbnail user-thumbnail--small"
                                                [src]="
                                                    bookingTransaction.booking
                                                        .customer.thumbnail
                                                "
                                            />
                                            {{
                                                bookingTransaction.booking
                                                    .customer.forename
                                            }}
                                            {{
                                                bookingTransaction.booking
                                                    .customer.surname
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                bookingTransaction.booking
                                                    .reservation_number
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                bookingTransaction.booking
                                                    .agreement_number
                                            }}
                                        </td>
                                        <td class="align-right">
                                            {{
                                                bookingTransaction.amount >= 0
                                                    ? bookingTransaction.amount.toFixed(
                                                          2
                                                      )
                                                    : '(' +
                                                      bookingTransaction.amount.toFixed(
                                                          2
                                                      ) +
                                                      ')'
                                            }}
                                        </td>
                                        <td class="align-right">
                                            {{
                                                bookingTransaction.date
                                                    | date: 'dd/MM/yyyy hh:mm a'
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            class="content-list__no-results"
                            *ngIf="bookingReport.transactions.length < 1"
                        >
                            No transactions where found for the given
                            parameters.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
