import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bookings, IApiResponse } from 'county-api';
import * as moment from 'moment';
import { MomentInput } from 'moment';
import { ApiConstant } from '../configs/api.constant';
import { ApiHttpClientService } from '../universal/api/apiHttpClient.service';
import { IBookingCancellation } from './booking/cancel/booking-cancel-dialog.component';

/**
 * The interface for the paging of bookings page.
 */
export interface IBookingsPaging {
    page: number;

    filter?: string;

    perpage?: number;

    orderby?: string;

    status?: string;

    location?: number;

    customer_ids?: string[];
}

/**
 * The booking service used to handle booking interaction with the api.
 */
@Injectable()
export class BookingsService extends ApiHttpClientService {
    /**
     * Consructs injected angular dependencies.
     * @param service The service used to get, post, put and delete people.
     */
    constructor(private service: HttpClient) {
        super();
    }

    /**
     * Gets all the bookings.
     * @param params The page to retrieve from the api.
     */
    get(params: IBookingsPaging) {
        return this.service.get<IApiResponse<Bookings.IBookingsViewModel>>(
            ApiConstant.url + 'bookings',
            {
                params: this.filterRequestParams(params),
            },
        );
    }

    /**
     * Get a single booking by its id.
     * @param id The id of the booking.
     */
    getById(id: string) {
        return this.service.get<IApiResponse<Bookings.IBookingViewModel>>(
            `${ApiConstant.url}bookings/${id}`,
        );
    }

    /**
     * Makes a put request to update the already existing booking by its id.
     * @param id The id of the booking to update.
     * @param booking The new booking data to update by.
     */
    update(id: string, booking: Bookings.IBooking) {
        return this.service.put<IApiResponse<Bookings.IBookingViewModel>>(
            `${ApiConstant.url}bookings/${id}`,
            { booking },
        );
    }

    /**
     * Recieves the booking data and cancels it.
     * @param cancellationData The data passed to cancel the booking.
     */
    cancel(id: string, cancellationData: IBookingCancellation) {
        return this.service.post<IApiResponse<Bookings.IBookingViewModel>>(
            `${ApiConstant.url}bookings/${id}/cancel`,
            {
                cancellation_type: cancellationData.type,
            },
        );
    }

    /**
     * Recieves the booking data and confirms it.
     * @param reservation_number The data passed to confirm the booking.
     */
    confirm(id: string, reservationNumber: any) {
        return this.service.post<IApiResponse<Bookings.IBookingViewModel>>(
            `${ApiConstant.url}bookings/${id}/confirm`,
            {
                reservation_number: reservationNumber,
            },
        );
    }

    /**
     * Recieves the booking data and opens it.
     * @param data The data passed to open the booking.
     */
    open(id: string, data: IBookingOpenData) {
        return this.service.post<IApiResponse<Bookings.IBookingViewModel>>(
            `${ApiConstant.url}bookings/${id}/open`,
            data,
        );
    }

    /**
     * Recieves the booking data and completes it.
     * @param data The data passed to complete the booking.
     */
    complete(id: string, completionData: any) {
        return this.service.post<IApiResponse<Bookings.IBookingViewModel>>(
            `${ApiConstant.url}bookings/${id}/complete`,
            {
                refund_type: completionData.type,
                refund_amount: completionData.amount,
                agreement_number: completionData.agreement_number,
            },
        );
    }

    /**
     * Recieves the booking data and opens it.
     * @param data The data passed to open the booking.
     */
    refundCharge(id: string, chargeId: string, count: number) {
        return this.service.post<IApiResponse<Bookings.IBookingViewModel>>(
            `${ApiConstant.url}bookings/${id}/charges/${chargeId}/refund`,
            { count },
        );
    }

    /**
     * Gets all the reports according to the params passed in to the call.
     * @param startDate The earliest date in which to get the reports.
     * @param endDate The latest date in which to get the reports.
     * @param locationId The location in which the reports were.
     */
    getReport(
        startDate: MomentInput,
        endDate: MomentInput,
        locationId: number,
    ) {
        const params = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            location_id: locationId === -1 ? null : locationId,
        };

        return this.service.get<
            IApiResponse<Bookings.IBookingTransactionsViewModel>
        >(`${ApiConstant.url}bookings/report`, {
            params: this.filterRequestParams(params),
        });
    }
}

export interface IBookingOpenData {
    last4_digits?: number;

    agreement_number?: string;

    deposit_authorisation_number?: string;
}
