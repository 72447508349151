<div class="dialog-container">
    <button
        aria-label="Dismiss"
        class="dialog-close"
        mat-dialog-close
        tabindex="-1"
    ></button>

    <div class="dialog-heading">
        <h3>Address Details</h3>
    </div>

    <form (validSubmit)="confirm($event)">
        <div mat-dialog-content>
            <ul class="form">
                <li class="form__item">
                    <label class="input-label" for="address_line1">Line 1</label>
                    <input
                        type="text"
                        id="address_line1"
                        name="address_line1"
                        required
                        [(ngModel)]="addressDetail.line1"
                        titlecase
                        #addressLine1="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="addressLine1">
                        <label
                            for="address_line1"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter the first line of the address.
                        </label>
                    </div>
                </li>

                <li class="form__item" *ngIf="addressDetail.line1">
                    <label class="input-label" for="address_line2"
                        >Line 2 <span class="input-optional">Optional</span></label
                    >
                    <input
                        type="text"
                        id="address_line2"
                        name="address_line2"
                        [(ngModel)]="addressDetail.line2"
                        titlecase
                    />
                </li>

                <li class="form__item" *ngIf="addressDetail.line2">
                    <label class="input-label" for="address_line3"
                        >Line 3 <span class="input-optional">Optional</span></label
                    >
                    <input
                        type="text"
                        id="address_line3"
                        name="address_line3"
                        [(ngModel)]="addressDetail.line3"
                        titlecase
                    />
                </li>

                <li class="form__item">
                    <label class="input-label" for="address_city">City</label>
                    <input
                        type="text"
                        id="address_city"
                        name="address_city"
                        required
                        [(ngModel)]="addressDetail.city"
                        #addressCity="ngModel"
                        titlecase
                    />

                    <div class="input-errors" [ngxErrors]="addressCity">
                        <label
                            for="address_city"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter the city of the address.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="address_country">Country</label>

                    <div class="input-select">
                        <mat-select
                            id="address_country"
                            name="address_country"
                            [(ngModel)]="addressDetail.country"
                            required
                            #addressCountry="ngModel"
                        >
                            <mat-option></mat-option>
                            <mat-option
                                [value]="country"
                                *ngFor="let country of countries"
                                >{{ country }}</mat-option
                            >
                        </mat-select>
                    </div>

                    <div class="input-errors" [ngxErrors]="addressCountry">
                        <label
                            for="address_country"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter the country of the address.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label" for="address_postcode"
                        >Postcode</label
                    >
                    <input
                        type="text"
                        id="address_postcode"
                        name="address_postcode"
                        required
                        [(ngModel)]="addressDetail.postcode"
                        capitalise
                        #addressPostcode="ngModel"
                    />
                    <div class="input-errors" [ngxErrors]="addressPostcode">
                        <label
                            for="address_postcode"
                            ngxError="required"
                            class="input-error"
                        >
                            Please enter the postcode of the address.
                        </label>
                    </div>
                </li>

                <li class="form__item">
                    <label class="input-label">Move in Date</label>
                    <custom-date-time-picker
                        name="address_from_date"
                        id="address_from_date"
                        [startDate]="startFromDate"
                        [(ngModel)]="addressDetail.from_date"
                        [required]="requireDate"
                        hideTimePicker="true"
                    ></custom-date-time-picker>
                </li>

                <li class="form__item">
                    <label class="input-label">Move out Date</label>
                    <custom-date-time-picker
                        name="address_to_date"
                        id="address_to_date"
                        [startDate]="startToDate"
                        [(ngModel)]="addressDetail.to_date"
                        hideTimePicker="true"
                    ></custom-date-time-picker>
                </li>
            </ul>
        </div>

        <div class="btns btns--horizontal" mat-dialog-actions>
            <button type="button" mat-dialog-close class="btn">
                Cancel
            </button>

            <input
                type="submit"
                value="Add Address"
                class="btn btn--success btn--right"
            />
        </div>
    </form>
</div>