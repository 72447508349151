import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Locations, Users } from 'county-api';
import rangeCompare from 'ip-range-check';
import moment from 'moment';
import { zip } from 'rxjs';
import { GotLocations } from '../../../locations/store/locations.actions';
import {
    LocationUpdate,
    LoggedIn,
    UserLocationChange,
} from '../../store/user.actions';
import { LocationChangeDialogComponent } from './location-change-dialog.component';

@Injectable()
export class LocationChangeHandlerService {
    constructor(
        actions$: Actions,
        private dialog: MatDialog,
        private store: Store,
    ) {
        zip(
            actions$.pipe(ofActionSuccessful(LoggedIn)),
            actions$.pipe(ofActionSuccessful(GotLocations)),
        ).subscribe((data: [LoggedIn, GotLocations]) =>
            this.handleLocationChange(data[0].payload, data[1].payload),
        );
    }

    /**
     * Check the users location to ensure that we know the most recent location
     * based on thier ip. If we know the ip is a branch we prompt if they want
     * to change else we just ignore.
     */
    handleLocationChange(user: Users.IUser, locations: Locations.ILocation[]) {
        const locationChange = this.getLocationsChangeData(user, locations);

        if (locationChange.shouldCheck) {
            const dialog = this.dialog.open(LocationChangeDialogComponent, {
                width: '350px',
                data: locationChange,
            });

            dialog.afterClosed().subscribe(result => {
                // If the user chooses to update their location trigger the
                // action for that change.
                this.store.dispatch(
                    new LocationUpdate(
                        result
                            ? locationChange.newLocation.id
                            : locationChange.previousLocation.id,
                    ),
                );
            });
        }
    }

    private getLocationsChangeData(
        user: Users.IUser,
        locations: Locations.ILocation[],
    ): UserLocationChange {
        let previousLocation: Locations.ILocation;
        let newLocation: Locations.ILocation;
        let shouldCheck = moment(user.updated_location_date).isBefore(
            moment().startOf('day'),
        );

        // First check if the user has updated their location since the start of
        // the day. If they have we dont need to check their location as we see
        // any mis-match is intentional.
        if (shouldCheck) {
            // Loop the locations looking for 2 things, first the users recorded
            // location and secondly the users current physical location.
            for (const location of locations) {
                if (location.id === user.location_id) {
                    previousLocation = location;
                }

                if (location.ip && rangeCompare(user.ip, location.ip)) {
                    newLocation = location;
                }

                // Once we have both locations we can stop looking.
                if (previousLocation && newLocation) {
                    break;
                }
            }

            // If no new location was found, ie they're remote. Set the new
            // location to the recorded location.
            if (newLocation == null) {
                newLocation = previousLocation;
            }

            shouldCheck = newLocation.id !== previousLocation.id;
        }

        // Map to return type.
        return {
            previousLocation: previousLocation,
            newLocation: newLocation,
            shouldCheck: shouldCheck,
        } as UserLocationChange;
    }
}
