export interface IMenuItem {
    label: string;
    url?: string;
    icon?: string | string[];
    permission?: string | string[];
    children?: IMenuItem[];
    show?: boolean;
    active?: boolean;
    newTab?: boolean;
}
export const MENU_ITEMS: IMenuItem[] = [
    {
        url: '/dashboard',
        label: 'Home',
        icon: ['fas', 'home'],
    },
    {
        label: 'Tariff',
        icon: ['fas', 'tag'],
        children: [
            { url: '/tariff/pricing', label: 'Pricing' },
            { url: '/tariff/catalogue', label: 'Catalogue' },
            {
                url: '/tariff/corporate',
                label: 'Corporate',
                permission: 'tariff-corporate',
            },
            { url: '/tariff/settings', label: 'Settings' },
        ],
    },
    {
        label: 'Rental',
        icon: ['fas', 'shopping-cart'],
        children: [
            {
                url: '/bookings',
                label: 'Bookings',
                permission: 'booking',
            },
            {
                url: '/bookings/customers',
                label: 'Online Customers',
                permission: 'booking',
            },
            {
                url: '/bookings/report',
                label: 'Booking Report',
                permission: 'booking-report',
            },
            {
                url: 'https://labs.county-rental.co.uk/LTEQR/rent28',
                label: 'Rent 28',
                newTab: true,
            },
            {
                url: 'https://labs.county-rental.co.uk/LTEQR',
                label: 'Contract Hire',
                newTab: true,
            },
        ],
    },
    {
        label: 'Fleet',
        icon: ['fas', 'car'],
        children: [
            { url: '/fleet/vehicles', label: 'Vehicles' },
            { url: '/fleet/incidents', label: 'Incidents' },
            { url: '/fleet/groups', label: 'Groups' },
            { url: '/fleet/servicing', label: 'Servicing' },
            {
                url: '/fleet/settings',
                label: 'Settings',
                permission: [
                    'fleet-admin',
                    'fleet-tracker-installer',
                    'incident-estimate-catalogue-manage',
                ],
            },
        ],
    },
    {
        label: 'Customers',
        icon: ['fas', 'address-book'],
        children: [
            { url: '/customers/companies', label: 'Companies' },
            { url: '/customers/people', label: 'People' },
            {
                url: '/invoices',
                label: 'Invoices',
            },
            {
                url: '/invoices/credit-notes',
                label: 'Credit Notes',
            },
            {
                url: '/customers/settings',
                label: 'Settings',
                permission: ['admin', 'customers-jobs'],
            },
        ],
    },
    {
        label: 'Insurance',
        icon: ['fas', 'gavel'],
        children: [
            { url: '/insurance', label: 'Qualifications' },
            { url: '/insurance/add', label: 'Request' },
            {
                url: '/endorsements',
                label: 'Endorsements',
                permission: 'endorsements-edit',
            },
        ],
    },
    {
        label: 'Users',
        icon: ['fas', 'address-card'],
        children: [{ url: '/users', label: 'List' }],
    },
    {
        label: 'Data Exchange',
        icon: ['fas', 'cloud-binary'],
        permission: 'data-exchange',
        children: [
            { 
                url: '/data-exchange/performance-data', 
                label: 'Performance Data',
            }
        ],
    },
    // {
    //     label: 'Admin',
    //     permission: 'admin',
    //     icon: ['fas', 'lock'],
    //     children: [{ url: '/admin/settings', label: 'Settings' }],
    // },
];
