<h3 class="dialog__heading" mat-dialog-title>Upload</h3>

<form (validSubmit)="submit()">
    <div class="dialog-selection" mat-dialog-content>
        <ul class="form">
            <li class="form__item">
                <label class="input-label"
                    >File
                    <small
                        >Last Upload:
                        {{ lastUploadDate.format('Do MMM, YYYY') }}</small
                    >
                </label>
                <file
                    [(ngModel)]="files"
                    name="files"
                    id="files"
                    #fileUpload="ngModel"
                    required
                ></file>
                <div class="input-errors" [ngxErrors]="fileUpload">
                    <label class="input-error" for="files" ngxError="required">
                        Please select a file to upload.
                    </label>
                    <label
                        class="input-error"
                        for="files"
                        ngxError="invalidExtension"
                    >
                        Invalid file type.
                    </label>
                </div>
            </li>
        </ul>
    </div>

    <div class="btns btns--horizontal" mat-dialog-actions>
        <button type="button" class="btn" (click)="changeStep(0)">
            Cancel
        </button>
        <input class="btn btn--primary" type="submit" value="Upload" />
    </div>
</form>
