import {
    Component,
    ContentChild,
    Input,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { PageActionsDefDirective } from './directives/page-actions-def.directive';
import { PageExtrasDefDirective } from './directives/page-extras-def.directive';
import { PagePrimaryActionsDefDirective } from './directives/page-primary-actions-def.directive';

/**
 * Page component.
 */
@Component({
    selector: 'page',
    templateUrl: './page.component.html',
    host: {
        '[class.page]': 'true',

        '[class.page--s-x-narrow]': 'pSize == "x-narrow"',
        '[class.page--s-narrow]': 'pSize == "narrow"',
        '[class.page--s-wide]': 'pSize == "wide"',
        '[class.page--s-full]': 'pSize == "full"',
    },
})
export class PageComponent {
    /**
     * The page main title, passed to PageHeader.
     */
    @Input()
    pTitle: string;

    /**
     * Pages sub title, passed to PageHeader.
     */
    @Input()
    pSubTitle: string;

    @Input()
    pTitleExtras: TemplateRef<any>;

    @Input()
    pHeaderActions: TemplateRef<any>;

    @Input()
    pHeaderPrimaryActions: TemplateRef<any>;

    @Input()
    pPageHeader: TemplateRef<any>;

    @ContentChild(PageActionsDefDirective, { static: true, read: TemplateRef })
    _pHeaderActions: TemplateRef<any>;

    @ContentChild(PagePrimaryActionsDefDirective, {
        static: true,
        read: TemplateRef,
    })
    _pHeaderPrimaryActions: TemplateRef<any>;

    @ContentChild(PageExtrasDefDirective, {
        static: true,
        read: TemplateRef,
    })
    _pHeaderExtras: TemplateRef<any>;

    @Input()
    pSize: string;
}
