<button
    aria-label="Dismiss"
    class="dialog-close"
    mat-dialog-close
    tabindex="-1"
></button>

<h3 mat-dialog-title>Close Down</h3>

<form (validSubmit)="submit()">
    <div mat-dialog-content>
        <p>
            A booking should only be marked as complete when it has been closed
            down in rentman.
        </p>

        <div class="dialog__divider"></div>

        <ul class="form">
            <li class="form__item" *ngIf="booking.paid_via_stripe">
                <label class="input-label">Refund Type</label>

                <div class="input-select">
                    <mat-select
                        id="refund_type"
                        name="refund_type"
                        [(ngModel)]="result.type"
                        required
                        (ngModelChange)="selectChange(result)"
                        #refundType="ngModel"
                    >
                        <mat-option></mat-option>
                        <mat-option
                            [value]="value.id"
                            *ngFor="let value of bookingRefundItems"
                        >
                            {{ value.label }}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="input-comment" *ngIf="depositPaidByTerminal">
                    This should include refunds processed with the terminal.
                </div>

                <div class="input-errors" [ngxErrors]="refundType">
                    <label
                        for="refund_amount"
                        *ngxErrorDefault
                        class="input-error"
                    >
                        Please select a refund type.
                    </label>
                </div>
            </li>

            <li
                class="form__item"
                *ngIf="
                    booking.paid_via_stripe &&
                    result.type == bookingRefunds.Custom
                "
            >
                <label class="input-label">Refund Amount</label>

                <input
                    type="number"
                    name="refund_amount"
                    id="refund_amount"
                    required
                    [(ngModel)]="result.amount"
                    [min]="min"
                    [max]="max"
                    #refundAmount="ngModel"
                />

                <div class="input-errors" [ngxErrors]="refundAmount">
                    <label
                        for="refund_amount"
                        [ngxError]="['required', 'min', 'max']"
                        class="input-error"
                    >
                        Please enter a value that is between {{ min }} and
                        {{ max }}.
                    </label>
                </div>
            </li>

            <li class="form__item">
                <label class="input-label" for="agreement_number">
                    Agreement Number
                </label>

                <input
                    type="text"
                    name="agreement_number"
                    id="agreement_number"
                    required
                    [(ngModel)]="result.agreement_number"
                    [maxlength]="20"
                    capitalise
                    #agreementNumber="ngModel"
                />

                <div class="input-errors" [ngxErrors]="agreementNumber">
                    <label
                        for="agreement_number"
                        ngxError="required"
                        class="input-error"
                    >
                        Please enter a valid agreement number.
                    </label>
                </div>
            </li>

            <li class="form__item" *ngIf="isDepositFailed">
                <p class="dialog__alert dialog__alert--warn">
                    The deposit for this rental was not taken via stripe, if you
                    wish to refund the customers deposit do so using their card.
                </p>
            </li>

            <li class="form__item" *ngIf="isDepositFailed">
                <label class="input-label" for="deposit_payment_made">
                    Please acknowledge you have read the warning above
                </label>

                <label class="input-checkbox">
                    <input
                        type="checkbox"
                        name="deposit_payment_made"
                        value="true"
                        required
                        [(ngModel)]="result.deposit_payment_made"
                        #depositPaymentMade="ngModel"
                    />
                    <span class="input-checkbox__label">Yes</span>
                </label>

                <div class="input-errors" [ngxErrors]="depositPaymentMade">
                    <label
                        for="deposit_payment_made"
                        ngxError="required"
                        class="input-error"
                    >
                        Please confirm you have read the warning.
                    </label>
                </div>
            </li>
        </ul>

        <p
            class="dialog__sub-message"
            *ngIf="
                booking.paid_via_stripe && result.type == bookingRefunds.Deposit
            "
        >
            Your customer will receive an email invitation to leave us a
            <a
                href="https://www.reviews.co.uk/company-reviews/store/county-car-and-van-rental"
                class="link"
                target="_blank"
            >
                review
            </a>
            . Please encourage them to do so!
        </p>
    </div>

    <div class="btns btns--horizontal" mat-dialog-actions>
        <button type="button" class="btn" mat-dialog-close>
            Cancel
        </button>

        <input
            type="submit"
            class="btn btn--primary btn--right"
            value="Close Down"
            *ngIf="
                !booking.paid_via_stripe || result.type == bookingRefunds.None
            "
        />
        <input
            type="submit"
            class="btn btn--success btn--right"
            value="Refund Deposit"
            *ngIf="
                booking.paid_via_stripe && result.type == bookingRefunds.Deposit
            "
        />
        <input
            type="submit"
            class="btn btn--error btn--right"
            [value]="'Refund ' + (result.amount || 0 | gbp)"
            *ngIf="
                booking.paid_via_stripe && result.type == bookingRefunds.Custom
            "
            [disabled]="!result.amount || result.amount < 0"
        />
    </div>
</form>
