import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { LayoutModule } from '../layout/layout.module';
import { LocationsModule } from '../locations/locations.module';
import { UniversalModule } from '../universal/universal.module';
import { CurrentUserService } from './current-user.service';
import { UsersListItemComponent } from './list/users-list-item.component';
import { LocationChangeHandlerService } from './locations/change/location-change-handler.service';
import { PermissionsService } from './permissons/permissions.service';
import { NotificationsState } from './store/notifications.state';
import { UserState } from './store/user.state';
import { UsersState } from './store/users.state';
import { UserGuard } from './user.guard';
import { UserActivitiesListItemComponent } from './user/activities/list/user-activities-list-item.component';
import { UserActivitiesComponent } from './user/activities/user-activities.component';
import { UserLocationsChangeDialogComponent } from './user/locations/user-locations-change-dialog.component';
import { UserPermissionAddDialogComponent } from './user/permission/add/user-permission-add-dialog.component';
import { UserPermissionComponent } from './user/permission/user-permission.component';
import { UsersComponentsModule } from './users-components.module';
import { routedComponents, UsersRoutingModule } from './users-routing.module';
import { UsersService } from './users.service';

export function noop() {
    return function() {};
}

@NgModule({
    imports: [
        CommonModule,
        UniversalModule,
        LayoutModule,
        NgxsModule.forFeature([UsersState, UserState, NotificationsState]),
        UsersRoutingModule,
        UsersComponentsModule,
        LocationsModule,
    ],
    declarations: [
        ...routedComponents,
        UserLocationsChangeDialogComponent,
        UserActivitiesComponent,
        UserPermissionComponent,
        UserPermissionAddDialogComponent,
        UserActivitiesListItemComponent,
        UsersListItemComponent,
    ],
    providers: [
        CurrentUserService,
        UserGuard,
        UsersService,
        PermissionsService,
        LocationChangeHandlerService,
        {
            provide: APP_INITIALIZER,
            useFactory: noop,
            deps: [LocationChangeHandlerService],
            multi: true,
        },
    ],
    exports: [UsersComponentsModule, UserActivitiesListItemComponent],
})
export class UsersModule {}
