<topbar>
    <img [src]="user.thumbnail" topbar-thumbnail />
    <h1 topbar-title>{{ user.username }}</h1>
</topbar>

<div class="container-content" *ngIf="user && loading < 1">
    <div class="content">
        <!-- <div class="booking-customer-profile">
            <div class="wrapper">
                <div class="booking-customer-profile__details">
                    <img class="booking-customer-profile__image" [src]="user.thumbnail" />

                    <div class="booking-customer-profile__info">
                        <h1 class="booking-customer-profile__name">{{isCurrentUser ? 'You' : user.username}}</h1>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="content-section">
            <div class="wrapper">
                <div class="content-card">
                    <div
                        class="content-card__header content-card__header--blank"
                    >
                        <h3 class="content-card__heading">General Details</h3>
                    </div>
                    <div class="content-card__body">
                        <div class="content-card-table">
                            <table>
                                <tr>
                                    <th>Job Title</th>
                                    <td>{{ user.title }}</td>
                                </tr>

                                <tr *ngIf="user.email">
                                    <th>Email</th>
                                    <td>
                                        <a
                                            class="link"
                                            [href]="'mailto:' + user.email"
                                            >{{ user.email }}</a
                                        >
                                        -
                                        <a class="link" [href]="sanitizedUrl"
                                            >Open in skype</a
                                        >
                                    </td>
                                </tr>

                                <tr *ngIf="user.telephone">
                                    <th>Telephone</th>
                                    <td>
                                        <a
                                            class="link"
                                            [href]="'tel:' + user.telephone"
                                            >{{ user.telephone }}</a
                                        >
                                    </td>
                                </tr>

                                <tr>
                                    <th>Last Seen</th>
                                    <td>
                                        {{
                                            user.last_seen
                                                | date: 'dd/MM/yyyy h:mm a'
                                        }}
                                    </td>
                                </tr>

                                <tr>
                                    <th>Current Location</th>
                                    <td [ngSwitch]="loadingLocation > 0">
                                        <loader
                                            [inline]="true"
                                            *ngSwitchCase="true"
                                        ></loader>
                                        <div *ngSwitchCase="false">
                                            <location-label
                                                [locationId]="user.location_id"
                                            ></location-label>
                                            <span
                                                *hasPermission="
                                                    'admin';
                                                    orIf: isCurrentUser
                                                "
                                            >
                                                -
                                                <a
                                                    class="link"
                                                    (click)="
                                                        updateLocationClick()
                                                    "
                                                    >change</a
                                                >
                                            </span>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <th>Base Location</th>
                                    <td [ngSwitch]="loadingBaseLocation > 0">
                                        <loader
                                            [inline]="true"
                                            *ngSwitchCase="true"
                                        ></loader>
                                        <div *ngSwitchCase="false">
                                            <location-label
                                                [locationId]="
                                                    user.base_location_id
                                                "
                                            ></location-label>
                                            <span *hasPermission="'admin'">
                                                -
                                                <a
                                                    class="link"
                                                    (click)="
                                                        updateBaseLocationClick()
                                                    "
                                                    >change</a
                                                >
                                            </span>
                                        </div>
                                    </td>
                                </tr>

                                <tr *ngIf="user.pushover_id">
                                    <th>Pushover Id</th>
                                    <td>
                                        {{ user.pushover_id }}
                                    </td>
                                </tr>

                                <tr>
                                    <th>Last Sync</th>
                                    <td>
                                        {{
                                            user.adsync_date
                                                | date: 'dd/MM/yyyy h:mm a'
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <user-permission
                    *hasPermission="'admin'; orIf: isCurrentUser"
                    [user]="user"
                    [isCurrentUser]="isCurrentUser"
                    (permissionChange)="userUpdated($event)"
                ></user-permission>

                <user-activities [user]="user"></user-activities>
            </div>
        </div>
    </div>
</div>

<loader *ngIf="loading > 0"></loader>
