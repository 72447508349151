<ng-container *ngIf="customer">
    <topbar>
        <h1 topbar-title>{{ customer.forename + ' ' + customer.surname }}</h1>
    </topbar>

    <div class="container-content">
        <div class="content">
            <div class="booking-customer-profile">
                <div class="wrapper">
                    <div class="booking-customer-profile__details">
                        <img
                            class="booking-customer-profile__image"
                            [src]="customer.thumbnail"
                        />

                        <div class="booking-customer-profile__info">
                            <h1 class="booking-customer-profile__name">
                                {{ customer.forename }} {{ customer.surname }}
                            </h1>
                        </div>

                        <ul
                            class="booking-customer-profile__actions list-unstyled"
                        >
                            <li
                                class="booking-customer-profile__action"
                                *ngIf="!customer.blocked"
                                (click)="block()"
                            >
                                <a>Block</a>
                            </li>

                            <li
                                class="booking-customer-profile__action"
                                *ngIf="customer.blocked"
                                (click)="unblock()"
                            >
                                <a>Unblock</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="content-section">
                <div class="wrapper">
                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">Details</h3>
                        </div>
                        <div
                            class="content-card__body booking-customer-contact"
                        >
                            <div class="row">
                                <div class="m-col content-card-detail">
                                    <div class="content-card-detail__label">
                                        Email
                                    </div>
                                    <div class="content-card-detail__value">
                                        {{ customer.email }}
                                    </div>
                                </div>
                                <div class="m-col content-card-detail">
                                    <div class="content-card-detail__label">
                                        Telephone
                                    </div>
                                    <div class="content-card-detail__value">
                                        {{ customer.telephone }}
                                    </div>
                                </div>
                            </div>

                            <div class="content-card-detail">
                                <div class="content-card-detail__label">
                                    Address
                                </div>
                                <address class="content-card-detail__value">
                                    {{ customer.address.street }}, <br />
                                    {{ customer.address.city }}, <br />
                                    {{ customer.address.country }}, <br />
                                    {{ customer.address.postcode }}
                                </address>
                            </div>
                        </div>
                    </div>

                    <div class="content-card">
                        <div
                            class="content-card__header content-card__header--blank"
                        >
                            <h3 class="content-card__heading">History</h3>
                        </div>

                        <div
                            class="content-list booking-list booking-list--simple"
                        >
                            <div *ngIf="bookingsLoading < 1 && pages > 0">
                                <div class="content-list__list">
                                    <bookings-customer-booking-list-item
                                        *ngFor="let booking of bookings"
                                        [booking]="booking"
                                    >
                                    </bookings-customer-booking-list-item>
                                </div>
                                <mat-paginator
                                    [length]="length"
                                    [pageSize]="perpage"
                                    (page)="pageChange($event)"
                                    [pageIndex]="page - 1"
                                ></mat-paginator>
                            </div>

                            <div
                                class="content-list__no-results"
                                *ngIf="bookingsLoading < 1 && pages < 1"
                            >
                                No bookings found.
                            </div>

                            <loader *ngIf="bookingsLoading > 0"></loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<loader *ngIf="loading > 0"></loader>
