export enum VehicleStatus {
    Draft = 'Draft',
    Available = 'Available',
    Deleted = 'Deleted',
    Defleeted = 'Defleeted',

    // Filter only status
    AvailableAndDefleeted = 'AvailableAndDefleeted',
}

export enum VehicleTransmissionType {
    Manual = 'Manual',
    Automatic = 'Automatic',
}

//export enum VehicleDistanceUnit {
//    Miles = 'Miles',
//    Kilometres = 'Kilometres'
//}

export enum VehicleDistanceType {
    OnFleet = 'OnFleet',
    Update = 'Update',
    OffFleet = 'OffFleet',
}

export enum VehicleType {
    Car = 'Car',
    Van = 'Van',
    Truck = 'Truck',
    Other = 'Other',
}

export enum VehicleDriveType {
    FWD = 'FWD',
    RWD = 'RWD',
    AWD = 'AWD',
}

export enum VehicleFuelType {
    Unleaded = 'Unleaded',
    Diesel = 'Diesel',
    Electric = 'Electric',
    Hybrid = 'Hybrid',
}

export enum VehicleRegistrationType {
    OnFleet = 'OnFleet',
    Update = 'Update',
}

export enum VehicleTrackerStatus {
    Deleted = 'Deleted',
    Active = 'Active',
    Uninstalled = 'Uninstalled',
}
