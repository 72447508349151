import { Directive, HostBinding, Input } from '@angular/core';
import { NgxErrorElement } from './ngxerror-element';

export type ErrorOptions = string | string[];

export const toArray = (value: ErrorOptions): string[] =>
    Array.isArray(value) ? value : [value];

@Directive({
    selector: '[ngxError]',
    providers: [{ provide: NgxErrorElement, useExisting: NgxErrorDirective }],
})
export class NgxErrorDirective {
    @HostBinding('[class.input-error]')
    errorClass = true;

    @Input()
    set ngxError(value: ErrorOptions) {
        this.errorNames = toArray(value);
    }

    @Input()
    @HostBinding('attr.id')
    get for(): string {
        return this._for;
    }
    set for(value: string) {
        this._for = value || this._controlId;
    }
    protected _for: string;

    @Input()
    get controlId(): string {
        return this._controlId;
    }
    set controlId(value: string) {
        this._controlId = value;
        this.for = this.for;
    }
    protected _controlId: string;

    errorNames: string[] = [];

    @HostBinding('hidden')
    hidden = true;

    hide() {
        this.hidden = true;
    }

    show() {
        this.hidden = false;
    }
}
