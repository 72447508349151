import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ErrorHandler,
    NgModule,
    Provider,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Route, RouterModule } from '@angular/router';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
// import { Insights } from './layout/insights.service';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { environment } from '../environments/environment';
import { ActivitiesModule } from './activities/activities.module';
import { BookingsModule } from './bookings/bookings.module';
import { API_BASE_URL } from './county-api';
import { CustomersModule } from './customers/customers.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { EndorsementModule } from './endorsement/endorsement.module';
import { FleetModule } from './fleet/fleet.module';
import { AppComponent } from './layout/app.component';
import { LayoutModule } from './layout/layout.module';
 import { NoContentComponent } from './layout/no-content/no-content.component';
import { UnauthorisedComponent } from './layout/unauthorised/unauthorised.component';
// import { LocationsModule } from './locations/locations.module';
import { UniversalModule } from './universal';
import { GlobalErrorHandler } from './universal/errors/global-error-handler';
import { UsersModule } from './users/users.module';
import { InteractionType, PublicClientApplication, IPublicClientApplication  } from "@azure/msal-browser";
import { CommonModule } from '@angular/common';
import { NotificationsService } from './universal/notifications/notifications.service';
import { DataExchangeModule } from './data-exchange/data-exchange.module';

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

const customProviders: Provider[] = [];

const routes: Route[] = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: 'insurance',
        data: { breadcrumb: 'Insurance' },
        canActivate: [MsalGuard],
        loadChildren: () =>
            import('./insurance/insurance.module').then(m => m.InsuranceModule),
    },
    {
        path: 'invoices',
        data: { breadcrumb: 'Invoices' },
        canActivate: [MsalGuard],
        loadChildren: () =>
            import('./invoices/invoices.module').then(m => m.InvoicesModule),
    },
    {
        path: 'tariff',
        data: { breadcrumb: 'Tariff' },
        canActivate: [MsalGuard],
        loadChildren: () =>
            import('./tariff/tariff.module').then(m => m.TariffModule),
    },
    { path: 'unauth', component: UnauthorisedComponent },
     { path: '**', component: NoContentComponent },
];

const protectedResources = {
    mainAPI: {
      endpoint: environment.apiUrl,
      scopes: [environment.apiScope],
    },
  }

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.clientId,
            authority:
                'https://login.microsoftonline.com/' +
                environment.tenant,
            redirectUri: environment.baseUrl + 'redirect',
            postLogoutRedirectUri: environment.baseUrl + 'logged-out',
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE,
        },
    });
}

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['openid', 'profile', environment.apiScope]
      },
    };
  }

  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);
    protectedResourceMap.set(environment.apiUrl, [environment.apiScope]);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
  }

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CommonModule,
        UniversalModule.forRoot(),
        RouterModule.forRoot(routes),
        MsalModule,
        // MsalModule.forRoot(new PublicClientApplication( //TODO: Uncomment once the azure login has been updated
        //     {
        //         auth: {
        //             clientId: environment.clientId,
        //             authority:
        //                 'https://login.microsoftonline.com/' +
        //                 environment.tenant,
        //             redirectUri: environment.baseUrl + 'redirect',
        //             postLogoutRedirectUri: environment.baseUrl + 'logged-out',
        //             navigateToLoginRequestUrl: true,
        //         },
        //         cache: {
        //             cacheLocation: 'localStorage',
        //             storeAuthStateInCookie: isIE,
        //         },
        //     }),
        //     {
        //         interactionType: InteractionType.Popup,
        //         authRequest: {
        //             scopes: ['openid', 'profile', environment.apiScope]
        //         },
        //     },
        //     {
        //         interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        //         protectedResourceMap: new Map([
        //             [protectedResources.mainAPI.endpoint, protectedResources.mainAPI.scopes]
        //           ])
        //     },
        // ),

        NgxsModule.forRoot([], { developmentMode: environment.debug }),
        NgxsStoragePluginModule.forRoot({
            key: [
                'layout.pinNotifications',
                'layout.openNotifications',
                'layout.seenVersion',
                'notifications.disableSignalr',
            ],
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            maxAge: 25,
            disabled: !environment.debug,
            name: 'County Platform',
        }),
        NgProgressModule.withConfig({
            meteor: false,
            color: '#5092da',
            spinner: false,
            thick: true,
        }),
        NgProgressHttpModule.withConfig({}),

        LayoutModule,
        DashboardModule,
        CustomersModule,
         UsersModule,
         DataExchangeModule,
        // LocationsModule,
        ActivitiesModule,
        BookingsModule,
        EndorsementModule,
        FleetModule,
    ],
    providers: [
        ...customProviders,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => function() {},
            deps: [NotificationsService],
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: API_BASE_URL,
            useValue: environment.apiUrl.substring(
                0,
                environment.apiUrl.length - 1,
            ),
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    declarations: [],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
