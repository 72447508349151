import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Bookings } from 'county-api';
import { BookingCustomersService } from '../booking-customers.service';

/**
 * Maps the data from the companies service for the customer of the given id.
 */
@Injectable()
export class BookingsCustomerResolve
    implements Resolve<Bookings.IBookingCustomerViewModel | boolean> {
    constructor(
        private bookingCustomersService: BookingCustomersService,
        private router: Router,
    ) {}

    /**
     * Uses the current route and maps the passed in id to the url to get the customer data.
     */
    resolve(route: ActivatedRouteSnapshot) {
        return this.bookingCustomersService
            .getById(route.paramMap.get('id'))
            .pipe(
                map(data => data.result),
                catchError(error => {
                    // Delay the navigation in order to maintain the url states.
                    setTimeout(() =>
                        this.router.navigate(['/bookings/customers'], {
                            skipLocationChange: true,
                        }),
                    );
                    return observableOf(false);
                }),
            );
    }
}
