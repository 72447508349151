import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    HostBinding,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector:
        'button[pButton], a[pButton], button[pIconButton], a[pIconButton]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],

    // TODO: remove once migrated
    encapsulation: ViewEncapsulation.Emulated,

    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.btn]': ' true',
        '[attr.disabled]': 'disabled || pLoading || null',
        '[attr.aria-busy]': 'pLoading || null',
        '[class.btn--disabled]': 'disabled',
        '[class.btn--loading]': 'pLoading',

        '[class.btn--v-success]': 'pVariant == "success"',
        '[class.btn--v-primary]': 'pVariant == "primary"',
        '[class.btn--v-error]': 'pVariant == "error"',
        '[class.btn--v-info]': 'pVariant == "info"',
        '[class.btn--v-warn]': 'pVariant == "warn"',
        '[class.btn--v-extra]': 'pVariant == "extra"',
        '[class.btn--v-neutral]': 'pVariant == "neutral"',
        '[class.btn--s-x-small]': 'pSize == "x-small"',
        '[class.btn--s-small]': 'pSize == "small"',
        '[class.btn--s-large]': 'pSize == "large"',
        '[class.btn--s-x-large]': 'pSize == "x-large"',
        '[class.btn--icon]': 'isIconButton',
    },
})
export class ButtonComponent {
    @Input()
    @HostBinding('class.btn--full')
    get pFull() {
        return this._full;
    }

    set pFull(value: any) {
        this._full = coerceBooleanProperty(value);
    }
    private _full: boolean = false;

    @Input()
    @HostBinding('class.btn--outlined')
    get pOutlined() {
        return this._outlined;
    }

    set pOutlined(value: any) {
        this._outlined = coerceBooleanProperty(value);
    }
    private _outlined: boolean = false;

    @Input()
    @HostBinding('class.btn--subtle')
    get pSubtle() {
        return this._subtle;
    }

    set pSubtle(value: any) {
        this._subtle = coerceBooleanProperty(value);
    }
    private _subtle: boolean = false;

    @Input()
    @HostBinding('class.btn--link')
    get pLink() {
        return this._link;
    }

    set pLink(value: any) {
        this._link = coerceBooleanProperty(value);
    }
    private _link: boolean = false;

    @Input()
    @HostBinding('class.btn--plain')
    get pPlain() {
        return this._plain;
    }

    set pPlain(value: any) {
        this._plain = coerceBooleanProperty(value);
    }
    private _plain: boolean = false;

    @Input()
    @HostBinding('class.btn--loading')
    get pLoading() {
        return this._loading;
    }
    set pLoading(value: any) {
        this._loading = coerceBooleanProperty(value);
    }
    private _loading: boolean = false;

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
    }
    private _disabled: boolean = false;

    @Input()
    pVariant: string;

    @Input()
    pSize: string;

    @Input()
    pConnected: string;

    readonly isIconButton: boolean = this._hasHostAttributes('pIconButton');

    constructor(private _elementRef: ElementRef) {}

    _getHostElement() {
        return this._elementRef.nativeElement;
    }

    /** Gets whether the button has one of the given attributes. */
    _hasHostAttributes(...attributes: string[]) {
        return attributes.some(attribute =>
            this._getHostElement().hasAttribute(attribute),
        );
    }
}
