import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutSectionComponent } from './components/layout-section.component';
import { LayoutTopComponent } from './components/layout-top.component';
import { LayoutComponent } from './layout.component';

@NgModule({
    declarations: [LayoutComponent, LayoutSectionComponent, LayoutTopComponent],
    exports: [LayoutComponent, LayoutSectionComponent, LayoutTopComponent],
    imports: [CommonModule],
})
export class LayoutModule {}
