import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/universal/breadcrumbs/breadcrumb.service';
import { Users } from 'county-api';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    ActivitiesService,
    IActivityRendered,
} from '../../../../activities/activities.service';
import {
    IPagingParams,
    IRequestPaging,
    PagingController,
} from '../../../../universal/paging';
import { UsersService } from '../../../users.service';

/**
 * The activities page component used to get all users activities and output them.
 */
@Component({
    selector: 'users-activites-page',
    templateUrl: './users-activites-page.component.html',
})
export class UsersActivitesPageComponent
    extends PagingController<IRequestPaging>
    implements OnInit {
    /**
     * The array of activities for the user.
     */
    activities: IActivityRendered[] = [];

    /**
     * Determines the loading state of the page.
     */
    loading = 0;

    /**
     * The individual users details.
     */
    user: Users.IUser;

    /**
     * The users id.
     */
    userId: string;

    /**
     * Query details for collecting the companies from the api.
     */
    pagingData: IRequestPaging = {
        page: 1,
        filter: null,
        perpage: null,
    };

    /**
     * The last active request subscription used for cancelling of the request.
     */
    private request: Subscription;

    /**
     * Constructs injected angular dependencies.
     * @param router The router injected by angular.
     * @param route The activated route to get the users id.
     * @param usersService The users service used to get the users details.
     */
    constructor(
        private usersService: UsersService,
        private activitiesService: ActivitiesService,
        private breadcrumbService: BreadcrumbService,
        router: Router,
        route: ActivatedRoute,
    ) {
        super(router, route);
    }

    /**
     * Gets the users details by there id.
     */
    ngOnInit() {
        this.route.data.subscribe((data: { user: Users.IUser }) => {
            this.userId = data.user.id;
            this.breadcrumbService.changeBreadcrumb(
                this.route.parent.snapshot,
                data.user.username,
            );

            super.ngOnInit();
        });
    }

    /**
     * Update the local paging data from the url params.
     * @param params The query params from the url.
     */
    updateLocalParams(params: IPagingParams) {
        this.activities = [];
        this.pages = 0;

        // Gather the paging information from the url.
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            filter: params['filter'] ? params['filter'] : null,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
        } as IRequestPaging;

        // Floor the page number to 1.
        if (this.pagingData.page < 1) {
            this.pagingData.page = 1;
        }

        return this.pagingData;
    }

    /**
     * Load the latest set of data based on the passed in pagingData.
     * @param pagingData The data returned from updateLocalParams.
     */
    updateData(pagingData: IRequestPaging) {
        this.loading++;

        // If any active request cancel it and then decrease the loader.
        if (this.request && !this.request.closed) {
            this.request.unsubscribe();
            this.loading--;
        }

        // Make a new request for the activities details. Handle both success and error states.
        this.request = this.usersService
            .getActivities(this.userId, pagingData)
            .pipe(
                takeUntil(this.unsubscribe$),
                this.activitiesService.mapActivities(),
            )
            .subscribe(
                response => {
                    this.activities = response.result.activities;
                    this.pages = response.result.paging.total_pages;
                    this.totalItems = response.result.paging.total;
                },
                () => {
                    this.pages = 0;
                },
                () => this.loading--,
            );
    }
}
