import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[ngxErrorDefault]',
})
export class NgxErrorDefaultDirective {
    private _shown = false;

    @Input()
    get controlId(): string {
        return this._controlId;
    }
    set controlId(value: string) {
        this._controlId = value;
    }
    protected _controlId: string;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    hide() {
        this.viewContainer.clear();
        this._shown = false;
    }

    show() {
        if (this._shown == false) {
            this.hide();
            this._shown = true;
            const embedElement = this.viewContainer.createEmbeddedView(
                this.templateRef,
                {},
            );

            // TODO allows usage inside form-control. Only works on the creation
            // of the error message.
            if (this.controlId) {
                const domElem = embedElement.rootNodes[0] as HTMLElement;
                if (!domElem.getAttribute('for')) {
                    domElem.setAttribute('for', this.controlId);
                }
                domElem.classList.add('input-error');
            }
        }
    }
}
