import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { NotificationsState } from '../../../users/store/notifications.state';
import { ToggleNotifications } from '../../store/layout.actions';

@Component({
    selector: 'sidebar-notification-toggle',
    templateUrl: './sidebar-notifications-toggle.component.html',
    styleUrls: ['./sidebar-notifications-toggle.component.scss'],
})
export class SidebarNotificationToggleComponent implements OnInit, OnDestroy {
    unreadNotification: Subscription;

    animateCount = 0;

    unreadNotificationCount = 0;

    constructor(private store: Store) {}

    ngOnInit() {
        this.unreadNotification = this.store
            .select(NotificationsState.unreadCount)
            .subscribe(notifications => {
                this.setUnreadCount(notifications);
            });
    }

    setUnreadCount(count: number) {
        this.unreadNotificationCount = count;

        if (count) {
            this.animateCount++;

            setTimeout(() => {
                this.animateCount--;
            }, 2500);
        }
    }

    openNotificationsClick() {
        this.store.dispatch(new ToggleNotifications());
    }

    ngOnDestroy(): void {
        this.unreadNotification.unsubscribe();
    }
}
