import { Directive, Input, Optional } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

/**
 * The directive in order to validate whether the value passed is valid.
 */
@Directive({
    selector: '[invalidValueValidation]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: InvalidValueValidationDirective,
            multi: true,
        },
    ],
})
export class InvalidValueValidationDirective implements Validator {
    /**
     * The input of the invalid value form the input.
     */
    _invalidValues: string[];

    get invalidValues() {
        return this._invalidValues;
    }

    @Input('invalidValueValidation')
    set invalidValues(values: string | string[]) {
        if (Array.isArray(values)) {
            this._invalidValues = values;
        } else {
            this._invalidValues = [values];
        }
    }

    /**
     * Detemines whether the input should be disabled.
     */
    condition = false;

    /**
     * Sets the condition to the value of the boolean passed from the input.
     */
    @Input('invalidValueValidationDisabled')
    @Optional()
    set invalidValuesDisabled(condition: boolean) {
        this.condition = condition;
    }

    /**
     * returns an error when the wrong string is selected.
     * @param c The value being checked for validation.
     */
    validate(c: AbstractControl): { [key: string]: any } {
        if (
            c.value &&
            this.condition === false &&
            this._invalidValues.indexOf(c.value) !== -1
        ) {
            return { invalidValue: true };
        }

        return null;
    }
}
