import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Moment } from 'moment';
import {
    IRevenueDialogResult,
    RevenueDialogResultType,
} from '../revenue-dialog.component';

@Component({
    selector: 'revenue-dialog-upload',
    templateUrl: './revenue-dialog-upload.component.html',
    styleUrls: ['./revenue-dialog-upload.component.scss'],
})
export class RevenueDialogUploadComponent {
    files: File[];

    @Output()
    dialogChangeStep = new EventEmitter<number>();

    @Output()
    dialogSubmit = new EventEmitter<IRevenueDialogResult>();

    @Input()
    lastUploadDate: Moment;

    changeStep(step: number) {
        this.dialogChangeStep.emit(step);
    }

    submit() {
        this.dialogSubmit.emit({
            type: RevenueDialogResultType.Upload,
            files: this.files,
        });
    }
}
