<a
    class="content-list__item booking-list-item content-list__link grid"
    [routerLink]="['/bookings/' + booking.id]"
>
    <div class="row row--align-center">
        <div class="content-list__status">
            <div
                [ngClass]="
                    'content-list__status-label booking-list-item__status-label booking-list-item__status-label--' +
                    (booking.status | lowercase)
                "
            >
                <span class="booking-list-item__status-label-dot"></span>
                {{ booking.status }}
            </div>
        </div>
        <div class="booking-list-item__details m-col">
            <h5 class="booking-list-item__vehicle">
                {{ booking.reference }} - {{ booking.group_name }}
            </h5>
        </div>

        <div class="booking-list-item-info booking-list-item__length m-col-1">
            <div class="booking-list-item-info__value">
                <location-label
                    [locationId]="booking.location_id"
                ></location-label>
            </div>
        </div>
        <div class="booking-list-item-info booking-list-item__length m-col-1">
            <div class="booking-list-item-info__value">
                {{ booking.length }}
            </div>
        </div>
        <div class="booking-list-item-info booking-list-item__length m-col-1">
            <div class="booking-list-item-info__value">
                {{ booking.mileage }}
            </div>
        </div>
        <div class="booking-list-item-info booking-list-item__length m-col-1">
            <div class="booking-list-item-info__value">
                {{ booking.sub_total | gbp }}
            </div>
        </div>
    </div>
</a>
