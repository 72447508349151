import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutoSizeDirective } from './directives/auto-size.directive';
import { FormFieldComponent } from './form-field.component';

@NgModule({
    declarations: [FormFieldComponent, AutoSizeDirective],
    exports: [FormFieldComponent, AutoSizeDirective],
    imports: [CommonModule, MatFormFieldModule],
})
export class FormFieldModule {}
