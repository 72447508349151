<ng-container *ngIf="booking">
    <topbar>
        <h1 topbar-title>
            {{ booking.customer.forename }} {{ booking.customer.surname }}
        </h1>
    </topbar>

    <div class="container-content">
        <div class="content">
            <div class="content-header-container">
                <div class="wrapper wrapper--large">
                    <div class="content-header">
                        <div
                            class="btns btns--horizontal btns--right content-actions"
                        >
                            <span
                                class="content-actions__status-label status-label"
                                [ngClass]="{
                                    'status-label--success':
                                        booking.status == 'Completed',
                                    'status-label--warn':
                                        booking.status == 'Review',
                                    'status-label--error':
                                        booking.status == 'Failed',
                                    'status-label--primary':
                                        booking.status == 'Reserved',
                                    'status-label--info':
                                        booking.status == 'Open'
                                }"
                            >
                                <span class="status-label__dot"></span>
                                {{ booking.status }}
                            </span>

                            <a
                                class="btn"
                                (click)="cancelBooking()"
                                *ngIf="
                                    booking.status != bookingStatus.Completed &&
                                    booking.status != bookingStatus.Review &&
                                    booking.status != bookingStatus.Cancelled &&
                                    booking.status != bookingStatus.Open
                                "
                            >
                                Cancel
                            </a>

                            <a
                                class="btn"
                                (click)="confirmBooking()"
                                *ngIf="booking.status == bookingStatus.Pending"
                            >
                                Confirm
                            </a>

                            <a
                                class="btn"
                                (click)="openBooking()"
                                *ngIf="
                                    booking.status == bookingStatus.Reserved ||
                                    booking.status ==
                                        bookingStatus.FailedDeposit ||
                                    booking.status ==
                                        bookingStatus.AwaitingDeposit
                                "
                            >
                                Open
                            </a>

                            <a
                                class="btn"
                                (click)="completeBooking()"
                                *ngIf="booking.status == bookingStatus.Open"
                                >Close Down</a
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-section">
                <div class="wrapper wrapper--large">
                    <div class="row">
                        <div class="m-col-2">
                            <h4>Customer Details</h4>
                            <div class="booking-customer-details">
                                <ul class="booking-customer-details__list">
                                    <li>
                                        <div
                                            class="booking-customer-details__label"
                                        >
                                            Telephone
                                        </div>
                                        <div>
                                            {{ booking.customer.telephone }}
                                        </div>
                                    </li>

                                    <li>
                                        <div
                                            class="booking-customer-details__label"
                                        >
                                            Email
                                        </div>
                                        <div>
                                            <a
                                                [href]="
                                                    'mailto:' +
                                                    booking.customer.email
                                                "
                                            >
                                                {{ booking.customer.email }}
                                            </a>
                                        </div>
                                    </li>

                                    <li>
                                        <div
                                            class="booking-customer-details__label"
                                        >
                                            Address
                                        </div>
                                        <div>
                                            <div>
                                                {{ booking.address_street }}
                                            </div>
                                            <div>
                                                {{ booking.address_city }}
                                            </div>
                                            <div>
                                                {{ booking.address_country }}
                                            </div>
                                            <div>
                                                {{ booking.address_postcode }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <notes-list
                                prefix="bookings"
                                [id]="booking.id"
                            ></notes-list>
                        </div>

                        <div class="m-col-8">
                            <div
                                class="content-alert content-alert--warn"
                                *ngIf="booking.status == bookingStatus.Review"
                            >
                                <h2 class="content-alert__heading">
                                    In Review
                                </h2>
                                <div class="content-alert__body">
                                    <p>
                                        This booking request requires review by
                                        Senior management. Do not discuss with
                                        customer until instructed to do so.
                                    </p>
                                </div>
                            </div>

                            <div class="content-card">
                                <div
                                    class="content-card__header content-card__header--blank"
                                >
                                    <h3 class="content-card__heading">
                                        Rental Details
                                    </h3>
                                </div>

                                <div class="content-card__body">
                                    <div class="content-card-table">
                                        <table>
                                            <tr>
                                                <th>Reference</th>
                                                <td>{{ booking.reference }}</td>
                                            </tr>
                                            <tr>
                                                <th>Vehicle</th>
                                                <td>
                                                    {{ booking.group_name }}
                                                    <a
                                                        class="link"
                                                        [routerLink]="[
                                                            '/tariff/group/' +
                                                                booking.group_id
                                                        ]"
                                                    >
                                                        Tariff Group
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>Length</th>
                                                <td>{{ booking.length }}</td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Mileage
                                                    <small>perday</small>
                                                </th>
                                                <td>{{ booking.mileage }}</td>
                                            </tr>

                                            <tr>
                                                <th>Location</th>
                                                <td>
                                                    <location-label
                                                        [locationId]="
                                                            booking.location_id
                                                        "
                                                    ></location-label>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>Start Date</th>
                                                <td>
                                                    {{
                                                        booking.start_date
                                                            | date
                                                                : 'dd/MM/yyyy h:mm a'
                                                    }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>End Date</th>
                                                <td>
                                                    {{
                                                        booking.end_date
                                                            | date
                                                                : 'dd/MM/yyyy h:mm a'
                                                    }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>Requested Date</th>
                                                <td>
                                                    {{
                                                        booking.created_date
                                                            | date
                                                                : 'dd/MM/yyyy h:mm a'
                                                    }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>Payment Type</th>
                                                <td>
                                                    {{ booking.payment_type }}
                                                </td>
                                            </tr>

                                            <tr *ngIf="booking.comment">
                                                <th>Customers Comment</th>
                                                <td>{{ booking.comment }}</td>
                                            </tr>

                                            <tr
                                                *ngIf="
                                                    booking.reservation_number
                                                "
                                            >
                                                <th>Reservation Number</th>
                                                <td>
                                                    {{
                                                        booking.reservation_number
                                                    }}
                                                </td>
                                            </tr>

                                            <tr
                                                *ngIf="booking.agreement_number"
                                            >
                                                <th>Agreement Number</th>
                                                <td>
                                                    {{
                                                        booking.agreement_number
                                                    }}
                                                </td>
                                            </tr>

                                            <tr *ngIf="!booking.coi">
                                                <th>Insurance Excess</th>
                                                <td>
                                                    {{
                                                        booking.insurance_excess
                                                    }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="content-card"
                                *ngIf="booking.insurance_licence_date"
                            >
                                <div
                                    class="content-card__header content-card__header--blank"
                                >
                                    <h3 class="content-card__heading">
                                        Insurance Qualification Details
                                    </h3>
                                </div>

                                <div class="content-card__body">
                                    <div class="content-card-table">
                                        <table>
                                            <tr>
                                                <th>Licence date</th>
                                                <td>
                                                    {{
                                                        booking.insurance_licence_date
                                                    }}
                                                </td>
                                            </tr>

                                            <tr
                                                *ngIf="
                                                    booking.insurance_licence_endorsements
                                                "
                                            >
                                                <th>Licence points</th>
                                                <td>
                                                    {{
                                                        booking.insurance_licence_point
                                                    }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Has been involved in a road
                                                    traffic accident in the last
                                                    3 years
                                                </th>
                                                <td>
                                                    {{
                                                        booking.insurance_accident
                                                            ? 'Yes'
                                                            : 'No'
                                                    }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Has a medical condition
                                                    requiring notification to
                                                    the DVLA
                                                </th>
                                                <td>
                                                    {{
                                                        booking.insurance_health
                                                            ? 'Yes'
                                                            : 'No'
                                                    }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Has been refused insurance
                                                    cover in the past
                                                </th>
                                                <td>
                                                    {{
                                                        booking.insurance_refusal
                                                            ? 'Yes'
                                                            : 'No'
                                                    }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="content-card">
                                <div
                                    class="content-card__header content-card__header--blank"
                                >
                                    <h3 class="content-card__heading">
                                        Charge Breakdown
                                    </h3>
                                </div>

                                <div class="content-card__body">
                                    <div
                                        class="booking-message booking-message--coi"
                                        *ngIf="booking.coi"
                                    >
                                        <fa-icon
                                            icon="comment-alt"
                                            class="booking-message__icon"
                                        ></fa-icon>
                                        <span class="booking-message__message"
                                            >This booking is COI only.
                                            <span *ngIf="booking.coi_estimate"
                                                >The customer is interesting in
                                                County Insurance at the rate of
                                                <strong>{{
                                                    booking.coi_estimate | gbp
                                                }}</strong>
                                                <sup>+VAT</sup>.</span
                                            >
                                        </span>
                                    </div>

                                    <table class="booking-pricing">
                                        <tr
                                            *ngFor="
                                                let charge of booking.charges
                                            "
                                        >
                                            <td>
                                                <div>
                                                    <small
                                                        class="booking-pricing__rentman-id"
                                                        *ngIf="
                                                            charge.rent_man_id
                                                        "
                                                    >
                                                        [{{
                                                            charge.rent_man_id
                                                        }}]
                                                    </small>
                                                    {{
                                                        (charge.count > 1
                                                            ? charge.count +
                                                              '&times;
                                                    '
                                                            : '') +
                                                            charge.description
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        charge.refunded_count >
                                                        0
                                                    "
                                                    class="booking-pricing__refund"
                                                >
                                                    {{
                                                        charge.refunded_count +
                                                            '&times; '
                                                    }}
                                                    Refunded
                                                </div>
                                            </td>
                                            <td class="align-right">
                                                <a
                                                    *ngIf="
                                                        charge.count >
                                                            charge.refunded_count &&
                                                        charge.type ==
                                                            'OptionalExtra' &&
                                                        canRefundExtras()
                                                    "
                                                    class="link"
                                                    (click)="
                                                        refundCharge(charge)
                                                    "
                                                    >Refund</a
                                                >
                                            </td>
                                            <td class="booking-pricing__price">
                                                <div>
                                                    {{ charge.amount | gbp }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        charge.refunded_count >
                                                        0
                                                    "
                                                    class="booking-pricing__refund"
                                                >
                                                    {{
                                                        charge.refunded_amount
                                                            | gbp
                                                    }}
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="booking-pricing__total">
                                            <td>Subtotal Before VAT</td>
                                            <td></td>
                                            <td class="booking-pricing__price">
                                                {{ booking.sub_total | gbp }}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>VAT</td>
                                            <td></td>
                                            <td class="booking-pricing__price">
                                                {{ booking.vat | gbp }}
                                            </td>
                                        </tr>

                                        <tr class="booking-pricing__total">
                                            <td>Subtotal After VAT</td>
                                            <td></td>
                                            <td
                                                class="booking-pricing__price booking-pricing__price--bold"
                                            >
                                                {{
                                                    booking.sub_total +
                                                        booking.vat | gbp
                                                }}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Deposit</td>
                                            <td></td>
                                            <td class="booking-pricing__price">
                                                {{ booking.deposit | gbp }}
                                            </td>
                                        </tr>

                                        <tr class="booking-pricing__total">
                                            <td>Grand Total</td>
                                            <td></td>
                                            <td
                                                class="booking-pricing__price booking-pricing__price--bold"
                                            >
                                                {{ booking.total | gbp }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div
                                class="content-card"
                                *ngIf="getOptionalExtras().length > 0"
                            >
                                <div
                                    class="content-card__header content-card__header--blank"
                                    (click)="
                                        showExtraAdditional = !showExtraAdditional
                                    "
                                >
                                    <h3 class="content-card__heading">
                                        Optional Extra Extension Charges
                                    </h3>
                                    <fa-icon
                                        [icon]="
                                            showExtraAdditional
                                                ? 'chevron-up'
                                                : 'chevron-down'
                                        "
                                    ></fa-icon>
                                </div>

                                <div
                                    class="content-card__body"
                                    *ngIf="showExtraAdditional"
                                >
                                    <table class="booking-pricing">
                                        <tr
                                            *ngFor="
                                                let optionExtraCharge of getOptionalExtras()
                                            "
                                        >
                                            <td>
                                                <small
                                                    class="booking-pricing__rentman-id"
                                                    *ngIf="
                                                        optionExtraCharge.rent_man_id
                                                    "
                                                    >[{{
                                                        optionExtraCharge.rent_man_id
                                                    }}]</small
                                                >
                                                {{
                                                    (optionExtraCharge.count > 1
                                                        ? optionExtraCharge.count +
                                                          '&times; '
                                                        : '') +
                                                        optionExtraCharge.description
                                                }}
                                            </td>
                                            <td
                                                class="booking-pricing__price"
                                                [ngSwitch]="
                                                    optionExtraCharge.rent_man_id ==
                                                    'LUW'
                                                "
                                            >
                                                <div *ngSwitchCase="true">
                                                    9% of RENT for additional
                                                    period
                                                </div>
                                                <div *ngSwitchCase="false">
                                                    {{
                                                        optionExtraCharge.amount /
                                                            optionExtraCharge.count /
                                                            booking.length | gbp
                                                    }}
                                                    x Length
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div
                                class="content-card"
                                *ngIf="booking.transactions.length > 0"
                            >
                                <div
                                    class="content-card__header content-card__header--blank"
                                    (click)="
                                        showTransactions = !showTransactions
                                    "
                                >
                                    <h3 class="content-card__heading">
                                        Transactions
                                    </h3>
                                    <fa-icon
                                        [icon]="
                                            showTransactions
                                                ? 'chevron-up'
                                                : 'chevron-down'
                                        "
                                    ></fa-icon>
                                </div>

                                <div
                                    class="content-card__body"
                                    *ngIf="showTransactions"
                                >
                                    <div class="content-card-table">
                                        <table>
                                            <tr
                                                *ngFor="
                                                    let transaction of booking.transactions
                                                "
                                            >
                                                <th>{{ transaction.type }}</th>
                                                <td>
                                                    {{ transaction.method }}
                                                </td>
                                                <td>
                                                    {{
                                                        transaction.amount | gbp
                                                    }}
                                                </td>
                                                <td>
                                                    <user-label
                                                        *ngIf="
                                                            transaction.user_id
                                                        "
                                                        [userId]="
                                                            transaction.user_id
                                                        "
                                                    ></user-label>
                                                </td>
                                                <td class="align-right">
                                                    <time-ago
                                                        [time]="
                                                            transaction.date
                                                        "
                                                    ></time-ago>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <activities-short-list
                                prefix="bookings"
                                [id]="booking.id"
                            ></activities-short-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
