<topbar>
    <h1 topbar-title>Users</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="content-section">
            <div class="wrapper wrapper--large">
                <div class="content-card">
                    <div class="content-list user-list">
                        <div class="content-list-toolbar">
                            <ul
                                class="content-list-toolbar__list list-horizontal"
                            >
                                <li
                                    class="content-list-toolbar__item list--item__right"
                                >
                                    <div
                                        class="content-list-toolbar__input input-group input-group--accent"
                                    >
                                        <div class="input-group__prepend">
                                            <label
                                                class="input-group__text"
                                                for="search"
                                            >
                                                <fa-icon
                                                    icon="search"
                                                ></fa-icon>
                                            </label>
                                        </div>
                                        <input
                                            type="search"
                                            name="search"
                                            id="search"
                                            class="input-group__input"
                                            [ngModel]="pagingData.filter"
                                            (input)="
                                                filterUpdate(
                                                    $event.target,
                                                    'filter'
                                                )
                                            "
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="loading < 1 && pages > 0">
                            <div class="content-list__list">
                                <users-list-item
                                    *ngFor="let user of users"
                                    [user]="user"
                                >
                                </users-list-item>
                            </div>
                            <mat-paginator
                                [length]="totalItems"
                                [pageSize]="pagingData.perpage"
                                (page)="pageChange($event)"
                                [pageIndex]="pagingData.page - 1"
                            ></mat-paginator>
                        </div>

                        <div
                            class="content-list__no-results"
                            *ngIf="
                                loading < 1 && (pages < 1 || users.length < 1)
                            "
                        >
                            No users found.
                        </div>

                        <loader *ngIf="loading > 0"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
