import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import {
    ConfirmDialogComponent,
    IConfirmDialogData,
} from '../../universal/dialogs/confirm/confirm-dialog.component';
import { NotificationsService } from '../../universal/notifications/notifications.service';
import { CustomerContactsService } from '../contacts/customers-contacts.service';
import { ICompany, IContactDetail } from '../../county-api/src/customers';
import { BreadcrumbService } from '../../universal/breadcrumbs/breadcrumb.service';
import { CustomersSageService } from '../sage/customers-sage.service';
import { CustomersSageLinkDialogComponent } from '../sage/link/customers-sage-link-dialog.component';

/**
 * The company details component used to output the individual company.
 */
@Component({
    selector: 'company-details',
    templateUrl: './company-details.component.html',
})
export class CompanyDetailsComponent implements OnInit {
    /**
     * Displaying company details.
     */
    company: ICompany;

    /**
     * Construct injecting angular dependencies.
     * @param route The current active route details.
     */
    constructor(
        private route: ActivatedRoute,
        private customerContactsService: CustomerContactsService,
        private customersSageService: CustomersSageService,
        private breadcrumbService: BreadcrumbService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog,
    ) {}

    /**
     * On init get the company data from the route data.
     */
    ngOnInit() {
        this.route.data.subscribe((data) => {
            this.company = data.company;
            this.breadcrumbService.changeBreadcrumb(
                this.route.snapshot,
                this.company.name ?? "",
            );
        });
    }

    /**
     * Return a clickable link for a contact type.
     * @param contactDetail The contact to geterate the link for.
     */
    getContactLink(contactDetail: IContactDetail) {
        return this.customerContactsService.getLink(contactDetail);
    }

    openLinkClick() {
        this.dialog
            .open(CustomersSageLinkDialogComponent)
            .afterClosed()
            .pipe(
                filter(r => !!r),
                switchMap(r =>
                    this.customersSageService.link(
                        this.company.id ?? "",
                        r.customer.sl_customer_account_id,
                    ),
                ),
            )
            .subscribe({
                next: r => {
                    this.company.sage_account_id =
                        r.result.customer.sl_customer_account_id;
                    this.company.sage_account_number =
                        r.result.customer.customer_account_name;
                    this.notificationsService.success(
                        'Successfully linked Sage account to customer.',
                    );
                },
                error: () => {
                    this.notificationsService.error(
                        'Failed to link Sage account to customer.',
                    );
                },
            });
    }

    openUnlinkClick() {
        this.dialog
            .open<ConfirmDialogComponent, IConfirmDialogData>(
                ConfirmDialogComponent,
                {
                    data: {
                        btnClass: 'btn--error',
                        title: 'Unlink customer from Sage account?',
                        message: `This will unlink this customer from the Sage account ${this.company.sage_account_number}. This will not update any invoices previously posted to Sage.`,
                    },
                    width: '250px',
                },
            )
            .afterClosed()
            .pipe(
                filter(r => !!r),
                switchMap(r =>
                    this.customersSageService.unlink(this.company.id ?? ""),
                ),
            )
            .subscribe({
                next: r => {
                    this.company.sage_account_id = null;
                    this.company.sage_account_number = null;
                    this.notificationsService.success(
                        'Successfully linked Sage account to customer.',
                    );
                },
            });
    }
}
