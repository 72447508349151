import { Component, Input, OnInit } from '@angular/core';
import { ActivitiesService, IActivityRendered } from '../activities.service';

/**
 * The activities short list component used to add a short list of activities to a page.
 */
@Component({
    selector: 'activities-short-list',
    templateUrl: './activities-short-list.component.html',
})
export class ActivitiesShortListComponent implements OnInit {
    /**
     * The prefix passed from the parent.
     */
    @Input()
    prefix: string;

    /**
     * The id passed from the parent.
     */
    @Input()
    id: string;

    /**
     * The list of activities being displayed, if any.
     */
    activities: IActivityRendered[];

    /**
     * Total count of pages for the current entity, this is based on 5 items per page so we
     * purely use this as an indication of whether we need to link to the activities page or
     * not.
     */
    pages: number;

    /**
     * Toggle of if to show the activities or not.
     */
    show = false;

    /**
     * Loading state of the activities request.
     */
    loading = 0;

    /**
     * Constructs injected angular dependencies.
     * @param activitiesService The activities service used to get all activities for the list.
     */
    constructor(private activitiesService: ActivitiesService) {}

    ngOnInit() {
        this.activitiesService
            .get(this.prefix, this.id, { page: 1, perpage: 5 })
            .pipe(this.activitiesService.mapActivities())
            .subscribe(
                response => {
                    // Store the activities and the page count.
                    this.activities = response.result.activities;
                    this.pages = response.result.paging.total_pages;
                },
                null,
                () => {
                    this.loading--;
                },
            );
    }

    /**
     * The toggle click for showing activities, will request the activities the first time this
     * is triggered.
     */
    showActivitiesClick() {
        // If the activities are null we havent requested any yet.
        if (this.activities == null) {
            // Set the activities so we dont make a request while a request is still running.
            this.activities = [];
            this.loading++;

            // Make the request to the api to get the activites for the current entity.
            this.activitiesService
                .get(this.prefix, this.id, { page: 1, perpage: 5 })
                .pipe(this.activitiesService.mapActivities())
                .subscribe(
                    response => {
                        // Store the activities and the page count.
                        this.activities = response.result.activities;
                        this.pages = response.result.paging.total_pages;
                    },
                    null,
                    () => {
                        this.loading--;
                    },
                );
        }

        // Toggle the show state variable.
        this.show = !this.show;
    }
}
