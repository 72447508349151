<stack
    class="alert__body"
    [pVertical]="!pFlat"
    [pAlignment]="!pFlat ? '' : 'center'"
    pSpacing="x-small"
>
    <div class="alert__title">{{ pTitle }}</div>

    <div class="alert__content">
        <ng-content></ng-content>
    </div>

    <ng-container *ngIf="!pActions; else actionsTemplate">
        <stack
            pDistribution="trailing"
            pSpacing="xx-small"
            *ngIf="pSecondaryActionLabel || pActionLabel"
        >
            <button
                *ngIf="pSecondaryActionLabel"
                pButton
                [pVariant]="pVariant || 'info'"
                pSubtle
                pSize="small"
                (click)="pSecondaryActionClick.emit()"
            >
                {{ pSecondaryActionLabel }}
            </button>
            <button
                *ngIf="pActionLabel"
                pButton
                [pVariant]="pVariant || 'info'"
                pSize="small"
                (click)="pActionClick.emit()"
            >
                {{ pActionLabel }}
            </button>
        </stack>
    </ng-container>
</stack>

<a class="alert__close" *ngIf="pClosable" (click)="pCloseClick.emit()">
    <fa-icon icon="times"></fa-icon>
</a>

<ng-template #actionsTemplate>
    <div class="alert__custom-actions">
        <ng-container *ngTemplateOutlet="pActions"></ng-container>
    </div>
</ng-template>
