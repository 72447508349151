import {
    Component,
    ComponentFactoryResolver,
    Inject,
    OnInit,
    Type,
    ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash/get';
import { takeUntil, tap } from 'rxjs/operators';
import { DestroyController } from '../../components/destroy.controller';
import {
    ConfirmContentDirective,
    IConfirmContentComponent,
} from './content/confirm-content.directive';

export interface IConfirmDialogData {
    title?: string;
    message?: string;
    btnLabel?: string;
    btnClass?: string;

    disableConfirm?: boolean;

    reason?: boolean;
    reasonLabel?: string;

    messageComponent?: Type<any>;

    messageComponentData?: any;
}

export interface IConfirmDialogResult {
    reason?: string;
}

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends DestroyController
    implements OnInit {
    title = 'Confirm';

    message: string = 'Please confirm your action';

    btnLabel = 'Confirm';

    btnClass: string;

    disableConfirm = false;

    reason = false;

    reasonLabel = 'Reason';

    result: IConfirmDialogResult = {};

    messageComponent: Type<any> | null;

    messageComponentData: any;

    @ViewChild(ConfirmContentDirective, { static: true })
    confirmContentHost: ConfirmContentDirective;

    constructor(
        private dialogRef: MatDialogRef<
            ConfirmDialogComponent,
            IConfirmDialogResult
        >,
        @Inject(MAT_DIALOG_DATA) data: IConfirmDialogData,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {
        super();
        this.title = get(data, 'title', this.title);
        this.message = get(data, 'message', this.message);
        this.btnLabel = get(data, 'btnLabel', this.btnLabel);
        this.btnClass = get(data, 'btnClass', this.btnClass);
        this.disableConfirm = get(data, 'disableConfirm', this.disableConfirm);
        this.reason = get(data, 'reason', this.reason);
        this.reasonLabel = get(data, 'reasonLabel', this.reasonLabel);
        this.messageComponent = get(data, 'messageComponent', null);
        this.messageComponentData = get(data, 'messageComponentData', null);
    }

    ngOnInit() {
        if (this.messageComponent) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                this.messageComponent,
            );

            const viewContainerRef = this.confirmContentHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(
                componentFactory,
            );
            const component = <IConfirmContentComponent>componentRef.instance;
            component.data = this.messageComponentData;

            if (component.disabled$) {
                component.disabled$
                    .pipe(
                        tap(disabled => {
                            this.disableConfirm = disabled;
                        }),
                        takeUntil(this.unsubscribe$),
                    )
                    .subscribe();
            }
        }
    }

    submit() {
        this.dialogRef.close(this.result);
    }
}
