import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeleteDialogComponent } from '../universal/dialogs/delete-dialog/delete-dialog.component';
import { NotificationsService } from '../universal/notifications/notifications.service';
import {
    IPagingParams,
    IRequestPaging,
    PagingController,
} from '../universal/paging';
import { EndorsementAddDialogComponent } from './add/endorsement-add-dialog.component';
import { EndorsementService } from './endorsement.service';
import { IEndorsement } from '../county-api/src/endorsements';

/**
 * The endorsement dialog used to get, create, update and delete 
 */
@Component({
    selector: 'endorsement',
    templateUrl: './endorsement.component.html',
})
export class EndorsementComponent extends PagingController<IRequestPaging> {
    /**
     * Query details for collecting the jobs from the api.
     */
    pagingData: IRequestPaging = {
        page: 1,
        filter: null,
        perpage: null,
    };

    /**
     * Determine the loading state of the page.
     */
    loading = 0;

    /**
     * The array of 
     */
    endorsements: IEndorsement[] = [];

    /**
     * The last active request subscription used for cancelling of the request.
     */
    private request: Subscription;

    /**
     * Contructs injected angular depencies.
     * @param endorsementService The endorsement service to get the 
     * @param router Router service used to update the url with the new query strings.
     * @param route Needed for the paging component to gather the current query string params.
     * @param dialog The dialog functionality to open the dialog and pass the data.
     */
    constructor(
        private endorsementService: EndorsementService,
        router: Router,
        route: ActivatedRoute,
        private dialog: MatDialog,
        private notifications: NotificationsService,
    ) {
        super(router, route);
    }

    /**
     * Update the local paging data from the url params.
     * @param params The query params from the url.
     */
    updateLocalParams(params: IPagingParams) {
        this.endorsements = [];
        this.pages = 0;

        // Gather the paging information from the url.
        this.pagingData = {
            page: params['page'] ? parseInt(params['page'], 10) || 1 : 1,
            filter: params['filter'] ? params['filter'] : null,
            perpage: params['perpage']
                ? parseInt(params['perpage'], 10) || 10
                : 10,
        } as IRequestPaging;

        // Floor the page number to 1.
        if (this.pagingData.page < 1) {
            this.pagingData.page = 1;
        }

        return this.pagingData;
    }

    /**
     * Load the latest set of data based on the passed in pagingData.
     * @param pagingData The data returned from updateLocalParams.
     */
    updateData(pagingData: IRequestPaging) {
        this.loading++;

        // If any active request cancel it and then decrease the loader.
        if (this.request && !this.request.closed) {
            this.request.unsubscribe();
            this.loading--;
        }

        // Make a new request for the job details. Handle both success and error states.
        this.request = this.endorsementService
            .get(pagingData)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                response => {
                    this.endorsements = response.result.endorsements;
                    this.pages = response.result.paging.total_pages;
                    this.totalItems = response.result.paging.total;
                },
                () => {
                    this.pages = 0;
                },
                () => this.loading--,
            );
    }

    /**
     * Opens the add dialog and pushes the new endorsement to the endorsements array.
     */
    addEndorsementClick() {
        const openDialog = this.dialog.open(EndorsementAddDialogComponent, {
            width: '500px',
            data: { endorsement: {} },
        });

        // Pushes a new endorsement to the array of 
        openDialog
            .afterClosed()
            .subscribe((endorsement: IEndorsement) => {
                if (endorsement) {
                    this.endorsementService.create(endorsement).subscribe(
                        response => {
                            this.endorsements.push(response.result.endorsement);
                            this.notifications.success(
                                `Endorsement ${endorsement.code} successfully added.`,
                            );
                        },
                        () => {
                            this.notifications.error(
                                'Failed to create endorsement',
                            );
                        },
                    );
                }
            });
    }

    /**
     * Opens the add dialog and updates an existing endorsement.
     * @param index The index of the endorsement in the endorsements array.
     * @param endorsement The endorsement data passed in to the dialog.
     */
    editEndorsementClick(
        index: number,
        endorsement: IEndorsement,
    ) {
        const endorsementClone = Object.assign({}, endorsement);

        const openDialog = this.dialog.open(EndorsementAddDialogComponent, {
            width: '500px',
            data: { endorsement: endorsementClone },
        });

        // Overrides the previous endorsement with the edited endorsement.
        openDialog
            .afterClosed()
            .subscribe((editedEndorsement: IEndorsement) => {
                if (editedEndorsement) {
                    this.endorsementService
                        .edit(endorsement.code ?? "", editedEndorsement)
                        .subscribe(
                            response => {
                                this.endorsements[index] =
                                    response.result.endorsement;
                                this.notifications.success(
                                    `Endorsement ${editedEndorsement.code} successfully edited.`,
                                );
                            },
                            () => {
                                this.notifications.error(
                                    'Failed to update endorsement.',
                                );
                            },
                        );
                }
            });
    }

    /**
     * Opens the delete dialog and deletes the endorsement from the endorsements array.
     * @param index The index of the endorsement from the endorsements array.
     * @param endorsement The endorsement data.
     */
    deleteEndorsementClick(
        index: number,
        endorsement: IEndorsement,
    ) {
        // const openDialog = this.dialog.open(DeleteDialogComponent, {
        //     width: '500px',
        // });

        // // Splices the endorsement by its endorsement code from the endorsements array.
        // openDialog.afterClosed().subscribe(result => {
        //     if (result) {
        //         this.endorsementService.delete(endorsement.code).subscribe(
        //             response => {
        //                 this.splice(index, 1);
        //                 this.notifications.success(
        //                     `Endorsement ${endorsement.code} successfully deleted.`,
        //                 );
        //             },
        //             () => {
        //                 this.notifications.error(
        //                     'Failed to delete endorsement.',
        //                 );
        //             },
        //         );
        //     }
        // });
    }
}
