<topbar>
    <h1 topbar-title>Customer Jobs</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="wrapper wrapper--small">
            <div class="content-section">
                <div class="content-card">
                    <div class="content-card__body">
                        <ul class="form">
                            <li class="form__item">
                                <label class="input-label">Search</label>

                                <input
                                    type="search"
                                    name="jobs_search"
                                    id="jobs_search"
                                    [(ngModel)]="pagingData.filter"
                                    (input)="
                                        filterUpdate($event.target, 'filter')
                                    "
                                />

                                <a class="btn btn--primary" (click)="addJob()"
                                    >Add New</a
                                >
                            </li>
                        </ul>
                        <div *ngIf="loading < 1 && pages > 0">
                            <ul class="list-styled">
                                <li
                                    class="list-styled__item"
                                    *ngFor="let job of jobs"
                                >
                                    <div class="list-styled__label">
                                        {{ job.title }}
                                    </div>

                                    <ul class="list-styled__actions">
                                        <li class="list-styled__action">
                                            <a
                                                (click)="editJob(job.id, job)"
                                                class="list-styled__action-link"
                                            >
                                                <fa-icon
                                                    icon="pencil"
                                                ></fa-icon>
                                            </a>
                                        </li>
                                        <li class="list-styled__action">
                                            <a
                                                (click)="deleteJob(job.id)"
                                                class="list-styled__action-link"
                                            >
                                                <fa-icon icon="times"></fa-icon>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <mat-paginator
                                [length]="totalItems"
                                [pageSize]="pagingData.perpage"
                                (page)="pageChange($event)"
                                [pageIndex]="pagingData.page - 1"
                            ></mat-paginator>
                        </div>

                        <div
                            class="content-list__no-results"
                            *ngIf="
                                loading < 1 && (pages < 1 || jobs.length < 1)
                            "
                        >
                            No jobs found.
                        </div>

                        <loader *ngIf="loading > 0"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
