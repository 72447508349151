import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResponse, Locations } from 'county-api';
import { ApiConstant } from '../configs/api.constant';

@Injectable()
export class LocationsService {
    constructor(private service: HttpClient) {}

    get() {
        return this.service.get<IApiResponse<Locations.ILocationsViewModel>>(
            ApiConstant.url + 'locations',
        );
        // return of<ILocation[]>([
        //     { id: 0, name: 'Stockport', ip: '88.98.71.192/27' },
        //     { id: 4, name: 'Preston', ip: '82.68.244.16/29' },
        //     { id: 5, name: 'Warrington', ip: '82.68.219.184/29' },
        // ]);
    }
}
