import { Component, Input } from '@angular/core';
import { Bookings } from 'county-api';

/**
 * The booking customer list item used to output a singular booking.
 */
@Component({
    selector: 'bookings-customer-booking-list-item',
    templateUrl: './bookings-customer-booking-list-item.component.html',
})
export class BookingsCustomerBookingListItemComponent {
    /**
     * The customers booking input from the parent.
     */
    @Input()
    booking: Bookings.IBooking;
}
