import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Customers } from 'county-api';
import { CompaniesService } from '../companies/companies.service';

/**
 * Maps the data from the companies service for the company of the given id.
 */
@Injectable()
export class CompanyResolve implements Resolve<Customers.ICompany | boolean> {
    constructor(
        private companiesService: CompaniesService,
        private router: Router,
    ) {}

    /**
     * Uses the current route and maps the passed in id to the url to get the company data.
     */
    resolve(route: ActivatedRouteSnapshot) {
        return this.companiesService.getById(route.paramMap.get('id') ?? "").pipe(
            map(data => data.result.company),
            catchError(error => {
                // Delay the navigation in order to maintain the url states.
                setTimeout(() =>
                    this.router.navigate(['/companies'], {
                        skipLocationChange: true,
                    }),
                );
                return observableOf(false);
            }),
        );
    }
}
