import { AbstractControl } from '@angular/forms';

export class NgxErrorElement {
    errorNames: string[] = [];

    controlId?: string;

    hide() {}
    show(error: NgxErrorDetails) {}
}

export class NgxErrorDetails {
    control: AbstractControl;
    errorName: string;
    data?: any;
}
