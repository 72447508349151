import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, CurrencyPipe } from '@angular/common';
import {
    Injectable,
    LOCALE_ID,
    ModuleWithProviders,
    NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatMomentDateModule,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
    MatNativeDateModule,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MAT_RIPPLE_GLOBAL_OPTIONS,
    RippleGlobalOptions,
    DateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatDialogConfig,
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import {
    MatMenuDefaultOptions,
    MatMenuModule,
    MAT_MENU_DEFAULT_OPTIONS,
} from '@angular/material/menu';
import {
    MatPaginatorDefaultOptions,
    MatPaginatorModule,
    MAT_PAGINATOR_DEFAULT_OPTIONS,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { MsalModule } from '@azure/msal-angular';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgProgressModule } from 'ngx-progressbar';
import { HasPermissionDirective } from '../users/has-permissions.directive';
import { AgePipe } from './age/age.pipe';
 import { BreadcrumbService } from './breadcrumbs/breadcrumb.service';
import {
    DEFAULT_NOTIFICATION_SETTINGS,
    PushNotificationService,
} from './browser-notifications/push-notification.service';
import { ConfirmDialogComponent } from './dialogs/confirm/confirm-dialog.component';
import { ConfirmContentDirective } from './dialogs/confirm/content/confirm-content.directive';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { ForceReloadDialogComponent } from './dialogs/force-reload-dialog.component';
import { ImagePreviewGroupDirective } from './dialogs/image-preview/group/image-preview-group.directive';
import { ImagePreviewOverlayComponent } from './dialogs/image-preview/image-preview-overlay.component';
import { ImagePreviewOverlayService } from './dialogs/image-preview/image-preview-overlay.service';
import { ImagePreviewDirective } from './dialogs/image-preview/image-preview.directive';
import { UniversalFontsawesomeModule } from './fontawesome/universal-fontsawesome.module';
import { CustomDatepickerComponent } from './forms/datepicker/custom-datepicker.component';
import { TextDatepickerComponent } from './forms/text-datepicker/text-datepicker.component';
import { DebounceDirective } from './forms/debounce.directive';
import { NgtErrorDirective } from './forms/errors/ngterror.directive';
import { NgxErrorDefaultDirective } from './forms/errors/ngxerror-default.directive';
import { NgxErrorDirective } from './forms/errors/ngxerror.directive';
import { NgxErrorsDirective } from './forms/errors/ngxerrors.directive';
import { FileComponent } from './forms/files/file.component';
import { InvalidValueValidationDirective } from './forms/invalid-value-validation.directive';
// import { MathsStringValidationDirective } from './forms/maths/maths-string.directive';
// import { MaxDirective } from './forms/max/max.directive';
// import { MinDirective } from './forms/min/min.directive';
// import { MY_DATE_FORMATS } from './forms/my-date-formats';
// import { ProvideParentFormDirective } from './forms/provide-parent-form.directive';
import { SelectionInputDialogComponent } from './forms/selection/dialog/selection-input-dialog.component';
import { SelectionInputComponent } from './forms/selection/selection-input.component';
import { SelectionInputItemDirective } from './forms/selection/templates/selection-input-item.directive';
import { SelectionInputResultDirective } from './forms/selection/templates/selection-input-result.directive';
// import { StripLeadingZeroDirective } from './forms/strip-leading.directive';
import { TagListInputComponent } from './forms/tag-list/tag-list-input.component';
import { TimePickerInputComponent } from './forms/timepicker/time-picker-input.component';
// import { TitleCaseInputDirective } from './forms/titlecase.directive';
// import { LowerCaseInputDirective } from './forms/uppercase.directive';
import { ValidSubmitButtonDirective } from './forms/valid-submit/button/valid-submit-button.directive';
import { ValidSubmitDirective } from './forms/valid-submit/valid-submit.directive';
import { EmptyRouterLinkDirective } from './link/router-link.directive';
import { LoaderComponent } from './loading/loader.component';
import { LoadingComponent } from './loading/loading.component';
 import { NotificationsService } from './notifications/notifications.service';
import { OperatorSelectionComponent } from './operator/operator-selection.component';
import { GbpPipe } from './pipes/gbp.pipe';
import { TimeagoPipe } from './pipes/timeago.pipe';
import { PrintElementDirective } from './print/print-element.directive';
import { RouterLinkClickDirective } from './routerLink/click/router-link-click.directive';
import { CountryCodesService } from './services/country-codes.service';
import { VarDirective } from './template/var.directive';
import { TimeAgoComponent } from './time/time-ago.component';
import { TitleService } from './title/title.service';
import { NameService } from './name/name.service';

import { UriPipe } from './uri/pipe/uri.pipe';
import { UriService } from './uri/uri.service';
import { AppDateAdapter } from './forms/datepicker/date-format';

@Injectable()
export class CustomDialogConfig extends MatDialogConfig {
    panelClass = 'dialog';

    minWidth = '350px';
}

export function currencyFactory() {
    return new CurrencyPipe('en-GB');
}

export function MAT_MENU_OPTIONS_FACTORY(): MatMenuDefaultOptions {
    return {
        overlapTrigger: false,
        xPosition: 'after',
        yPosition: 'below',
        backdropClass: 'cdk-overlay-transparent-backdrop',
    };
}

// @NgModule({
//     imports: [
//         CommonModule,
//         MsalModule,
//         FormsModule,
//         ReactiveFormsModule,
//         MatAutocompleteModule,
//         MatDialogModule,
//         MatPaginatorModule,
//         MatTooltipModule,
//         MatSnackBarModule,
//         MatListModule,
//         MatNativeDateModule,
//         MatDatepickerModule,
//         MatMomentDateModule,
//         MatSelectModule,
//         MatSidenavModule,
//         MatSliderModule,
//         MatProgressBarModule,
//         MatGridListModule,
//         MatCheckboxModule,
//         MatRadioModule,
//         MatMenuModule,
//         MatTabsModule,
//         MatTreeModule,
//         MatDividerModule,
//         MatFormFieldModule,
//         MatInputModule,
//         ScrollingModule,
//         RouterModule,
//         UniversalFontsawesomeModule
//     ],
//     declarations: [
//         // AgePipe,
//         // ConfirmContentDirective,
//         // ConfirmDialogComponent,
//         // CustomDatepickerComponent,
//         // DebounceDirective,
//         // DeleteDialogComponent,
//         // EmptyRouterLinkDirective,
//         // FileComponent,
//         // ForceReloadDialogComponent,
//         // GbpPipe,
//         // InvalidValueValidationDirective,
//         // LoaderComponent,
//         // LoadingComponent,
//         // LowerCaseInputDirective,
//         // MathsStringValidationDirective,
//         // MaxDirective,
//         // MinDirective,
//         // NgxErrorDefaultDirective,
//         // NgxErrorDirective,
//         // NgtErrorDirective,
//         // NgxErrorsDirective,
//         // OperatorSelectionComponent,
//         // PrintElementDirective,
//         // ProvideParentFormDirective,
//         // RouterLinkClickDirective,
//         // TagListInputComponent,
//         // SelectionInputComponent,
//         // SelectionInputDialogComponent,
//         // TimeAgoComponent,
//         // TimeagoPipe,
//         // TimePickerInputComponent,
//         // TitleCaseInputDirective,
//         // UriPipe,
//         // ValidSubmitButtonDirective,
//         // ValidSubmitDirective,
//         // VarDirective
//         // ImagePreviewOverlayComponent,
//         // ImagePreviewDirective,
//         // ImagePreviewGroupDirective,
//         // SelectionInputResultDirective,
//         // SelectionInputItemDirective,
//         // StripLeadingZeroDirective,
//     ],
//     exports: [
//         // AgePipe,
//         // CommonModule,
//         // CustomDatepickerComponent,
//         // DebounceDirective,
//         // DeleteDialogComponent,
//         // EmptyRouterLinkDirective,
//         // FileComponent,
//         // FormsModule,
//         // GbpPipe,
//         // Object,
//         // InvalidValueValidationDirective,
//         // LoaderComponent,
//         // LoadingComponent,
//         // LowerCaseInputDirective,
//         // MatAutocompleteModule,
//         // MatCheckboxModule,
//         // MatDatepickerModule,
//         // MatDialogModule,
//         // MatDividerModule,
//         // MatGridListModule,
//         // MathsStringValidationDirective,
//         // MatInputModule,
//         // MatListModule,
//         // MatListModule,
//         // MatMenuModule,
//         // MatNativeDateModule,
//         // MatPaginatorModule,
//         // MatProgressBarModule,
//         // MatRadioModule,
//         // MatSelectModule,
//         // MatSidenavModule,
//         // MatSliderModule,
//         // MatSnackBarModule,
//         // MatStepperModule,
//         // MatTabsModule,
//         // MatTooltipModule,
//         // MatTreeModule,
//         // MatFormFieldModule,
//         // MaxDirective,
//         // MinDirective,
//         // NgProgressModule,
//         // DragDropModule,
//         // NgxErrorDefaultDirective,
//         // NgxErrorDirective,
//         // NgtErrorDirective,
//         // NgxErrorsDirective,
//         // NgxMatSelectSearchModule,
//         // OperatorSelectionComponent,
//         // PrintElementDirective,
//         // ProvideParentFormDirective,
//         // ReactiveFormsModule,
//         // RouterLinkClickDirective,
//         // RouterModule,
//         // ScrollingModule,
//         // TagListInputComponent,
//         // SelectionInputComponent,
//         // SelectionInputResultDirective,
//         // SelectionInputItemDirective,
//         // TimeAgoComponent,
//         // TimeagoPipe,
//         // TimePickerInputComponent,
//         // TitleCaseInputDirective,
//         // UniversalFontsawesomeModule,
//         // UriPipe,
//         // ValidSubmitButtonDirective,
//         // ValidSubmitDirective,
//         // VarDirective,
//         // ImagePreviewDirective,
//         // ImagePreviewGroupDirective,
//         // StripLeadingZeroDirective
//     ],
//     providers: [
//         { provide: CurrencyPipe, useFactory: currencyFactory },
//         // {
//         //     provide: DateAdapter,
//         //     useClass: MomentDateAdapter,
//         //     deps: [MAT_DATE_LOCALE],
//         // },
//         // { provide: DEFAULT_NOTIFICATION_SETTINGS, useValue: {} },
//         { provide: LOCALE_ID, useValue: 'en-GB' },
//         { provide: MAT_DATE_FORMATS, useValue: {} },
//         { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
//         {
//             provide: MAT_DIALOG_DEFAULT_OPTIONS,
//             useClass: CustomDialogConfig,
//         },
//         {
//             provide: MAT_MENU_DEFAULT_OPTIONS,
//             useFactory: MAT_MENU_OPTIONS_FACTORY,
//         },
//         {
//             provide: MAT_RIPPLE_GLOBAL_OPTIONS,
//             useValue: {
//                 disabled: true,
//                 animation: { disabled: true },
//             } as RippleGlobalOptions,
//         },
//         {
//             provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
//             useValue: {
//                 pageSizeOptions: [10, 25, 50],
//             } as MatPaginatorDefaultOptions,
//         },
//         {
//             provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
//             useValue: { useUtc: true },
//         },
//         // CountryCodesService,

//         // PushNotificationService,
//         // TitleService,
//         // UriService
//         // ImagePreviewOverlayService,
//     ]
// })
@NgModule({
    imports: [
        MatSelectModule, 
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgProgressModule,
        UniversalFontsawesomeModule,
        RouterModule,
        MatDialogModule,
        MatSidenavModule,
        MatGridListModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatNativeDateModule,
        MatMenuModule,
        MatFormFieldModule,
        MatRadioModule,
        MatTooltipModule,
        MatTabsModule,
        MatDividerModule,
        MatTreeModule,
        MatSliderModule
    ],
    declarations: [
        LoaderComponent,
        LoadingComponent,
        UriPipe,
        TimeagoPipe,
        GbpPipe,
        AgePipe,
        ConfirmContentDirective,
        RouterLinkClickDirective,
        EmptyRouterLinkDirective,
        HasPermissionDirective,
        NgxErrorDefaultDirective,
        InvalidValueValidationDirective,
        NgxErrorDirective,
        NgtErrorDirective,
        NgxErrorsDirective,
        ValidSubmitDirective,
        ImagePreviewDirective,
        ImagePreviewGroupDirective,
        ValidSubmitButtonDirective,
        SelectionInputItemDirective,
        SelectionInputResultDirective,
        DebounceDirective,
        PrintElementDirective,
        ImagePreviewOverlayComponent,
        TimeAgoComponent,
        DeleteDialogComponent,
        CustomDatepickerComponent,
        TextDatepickerComponent,
        TimePickerInputComponent,
        ConfirmDialogComponent,
        TagListInputComponent,
        FileComponent,
        SelectionInputDialogComponent,
        SelectionInputComponent,
        OperatorSelectionComponent,
        ForceReloadDialogComponent
    ],
    exports: [
        MatSelectModule, 
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgProgressModule,
        UniversalFontsawesomeModule,
        LoaderComponent,
        LoadingComponent,
        UriPipe,
        TimeagoPipe,
        GbpPipe,
        AgePipe,
        RouterModule,
        RouterLinkClickDirective,
        EmptyRouterLinkDirective,
        HasPermissionDirective,
        NgxErrorDefaultDirective,
        NgxErrorDirective,
        NgtErrorDirective,
        NgxErrorsDirective,
        ValidSubmitDirective,
        InvalidValueValidationDirective,
        ImagePreviewDirective,
        ImagePreviewGroupDirective,
        ValidSubmitButtonDirective,
        SelectionInputItemDirective,
        SelectionInputResultDirective,
        DebounceDirective,
        PrintElementDirective,
        TimeAgoComponent,
        MatDialogModule,
        MatSidenavModule,
        MatGridListModule,
        MatPaginatorModule,
        MatSnackBarModule,
        DeleteDialogComponent,
        MatDatepickerModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatNativeDateModule,
        CustomDatepickerComponent,
        TextDatepickerComponent,
        TimePickerInputComponent,
        TagListInputComponent,
        FileComponent,
        SelectionInputComponent,
        OperatorSelectionComponent,
        MatMenuModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        MatRadioModule,
        MatTooltipModule,
        DragDropModule,
        MatTabsModule,
        MatDividerModule,
        MatTreeModule,
        MatSliderModule
    ],
    providers: [
        { provide: DEFAULT_NOTIFICATION_SETTINGS, useValue: {} },
        { provide: DEFAULT_NOTIFICATION_SETTINGS, useValue: {} },
        { provide: CurrencyPipe, useFactory: currencyFactory },
        { provide: LOCALE_ID, useValue: 'en-GB' },
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useClass: CustomDialogConfig,
        },
        {
            provide: MAT_MENU_DEFAULT_OPTIONS,
            useFactory: MAT_MENU_OPTIONS_FACTORY,
        },
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS,
            useValue: {
                disabled: true,
                animation: { disabled: true },
            } as RippleGlobalOptions,
        },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: {
                pageSizeOptions: [10, 25, 50],
            } as MatPaginatorDefaultOptions,
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        CountryCodesService,
        PushNotificationService,
        TitleService,
        NameService,
        UriService,
        ImagePreviewOverlayService
    ]
})
export class UniversalModule {
    static forRoot(): ModuleWithProviders<UniversalModule> {
        return {
            ngModule: UniversalModule,
            providers: [BreadcrumbService, NotificationsService],
        };
    }
}
