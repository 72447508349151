export class FileExtensions {
    static images = ['jpg', 'gif', 'jpeg', 'png'];
    static documents = ['doc', 'docx', 'txt', 'rtf', 'xls', 'xlsx', 'pdf'];
    static all = [...FileExtensions.images, ...FileExtensions.documents];
    static getIcon(ext: string) {
        switch (ext) {
            case 'image/jpeg':
            case 'image/png':
                return 'file-image';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'file-word';
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'file-excel';
            case 'application/pdf':
                return 'file-pdf';
        }
        return 'file';
    }
}
