import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniversalModule } from '../universal/universal.module';
import { UserLabelComponent } from './label/user-label.component';
import { LocationChangeDialogComponent } from 'src/app/users/locations/change/location-change-dialog.component';

@NgModule({
    imports: [CommonModule, UniversalModule],
    declarations: [LocationChangeDialogComponent, UserLabelComponent],
    exports: [LocationChangeDialogComponent, UserLabelComponent],
})
export class UsersComponentsModule {}
