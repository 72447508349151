<div
    class="content-list__item content-list__item--simple grid"
    (click)="activityClick(activity, $event)"
    [ngClass]="{ 'content-list__link': getUrl(activity) }"
>
    <div class="row row--align-center">
        <h4 class="content-list__title m-col-1">{{ activity.type }}</h4>
        <div
            class="content-list__description m-col"
            [innerHTML]="activity.text_rendered"
        ></div>

        <time-ago
            class="activities-list__date m-col-2 align-right"
            [time]="activity.date"
        ></time-ago>
        <user-label
            class="activities-list__user m-col-auto"
            [userId]="activity.user_id"
            *ngIf="showUser && activity.user_id"
        ></user-label>
    </div>
</div>
