import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isEmptyInputValue } from './array.validators';

export class CustomValidators {
    static numberMinLength(minLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null; // don't validate empty values to allow optional controls
            }
            const length: number = control.value
                ? control.value.toString().length
                : 0;
            return length < minLength
                ? {
                      minlength: {
                          requiredLength: minLength,
                          actualLength: length,
                      },
                  }
                : null;
        };
    }

    static numberMaxLength(maxLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null; // don't validate empty values to allow optional controls
            }
            const length: number = control.value
                ? control.value.toString().length
                : 0;
            return length > maxLength
                ? {
                      maxlength: {
                          requiredLength: maxLength,
                          actualLength: length,
                      },
                  }
                : null;
        };
    }

    static invalidValues(values: string | string[]): ValidatorFn {
        if (!Array.isArray(values)) {
            values = [values];
        }

        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null; // don't validate empty values to allow optional controls
            }

            return values.indexOf(control.value) !== -1
                ? { invalidValue: true }
                : null;
        };
    }

    static requiredAll(siblingControlNames: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let hasValue = false;
            for (const siblingControlName of siblingControlNames) {
                const siblingContol = control.get(siblingControlName);
                if (
                    siblingContol.value !== null &&
                    siblingContol.value !== '' &&
                    siblingContol.value !== false
                ) {
                    hasValue = true;
                } else if (hasValue) {
                    return {
                        requiredAll: {
                            siblingControlName: siblingControlNames,
                        },
                    };
                }
            }
        };
    }

    static requiredIf(siblingControlNames: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            for (const siblingControlName of siblingControlNames) {
                const siblingContol = control.get(siblingControlName);
                if (
                    siblingContol.value != null &&
                    siblingContol.value != '' &&
                    siblingContol.value != false
                ) {
                    return null;
                }
            }

            return {
                requiredIf: {
                    siblingControlName: siblingControlNames,
                },
            };
        };
    }

    static requireEqual(
        value: string | string[],
        caseSensitive = false,
    ): ValidatorFn {
        const values = !Array.isArray(value) ? [value] : value;

        return (control: AbstractControl): ValidationErrors | null => {
            const controlValue = control.value;
            if (
                values.find(v =>
                    caseSensitive
                        ? v === controlValue
                        : v.toLowerCase() === controlValue.toLowerCase(),
                )
            ) {
                return;
            }
            return {
                requireEqual: {
                    shouldEqual: values,
                    actual: control.value,
                },
            };
        };
    }

    static decimal = Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/);
    static integer = Validators.pattern(/^[0-9]+$/);

    /**
     * Validator to ensure an image is larger than the entered size.
     */
    static imageSize(sizes: { minWidth: number; minHeight: number }) {
        return (c: AbstractControl) => {
            const files = c.value as File[];
            if (!files || files.length === 0) {
                return EMPTY;
            }
            const mimeType = files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                return EMPTY;
            }
            return Observable.create((observer: Subject<boolean>) => {
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = _event => {
                    const img = new Image();

                    // Checks the size of the image.
                    img.onload = function() {
                        const idealWidth =
                            sizes && sizes.minWidth ? sizes.minWidth : 0;
                        const idealHeight =
                            sizes && sizes.minHeight ? sizes.minWidth : 0;

                        // Perform minor validation of the image.
                        if (
                            (this as HTMLImageElement).width <= idealWidth ||
                            (this as HTMLImageElement).height <= idealHeight
                        ) {
                            observer.error(null);
                        } else {
                            observer.next(null);
                        }

                        observer.complete();
                    };

                    img.onerror = function() {
                        observer.error(null);
                        observer.complete();
                    };

                    img.src = reader.result as string;
                };
            }).pipe(catchError(() => of({ imageValidate: true })));
        };
    }
}
