import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
    @Input()
    get hideBreadcrumbs(): boolean {
        return this._hideBreadcrumbs;
    }
    set hideBreadcrumbs(value: boolean) {
        this._hideBreadcrumbs = coerceBooleanProperty(value);
    }
    private _hideBreadcrumbs = false;

    debug = environment.debug;
}
