import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

export class ArrayValidators {
    // max length
    public static maxLength(max: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormArray)) {
                return;
            }
            return control.length > max ? { maxLength: true } : null;
        };
    }

    // min length
    public static minLength(min: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormArray)) {
                return;
            }
            return control.length < min ? { minLength: true } : null;
        };
    }

    /**
     * Simple validator to ensure at least min amount of items in the form array
     * match the predicate.
     */
    public static minLengthWhen(
        min: number,
        predicate: (v: any) => boolean,
    ): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormArray)) {
                return;
            }

            let validCount = 0;
            const value = control.value;
            for (let c = 0; c < value.length; c++) {
                if (predicate(value[c])) {
                    validCount++;
                }
            }

            return validCount < min ? { minLength: true } : null;
        };
    }

    public static minLengthBoolean(min: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormArray)) {
                return;
            }
            let truthyValues = 0;

            for (let c = 0; c < control.length; c++) {
                if (control.at(c).value === true) {
                    truthyValues++;
                }
            }

            return truthyValues < min ? { minLength: true } : null;
        };
    }
}
