<ng-content select="fa-icon"></ng-content>

<span class="btn__content">
    <ng-content></ng-content>
</span>

<fa-icon
    *ngIf="pConnected || pConnected === ''"
    class="btn__connected-icon"
    [icon]="['far', pConnected === 'up' ? 'chevron-up' : 'chevron-down']"
></fa-icon>

<spinner
    class="btn__spinner"
    *ngIf="pLoading"
    pSize="small"
    [pColor]="!!pVariant ? 'current' : ''"
></spinner>
