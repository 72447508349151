<button aria-label="Dismiss" class="dialog-close" [mat-dialog-close]></button>

<h3 class="mat-dialog-title">Assign Permissions</h3>

<div *ngIf="loading < 1" mat-dialog-content>
    <div>
        <p>
            Users with the admin permission will inherit all permissions, but
            will not recieve notifications for permissions that are not directly
            assigned to them.
        </p>
    </div>

    <div
        class="content-list permissions-list content-list__permissions"
        *ngIf="permissions.length > 0"
    >
        <div class="content-list__list">
            <div
                class="content-list__item content-list__item--simple
            content-list__link"
                (click)="selectPermissionClick(permission.id)"
                *ngFor="let permission of permissions"
            >
                <div class="row">
                    <div class="m-col-auto">
                        <div
                            class="content-list__check"
                            [ngClass]="{
                                'content-list__check--checked':
                                    selectedPermissionIds.indexOf(
                                        permission.id
                                    ) != -1
                            }"
                        ></div>
                    </div>
                    <h4 class="content-list__title m-col-2">
                        {{ permission.name }}
                    </h4>
                    <div
                        class="content-list__description
                    m-col"
                    >
                        {{ permission.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<loader *ngIf="loading > 0"></loader>

<div class="btns btns--horizontal" mat-dialog-actions>
    <button type="button" class="btn" mat-dialog-close>
        Cancel
    </button>

    <button
        type="button"
        class="btn btn--primary btn--right"
        [mat-dialog-close]="selectedPermissionIds"
        *ngIf="loading < 1"
    >
        Add Permission{{ selectedPermissionIds.length > 1 ? 's' : '' }}
    </button>
</div>
