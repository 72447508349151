<topbar>
    <h1 topbar-title>User Activities</h1>
</topbar>

<div class="container-content">
    <div class="content">
        <div class="content-section">
            <div class="wrapper wrapper--large">
                <div class="content-card">
                    <div class="content-list activities-list">
                        <div class="content-list-toolbar">
                            <ul
                                class="content-list-toolbar__list list-horizontal"
                            >
                                <li
                                    class="content-list-toolbar__item list--item__right"
                                >
                                    <!-- <div class="content-list-toolbar__input">
                                        <input type="search" name="search" id="search" [(ngModel)]="pagingData.filter" (input)="filterUpdate($event.target, 'filter')"/>
                                    </div> -->
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="loading < 1">
                            <div class="content-list__list">
                                <user-activities-list-item
                                    *ngFor="let activity of activities"
                                    [activity]="activity"
                                >
                                </user-activities-list-item>
                            </div>

                            <mat-paginator
                                [length]="totalItems"
                                [pageSize]="pagingData.perpage"
                                (page)="pageChange($event)"
                                [pageIndex]="pagingData.page - 1"
                            ></mat-paginator>

                            <div
                                class="content-list__no-results"
                                *ngIf="pages < 1 || activities.length < 1"
                            >
                                User has no activities to display.
                            </div>
                        </div>
                    </div>

                    <loader *ngIf="loading > 0"></loader>
                </div>
            </div>
        </div>
    </div>
</div>
